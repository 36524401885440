import permissions from "@/modules/auth/auth.types.ts";
import apiUsage from "@/views/examples/api/apiUsage";
import notificationUsage from "@/views/examples/notifications/notificationUsage";
import table from "@/views/examples/table/table";


export const examplesRoutes = [
  {
    path: '/examples/api',
    component: apiUsage,
    name: 'apiUsage',
    meta: {
      title: "Использование апи",
      middleware: [permissions.ANY_USER_MANAGE]
    },
  },
  {
    path: '/examples/notificationUsage',
    component: notificationUsage,
    name: 'notificationUsage',
    meta: {
      title: "Всплывающие уведомления",
      middleware: [permissions.ANY_USER_MANAGE]
    },
  },
  {
    path: "/examples/table",
    component: table,
    name: "simple-table",
    meta: {
      title: "Простая таблица",
      isHeaderBackLink: true,
      middleware: [permissions.SHOP_STAT_VIEW]
    },
  },

]
