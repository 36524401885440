import { ref, onUnmounted } from 'vue';

const useFitting = () => {
    let interval;
    const date = ref(new Date());
    const isFittingStarted = ref(false);

    const stopFitting = (callback) => {
        isFittingStarted.value = false;

        clearInterval(interval);

        interval = null;

        if (callback) {
            callback();
        }
    }

    const startFitting = (hours = 0, minutes = 1, callback) => {
        isFittingStarted.value = true;

        date.value.setHours(hours);
        date.value.setMinutes(minutes);
        date.value.setSeconds(0);
        date.value.setMilliseconds(0);

        const startDate = new Date();

        interval = setInterval((initedDate) => {
            const updatedDate = new Date();
            const elapsed = updatedDate - startDate;

            updatedDate.setTime(initedDate - elapsed);

            date.value = updatedDate;

            if (date.value.toLocaleTimeString() == '00:00:00') {
                stopFitting(callback);
            }
        }, 300, date.value);
    };

    onUnmounted(() => stopFitting());

    return {
        date,
        isFittingStarted,
        startFitting,
        stopFitting
    }
}

export { useFitting }