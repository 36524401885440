<template>
  <li class="sideBar-item" v-if="countLink !== 0">
    <template v-if="countLink === 1">
      <div v-for="(link, index) in item.links" :key="index">
        <router-link
            :to="link.link"
            class="sideBar-button"
            exact-active-class="sideBar-button-active"
            :class="`${item.icon}-link`"
            v-if="link.permission === true && link.target === undefined"
        >
          {{ link.title }}
        </router-link>

        <a v-bind:key="link.id" v-if="link.permission === true && link.target != null" :target="link.target" class="sideBar-button sidebar-link" :class="`${item.icon}-link`" :href="link.link">{{ link.title }}</a>
      </div>
    </template>
    <template v-if="countLink > 1">
      <div
        class="sideBar-button"
        :class="`${item.icon}-link`"
        v-if="item.links"
        @click="toogle"
        style="cursor: pointer; user-select: none; font-weight: 700"
      >
        {{ item.title }}
            <a
              id="dropdownAdresLink"
              v-if="item.links"
              type="button"
              class="btn dropdown-toggle customArrow"
              :class="{ show: opened }"
              :style="{rotate: opened ? '0deg' : '-90deg', transition: '0.3s'}"
              data-bs-toggle="dropdown"
            >
              <span class="visually-hidden"></span>
            </a>
      </div>

      <ul
        v-if="item.links"
        class="dropdown-menu dropdown-transition"
        :class="{ show: opened }"
        aria-labelledby="dropdownAdresLink"
      >
        <template v-for="link in item.links">
          <li
            v-if="link.permission"
            v-bind:key="link.id"
            class="dropdown-item"
          >
            <template v-if="!link.target">
              <router-link style="font-weight: 400" :to="{ path: link.link }">{{ link.title }}</router-link>
            </template>

            <template v-else>
                  <a :target="link.target" :href="link.link">
                    <Link style="width: 1em; height: 1em; margin-right: 8px"  />
                    {{ link.title }}
                  </a>
            </template>
          </li>
        </template>
      </ul>
    </template>
  </li>
</template>
<style scoped>
.dropdown-item > a{
  overflow: hidden;
  text-overflow: ellipsis;
}
.router-link-active {
  color: #fff;
}

.customArrow {
  border: none;
}
</style>
<script>
import {Link} from "@element-plus/icons-vue";
export default {
  name: "NavigationGroup",
  components: {Link},
  props: {
    item: Object
  },
  computed: {
    // Если у пользователя есть доступ хотя бы к одной ссылке категории, показываем категорию
    countLink() {
      let count = 0;

      this.item.links.map(link => {
        if (link.permission === true) {
          count += 1;
        }
      })

      return count;
    },
  },
  data() {
    return {
      opened: false,
    };
  },
  methods: {
    toogle() {
      if (this.opened) {
        this.opened = !this.opened
      } else {
        this.opened = !this.opened;
      }
    },
  },
};
</script>
