<template>
  <div>
    <Toolbar>
      <template v-slot:filter>
        <Search
            v-model:value="filters.search"
            :placeholder="titles.search"
            @search="getList({ page: 1 })"
        />
        <Select
            v-model:value="filters.status"
            :options="statusSelect"
            :placeholder="titles.status"
            @search="getList({ page: 1 })"
        />
        <DatePicker
            v-model:value="filters.publish_at"
            :placeholder="titles.publish_at"
            @search="getList({ page: 1 })"
        />
        <DatePicker
            v-model:value="filters.deleted_at"
            :placeholder="titles.deleted_at"
            @search="getList({ page: 1 })"
        />
      </template>
      <template v-slot:buttons>
        <Button modifyer="news-item">Добавить</Button>
      </template>
    </Toolbar>
    <Grid
        v-if="news.length > 0"
        :head="tableHead"
        :data="news"
        :cellClass="cellClass"
    />
    <div v-else-if="false" class="pt-3 pb-3">Загрузка...</div>
    <div v-else class="pt-3 pb-3">Нет информации</div>
  </div>
</template>

<script>
import Toolbar, {Search, Select} from "@/components/toolbar";
import Button from "@/components/base/Button";
import Grid from "@/components/grid";
import DatePicker from "@/components/toolbar/DatePicker";

export default {
  name: "News",
  components: {
    Toolbar,
    Search,
    Button,
    Grid,
    DatePicker,
    Select,
  },

  data() {
    return {
      filters: {
        search: "",
        status: -1,
        publish_at: "",
        deleted_at: "",
      },
      titles: {
        search: "Поиск по названию",
        status: "Статус",
        publish_at: "Дата публикации",
        deleted_at: "Дата удаления",
      },
      tableHead: {
        id: "ID",
        name: "Название",
        text: "Текст",
        status: "Статус",
        publish_at: "Время публикации",
        link: "Ссылка",
      },

      news: [
        {
          id: 1,
          name: "name",
          text: "text",
          status: "123",
          publish_at: "10.01.2021, 10:45:06",
          link: "test link"
        },
        {
          id: 2,
          name: "name",
          text: "text",
          status: "123",
          publish_at: "10.01.2021, 10:45:06",
          link: "test link"
        }
      ],

      statusSelect: [
        { value: 0, title: "Опубликована" },
        { value: 1, title: "Черновик" },
      ],

      editInstructionLoading: false,
    };
  },

  methods: {
    getList() {
      console.log('getList')
    },

    cellClass(name) {
      return `news-item news-item_${name}`;
    },
  }
};
</script>

<style lang="scss">
.news-item {
  width: 176px;

  &_publish_at {
    width: 174px;
  }
}
</style>
