<template>
  <el-form
    v-if="!advancedSearch"
    ref="formRef"
    novalidate
    @input="$emit('input', $event.target.value)"
    label-position="top"
    class="mt-4"
    @submit.prevent="submitForm"
  >
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item :required="true">
          <el-input type="text" v-model="form.identifier"
                    placeholder="Поиск по ID, Номеру или ШК" required/>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
  <el-form
    v-if="advancedSearch"
    novalidate
    @input="$emit('input', $event.target.value)"
    label-position="top"
    class="mt-4"
    @submit.prevent="$emit('submit', advancedForm)"
  >
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item label="Статус" v-if="!excludeFields.includes('status')">
          <el-select class="w-100" v-model="advancedForm.status" placeholder="Статус" clearable>
            <el-option
              v-for="key in Object.keys(statuses)"
              :key="key"
              :label="statuses[key]"
              :value="key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Создан от"  v-if="!excludeFields.includes('date_start')">
          <el-date-picker
            class="w-100"
            v-model="advancedForm.date_start"
            type="date"
            placeholder="Создан от"
          />
        </el-form-item>
        <el-form-item label="Создан до" v-if="!excludeFields.includes('date_end')">
          <el-date-picker
            class="w-100"
            v-model="advancedForm.date_end"
            type="date"
            placeholder="Создан до"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="ФИО" v-if="!excludeFields.includes('fio')">
          <el-input type="text" v-model="advancedForm.fio" :placeholder="'ФИО'" />
        </el-form-item>
        <el-form-item label="Телефон" v-if="!excludeFields.includes('phone')">
          <el-input type="text" v-model="advancedForm.phone" :placeholder="'Телефон'" />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Номер документа" v-if="!excludeFields.includes('document_number')">
          <el-input type="text" v-model="advancedForm.document_number" :placeholder="'Номер документа'"/>
        </el-form-item>
        <el-form-item label="Серия документа" v-if="!excludeFields.includes('document_series')">
          <el-input type="text" v-model="advancedForm.document_series" :placeholder="'Серия документа'"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="16" class="multi-accept__search-block">
      <el-col :offset="20" :span="4">
        <el-input
          type="submit"
          :loading="loading"
          @click.prevent="$emit('submit', advancedForm)"
          :model-value="'Поиск'"
        >
        </el-input>
      </el-col>
    </el-row>
  </el-form>
  <el-checkbox v-model="advancedSearch">
    Расширенный поиск
  </el-checkbox>
  <el-checkbox v-model="form.is_cainiao_code">
    Код цайняо
  </el-checkbox>
</template>

<script>
export default {
  name: "ShipmentsSearchForm",
  data() {
    return {
      advancedForm: {
        fio: null,
        phone: null,
        document_series: null,
        document_number: null,
        status: null,
        date_start: null,
        date_end: null,
        page: 1,
      },
      form: {
        identifier: null,
        is_cainiao_code: false,
      },
      advancedSearch: false,
    };
  },
  props: {
    statuses: {
      default: null,
      type: Object,
    },
    loading: {
      default: null,
      type: Boolean,
    },
    excludeFields: {
      default: [],
    },
    defaultValues: {
      default: {},
    },
  },
  emits: ["submit"],
  watch: {
    advancedSearch: {
      handler() {
        if (this.advancedSearch) {
          this.advancedFormToRoute();
        } else {
          this.formToRoute();
        }
      },
    },
    advancedForm: {
      handler() {
        this.advancedFormToRoute();
      },
      deep: true
    },
    form: {
      handler() {
        this.formToRoute();
      },
      deep: true
    },
  },
  mounted() {
    this.initAdvancedForm();
    this.initForm();

    if (this.$route.query.advancedSearch === 'true') {
      this.advancedSearch = true;
    }
  },
  methods: {
    advancedFormToRoute() {
      if (!this.advancedSearch) {
        return;
      }

      const query = Object.fromEntries(Object.entries(this.advancedForm).filter(item => {
        return item[1] !== null;
      }));

      query['advancedSearch'] = true;

      this.$router.replace({
        query: query
      })
    },
    formToRoute() {
      if (this.advancedSearch) {
        return;
      }

      const query = Object.fromEntries(Object.entries(this.form).filter(item => {
        return item[1] !== null;
      }));

      query['advancedSearch'] = false;

      this.$router.replace({
        query: query
      })
    },
    initAdvancedForm() {
      for (let key in this.advancedForm) {
        this.advancedForm[key] = this.defaultValues[key] ?? this.advancedForm[key];
      }

      for (let key in this.advancedForm) {
        this.advancedForm[key] = this.$route.query[key] ?? this.advancedForm[key];
      }

      this.advancedForm.date_end = 'date_end' in this.$route.query ? new Date(this.$route.query.date_end) : new Date();

      if ('date_start' in this.$route.query) {
        this.advancedForm.date_start = new Date(this.$route.query.date_start);
      } else {
        this.advancedForm.date_start = new Date(this.advancedForm.date_end - 604800000);
      }
    },
    initForm() {
      for (let key in this.form) {
        if (key === 'is_cainiao_code') {
          this.form.is_cainiao_code = this.$route.query[key] === 'true';
        } else {
          this.form[key] = this.$route.query[key] ?? this.form[key];
        }
      }
    },
    async submitForm() {
      const form = this.$refs.formRef.$el;

      if (!form.checkValidity()) {
        form.reportValidity();

        return;
      }

      this.$emit('submit', this.form);
    }
  }
};
</script>

<style scoped>

</style>
