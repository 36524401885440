<template>
  <form class="ModalForm form-send-mail" novalidate>
    <template v-if="!sendRequest">
      <div class="closeForgetForm" @click="closeForgetForm">
        <el-icon :color="'#a6a5a5'" :size="40">
          <component :is="CloseBold"/>
        </el-icon>
      </div>
      <label class="DescriptionLog">Введите почту</label>
      <Input v-model:value="email" placeholder="Почта" class="marginModal" type="text" required>
        <template v-slot:label>
          <label class="ModalExplanation">На данную почту будет выслан код, для восстановления пароля</label>
        </template>
      </Input>
      <input class="SendMail" type="submit" value="Отправить" @click="sendLinkForChangePassword">
    </template>

    <template v-if="sendRequest">
      <div style="text-align: center">
        <div class="closeForgetForm" @click="closeForgetForm">
          <el-icon :color="'#a6a5a5'" :size="40">
            <component :is="CloseBold"/>
          </el-icon>
        </div>

        <el-icon :color="'#4ab34d'" :size="40">
          <component :is="SuccessFilled"/>
        </el-icon>
        <p style="font-size: 20px; padding: 10px 0">Запрос на смену пароля отправлен!</p>
        <p style="font-size: 20px; padding: 10px 0">Вам придет сообщение на указанный email!</p>
      </div>
    </template>
  </form>
</template>

<script>
import Input from './Input.vue'
import {CloseBold} from '@element-plus/icons-vue'
import {SuccessFilled} from "@element-plus/icons-vue";

export default {
  name: 'ForgetForm',
  components: {
    Input,
  },
  data() {
    return {
      CloseBold,
      SuccessFilled,
      email: '',
      sendRequest: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user.form
    },
  },
  methods: {
    async sendLinkForChangePassword() {
      await this.$store.dispatch('user/sendLinkForChangePassword', this.email);
      this.sendRequest = true;
    },

    closeForgetForm() {
      this.$store.commit("user/CLOSE_RESET");
    }
  },
}
</script>

<style scoped>
  .closeForgetForm {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer
  }
</style>
