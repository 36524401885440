<template>
  <div v-if="isAllowedPlannedInventory" class="d-flex mt-4 mb-4 justify-content-end">
    <button
      class="edit-worker-modal-button-save"
      type="button"
      @click="toggleDialog"
    >
      Запланировать инвентаризацию
    </button>
  </div>
  <div class="mt-4" style="width: 1230px">
  <Grid
    ref="grid"
    :take="take"
    :skip="skip"
    :sortable="true"
    :sort="sort"
    :data-items="products"
    :columns="getColumns"
    :total="total"
    :pageable="true"
    :page-size="pageSize"
    @pagechange="pageChangeHandler"
  >

    <template v-slot:planned_date="{props}">
      <td>
        {{ formatDate(props.dataItem.planned_date) }}
      </td>
    </template>

    <template v-slot:action_admin="{props}">
      <td>
        <KButton
          v-if="props.dataItem.status === INVENTORY_STATUS_FINISHED"
          :theme-color="'warning'"
          style="color: #fff"
          @click="downloadExcelInventoryReport(props.dataItem.uuid)"
        >Отчет</KButton>
      </td>
    </template>

    <template v-slot:action="{props}">
      <td>
        <KButton
          v-if="props.dataItem.status === INVENTORY_STATUS_PLANNED && isAllowedStartInventory"
          :theme-color="'success'"
          @click="startInventory(props.dataItem.uuid)"
        >Старт</KButton>
        <KButton
          v-if="props.dataItem.status === INVENTORY_STATUS_FINISHED"
          :theme-color="'warning'"
          style="color: #fff"
          @click="downloadExcelInventoryReport(props.dataItem.uuid)"
        >Отчет</KButton>
      </td>
    </template>
  </Grid>
  </div>

  <Window v-if="windowVisible"
          :title="'Выбор ПВЗ для проведения инвентаризации'"
          :top="top"
          :width="windowWidth"
          :height="windowHeight"

          @close="toggleDialog"
  >
    <Checkbox
      @change="handleChange"
    >
      Выбрать все
    </Checkbox>

    <div class="mt-2">
      <PointSelect
        :model-value="this.selectedPointIds"
        :multiple="true"
        :closeOnSelect="false"
        @change="onChangePointId($event)">
      </PointSelect>
    </div>

    <div class="d-flex mt-4 justify-content-end">
      <el-date-picker
        v-model="planned_date"
        type="date"
        placeholder="Дата инвентаризации"
        format="DD.MM.YYYY"
        value-format="YYYY-MM-DD"
      />
    </div>

    <div class="d-flex mt-4 justify-content-end">
      <button
        :disabled="isDisabledCreateInventory"
        class="edit-worker-modal-button-save"
        type="button"
        @click="createInventory"
      >
        Провести инвентаризацию
      </button>
    </div>
  </Window>
</template>

<script>
import { Grid } from '@progress/kendo-vue-grid';
import '@progress/kendo-theme-default/dist/all.css';
import { Button } from '@progress/kendo-vue-buttons';
import { Window } from '@progress/kendo-vue-dialogs';
import { Checkbox } from "@progress/kendo-vue-inputs";
import PointSelect from "@/components/selectors/pointSelect/PointSelect";
import accessGuard from "@/router/middleware/accessGuard.middleware";
import permissions from "@/modules/auth/auth.types.ts";
import {INVENTORY_STATUS_FINISHED, INVENTORY_STATUS_PLANNED} from "@/constants";
import {ElMessageBox, ElNotification} from "element-plus";
import {downloadFile, getFileNameWithExtensionFromHeaders} from "@/store/helper";


export default {
  name: "Inventory",
  components: {
    Grid,
    KButton: Button,
    Window,
    PointSelect,
    Checkbox,
  },
  created() {
    this.getInventories();
  },
  data: function () {
    return {
      INVENTORY_STATUS_PLANNED: INVENTORY_STATUS_PLANNED,
      INVENTORY_STATUS_FINISHED: INVENTORY_STATUS_FINISHED,
      isViewPlannedInventory: false,
      windowVisible: false,
      windowWidth: 700,
      windowHeight: 700,
      skip: 0,
      planned_date: null,
      take: 10,
      pageSize: 10,
      sort: null,
      filter: null,
      total: null,
      selectedPointIds: [],
      products: null,
      isDisabledCreateInventory: false
    }
  },
  computed: {
    getColumns() {
      if (accessGuard.checkScope(this.$store.state.user.user, permissions.OWN_INVENTORY_ADD) || accessGuard.checkScope(this.$store.state.user.user, permissions.ANY_INVENTORY_ADD)) {
        const columns = [
          {
            field: 'planned_date',
            title: 'Дата',
            width: '100px',
            cell: 'planned_date'
          },
          {
            field: 'point.name',
            title: 'ПВЗ',
            width: '150px',
          },
          {
            field: 'user.full_name',
            title: 'Инициатор',
            width: '150px',
          },
          {
            field: 'count_scanned_shipments',
            title: 'Отсканировано',
            width: '140px',
          },
          {
            field: 'count_shortage_shipments',
            title: 'Недостач',
            width: '100px',
          },
          {
            field: 'count_surplus_shipments',
            title: 'Излишков',
            width: '100px',
          },
          {
            field: 'status_name',
            title: 'Статус',
            width: '140px',
          },
          {
            field: 'started_at',
            title: 'Начало',
            width: '110px',
          },
          {
            field: 'cancelled_at',
            title: 'Отмена',
            width: '110px'
          },
          {
            cell: 'action_admin',
            title: 'Действие',
          },
        ];

        return columns;
      }

      if (accessGuard.checkScope(this.$store.state.user.user, permissions.OWN_INVENTORY_MANAGE)) {
        const columns = [
          {
            field: 'planned_date',
            title: 'Дата',
            width: '100px',
            cell: 'planned_date'
          },
          {
            field: 'user.full_name',
            title: 'Инициатор',
            width: '200px',
          },
          {
            field: 'count_shipments',
            title: 'Кол-во посылок',
            width: '150px',
          },
          {
            field: 'count_scanned_shipments',
            title: 'Отсканировано',
            width: '140px',
          },
          {
            field: 'count_shortage_shipments',
            title: 'Недостач',
            width: '100px',
          },
          {
            field: 'count_surplus_shipments',
            title: 'Излишков',
            width: '100px',
          },
          {
            field: 'status_name',
            title: 'Статус',
            width: '160px',
          },
          {
            cell: 'action',
            title: 'Действие',
          }
        ];

        return columns;
      }

      return null;
    },
    isAllowedStartInventory() {
      if (accessGuard.checkScope(this.$store.state.user.user, permissions.OWN_INVENTORY_MANAGE)) {
        return true;
      }

      return false;
    },

    isAllowedPlannedInventory() {
      if (
        accessGuard.checkScope(this.$store.state.user.user, permissions.OWN_INVENTORY_ADD)
        || accessGuard.checkScope(this.$store.state.user.user, permissions.ANY_INVENTORY_ADD)
      ) {
        return true;
      }

      return false;
    },

    selectAllPoints() {
      return this.$store.state.points.list.map(x => x.id);
    }
  },
  methods: {
    getInventories() {
      this.$store.dispatch('apiV2InventoryStore/index', {
        take: this.take,
        skip: this.skip,
      }).then((result) => {
        console.log(result);
        this.products = result.inventories;
        this.total = result.total;
      });
    },
    createInventory() {
      this.isDisabledCreateInventory = true;

      let query = {};

      query.point_ids = this.selectedPointIds;
      query.planned_date = this.planned_date;

      this.$store.dispatch('apiV2InventoryStore/create', query).then(() => {
        this.getInventories();

        ElNotification({
          title: 'Успешно',
          message: 'Инвентаризация успешно создана',
          type: 'success',
        });

        this.isDisabledCreateInventory = false;
      });
    },
    pageChangeHandler(event) {
      console.log(event);

      this.take = event.page.take;
      this.skip = event.page.skip;

      this.getInventories();
    },
    toggleDialog () {
      this.windowVisible = !this.windowVisible;
    },
    onChangePointId(event) {
      this.selectedPointIds = event;
    },
    handleChange(e) {
      if (e.value) {
        if (this.$store.state.user.user.permissions.includes(permissions.VIEW_ANY_POINTS_IN_SELECTOR)) {
          this.selectedPointIds = this.selectAllPoints;
        }

        if (this.$store.state.user.user.permissions.includes(permissions.VIEW_OWN_POINTS_IN_SELECTOR)) {
          this.$store.dispatch("partners/readItem", this.$store.state.user.user.partner_id).then((result) => {
            this.selectedPointIds = result.data.active_points.map(x => x.id);
          });
        }
      } else {
        this.selectedPointIds = [];
      }
    },
    formatDate(date) {
      return date.split('-').reverse().join('.');
    },
    async startInventory(inventoryUuid) {
      await ElMessageBox.confirm(
        'Вы уверены что хотите запустить инвентаризацию?',
        {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          cancelButtonClass: 'el-button--info'
        },
      )

      this.$store.dispatch('apiV2InventoryStore/startInventory', inventoryUuid).then(() => {
        this.getInventories();
      });
    },
    downloadExcelInventoryReport(inventoryId) {
      this.$store.dispatch('apiV2DocumentStore/downloadInventoryReport', inventoryId).then(response => {
        const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        downloadFile(url, fileNameWithExtension)
      });
    },
  },
};
</script>

<style scoped>
::v-deep(.el-picker__popper) {
  padding: 2px 16px;
}

::v-deep(.k-window ) {
  z-index: 1000;
}
</style>
