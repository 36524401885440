<template>
  <div class="container-search d-flex justify-content-between">
      <el-date-picker
        class="input--date-range"
        v-model="dateRange"
        type="daterange"
        start-placeholder="Дата от"
        end-placeholder="Дата до"
        @change="mapMultipleValues"
      />
      <template v-if="role != 6">
        <BaseSelect
          v-model="multipleStatus"
          :options="statusOptions"
          multiple
          placeholder="Статус"
          @search="mapMultipleValues"
        />

        <BaseSelect
          v-model="filters.from"
          :options="pointSelect"
          placeholder="ПВЗ отправления"
          @search="mapMultipleValues"
        />

        <BaseSelect
          v-model="filters.to"
          :options="pointSelect"
          placeholder="ПВЗ назначения"
          @search="mapMultipleValues"
        />
      </template>
  </div>
  <div class="show-sended-container d-flex align-items-center justify-content-between">
    <span></span>
    <a class="el-button el-button--success el-button--default text-decoration-none" :href="link || exportLink" download>Экспорт Excel</a>
  </div>

  <ShipmentsTable v-if="shipments.length > 0" :shipments="shipments" />
  <div v-else-if="getListLoading" class="pt-3 pb-3">Загрузка...</div>
  <div v-else class="pt-3 pb-3">Нет информации</div>

  <Pages
    v-if="shipments.length"
    :totalPages="totalPages"
    :currentPage="filters.page"
    :last="totalPages"
    :total="total"
    :perPage="perPage"
    @change="switchPage"
  />
</template>


<style>
.input--date-range{
  height: 42px;
  box-shadow: 2px 2px 12px rgb(42 48 66 / 11%);
}
.toolbar-select{
  margin: 0;
}
</style>

<script>

import Pages from '@/components/Pages';
import ShipmentsTable from '@/components/give/ShipmentsTable';
import {mapActions, mapGetters, mapState} from 'vuex';
import BaseSelect from '@/components/toolbar/BaseSelect';

const baseURL = process.env.VUE_APP_API_ROOT;

export default {
  name: "Report",
  components: {
    BaseSelect,
    Pages,
    ShipmentsTable,
  },

  data() {
    return {
      multipleStatus: [],
      dateRange: [],
      link: '',
    }
  },

  computed: {
    role() {
      return this.$store.state.user.user?.role_id;
    },
    exportLink() {
      const token = window.localStorage.getItem("auth") || window.sessionStorage.getItem("auth")
      return baseURL + (this.role != 6  ? '/shipment_export' : '/franchisee_export') + `?token=${token}`;
    },
    ...mapState({
      shipments: state => state.shipments.list,
      totalPages: state => state.shipments.pagination.totalPages,
      total: state => state.shipments.pagination.totalItems,
    }),
    perPage() {
      return this.$store.state.shipments.pagination.perPage;
    },
    ...mapGetters({
      statusOptions: 'shipments/statusOptions',
    }),

    pointSelect() {
      return this.$store.state.points.list.map((item) => {
        return { value: item.id, title: item.name };
      });
    },
  },

  created() {
    this.getShipments();

    if (!this.pointSelect.length) {
      this.$store.dispatch("points/getList")
    }
  },

  methods: {
    mapMultipleValues() {
      if (this.dateRange.length) {
        this.filters.date_from = this.dateRange[0].toLocaleDateString('fr-CA');
        this.filters.date_to = this.dateRange[1].toLocaleDateString('fr-CA');
      } else {
        this.filters.date_from = '';
        this.filters.date_to = '';
      }

      if (this.multipleStatus.length) {
        this.filters.status = JSON.stringify(this.multipleStatus);
      } else {
        this.filters.status = '';
      }

      this.getShipments({ page: 1 });
      this.generateExportLink();
    },
    generateExportLink() {
      const dateFrom = this.filters.date_from ? `date_from=${this.filters.date_from}` : '';
      const dateTo = this.filters.date_to ? `date_to=${this.filters.date_to}` : '';
      const status = this.filters.status ? `status=${this.filters.status}` : '';
      const from = this.filters.from ? `point_src=[${this.filters.from}]` : '';
      const to = this.filters.to ? `point_dst=[${this.filters.to}]` : '';

      this.link = this.exportLink + '&' + [dateFrom, dateTo, status, from, to].filter(i => i).join('&');
    },

    ...mapActions({
      getList: 'shipments/getList',
    }),

    async getShipments(opts) {
      this.filters = { ...this.filters, ...opts };
      this.getListLoading = true;

      if (this.$store.state.user.userLoadedPromise) {
        await this.$store.state.user.userLoadedPromise;
      }

      if (this.role == 6) {
        this.filters.franchisee = true;
      }

      await this.getList(this.filters);

      this.getListLoading = false;
    },

    cellClass(name) {
      return `table-items-transport table-${name}-width`;
    },

    async switchPage(page) {
      this.filters.page = page;
      await this.getShipments();
    },
  },
};
</script>
