import apiService from "@/api/V2/services/api.service";

const getReturnActShipments = (data) => {
  return apiService.post(`/v2/return-acts/getReturnActShipments`, {returnActId: data})
};

const getReturnActs = (take, skip, shopId, point, status) => {
  return apiService.post('/v2/return-acts/getReturnActs', {
    take: take,
    skip: skip,
    shop: shopId,
    point: point,
    status: status
  })
};

const getReturnActStatuses = () => {
  return apiService.post(`/v2/return-acts/getReturnActStatuses`);
};
const issueReturnToCourier = (courier, barcode, shopId) => {
  return apiService.post(`/v2/return-acts/issueReturnToCourier`, {
    courier: courier,
    barcode: barcode,
    shopId: shopId
  });
};
const scanReturn = (courier, barcode, shopId) => {
  return apiService.post(`/v2/return-acts/scanReturn`, {
    barcode: barcode,
    shopId: shopId,
    courier: courier
  });
};

const downloadReturnAct = (barcode, courier) => {
  return apiService.postBlob(`/v2/documents/downloadReturnAct/`,{
    barcode: barcode,
    courier: courier
  });
};

const downloadCainiaoReturnLabel = (barcode) => {
  return apiService.postBlob(`/v2/documents/downloadCainiaoReturnLabel/`,{
    barcode: barcode,
  });
};
const downloadCainiaoReturnDocument = (barcode, courier) => {
  return apiService.postBlob(`/v2/documents/downloadCainiaoReturnDocument/`,{
    barcode: barcode,
    courier: courier
  });
};

const getShopsWithReturnShipmentsStatusReady = () => {
  return apiService.get(`/v2/shops/getShopsWithReturnShipmentsStatusReadyForShipmentOnPointUser`);
}

const getShopsSelfPickupForReturns = () => {
  return apiService.get(`/v2/shops/getShopsSelfCollectsReturnsFromPoint`);
}
export {
  getReturnActShipments,
  getReturnActs,
  getReturnActStatuses,
  issueReturnToCourier,
  scanReturn,
  getShopsWithReturnShipmentsStatusReady,
  downloadReturnAct,
  getShopsSelfPickupForReturns,
  downloadCainiaoReturnLabel,
  downloadCainiaoReturnDocument
}
