<template>
  <el-dialog
    :model-value="modelValue"
    title="Размещение отправления"
    width="30%"
    :lock-scroll="true"
    :close-on-click-modal="false"
    :show-close="false"
    append-to-body
    @close="onClose"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <el-form ref="formRef" :model="form">
      <el-form-item label="Ячейка">
        <el-select v-model="form.ceil" placeholder="Выбрать">
          <el-option
            v-for="item in ceilList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-button type="primary" @click="onPlacement">
        Разместить
      </el-button>
    </el-form>
  </el-dialog>
</template>

<script>
import {ElDialog} from 'element-plus';
import {mapActions} from "vuex";

export default {
  name: "DeparturePlacement",

  components: { ElDialog },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    shipmentId: {
      type: Number,
      required: true,
    },
    freightId: {
      type: Number,
    },
    initialPlace: {
      type: Number
    }
  },

  data: () => ({
    form: {
      ceil: null,
    }
  }),
  computed: {
    ceilList() {
      return this.$store.state.ceils.list;
    }
  },
  async created() {
    await this.$store.dispatch('ceils/getList');
  },
  watch: {
    initialPlace(value) {
      if (this.initialPlace !== null) {
        this.form.ceil = value;
        this.onPlacement();
      }
    }
  },
  methods: {
    ...mapActions({
      placedShipmentFromFreight: 'shipments/placedShipmentFromFreight',
      placed: 'shipments/placed'
    }),

    async onPlacement() {
      try {
        if (this.freightId) {
          const data = {
            ceil_id: this.form.ceil,
            freight_id: this.freightId,
          }

          await this.placedShipmentFromFreight({
            id: this.shipmentId,
            data,
          })
        } else {
          const data = {
            ceil_id: this.form.ceil
          }

          await this.placed({
            id: this.shipmentId,
            data,
          })
        }

        this.$emit('place', true)
      } catch (e) {
        this.$emit('place', false, e.message)
      } finally {
        this.$emit('update:modelValue', false)
      }
    },

    onClose() {
      this.form.ceil = null
    }
  }
}
</script>

<style scoped lang="scss">
.receiving-with-scanner {
  &__content {
    display: flex;
    flex-direction: column;

    &-item {
      margin-bottom: 5px;
    }
  }
}
</style>
