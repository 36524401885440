<template>
    <!-- <h2>Помощь</h2> -->
    <h2 class="pt-4">У вас нет прав доступа к данному разделу</h2>
</template>

<script>
    export default {
        name: 'Help',
        components: {
        }
    }
</script>
