<template>
  <div class="container-fluid mt-4">
    <h3 class="h3__title">{{ headerTitle }}</h3>
    <ShipmentsSearchForm
      :loading="loading"
      :exclude-fields="['status']"
      @submit="submitSearch"
    />
    <ShipmentsSearchTable
      :shipments="shipments"
      :loading="loading"
      :row-route-name="'give-item'"
    />
    <SimplePagination
      v-if="shipments.length"
      v-model:page="searchMeta.page"
      @next="next"
      @prev="prev"
      :show-page="false"
      :is-last-page="searchMeta.isLastPage"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ShipmentsSearchTable from "@/components/shipments/ShipmentsSearchTable.vue";
import SimplePagination from "@/components/SimplePagination.vue";
import ShipmentsSearchForm from "@/components/shipments/ShipmentsSearchForm.vue";

export default {
  name: "Give",
  components: {ShipmentsSearchForm, SimplePagination, ShipmentsSearchTable},
  data() {
    return {
      loading: false,
      headerTitle: 'Выдача отправлений',
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'shipments/statuses',
      shipments: 'apiV2ShipmentStore/search',
      searchMeta: 'apiV2ShipmentStore/searchMeta',
    }),
  },
  mounted() {
    this.$store.commit("SET_TITLE", this.headerTitle);
    this.clearSearch();
  },
  methods: {
    ...mapActions({
      shipmentsSearch: 'apiV2ShipmentStore/readyToPickUp',
      clearSearch: 'apiV2ShipmentStore/clearSearch',
    }),
    submitSearch(form) {
      this.form = form;
      this.form.page = 1;
      this.search();
    },
    async search() {
      this.loading = true;
      await this.shipmentsSearch(this.form).finally(() => {
        this.loading = false;
      });
    },
    next() {
      this.form.page++;
      this.search();
    },
    prev() {
      this.form.page--;
      this.search();
    }
  },
};
</script>
