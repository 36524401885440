<template>
  <div class="d-flex mt-4 mb-4 justify-content-end">
    <div class="me-5">
      <ShopSelect :type-shops="2" @change="onChangeShopId($event)"></ShopSelect>
    </div>

    <button
      class="edit-worker-modal-button-save"
      type="button"
      :disabled="shopId == null"
      @click="createReturnAct"
    >
      Создать возврат
    </button>
  </div>

  <Grid
    ref="grid"
    :take="take"
    :skip="skip"
    :sortable="true"
    :sort="sort"
    :data-items="returnActs"
    :columns="getColumns"
    :total="total"
    :pageable="true"
    :page-size="pageSize"
    @pagechange="pageChangeHandler"
  >
    <template v-slot:date="{props}">
      <td>
        {{ formatDate(props.dataItem.date) }}
      </td>
    </template>

    <template v-slot:number="{props}">
      <td>
        <router-link :to="{
          name: 'ReturnActAssembling',
          params: {
            id: props.dataItem.id
          }
        }">
          <span class="!k-color-info">{{ props.dataItem.number }}</span>
        </router-link>
      </td>
    </template>
  </Grid>
</template>

<script>

import { Grid } from '@progress/kendo-vue-grid';
import ShopSelect from "@/components/selectors/shopSelect/ShopSelect";

export default {
  name: "ReturnActCreate",
  components: {
    ShopSelect,
    Grid,
  },
  created() {
    this.getReturnActs();
  },
  data() {
    return {
      windowWidth: 700,
      windowHeight: 700,
      skip: 0,
      take: 10,
      pageSize: 10,
      sort: null,
      total: null,
      returnActs: [],
      shopId: null
    }
  },
  computed: {
    getColumns() {
      return [
        {
          field: 'date',
          title: 'Дата',
          cell: 'date'
        },
        {
          field: 'shop.name',
          title: 'Клиент',
        },
        {
          field: 'number',
          cell: 'number',
          title: 'Номер',
        },
        {
          field: 'count_shipments_added_in_act',
          title: 'Кол-во собр. мест',
        },
        {
          field: 'count_remaining_shipments_in_assembly',
          title: 'Кол-во в сборке',
        },
      ]
    }
  },
  methods: {
    createReturnAct() {
      this.$store.dispatch('apiV2ReturnActStore/viewOrCreate', {
        'shop_id': this.shopId
      }).then((result) => {
        this.$router.push({
          name: "ReturnActAssembling",
          params: {
            id: result.id
          }
        });
      });
    },
    getReturnActs() {
      this.$store.dispatch('apiV2ReturnActStore/index', {
        take: this.take,
        skip: this.skip,
      }).then((result) => {
        console.log(result);
        this.returnActs = result.data;
        this.total = result.total;
      });
    },
    pageChangeHandler(event) {
      this.take = event.page.take;
      this.skip = event.page.skip;

      this.getReturnActs();
    },
    onChangeShopId(event) {
      this.shopId = event.length > 0 ? event[0] : null;
    },
    formatDate(date) {
      return date.split('-').reverse().join('.');
    },
  }
}
</script>

<style scoped>

</style>
