<template>
  <VueSelect
      class="vue-select__xmail-style"
      :closeOnSelect="xcloseOnSelect"
      :clearSearchOnSelect="xclearSearchOfnSelect"
      :autoscroll="xautoscroll"
      :deselectFromDropdown="xdeselectFromDropdown"
      :loading="isLoading"
      :multiple="multiple"
      :placeholder="placeholder"
      :options="options"
      :label="label"
      :reduce="reduce"
      v-model="selectedIds"
      @update:modelValue="onChange()"
      :searchable="xsearchable"
      :autocomplete="xautocomplete"
  >
    <template #no-options>
      <span>К сожалению, совпадений не найдено</span>
    </template>
  </VueSelect>
</template>

<script>
import VueSelect from "vue-select";

export default {
  name: "PointSelect",
  components: {
    VueSelect
  },
  async created() {
    this.isLoading = true;

    this.selectedIds = this.modelValue;
    await this.getItems();

    this.isLoading = false;
  },
  data() {
    return {
      xclearSearchOfnSelect: false,
      xcloseOnSelect: true,
      xautoscroll: true,
      xdeselectFromDropdown: true,
      xsearchable: true,
      xautocomplete: false,

      placeholder: 'Название селектора',
      isLoading: false,
      label: "label",
      value: [],
      reduce: item => item.value,
      options: [],
      selectedIds: null
    }
  },
  computed: {
    clearSearchOnSelect: {
      get() {
        return this.xclearSearchOfnSelect;
      },

      set(value) {
        this.xclearSearchOfnSelect = value;
      }
    },

    closeOnSelect: {
      get() {
        return this.xcloseOnSelect;
      },

      set(value) {
        this.xcloseOnSelect = value;
      }
    },

    autoscroll: {
      get() {
        return this.xautoscroll;
      },

      set(value) {
        this.xautoscroll = value;
      }
    },

    deselectFromDropdown: {
      get() {
        return this.xdeselectFromDropdown;
      },

      set(value) {
        this.xdeselectFromDropdown = value;
      }
    },

    searchable: {
      get() {
        return this.xsearchable;
      },

      set(value) {
        this.xsearchable = value;
      }
    },

    autocomplete: {
      get() {
        return this.xautocomplete;
      },

      set(value) {
        this.xautocomplete = value;
      }
    },
  },
  emits: ['change'],
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Array, null],
      default: null,
    },
    partnerId: {
      type: [String, null],
      default: null
    },
  },
  methods: {
    onChange() {
      let value = [];
      if (this.multiple) {
        value = Object.assign([], this.selectedIds);
      } else {
        value = parseInt(this.selectedIds);
        value = isNaN(value) ? [] : [value];
      }

      this.$emit('change', value);
    },
    async getItems() {
      throw new Error('implement getItems');
    }
  },
  watch: {
    modelValue: function(newValue) {
      this.selectedIds = newValue;
    },

    partnerId: function() {
      this.getItems();
    },
  },
}
</script>
