<template>
  <div
    class="block-shadow accordion accordion-flush shipment-acordion-container" style="width:100%;margin-bottom:20px"
  >
    <div class="accordion-item">
      <h2 class="accordion-header" id="shipmentAccordionTitle">
        <button
          class="custom-accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#shipmentInfoCollapse"
          aria-expanded="false"
          aria-controls="shipmentInfoCollapse"
          @click.prevent="toggleGoods"
        >
          Товары из отправления № {{ shipment.number }} / ШК {{ shipment.barcode }}
        </button>
      </h2>
      <div
        v-if="isGoodsShow"
        id="shipmentInfoCollapse"
        class="accordion-collapse collapse show"
        aria-labelledby="shipmentAccordionTitle"
        data-bs-parent="#shipmentFlush"
        style="margin-top: 20px;"
      >
  <Grid
    v-if="shipment"
    ref="grid"
    :sortable="false"
    :data-items="goods"
    :columns="columns"
    :selected-field="selectedField"
    :pageable="false"
  >
    <template v-slot:sumprice="{ props }">
      <td>
        {{ props.dataItem.sumprice ? props.dataItem.sumprice : props.dataItem.price * props.dataItem.content_quantity }}
      </td>
    </template>
    <template v-slot:content_name="{props}">
      <td colspan="1">
        {{ props.dataItem.content_name }}
        <span class="ean" v-if="props.dataItem.ean" @click="showEan(props)">[E]</span>
        <span class="itemmark" v-if="props.dataItem?.good_option?.mark" @click="showMark(props)">[M]</span>
      </td>
    </template>
    <template v-slot:cancelCell="{ props }">
      <td colspan="1">
        <input v-if="props.dataItem.selectable" type="checkbox" @click="onSelectionChange(props)"/>
      </td>
    </template>

  </Grid>
  <div>
    <p class="sum">Наложенный платеж: {{ this.shipment.is_np ? 'да': 'нет'}}</p>
    <p class="payed" v-if="this.shipment && this.shipment.is_np">Оплачено: {{ this.shipment.payed_sum ?? 0}} ₽</p>
  </div>
  <k-dialog v-if="isDialogVisible" :title="dialogTitle" @close="toggleDialog">
    <p :style="{ margin: '25px', textAlign: 'center' }">{{ dialogText }}</p>
  </k-dialog>
      </div></div></div>
</template>

<style>
.deliveryItemsSent-sum-product-quantity-text-container .el-checkbox__label {
  display: none;
}
</style>

<script>
import {Grid} from '@progress/kendo-vue-grid';
import {Dialog} from '@progress/kendo-vue-dialogs';
import '@progress/kendo-theme-default/dist/all.css';
import {GOOD_STATUS_REFUSED} from "@/constants";

export default {
  name: "ShipmentShowGoods",
  components: {
    Grid,
    'k-dialog': Dialog,
  },
  props:
    ['shipment']
  ,
  watch: {
    shipment: {
      handler() {
        this.init()
      },
      deep: true
    }
  },
  data() {
    return {
      isGoodsShow: true,
      selectedField: 'cancelled',
      isDialogVisible: false,
      dialogText: '',
      dialogTitle: 'Подробней',
      sumPrice: null,
      goods: [],
      columns: [
        {
          title: "#",
          field: 'index',
          width: "60px"
        },

        {
          field: 'vendor_code',
          title: 'Артикул',
          width: '120px',
        },
        {
          cell: 'content_name',
          title: 'Наименование',
        },
        {
          field: 'content_quantity',
          title: 'Кол-во',
          width: '80px',
        },
        {
          field: 'price',
          title: 'Цена',
          width: '120px',
        },
        {
          field: 'declared_cost',
          title: 'Объяв. стоим.',
          width: '140px',
        },
        {
          cell: 'sumprice',
          title: 'Сумма',
          width: '120px',
        },

      ]

    };
  },
  mounted() {
    console.log('on mount')
    this.init()
  },
  methods: {

    init() {

      var rowIndex = 0;

      this.goods = this.shipment.items.map(item => {
        return {...item, cancelled: item.good_status == GOOD_STATUS_REFUSED, selectable: false, index: ++rowIndex}
      })
      this.goods.push({
        content_name: 'Доставка',
        vendor_code: '-',

        content_quantity: 1,
        price: this.shipment.delivery_price ?? 0,
        index: ++rowIndex,
        selectable: false
      })
      this.sumPrice = this.calcPrice()
      this.goods.push({
        vendor_code: '',
        content_name: 'К оплате',
        content_quantity: '',
        price: '',
        index: '',
        sumprice: this.sumPrice,
        selectable: false
      })
    },
    calcPrice() {
      if (!this.shipment.is_np) {
        return 0;
      }
      return  this.goods.reduce((all, curr) => {
        return all + ((curr.good_status ==  GOOD_STATUS_REFUSED) ? 0 : curr.price * curr.content_quantity);
      }, 0);
    },
    toggleDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    showMark(props) {
      this.dialogTitle = 'Маркировка';
      this.dialogText = props.dataItem?.good_option?.mark ?? '-'
      this.isDialogVisible = true
    },
    showEan(props) {
      this.dialogTitle = 'Штрихкод';
      this.dialogText = props.dataItem.ean
      this.isDialogVisible = true
    },
    toggleGoods() {
      this.isGoodsShow = !this.isGoodsShow;
    },
  },
}
</script>
<style>
.itemmark {
  color: green;
  font-weight: bold;
  cursor: pointer;
}

.ean {
  color: rgb(85, 110, 230);
  font-weight: bold;
  cursor: pointer;
}
.sum {
  padding-top: 20px;
  font-size:90%;
}
.payed {
  padding-top: 5px;
  font-size:90%;
}
</style>
