<template>
  <div class="container-fluid mt-4">
    <ShipmentsSearchForm
      :loading="loading"
      :statuses="statuses"
      @submit="submitSearch"
    />
    <ShipmentsSearchTable
      :shipments="shipments"
      :loading="loading"
      :row-route-name="'shipmentsDetail'"
    />
    <SimplePagination
      v-if="shipments.length"
      v-model:page="searchMeta.page"
      @next="next"
      @prev="prev"
      :show-page="false"
      :is-last-page="searchMeta.isLastPage"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ShipmentsSearchTable from "@/components/shipments/ShipmentsSearchTable.vue";
import SimplePagination from "@/components/SimplePagination.vue";
import ShipmentsSearchForm from "@/components/shipments/ShipmentsSearchForm.vue";
import {
  FRANCHISE_SHIPMENT_STATUSES,
  SHIPMENT_STATUS_VALUES,
  USER_ROLE_ADMIN,
  USER_ROLE_SUPER,
} from "@/constants";

export default {
  name: "ShipmentsSearch",
  components: {ShipmentsSearchForm, SimplePagination, ShipmentsSearchTable},
  data() {
    return {
      loading: false,
      headerTitle: 'Поиск отправлений',
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'shipments/statuses',
      shipments: 'apiV2ShipmentStore/search',
      searchMeta: 'apiV2ShipmentStore/searchMeta',
      roleId: 'user/role_id',
    }),
    isAdmin() {
      return [USER_ROLE_ADMIN, USER_ROLE_SUPER].includes(this.roleId);
    },
    statuses() {
      if (this.isAdmin) {
        return SHIPMENT_STATUS_VALUES;
      }

      return FRANCHISE_SHIPMENT_STATUSES;
    },
  },
  mounted() {
    this.$store.commit("SET_TITLE", this.headerTitle);
    this.clearSearch();
  },
  methods: {
    ...mapActions({
      shipmentsSearch: 'apiV2ShipmentStore/search',
      shipmentsFullSearch: 'apiV2ShipmentStore/fullSearch',
      clearSearch: 'apiV2ShipmentStore/clearSearch',
    }),
    submitSearch(form) {
      this.form = form;
      this.form.page = 1;
      this.search();
    },
    async search() {
      this.loading = true;

      if (this.isAdmin) {
        await this.shipmentsFullSearch(this.form).finally(() => {
          this.loading = false;
        });

        return;
      }

      await this.shipmentsSearch(this.form).finally(() => {
        this.loading = false;
      });
    },
    next() {
      this.form.page++;
      this.search();
    },
    prev() {
      this.form.page--;
      this.search();
    }
  },
};
</script>
