import Tariffs from "./Tariffs.vue";

export const tariffsRoutes = [
  {
    path: "/tariffs",
    component: Tariffs,
    name: "tariffs",
    meta: { title: "Тарифы" },
  },
];
