import Folder from "./Folder.vue";

export const folderRoutes = [
  {
    path: "/folder",
    component: Folder,
    name: "folder",
    meta: { title: "Адресное хранение" },
  },
];
