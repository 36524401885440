<template>
  <component :is="component" />
</template>

<script>
import AdminFreights from "./admin/AdminFreights.vue";
import FranchiseeFreights from "./franchisee/FranchiseeFreights.vue";

export default {
  name: "Freights",
  components: {
    AdminFreights,
    FranchiseeFreights,
  },
  computed: {
    component() {
      let component;

      switch (this.$store.getters["user/role_id"]) {
        case 0:
        case 1:
          component = AdminFreights;
          break;
        case 6:
          component = FranchiseeFreights;
          break;
        case 9:
          component = FranchiseeFreights;
          break;
        default:
          component = "span";
      }
      return component;
    },
  },
};
</script>
