import store from '@/store/index.js';
import {ElNotification} from "element-plus";

export const helpdeskRoutes = [
  {
    path: "/helpdesk",
    name: "Helpdesk",
    beforeEnter: (to, from, next) => {
      store.dispatch('apiV2HelpdeskStore/getUrl').then(response => {
        window.location.href = response.redirect;
      }, error => {
        ElNotification({
          title: 'Ошибка',
          message: error.message,
          type: 'error',
        });
      });

      next(from);
    },
  },
];
