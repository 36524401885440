import Notifications from './Notifications.vue'


export const notificationRoutes = [
    {
        path: '/notifications',
        component: Notifications,
        name: 'notifications',
        meta: { title: "Уведомления" },
    }
]