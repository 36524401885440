<template>
  <div class="storage-table">
    <Row :cells="head" is-header :cellClass="cellClass" />
    <Row
      v-for="(row, index) in data"
      v-bind:key="index"
      :cells="row"
      :cellClass="cellClass"
      @click="onClick(row, $event)"
      :cursor="cursor"
      @onEdit="$emit('onEdit', row)"
      @onDelete="$emit('onDelete', row)"
      @onBtnClick="$emit('onBtnClick', row)"
    />
  </div>
</template>

<script>
import Row from "./Row.vue";

export default {
  name: "Grid",
  components: {
    Row,
  },
  props: {
    head: Object,
    data: Array,
    cellClass: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    cursor() {
      return this.$attrs.onClickrow ? "pointer" : undefined;
    },
  },
  methods: {
    onClick(row, e) {
      const array = ['team-edit', 'team-edit__img', 'act-button']
      const find = array.find((item) => e.target.className.includes(item))
      // Выходим если клик был по контролам?
      if (find) {
        return
      }
      this.$emit("row-click", row, e);
    },
  },
};
</script>
