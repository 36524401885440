import Users from "./Users.vue";
import RequestLegalEntityView from "@/views/registration/RequestLegalEntityView";
import permissions from "@/modules/auth/auth.types.ts";


export const usersRoutes = [
  {
    path: "/users",
    component: Users,
    name: "users",
    meta: {
      middleware: [permissions.ANY_USER_MANAGE]
    }
  },
  {
    path: "/users/requestsRegistration",
    component: RequestLegalEntityView,
    name: 'requestsRegistration',
    meta: {
      middleware: [permissions.ANY_USER_REQUEST_MANAGE]
    }
  }
];
