import apiV2Service from "@/api/V2/services/apiV2.service";

const store = {
    namespaced: true,
    actions: {
        tariffsV2(context, data) {
            return apiV2Service.post(`avito-export/tariffsV2`, data)
        },
        exportSortingCenter() {
            return apiV2Service.post(`avito-export/tariffs/sorting-center`)
        },
        setTagsForSortingCenter(context, data) {
            return apiV2Service.post(`avito-export/tariffs/tagged-sorting-centers`, data)
        },
        getAvitoTasks(context, data) {
            return apiV2Service.post(`get-avito-tasks`, data)
        },
        sortingCenterImport() {
            return apiV2Service.post('avito-export/tariffs/sorting-center-import');
        },
        downloadAvitoTaskRequest(context, avitoTaskId) {
            return apiV2Service.postBlob(`download-avito-task-request/${avitoTaskId}`);
        }
    },
};
export default store;
