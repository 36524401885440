<template>
  <div class="d-flex mt-4 mb-4 justify-content-end">
    <div class="me-5">
      <ReturnActStatusSelector @change="onChangeStatus($event)"></ReturnActStatusSelector>
    </div>
    <div class="me-5">
      <PointSelect :multiple="true" :partner-id="hasAnyReturnsViewPermission ? null : currentUser.partner_id" @change="onChangePoint($event)"></PointSelect>
    </div>
    <div class="me-5">
      <ShopSelect :type-shops="4" @change="onChangeShopId($event)"></ShopSelect>
    </div>
  </div>

  <Grid
    ref="grid"
    :take="take"
    :skip="skip"
    :sortable="true"
    :sort="sort"
    :data-items="returnActs"
    :columns="getColumns"
    :total="total"
    :pageable="true"
    :page-size="pageSize"
    @pagechange="pageChangeHandler"
  >
    <template v-slot:date="{props}">
      <td>
        {{ formatDate(props.dataItem.date) }}
      </td>
    </template>

    <template v-slot:number="{props}">
      <td>
        <el-button-group @click="handleButtonClick(props.dataItem)" style="cursor:pointer;">
          {{ props.dataItem.number }}
        </el-button-group>
      </td>
    </template>
  </Grid>

  <Window v-if="windowVisible"
          :title="'Возвраты'"
          :top="top"
          :width="windowWidth"
          :height="windowHeight"
          @close="toggleDialog">
    <div class="main-context-PVZ">
      <div class="general-info">
        <h2>Общая информация</h2>
        <div class="text-info-context">
          <p class="leftPart-context">ПВЗ</p>
          <p>{{this.currentReturn.point.id}}</p>
        </div>
        <div class="text-info-context">
          <p class="leftPart-context">Адрес</p>
          <p>{{this.currentReturn.point.name}}, {{this.currentReturn.point.comment}}</p>
        </div>
        <div class="text-info-context">
          <p class="leftPart-context">Дата создания</p>
          <p>{{this.currentReturn.created_at}}</p>
        </div>
        <div class="text-info-context">
          <p class="leftPart-context">Сформирован</p>
          <p>{{this.currentReturn.date}}</p>
        </div>
        <div class="text-info-context">
          <p class="leftPart-context">Магазин</p>
          <p>{{this.currentReturn.shop.name}}</p>
        </div>
        <div class="text-info-context">
          <p class="leftPart-context">Статус</p>
          <p>{{this.currentReturn.status_name}}</p>
        </div>
        <div class="text-info-context">
          <p class="leftPart-context">Номер</p>
          <p>{{this.currentReturn.number}}</p>
        </div>
      </div>
      <div class="break"></div>
    </div>

      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="number" label="№" width="60" />
        <el-table-column label="Uuid">
          <template v-slot="scope">
            <el-link class="clickable-link" :href="'/shipments/' + scope.row.id" type="primary">{{ scope.row.uuid }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="shipment_number" label="Номер заказа" />
        <el-table-column prop="barcode" label="Штрихкод" />
      </el-table>
  </Window>

</template>

<script>

import { Grid } from '@progress/kendo-vue-grid';
import ShopSelect from "@/components/selectors/shopSelect/ShopSelect";
import PointSelect from "@/components/selectors/pointSelect/PointSelect.vue";
import ReturnActStatusSelector
  from "@/components/selectors/returnActStatusSelect/ReturnActStatusSelector.vue";
import {mapGetters} from "vuex";
import permissions from "@/modules/auth/auth.types.ts";
import { Window } from '@progress/kendo-vue-dialogs';
import { getReturnActShipments, getReturnActs } from './api/api';
export default {
  name: "ReturnActTable",
  components: {
    ReturnActStatusSelector,
    PointSelect,
    ShopSelect,
    Grid,
    Window,
  },
  created() {
    this.getReturnActs();
  },
  data() {
    return {
      windowWidth: 1300,
      windowHeight: 700,
      windowVisible: false,
      skip: 0,
      take: 10,
      pageSize: 10,
      sort: null,
      total: null,
      returnActs: [],
      shopId: null,
      point: null,
      status: null,
      currentReturn: null,
      tableData: null,
    }
  },
  computed: {
    ...mapGetters({
      roleId: 'user/role_id',
    }),
    getColumns() {
      return [
        {
          field: 'date',
          title: 'Дата',
          cell: 'date'
        },
        {
          field: 'shop.name',
          title: 'Клиент',
        },
        {
          field: 'number',
          cell: 'number',
          title: 'Номер',
        },
        {
          field: 'count_shipments_added_in_act',
          title: 'Кол-во собр. мест',
        },
        {
          field: 'point.name',
          title: 'ПВЗ',
        },
        {
          field: 'status_name',
          title: 'Статус',
        },
        {
          field: 'created_at',
          title: 'Дата создания',
        },
      ]
    },
    hasAnyReturnsViewPermission() {
      let user = this.currentUser
      return user.permissions.includes(permissions.ANY_RETURNS_VIEW);
    },
    currentUser() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    getReturnActs() {
      getReturnActs(
        this.take,
        this.skip,
        this.shopId,
        this.point,
        this.status
      ).then((result) => {
        this.returnActs = result.data.returnActs;
        this.total = result.total;
      });
    },
    pageChangeHandler(event) {
      this.take = event.page.take;
      this.skip = event.page.skip;

      this.getReturnActs();
    },
    onChangeShopId(event) {
      this.shopId = event.length > 0 ? event[0] : null;
      this.skip = 0;
      this.getReturnActs();
    },
    onChangePoint(event) {
      this.point = event.length > 0 ? event : null;
      this.skip = 0;
      this.getReturnActs();
    },
    onChangeStatus(event) {
      this.status = event.length > 0 ? event[0] : null;
      this.skip = 0;
      this.getReturnActs();
    },
    formatDate(date) {
      return date.split('-').reverse().join('.');
    },
    toggleDialog() {
      this.windowVisible = !this.windowVisible;
    },
    setCurrentReturn(event) {
      this.currentReturn = event
    },
    updateTableData() {
      this.toggleDialog();
      this.tableData = null;
      let returnActId = parseInt(this.currentReturn.id);

      try {
        getReturnActShipments(returnActId).then((response) => {

          if (response.data && response.data.returnActShipments) {
            const returnActShipments = response.data.returnActShipments;

            this.tableData = Object.values(returnActShipments).map((shipment, index) => ({
              number: index + 1,
              uuid: shipment.uuid,
              shipment_number: shipment.number,
              barcode: shipment.barcode,
              id: shipment.id
            }));

          } else {
            console.error('Неверная структура данных ответа:', response.data);
          }
        });
      } catch (error) {
        console.error('Ошибка при получении данных о заказах возврата:', error);
      }
    },
    async handleButtonClick(event) {
      this.setCurrentReturn(event);
      await this.updateTableData();
    }
  }
}
</script>

<style scoped>
.el-button-group {
  display: inline-block;
  margin: 0 5px;
  color: #82929d;
}
</style>
