<template>
  <div class="transport-current-table overflow-auto">
    <table style="width: 100%; border-collapse: collapse;">
      <thead>
        <tr>
          <th class="table__cell table__th">Статус</th>
          <th class="table__cell table__th">Дата отправки</th>
          <th class="table__cell table__th">Номер перевозки</th>
          <th class="table__cell table__th">Мест</th>
          <th class="table__cell table__th">Отправлений</th>
          <!--<th class="table__cell table__th">ПВЗ отправки</th>-->
          <th class="table__cell table__th">ПВЗ получения</th>
          <th class="table__cell table__th">Сортировочный центр</th>
          <th class="table__cell table__th">Сотрудник</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="table__cell">{{ freight.statusName || textStatus(freight.status) }}</td>
          <td class="table__cell">{{ freight.dispatch_date }}</td>
          <td class="table__cell">{{ freight.number }}</td>
          <td class="table__cell">{{ freight.number_of_containers }}</td>
          <td class="table__cell">{{ freight.number_of_shipments }}</td>
          <!--<td class="table__cell" v-html="pointInfo(freight.src_point)"></td>-->
          <td class="table__cell" v-html="pointInfo(freight.dst_point)"></td>
          <td class="table__cell">{{ freight.dst_sorting_center_id }}</td>
          <td class="table__cell">{{ freight.dispatch_user_id }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { FREIGHT_STATUS_VALUES } from '@/constants.js';

export default {
  name: "FromPointFreights",
  props: {
    freights: {
      type: Array,
      required: true,
    },
  },
  methods: {
    pointInfo(point) {
      if (point) {
        return `№: ${point.id}, ${point.name}`;
      }
      return '-';
    },
    textStatus(status) {
      return FREIGHT_STATUS_VALUES[status];
    }
  }
};
</script>
