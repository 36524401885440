import apiV2Service from "@/api/V2/services/apiV2.service";
import {PP_ACTS_PAGE_LIMIT} from "@/constants";

const store = {
    namespaced: true,
    state() {
        return {
            ppActsResult: [],
            ppActsMeta: {
                page: 1,
                isLastPage: false,
            },
        }
    },
    getters: {
        ppActs(state) {
            return state.ppActsResult;
        },
        ppActsMeta(state) {
            return state.ppActsMeta;
        },
    },
    actions: {
        findOpenPpAct() {
            return apiV2Service.get(`pp-acts/findOpenPpAct`)
        },
        getPpActByUuid(context, uuid) {
            return apiV2Service.get(`pp-acts/${uuid}`)
        },
        closePpActByUuid(context, uuid) {
            return apiV2Service.post(`pp-acts/${uuid}/close`)
        },
        createPpAct(context, params) {
            return apiV2Service.post(`pp-acts`, params)
        },
        ppActs({commit}, params) {
            return apiV2Service.post(`pp-acts/list`, params).then(result => {
                commit('setPpActsResult', {result, params});
            })
        },
        clearPpActs({commit}) {
            commit('clearPpActs');
        },
    },
    mutations: {
        setPpActsResult(state, {result, params}) {
            if (!Object.values(result).length && params.page > 1) {
                state.ppActsMeta.isLastPage = true;

                return;
            }

            state.ppActsResult = result;
            state.ppActsMeta.page = params.page;
            state.ppActsMeta.isLastPage = result.length < PP_ACTS_PAGE_LIMIT;
        },
        clearPpActs(state) {
            state.searchResult = [];
            state.searchMeta = {
                page: 1,
                isLastPage: false,
            };
        },
    },
};
export default store;
