<template>
  <div class="container-search d-flex justify-content-between">
    <el-date-picker
      class="input--date-range"
      v-model="dateRange"
      type="daterange"
      start-placeholder="Дата от"
      end-placeholder="Дата до"
      @change="createReport"
    />
  </div>

  <div v-if="reports.length" class="transport-current-table">
    <table style="width: 100%; border-collapse: collapse;">
      <thead>
      <tr>
        <th class="table__cell table__th">Название</th>
        <th class="table__cell table__th">ID отчета</th>
        <th class="table__cell table__th">Количество отправлений</th>
        <th class="table__cell table__th">Статус</th>
        <th class="table__cell table__th">Действия</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="report in reports" :key="report.id">
        <td class="table__cell">{{ report.name }}</td>
        <td class="table__cell">{{ report.id }}</td>
        <td class="table__cell">{{ report.shipments_count }}</td>
        <td class="table__cell">{{ statuses[report.status] }}</td>
        <td class="table__cell">
          <div class="d-flex">
            <button
              class="el-button el-button--success el-button--default text-decoration-none"
              @click="onDownloadFranchiseeReportBtnClick(report.id)"
            >
              Скачать
            </button>

            <template v-if="isMultifranchisee">
              <button
                class="el-button el-button--success el-button--default text-decoration-none"
                @click="onDownloadPaymentOrderBtnClick(report.id)"
              >
                Счет
              </button>


              <button
                class="el-button el-button--success el-button--default text-decoration-none"
                @click="onDownloadUPDBtnClick(report.id)"
              >
                УПД
              </button>
            </template>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div v-else-if="isLoading" class="pt-3 pb-3">Загрузка...</div>
  <div v-else class="pt-3 pb-3">Нет информации</div>
</template>


<style>
.input--date-range {
  height: 42px;
  box-shadow: 2px 2px 12px rgb(42 48 66 / 11%);
}

.toolbar-select {
  margin: 0;
}
</style>

<script>

import {downloadFile, getFileNameWithExtensionFromHeaders} from "@/store/helper";
import {ElNotification} from "element-plus";

export default {
  name: "Report",
  data() {
    return {
      dateRange: [],
      isLoading: false,
      statuses: {
        created: 'Создан',
        approved: 'Утверждён',
        payed: 'Оплачен'
      }
    }
  },
  computed: {
    reports() {
      return this.$store.state.reports.reports;
    },
    isMultifranchisee() {
      return this.$store.state.user?.user?.role_id == 9;
    }
  },
  created() {
    this.createReport()
  },
  methods: {
    async createReport() {
      this.isLoading = true;
      await this.$store.dispatch('reports/getReports', {
        from: this.dateRange[0] ? this.dateRange[0].toLocaleDateString('lt') : null,
        to: this.dateRange[1] ? this.dateRange[1].toLocaleDateString('lt') : null
      });
      this.isLoading = false;
    },
    onDownloadUPDBtnClick(franchiseeReportId) {
      this.$store.dispatch('apiV2DocumentStore/generateUPD', franchiseeReportId).then(response => {
        const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        downloadFile(url, fileNameWithExtension)
      });
    },
    onDownloadPaymentOrderBtnClick(franchiseeReportId) {
      this.$store.dispatch('apiV2DocumentStore/generatePaymentOrder', franchiseeReportId).then(response => {
        const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        downloadFile(url, fileNameWithExtension)
      });
    },
    onDownloadFranchiseeReportBtnClick(franchiseeReportId) {
      this.$store.dispatch('apiV2DocumentStore/downloadFranchiseeReport', franchiseeReportId).then(response => {
        const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        downloadFile(url, fileNameWithExtension)
      }, error => {
        return ElNotification({
          title: 'Ошибка скачивания отчёта!',
          message: error.message,
          type: 'error',
        });
      });
    }
  }
};
</script>
