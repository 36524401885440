import Address from './Address.vue'
import AddressItem from './AddressItem.vue'
import AddressTeam from './AddressTeam.vue';
import permissions from "@/modules/auth/auth.types.ts";

export const addressRoutes = [
    {
        path: '/address',
        component: Address,
        name: 'addresses',
        alias: '/',
    },
    {
        path: '/address/:id',
        component: AddressItem,
        name: 'address',
    },
    {
        path: '/address/:id/team',
        component: AddressTeam,
        name: 'addressTeam',
        meta: {
            middleware: [permissions.OWN_USER_MANAGE]
        },
    },
]
