import Give from "./Give.vue";
import GiveDetail from "./GiveDetail.vue";
import GiveConfirmAll from "./GiveConfirmAll.vue";
import permissions from "@/modules/auth/auth.types.ts";

export const giveRoutes = [
  {
    path: "/give",
    component: Give,
    name: "give",
    meta: {
      title: "Выдача отправлений",
      middleware: [permissions.OWN_SHIPMENT_ISSUE]
    },
  },
  {
    path: "/give/:id",
    component: GiveDetail,
    name: "give-item",
    meta: {
      title: "Выдача отправления",
      isHeaderBackLink: true,
      middleware: [permissions.OWN_SHIPMENT_ISSUE]
    },
  },
  {
    path: "/give/:uuid/confirm-all",
    component: GiveConfirmAll,
    name: "give-confirm-all",
    meta: {
      title: "Подтверждение выдачи отправления",
      isHeaderBackLink: true,
      middleware: [permissions.OWN_SHIPMENT_ISSUE, permissions.ANY_SHIPMENT_ISSUE]
    },
  },
];
