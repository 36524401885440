<template>
  <div class="context_menu">
    <div class="container-search">
      <form class="sort-items">
      </form>
      <div class="transport-button-container">
        <button
          :disabled="!isValidFreightStatusForAccept"
          class="transport-add-buttons"
          @click="isReceivingVisible = true"
        >
          Принять со сканером
        </button>
        <button
          :disabled="!isValidFreightStatusForAccept"
          class="transport-add-buttons"
          @click="onPrintAct()"
        >
          Печать Акта ПП
        </button>
      </div>
    </div>

    <div class="alert alert-secondary" style="font-variant-ligatures: no-contextual;" role="alert" v-if="freight && freight.shipments">
      <span>
        Перевозка ID: {{ freight.id }}
        | №: {{ freight.number }}
        | Принято: {{ acceptedShipments.length }} из {{ freight.shipments.length }}
        | Статус: {{ freightStatusName }}
      </span>
    </div>

    <!-- modal window -->
    <div class="modal fade" id="acceptWithScanner" aria-labelledby="acceptWithScannerLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scanner-width modal-dialog-centered">
        <div class="modal-content  customModalAcceptWithScanner">
          <button type="button" class="btn-close custom-close-modal" data-bs-dismiss="modal"
                  aria-label="Close"></button>
          <div class="modal-body">
            <h2 class="modal-transport-title-head">Приемка со сканером</h2>
            <ul>
              <li class="barcode-items">
                <span>12345678</span>
              </li>
              <li class="barcode-items">
                <span>2345678</span>
              </li>
              <li class="barcode-items">
                <span>78799055</span>
              </li>
              <li class="barcode-items">
                <span>45667899</span>
              </li>
              <li class="barcode-items">
                <span>123445676</span>
              </li>
              <li class="barcode-items">
                <span>1234688</span>
                <span class="barcode-mistake">Ошибка: не читается штрихкод</span>
              </li>
              <li class="barcode-items">
                <span>1234567</span>
              </li>
            </ul>
            <div class="modal-scanned">
              <p>ОТСКАНИРОВАНО</p>
              <p>7 из 8</p>
            </div>
            <div class="modal-barcode-mistakes">
              <p>ОШИБОК</p>
              <p>1</p>
            </div>
            <div class="barcode-button-end-container">
              <button class="mainButton" data-bs-dismiss="modal">Завершить приемку</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal window -->
    <div class="modal fade" id="placeShipment" aria-labelledby="placeShipmentLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scanner-width modal-dialog-centered">
        <div class="modal-content  customModalAcceptWithScanner">
          <button type="button" class="btn-close custom-close-modal" data-bs-dismiss="modal"
                  aria-label="Close"></button>
          <div class="modal-body">
            <form>
              <h2 class="modal-transport-title-head">Размещение отправления 1234567890-90</h2>
              <label for="cellDataList" class="barcode-label">Ячейка</label>
              <select class="custom-form-for-cells" id="cellDataList">
                <option class="test" value="A-00-01">A-00-01</option>
                <option value="A-00-02">A-00-02</option>
                <option value="A-00-03">A-00-03</option>
                <option value="A-00-04">A-00-04</option>
                <option value="A-00-05">A-00-05</option>
                <option value="A-00-06">A-00-06</option>
                <option value="A-00-07">A-00-07</option>
                <option value="A-00-08">A-00-08</option>
                <option value="A-00-09">A-00-09</option>
                <option value="A-00-10">A-00-10</option>
                <option value="A-00-11">A-00-11</option>
                <option value="A-00-12">A-00-12</option>
              </select>
              <div class="modale-barcode-container-position">
                <button class="mainButton" data-bs-dismiss="modal" type="submit">Разместить</button>
                <img src="img/barcode.png" alt="barcode">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="transport-reception-table">
      <ul class="table-container-transport table-head-transport">
        <li class="table-items-head-transport transport-reception-status-width">
          <span>Статус</span>
        </li>
        <li class="table-items-head-transport transport-reception-item-width">
          <span>Ячейка</span>
        </li>
        <li class="table-items-head-transport transport-reception-cell-width">
          <span>Отправление</span>
        </li>
        <!--        <li class="table-items-head-transport transport-reception-place-width">-->
        <!--          <span>Место назначения</span>-->
        <!--        </li>-->
        <!--        <li class="table-items-head-transport transport-reception-ship-width">-->
        <!--          <span>Перевозка</span>-->
        <!--        </li>-->
        <li class="table-items-head-transport transport-reception-shop-width">
          <span>Интернет-магазин</span>
        </li>
        <li class="table-items-head-transport transport-reception-pay-width">
          <span>К оплате</span>
        </li>
      </ul>
      <ul
        v-for="item in freight.shipments"
        :key="item.id"
        class="table-container-transport"
      >
        <li class="table-items-transport transport-reception-status-width">
          <span>{{ shipmentsStatusNameById(item.status) }}</span>
        </li>
        <li class="table-items-transport transport-reception-item-width">
          <span>{{ getCeil(item.ceil_id).name }}</span>
        </li>
        <li class="table-items-transport transport-reception-cell-width">
          <span>{{ item.number }}</span>
        </li>
        <!--        <li class="table-items-transport transport-reception-place-width">-->
        <!--          <span>Красноярск_12</span>-->
        <!--        </li>-->
        <!--        <li class="table-items-transport transport-reception-ship-width">-->
        <!--          <span>перевозка 013196296</span>-->
        <!--        </li>-->
        <li class="table-items-transport transport-reception-shop-width">
          <span>Авито</span>
        </li>
        <li class="table-items-transport transport-reception-pay-width">
          <span>{{ item.sum }} Р</span>
        </li>
        <li class="table-items-transport transport-reception-button-width">
          <div class="dropdown">
            <a class="dropdown-toggle transport-dropdown-style" href="#" role="button" id="dropdownMenuTransportLink"
               data-bs-toggle="dropdown"
               aria-expanded="false">
              ...
            </a>

            <ul class="dropdown-menu transport-dropdown-menu" aria-labelledby="dropdownMenuTransportLink">
              <li><a class="dropdown-item transport-dropdown-item"
                     data-bs-toggle="modal" href="#placeShipment" role="button">
                Разместить
              </a>
              </li>
              <li><a class="dropdown-item transport-dropdown-item" href="#">Принято</a></li>
              <li><a class="dropdown-item transport-dropdown-item" href="#">Расходник</a></li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="position-end-button">
      <button
        :disabled="!isValidFreightStatusForAccept"
        class="mainButton"
        @click.prevent="onButtonfinishFreightReceptionClick">
        Завершить приемку
      </button>
    </div>

    <receiving-with-scanner
      ref="receivingWithScannerRef"
      v-model="isReceivingVisible"
      :freight="freight"
    />
  </div>
</template>
<style scoped>
.table-items-transport {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
</style>
<script>

import {mapActions, mapGetters, mapState} from "vuex";
import ReceivingWithScanner from "@/components/freights/franchisee/ReceivingWithScanner";
import {useCeils} from '@/composition/useCeils.js';
import {
  FREIGHT_STATUS_ACCEPTED_ON_POINT, FREIGHT_STATUS_VALUES,
  SHIPMENT_ARRIVED_AT_WRONG_PICKUP_POINT,
  SHIPMENT_ARRIVED_TO_PICK_UP_POINT_WRONG_TRANSPORT,
  SHIPMENT_READY_TO_PICK_UP
} from "@/constants";
import {ElMessageBox, ElNotification} from "element-plus";

export default {
  name: 'FreightReception',

  components: {ReceivingWithScanner},

  setup() {
    return {
      ...useCeils(),
    }
  },

  data: () => ({
    isReceivingVisible: false,
  }),

  computed: {
    ...mapState({
      freight: state => state.freights.editItem,
    }),

    ...mapGetters({
      shipmentsStatusNameById: 'shipments/statusNameById',
    }),

    acceptedShipments() {
      if (!this.freight || !this.freight.shipments) {
        return [];
      }
      return this.freight.shipments.filter((item) => {
        return [
          SHIPMENT_READY_TO_PICK_UP,
          SHIPMENT_ARRIVED_TO_PICK_UP_POINT_WRONG_TRANSPORT,
          SHIPMENT_ARRIVED_AT_WRONG_PICKUP_POINT
        ].includes(parseInt(item.status))
      });
    },

    isValidFreightStatusForAccept() {
      if (!this.freight) {
        return false;
      }

      if (!this.freight.shipments || !this.freight.shipments.length) {
        return false;
      }

      if (parseInt(this.freight.status) !== FREIGHT_STATUS_ACCEPTED_ON_POINT) {
        return false;
      }
      return true;
    },

    freightStatusName() {
      if (!this.freight) {
        return null;
      }
      return FREIGHT_STATUS_VALUES[parseInt(this.freight.status)];
    }
  },

  watch: {
    isReceivingVisible(value) {
      if (!value) {
        this.initData()
      }
    }
  },

  created() {
    this.initData()
  },

  methods: {
    ...mapActions({
      readItem: 'freights/readItem',
      finished: 'freights/finished',
    }),

    initData() {
      this.readItem(this.$route.params.id);
    },

    async onButtonfinishFreightReceptionClick() {
      if (this.acceptedShipments.length !== this.freight.shipments.length) {
        await ElMessageBox.confirm(
          'В Перевозке имеются неотсканированные отправления. ' +
          'Вы действительно хотите завершить Перевозку с недостачей?',
          'Подтверждение действия',
          {
            confirmButtonText: 'Завершить',
            cancelButtonText: 'Отменить',
            cancelButtonClass: 'el-button--info'
          },
        )
      }
      await this.finishFreightReception();
      await this.initData();
      this.$refs.receivingWithScannerRef.initList();
    },

    async finishFreightReception() {
      try {
        const body = {
          id: this.freight.id,
          data: {is_new: 1}
        };
        await this.finished(body);
      } catch ({message}) {
        ElNotification({
          title: 'Ошибка',
          message: message,
          type: 'error',
        });
      }
    },
    onPrintAct() {
      const href = `${process.env.VUE_APP_API_ROOT}/freight_act?freight_id=${this.$route.params.id}`;
      window.open(href, '_blank');
    },
  }
};
</script>

