<template>
  <el-dialog
      :close-on-press-escape="false"
      :show-close="false"
      :close-on-click-modal="false"
      v-model="isNewsModalVisible"
      :title="modalContent.title"
      top="50px"
      width="60%"
      align-center
      center
  >
    <el-card style="height: auto; max-height: 700px">
      <div class="message-container">
        <span class="news-message">
          {{ modalContent.content }}
        </span>
      </div>

      <iframe
          v-if="this.modalContent.fileUrl"
          loading="eager"
          allowfullscreen="allowfullscreen"
          :src="`${this.modalContent.fileUrl}#zoom=50`"
          type="application/pdf"
          style="height: 600px;width: 100%"
      />
    </el-card>

    <template #footer>
        <span class="dialog-footer">
           <el-button type="info" @click="onCancelNewsBtnClick">Отмена</el-button>
          <el-button type="primary" @click="onAcceptanceNewsBtnClick">
            <template v-if="this.modalContent.isBlocking">
              Принять
            </template>
            <template v-else>
              Ок
            </template>
          </el-button>
        </span>
    </template>
  </el-dialog>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions, mapGetters} from 'vuex'
import {ElNotification} from "element-plus";

export default defineComponent({
  data() {
    return {
      userId: null,
      isNewsModalVisible: false,
      modalContent: {
        newsId: null,
        title: null,
        fileUrl: null,
        content: null,
        isBlocking: false
      }
    }
  },
  methods: {
    ...mapActions('user', ['logout']),
    ...mapActions({
      getUserNews: 'apiV2NewsStore/getFirstActiveUserNews',
      markAsAccepted: 'apiV2NewsStore/markNewsAsAcceptedForCurrentUser',
    }),
    onCheckUserNews() {
      if (!this.currentUser) {
        return;
      }

      this.getUserNews().then(data => {
        const news = data.news;
        if (!news) {
          return;
        }

        this.modalContent.newsId = news.id;
        this.modalContent.title = news.title;
        this.modalContent.content = news.content;
        this.modalContent.fileUrl = news.file_url;
        this.modalContent.isBlocking = news.is_blocking;

        this.isNewsModalVisible = true;
      })

    },
    onAcceptanceNewsBtnClick() {
      this.markAsAccepted(this.modalContent.newsId).then(() => {
        this.isNewsModalVisible = false;
        return ElNotification({
          title: 'Действие выполнено успешно',
          message: 'Действие выполнено успешно',
          type: 'success',
        });
      }, (error) => {
        return ElNotification({
          title: 'Ошибка',
          message: error,
          type: 'error',
        });
      });
    },

    onCancelNewsBtnClick() {
      if (this.modalContent.isBlocking) {
        return this.logout()
      }

      this.isNewsModalVisible = false;
    }
  },
  computed: {
    ...mapGetters('user', ['currentUser']),
  },
  watch: {
    currentUser: {
      handler(newUser) {
        if (newUser && newUser.id !== this.userId) {
          this.userId = newUser.id;
          this.onCheckUserNews();
        }
      },
      immediate: true,
      deep: true
    },
  }
})
</script>

<style scoped lang="scss">
.dialog-footer button:first-child {
  margin-right: 10px;
}

.news-message {
  word-break: normal;
  font-size: 14px;
  line-height: 25px;
}

.message-container {
  width: 100%;
  padding: 0 10%;
  text-align: center;
  margin-bottom: 20px;
  height: 100%;
}
</style>
