<template>
  <div>
    <FilterTabs
      :options="typesOptions"
      :value="filters.type"
      @onClick="onFilterTabClick"
    />

    <Toolbar>
      <template v-slot:filter>
        <Search
          v-model:value="filters.search"
          :placeholder="titles.search"
          @search="getList({ page: 1 })"
        />
        <el-date-picker
          v-if="filters.type === 2"
          class="input--date-range"
          v-model="filters.date"
          type="daterange"
          start-placeholder="Дата от"
          end-placeholder="Дата до"
          @change="getList({ page: 1 })"
        />
      </template>

      <template v-slot:buttons>
        <!--<Button @click="$router.push({ name: 'freight-create' })" modifyer="rounded">
          Новая перевозка
        </Button>-->
      </template>
    </Toolbar>

    <div v-if="freights.length">
      <component
        :is="freightsComponent"
        :freights="freights"
        @onAccept="onAccept"
        @onPrintAct="onPrintAct"
        @onAcceptWithBarcodes="onAcceptWithBarcodes"
      />
    </div>
    <div v-else-if="getListLoading" class="pt-3 pb-3">Загрузка...</div>
    <div v-else class="pt-3 pb-3">Нет информации</div>

    <Pages
      v-if="freights.length"
      :totalPages="totalPages"
      :currentPage="filters.page"
      :last="totalPages"
      :total="total"
      :perPage="perPage"
      @change="switchPage"
    />
  </div>
</template>

<script>
import FilterTabs from "@/components/partials/FilterTabs.vue";
import Toolbar, { Search, RangeDatePicker } from "src/components/toolbar";
import Pages from "src/components/Pages.vue";
// import Button from "src/components/base/Button.vue";
import CurrentFrights from "src/components/freights/franchisee/CurrentFrights.vue";
import CompletedFrights from "src/components/freights/franchisee/CompletedFrights.vue";
import {mapGetters} from "vuex";

export default {
  name: "FranchiseeFreights",
  components: {
    FilterTabs,
    Toolbar,
    Search,
    RangeDatePicker,
    Pages,
    // Button,
    CurrentFrights,
    CompletedFrights,
  },
  data() {
    return {
      filters: {
        page: 1,
        search: "",
        type: this.$store.getters["freights/typesOptions"][0].value || 1,
        date: [],
      },
      titles: {
        search: "Поиск",
      },
      getListLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      statusNameById: 'freights/statusNameById',
    }),

    totalPages() {
      return this.$store.state.freights.pagination.totalPages;
    },
    total() {
      return this.$store.state.freights.pagination.totalItems;
    },
    perPage() {
      return this.$store.state.freights.pagination.perPage;
    },
    typesOptions() {
      return this.$store.getters["freights/typesOptions"];
    },
    freightsComponent() {
      return this.filters.type === 1 ? CurrentFrights : CompletedFrights;
    },
    freights() {
      return this.$store.state.freights.list.map(item => ({
        ...item,
        statusName: this.statusNameById(item.status),
      }));
    },
  },
  mounted() {
    this.$store.commit("SET_TITLE", "Перевозки");

    let dicts = [];
    if (!this.$store.state.points.list.length) {
      dicts.push(this.$store.dispatch("points/getList"));
    }
    Promise.all(dicts).then(() => {
      this.getList();
    });
  },
  methods: {
    async getList(opts) {
      this.filters = { ...this.filters, ...opts };
      this.getListLoading = true;
      await this.$store.dispatch("freights/getList", this.filters);
      this.getListLoading = false;
    },
    onFilterTabClick(e) {
      this.filters.type = e.value;
      if (this.filters.type !== 2) {
        this.filters.date = [];
      }
      this.getList({ page: 1 });
    },
    async switchPage(page) {
      this.filters.page = page;
      await this.getList();
    },
    onPeriodUpdate(valArr) {
      if (valArr) {
        this.filters.date = [...valArr];
      } else {
        this.filters.date = [];
      }
    },
    onAccept(item) {
      this.$router.push({ name: 'freight-reception', params: { id: item.id } })
    },
    onPrintAct(item) {
        const href = `${process.env.VUE_APP_API_ROOT}/freight_act?freight_id=${item.id}`;
        window.open(href, '_blank');
    },
    onAcceptWithBarcodes(item) {
      this.$router.push({ name: 'freight-reception-with-barcodes', params: { id: item.id } })
    }
  },
};
</script>
