<template>
  <h2 class="pt-4">У вас нет прав доступа к данному разделу</h2>
</template>

<script>
export default {
  name: "AcceptShipmentsCreate",
  mounted() {
    this.$store.commit("SET_TITLE", "Оформление отправления");
  },
};
</script>
