<template>
  <span class="custom-search">
    <button class="invise-searchButton" @click.prevent="onSearch"></button>
    <input
      required
      class="custom-search-input form-control"
      :placeholder="placeholder"
      type="search"
      v-bind:value="value"
      @change="onChange"
    />
    <button @click="onClear" type="button" class="close-icon"></button>
  </span>
</template>

<script>
export default {
  name: "Search",
  props: {
    value: String,
    placeholder: String,
  },
  methods: {
    onChange({ target }) {
      this.$emit("update:value", target.value);
    },
    onClear() {
      this.$emit("update:value", "");
      this.onSearch();
    },
    onSearch() {
      this.$emit("search");
    },
  },
};
</script>
