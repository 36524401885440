<template>
  <form class="modal-add-worker addWorkerValidation">
    <h2 class="form-name">Добавление перевозки</h2>

    <div class="form-group">
      <label for="number">Номер перевозки</label>
      <div>
        <input
          type="text"
          class="form-control modal-add-worker-form-style-input"
          :class="{
            'is-invalid':
              (v$.freight.number.$dirty && v$.freight.number.$invalid) ||
              backendErrors.number,
          }"
          id="number"
          :value="freight.number"
          @input="onInput('number', $event)"
        />
        <div
          v-if="
            (v$.freight.number.$dirty && v$.freight.number.$invalid) ||
            backendErrors.number
          "
          class="invalid-feedback"
        >
          <p
            v-if="v$.freight.number.$dirty && v$.freight.number.$invalid"
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p v-if="backendErrors.number" class="invalid-feedback-text">{{
              backendErrors.number[0]
            }}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="freight_type_id">Тип перевозки</label>
      <div>
        <Select
          :class="{
            'is-invalid':
              (v$.freight.freight_type_id.$dirty &&
                v$.freight.freight_type_id.$invalid) ||
              backendErrors.freight_type_id,
          }"
          :options="typeListOptions"
          :value="-1"
          placeholder="Выберите значение"
          @select="onSelect('freight_type_id', $event)"
        />
        <div
          v-if="
            (v$.freight.freight_type_id.$dirty &&
              v$.freight.freight_type_id.$invalid) ||
            backendErrors.freight_type_id
          "
          class="invalid-feedback"
        >
          <p
            v-if="
              v$.freight.freight_type_id.$dirty &&
              v$.freight.freight_type_id.$invalid
            "
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p
            v-if="backendErrors.freight_type_id"
              class="invalid-feedback-text"
          >{{ backendErrors.freight_type_id[0] }}</p
          >
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="dispatch_date">Дата отправки</label>
      <div>
        <AppDatePicker
          :value="freight.dispatch_date"
          @onUpdate="onDPUpdate('dispatch_date', $event)"
          @onClear="onDPClear('dispatch_date')"
          :isInValid="
            (v$.freight.dispatch_date.$dirty &&
              v$.freight.dispatch_date.$invalid) ||
            backendErrors.dispatch_date
          "
        />
        <div
          v-if="
            (v$.freight.dispatch_date.$dirty &&
              v$.freight.dispatch_date.$invalid) ||
            backendErrors.dispatch_date
          "
          class="invalid-feedback"
        >
          <p
            v-if="
              v$.freight.dispatch_date.$dirty &&
              v$.freight.dispatch_date.$invalid
            "
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p v-if="backendErrors.dispatch_date" class="invalid-feedback-text">{{
              backendErrors.dispatch_date[0]
            }}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="arrival_date">Дата прибытия</label>
      <div>
        <AppDatePicker
          :value="freight.arrival_date"
          @onUpdate="onDPUpdate('arrival_date', $event)"
          @onClear="onDPClear('arrival_date')"
          :isInValid="
            (v$.freight.arrival_date.$dirty &&
              v$.freight.arrival_date.$invalid) ||
            backendErrors.arrival_date
          "
        />

        <div
          v-if="
            (v$.freight.arrival_date.$dirty &&
              v$.freight.arrival_date.$invalid) ||
            backendErrors.arrival_date
          "
          class="invalid-feedback"
        >
          <p
            v-if="
              v$.freight.arrival_date.$dirty && v$.freight.arrival_date.$invalid
            "
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p v-if="backendErrors.arrival_date" class="invalid-feedback-text">{{
              backendErrors.arrival_date[0]
            }}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="src_warehouse_id">Склад отправки</label>
      <div>
        <Select
          :class="{
            'is-invalid':
              (v$.freight.src_warehouse_id.$dirty &&
                v$.freight.src_warehouse_id.$invalid) ||
              backendErrors.src_warehouse_id,
          }"
          :options="warehouseOptions"
          :value="-1"
          placeholder="Выберите значение"
          @select="onSelect('src_warehouse_id', $event)"
        />
        <div
          v-if="
            (v$.freight.src_warehouse_id.$dirty &&
              v$.freight.src_warehouse_id.$invalid) ||
            backendErrors.src_warehouse_id
          "
          class="invalid-feedback"
        >
          <p
            v-if="
              v$.freight.src_warehouse_id.$dirty &&
              v$.freight.src_warehouse_id.$invalid
            "
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p
            v-if="backendErrors.src_warehouse_id"
            class="invalid-feedback-text"
          >{{ backendErrors.src_warehouse_id[0] }}</p
          >
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="dst_warehouse_id">Склад прибытия</label>
      <div>
        <Select
          :class="{
            'is-invalid':
              (v$.freight.dst_warehouse_id.$dirty &&
                v$.freight.dst_warehouse_id.$invalid) ||
              backendErrors.dst_warehouse_id,
          }"
          :options="warehouseOptions"
          :value="-1"
          placeholder="Выберите значение"
          @select="onSelect('dst_warehouse_id', $event)"
        />
        <div
          v-if="
            (v$.freight.dst_warehouse_id.$dirty &&
              v$.freight.dst_warehouse_id.$invalid) ||
            backendErrors.dst_warehouse_id
          "
          class="invalid-feedback"
        >
          <p
            v-if="
              v$.freight.dst_warehouse_id.$dirty &&
              v$.freight.dst_warehouse_id.$invalid
            "
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p
            v-if="backendErrors.dst_warehouse_id"
            class="invalid-feedback-text"
          >{{ backendErrors.dst_warehouse_id[0] }}</p
          >
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="src_point_id">ПВЗ отправки</label>
      <div>
        <Select
          :class="{
            'is-invalid':
              (v$.freight.src_point_id.$dirty &&
                v$.freight.src_point_id.$invalid) ||
              backendErrors.src_point_id,
          }"
          :options="pointSelect"
          :value="-1"
          placeholder="Выберите значение"
          @select="onSelect('src_point_id', $event)"
        />
        <div
          v-if="
            (v$.freight.src_point_id.$dirty &&
              v$.freight.src_point_id.$invalid) ||
            backendErrors.src_point_id
          "
          class="invalid-feedback"
        >
          <p
            v-if="
              v$.freight.src_point_id.$dirty && v$.freight.src_point_id.$invalid
            "
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p v-if="backendErrors.src_point_id" class="invalid-feedback-text">{{
              backendErrors.src_point_id[0]
            }}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="src_point_id">ПВЗ прибытия</label>
      <div>
        <Select
          :class="{
            'is-invalid':
              (v$.freight.dst_point_id.$dirty &&
                v$.freight.dst_point_id.$invalid) ||
              backendErrors.dst_point_id,
          }"
          :options="pointSelect"
          :value="-1"
          placeholder="Выберите значение"
          @select="onSelect('dst_point_id', $event)"
        />
        <div
          v-if="
            (v$.freight.dst_point_id.$dirty &&
              v$.freight.dst_point_id.$invalid) ||
            backendErrors.dst_point_id
          "
          class="invalid-feedback"
        >
          <p
            v-if="
              v$.freight.dst_point_id.$dirty && v$.freight.dst_point_id.$invalid
            "
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p v-if="backendErrors.dst_point_id" class="invalid-feedback-text">{{
              backendErrors.dst_point_id[0]
            }}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="src_sorting_center_id">Сортировочный центр отправки</label>
      <div>
        <Select
          :class="{
            'is-invalid':
              (v$.freight.src_sorting_center_id.$dirty &&
                v$.freight.src_sorting_center_id.$invalid) ||
              backendErrors.src_sorting_center_id,
          }"
          :options="sortingCentersOptions"
          :value="-1"
          placeholder="Выберите значение"
          @select="onSelect('src_sorting_center_id', $event)"
        />
        <div
          v-if="
            (v$.freight.src_sorting_center_id.$dirty &&
              v$.freight.src_sorting_center_id.$invalid) ||
            backendErrors.src_sorting_center_id
          "
          class="invalid-feedback"
        >
          <p
            v-if="
              v$.freight.src_sorting_center_id.$dirty &&
              v$.freight.src_sorting_center_id.$invalid
            "
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p
            v-if="backendErrors.src_sorting_center_id"
            class="invalid-feedback-text"
          >{{ backendErrors.src_sorting_center_id[0] }}</p
          >
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="dst_sorting_center_id">Сортировочный центр прибытия</label>
      <div>
        <Select
          :class="{
            'is-invalid':
              (v$.freight.dst_sorting_center_id.$dirty &&
                v$.freight.dst_sorting_center_id.$invalid) ||
              backendErrors.dst_sorting_center_id,
          }"
          :options="sortingCentersOptions"
          :value="-1"
          placeholder="Выберите значение"
          @select="onSelect('dst_sorting_center_id', $event)"
        />
        <div
          v-if="
            (v$.freight.dst_sorting_center_id.$dirty &&
              v$.freight.dst_sorting_center_id.$invalid) ||
            backendErrors.dst_sorting_center_id
          "
          class="invalid-feedback"
        >
          <p
            v-if="
              v$.freight.dst_sorting_center_id.$dirty &&
              v$.freight.dst_sorting_center_id.$invalid
            "
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p
            v-if="backendErrors.dst_sorting_center_id"
            class="invalid-feedback-text"
          >{{ backendErrors.dst_sorting_center_id[0] }}</p
          >
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="vehicle">Госномер</label>
      <div>
        <input
          type="text"
          class="form-control modal-add-worker-form-style-input"
          :class="{
            'is-invalid':
              (v$.freight.vehicle.$dirty && v$.freight.vehicle.$invalid) ||
              backendErrors.vehicle,
          }"
          id="vehicle"
          :value="freight.vehicle"
          @input="onInput('vehicle', $event)"
        />
        <div
          v-if="
            (v$.freight.vehicle.$dirty && v$.freight.vehicle.$invalid) ||
            backendErrors.vehicle
          "
          class="invalid-feedback"
        >
          <p
            v-if="v$.freight.vehicle.$dirty && v$.freight.vehicle.$invalid"
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p v-if="backendErrors.vehicle" class="invalid-feedback-text">{{
              backendErrors.vehicle[0]
            }}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="driver">Фио водителя</label>
      <div>
        <input
          type="text"
          class="form-control modal-add-worker-form-style-input"
          :class="{
            'is-invalid':
              (v$.freight.driver.$dirty && v$.freight.driver.$invalid) ||
              backendErrors.driver,
          }"
          id="driver"
          :value="freight.driver"
          @input="onInput('driver', $event)"
        />
        <div
          v-if="
            (v$.freight.driver.$dirty && v$.freight.driver.$invalid) ||
            backendErrors.driver
          "
          class="invalid-feedback"
        >
          <p
            v-if="v$.freight.driver.$dirty && v$.freight.driver.$invalid"
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p v-if="backendErrors.driver" class="invalid-feedback-text">{{
              backendErrors.driver[0]
            }}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="status">Статус</label>
      <div>
        <input
          type="text"
          class="form-control modal-add-worker-form-style-input"
          :class="{
            'is-invalid':
              (v$.freight.status.$dirty && v$.freight.status.$invalid) ||
              backendErrors.status,
          }"
          id="status"
          :value="freight.status"
          @input="onInput('status', $event)"
        />
        <div
          v-if="
            (v$.freight.status.$dirty && v$.freight.status.$invalid) ||
            backendErrors.status
          "
          class="invalid-feedback"
        >
          <p
            v-if="v$.freight.status.$dirty && v$.freight.status.$invalid"
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p v-if="backendErrors.status" class="invalid-feedback-text">{{
              backendErrors.status[0]
            }}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="number_of_containers">Мест</label>
      <div>
        <input
          type="text"
          class="form-control modal-add-worker-form-style-input"
          :class="{
            'is-invalid':
              (v$.freight.number_of_containers.$dirty &&
                v$.freight.number_of_containers.$invalid) ||
              backendErrors.number_of_containers,
          }"
          id="number_of_containers"
          :value="freight.number_of_containers"
          @input="onInput('number_of_containers', $event)"
        />
        <div
          v-if="
            (v$.freight.number_of_containers.$dirty &&
              v$.freight.number_of_containers.$invalid) ||
            backendErrors.number_of_containers
          "
          class="invalid-feedback"
        >
          <p
            v-if="
              v$.freight.number_of_containers.$dirty &&
              v$.freight.number_of_containers.$invalid
            "
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p
            v-if="backendErrors.number_of_containers"
            class="invalid-feedback-text"
          >{{ backendErrors.number_of_containers[0] }}</p
          >
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="number_of_shipments">Отправлений</label>
      <div>
        <input
          type="text"
          class="form-control modal-add-worker-form-style-input"
          :class="{
            'is-invalid':
              (v$.freight.number_of_shipments.$dirty &&
                v$.freight.number_of_shipments.$invalid) ||
              backendErrors.number_of_shipments,
          }"
          id="number_of_shipments"
          :value="freight.number_of_shipments"
          @input="onInput('number_of_shipments', $event)"
        />
        <div
          v-if="
            (v$.freight.number_of_shipments.$dirty &&
              v$.freight.number_of_shipments.$invalid) ||
            backendErrors.number_of_shipments
          "
          class="invalid-feedback"
        >
          <p
            v-if="
              v$.freight.number_of_shipments.$dirty &&
              v$.freight.number_of_shipments.$invalid
            "
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p
            v-if="backendErrors.number_of_shipments"
            class="invalid-feedback-text"
          >{{ backendErrors.number_of_shipments[0] }}</p
          >
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="total_weight">Вес</label>
      <div>
        <input
          type="text"
          class="form-control modal-add-worker-form-style-input"
          :class="{
            'is-invalid':
              (v$.freight.total_weight.$dirty &&
                v$.freight.total_weight.$invalid) ||
              backendErrors.total_weight,
          }"
          id="total_weight"
          :value="freight.total_weight"
          @input="onInput('total_weight', $event)"
        />
        <div
          v-if="
            (v$.freight.total_weight.$dirty &&
              v$.freight.total_weight.$invalid) ||
            backendErrors.total_weight
          "
          class="invalid-feedback"
        >
          <p
            v-if="
              v$.freight.total_weight.$dirty && v$.freight.total_weight.$invalid
            "
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p v-if="backendErrors.total_weight" class="invalid-feedback-text">{{
              backendErrors.total_weight[0]
            }}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="surplus">Излишки</label>
      <div>
        <input
          type="text"
          class="form-control modal-add-worker-form-style-input"
          :class="{
            'is-invalid':
              (v$.freight.surplus.$dirty && v$.freight.surplus.$invalid) ||
              backendErrors.surplus,
          }"
          id="surplus"
          :value="freight.surplus"
          @input="onInput('surplus', $event)"
        />
        <div
          v-if="
            (v$.freight.surplus.$dirty && v$.freight.surplus.$invalid) ||
            backendErrors.surplus
          "
          class="invalid-feedback"
        >
          <p
            v-if="v$.freight.surplus.$dirty && v$.freight.surplus.$invalid"
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p v-if="backendErrors.surplus" class="invalid-feedback-text">{{
              backendErrors.surplus[0]
            }}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="shortage">Недостачи</label>
      <div>
        <input
          type="text"
          class="form-control modal-add-worker-form-style-input"
          :class="{
            'is-invalid':
              (v$.freight.shortage.$dirty && v$.freight.shortage.$invalid) ||
              backendErrors.shortage,
          }"
          id="shortage"
          :value="freight.shortage"
          @input="onInput('shortage', $event)"
        />
        <div
          v-if="
            (v$.freight.shortage.$dirty && v$.freight.shortage.$invalid) ||
            backendErrors.shortage
          "
          class="invalid-feedback"
        >
          <p
            v-if="v$.freight.shortage.$dirty && v$.freight.shortage.$invalid"
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p v-if="backendErrors.shortage" class="invalid-feedback-text">{{
              backendErrors.shortage[0]
            }}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="accept_user_id">Получатель</label>
      <div>
        <Select
          :class="{
            'is-invalid':
              (v$.freight.accept_user_id.$dirty &&
                v$.freight.accept_user_id.$invalid) ||
              backendErrors.accept_user_id,
          }"
          :options="fakeSelectOpts"
          :value="-1"
          placeholder="Выберите значение"
          @select="onSelect('accept_user_id', $event)"
        />
        <div
          v-if="
            (v$.freight.accept_user_id.$dirty &&
              v$.freight.accept_user_id.$invalid) ||
            backendErrors.accept_user_id
          "
          class="invalid-feedback"
        >
          <p
            v-if="
              v$.freight.accept_user_id.$dirty &&
              v$.freight.accept_user_id.$invalid
            "
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p
            v-if="backendErrors.accept_user_id"
            class="invalid-feedback-text"
          >{{ backendErrors.accept_user_id[0] }}</p
          >
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="dispatch_user_id">Отправитель</label>
      <div>
        <Select
          :class="{
            'is-invalid':
              (v$.freight.dispatch_user_id.$dirty &&
                v$.freight.dispatch_user_id.$invalid) ||
              backendErrors.dispatch_user_id,
          }"
          :options="fakeSelectOpts"
          :value="-1"
          placeholder="Выберите значение"
          @select="onSelect('dispatch_user_id', $event)"
        />
        <div
          v-if="
            (v$.freight.dispatch_user_id.$dirty &&
              v$.freight.dispatch_user_id.$invalid) ||
            backendErrors.dispatch_user_id
          "
          class="invalid-feedback"
        >
          <p
            v-if="
              v$.freight.dispatch_user_id.$dirty &&
              v$.freight.dispatch_user_id.$invalid
            "
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p
            v-if="backendErrors.dispatch_user_id"
            class="invalid-feedback-text"
          >{{ backendErrors.dispatch_user_id[0] }}</p
          >
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="seal">seal</label>
      <div>
        <Select
          :class="{
            'is-invalid':
              (v$.freight.seal.$dirty && v$.freight.seal.$invalid) ||
              backendErrors.seal,
          }"
          :options="boolOpts"
          :value="-1"
          placeholder="Выберите значение"
          @select="onSelect('seal', $event)"
        />
        <div
          v-if="
            (v$.freight.seal.$dirty && v$.freight.seal.$invalid) ||
            backendErrors.seal
          "
          class="invalid-feedback"
        >
          <p
            v-if="v$.freight.seal.$dirty && v$.freight.seal.$invalid"
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p v-if="backendErrors.seal" class="invalid-feedback-text">{{
              backendErrors.seal[0]
            }}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="comment">Комментарий</label>
      <div>
        <input
          type="text"
          class="form-control modal-add-worker-form-style-input"
          :class="{
            'is-invalid':
              (v$.freight.comment.$dirty && v$.freight.comment.$invalid) ||
              backendErrors.comment,
          }"
          id="comment"
          :value="freight.comment"
          @input="onInput('comment', $event)"
        />
        <div
          v-if="
            (v$.freight.comment.$dirty && v$.freight.comment.$invalid) ||
            backendErrors.comment
          "
          class="invalid-feedback"
        >
          <p
            v-if="v$.freight.comment.$dirty && v$.freight.comment.$invalid"
            class="invalid-feedback-text"
          >Неверный формат</p
          >
          <p v-if="backendErrors.comment" class="invalid-feedback-text">{{
              backendErrors.comment[0]
            }}</p>
        </div>
      </div>
    </div>

    <button
      class="addWorker addWorker-modal-button"
      type="button"
      @click="save"
      :disabled="loading"
    >
      Добавить
    </button>

    <div v-if="backendErrors.error" class="is-invalid pt-4">
      <div class="invalid-feedback">
        <p class="invalid-feedback-text">{{ backendErrors.error }}</p>
      </div>
    </div>
  </form>
</template>

<script>
import Select from "src/components/userforms/Select.vue";
import AppDatePicker from "src/components/base/AppDatePicker.vue";

import dateFormat from "dateformat";
import { DATE_FORMAT_CREATE_FREIGHT } from "src/constants";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CreateFreight",
  components: { Select, AppDatePicker },
  props: {
    isSubmitFreight: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      loading: false,
      fakeSelectOpts: [
        { value: 1, title: "Значение 1" },
        { value: 2, title: "Значение 2" },
      ],
      boolOpts: [
        { value: 0, title: "Нет" },
        { value: 1, title: "Да" },
      ],
    };
  },
  validations() {
    return {
      freight: {
        number: { required },
        freight_type_id: { required },
        dispatch_date: { required },
        arrival_date: { required },
        src_warehouse_id: { required },
        dst_warehouse_id: { required },
        src_point_id: { required },
        dst_point_id: { required },
        src_sorting_center_id: { required },
        dst_sorting_center_id: { required },
        vehicle: { required },
        driver: { required },
        status: { required },
        number_of_containers: { required },
        number_of_shipments: { required },
        total_weight: { required },
        surplus: { required },
        shortage: { required },
        accept_user_id: { required },
        dispatch_user_id: { required },
        seal: { required },
        comment: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      typeListOptions: 'freights/typeListOptions',
      sortingCentersOptions: 'freights/sortingCentersOptions',
      warehouseOptions: 'warehouses/warehouseOptions',
    }),
    freight: {
      get() {
        return this.$store.state.freights.newFreight;
      },
      set(val) {
        this.$store.commit("freights/SET_NEW_FREIGHT", val);
      },
    },
    backendErrors() {
      return this.$store.state.freights.errors.saveFreight;
    },
    pointSelect() {
      return this.$store.getters["points/pointSelect"];
    },
  },
  created() {
    this.getFreightTypes()
    this.getSortingCenters()
    this.getWarehouses({limit: -1})
    this.getPoints()
  },
  methods: {
    ...mapActions({
      getFreightTypes: 'freights/getFreightTypes',
      getSortingCenters: 'freights/getSortingCenters',
      getWarehouses: 'warehouses/getList',
      getPoints: 'points/getList',
    }),

    async submitData() {
      this.loading = true;
      await this.$store.dispatch("freights/save", this.freight);
      this.loading = false;
      if (!Object.keys(this.backendErrors).length) {
        this.$emit("onFreightsCreated");
      }
    },

    async save() {
      if (this.loading) return;

      this.v$.$touch();
      const isValid = await this.v$.$validate();
      if (isValid) {
        this.v$.$reset();
        if (this.isSubmitFreight) {
          this.submitData()
        } else {
          this.$emit('add-freight', this.freight)
        }
      }
    },
    onInput(field, { target }) {
      this.v$.freight[field].$touch();
      this.freight = { [field]: target.value };
    },
    onSelect(field, val) {
      this.v$.freight[field].$touch();
      this.freight = { [field]: val };
    },
    onDPUpdate(field, val) {
      this.v$.freight[field].$touch();
      this.freight = { [field]: dateFormat(val, DATE_FORMAT_CREATE_FREIGHT) };
    },
    onDPClear(field) {
      this.v$.freight[field].$touch();
      this.freight = { [field]: "" };
    },
  },
};
</script>
