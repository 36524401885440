<script>
import { ElNotification } from "element-plus";
import BaseSelect from "@/components/selectors/BaseSelect";
import {getReturnActStatuses} from "@/views/return-acts/api/api";

export default {
  extends: BaseSelect,
  name: "ReturnActStatusSelector",
  props: {
    modelValue: {
      type: [String, Array, null],
      default: null,
    },
  },
  data() {
    return {
      placeholder: 'Статус возвратного акта',
      isLoading: false,
      label: "label",
      value: [],
      reduce: item => item.value,
      options: [],
      selectedIds: this.modelValue,
    };
  },
  async created() {
    this.isLoading = true;
    await this.getItems();
    this.isLoading = false;
  },
  methods: {
    onChange() {
      let value = [];
      if (this.multiple) {
        value = Object.assign([], this.selectedIds);
      } else {
        value = parseInt(this.selectedIds);
        value = isNaN(value) ? [] : [value];
      }
      this.$emit('change', value);
    },
    async getItems() {
      try {
        getReturnActStatuses()
          .then((result) => {
            let resultData = result.data
            this.options = Object.keys(resultData).map(key => ({
              label: resultData[key],
              value: parseInt(key),
            }));
          });
      } catch (error) {
        ElNotification({
          title: 'Ошибка',
          message: 'Не удалось загрузить статусы возвратного акта',
          type: "error",
        });
      }
    },
  },
  watch: {
    modelValue: function (newValue) {
      this.selectedIds = newValue;
    }
  }
}
</script>

<style scoped>
</style>
