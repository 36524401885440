import apiV2Service from "@/api/V2/services/apiV2.service";
import {SHIPMENTS_PAGE_LIMIT} from "@/constants";

const store = {
  namespaced: true,
  state() {
    return {
      searchResult: [],
      searchMeta: {
        isLastPage: false,
      },
    }
  },
  getters: {
    search(state) {
      return state.searchResult;
    },
    searchMeta(state) {
      return state.searchMeta;
    },
  },
  actions: {
    scanMulti(context, params) {
      return apiV2Service.post(`shipments/scanMulti`, params)
    },
    acceptMulti(context, params) {
      return apiV2Service.post(`shipments/acceptMulti`, params)
    },
    sendToGb(context, params) {
      return apiV2Service.post(`shipments/sendToGb`, params)
    },
    prolong(context, uuid) {
      return apiV2Service.put(`shipments/${uuid}/prolong`)
    },
    scanBarcode(context,params) {
      return apiV2Service.post('shipments/scanBarcode',params)
    },
    checkIfIssued(context,uuid) {
      return apiV2Service.put('shipments/'+uuid+'/checkIfIssued', {});
    },
    search({commit}, params) {
      return apiV2Service.get(`shipments/search`, {params}).then(result => {
        commit('setSearchResult', {result, params});
      })
    },
    fullSearch({commit}, params) {
      return apiV2Service.get(`shipments/fullSearch`, {params}).then(result => {
        commit('setSearchResult', {result, params});
      })
    },
    imported({commit}, params) {
      return apiV2Service.get(`shipments/imported`, {params}).then(result => {
        commit('setSearchResult', {result, params});
      })
    },
    forSending() {
      return apiV2Service.get(`shipments/forSending`);
    },
    readyToPickUp({commit}, params) {
      return apiV2Service.get(`shipments/readyToPickUp`, {params}).then(result => {
        commit('setSearchResult', {result, params});
      })
    },
    clearSearch({commit}) {
      commit('clearSearch');
    },
    forFreight() {
      return apiV2Service.get(`shipments/getForFreight`);
    },
  },
  mutations: {
    setSearchResult(state, {result, params}) {
      if (!result.length && params.page > 1) {
        state.searchMeta.isLastPage = true;

        return;
      }

      state.searchResult = result;
      state.searchMeta.page = params.page;
      state.searchMeta.isLastPage = result.length < SHIPMENTS_PAGE_LIMIT;
    },
    clearSearch(state) {
      state.searchResult = [];
      state.searchMeta = {
        page: 1,
        isLastPage: false,
      };
    },
  },
};
export default store;
