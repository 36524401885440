<template>
  <el-form
    label-position="top"
    class="mt-4"
    @submit.prevent
    ref="formScan"
  >
    <el-form-item>
      <input
        class="el-input__inner"
        type="search"
        @keyup.enter.prevent="scanShipment"
        v-model="barcode"
        placeholder="Введите штрихкод (Enter для подтверждения)"
        required
        :disabled="disabled"
      >
    </el-form-item>
  </el-form>
</template>

<script>
import {ElNotification} from 'element-plus'
import {useSound} from "@/composition/useSound";

export default {
  name: 'ScanShipmentForm',
  data() {
    return {
      barcode: '',
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    addedItems: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      ...useSound()
    }
  },
  methods: {
    playSound(success) {
      if (success) {
        this.playPositiveSound();
      } else {
        this.playNegativeSound()
      }
    },
    scanShipment() {
      const form = this.$refs.formScan.$el;

      if (!form.checkValidity()) {
        form.reportValidity();

        return;
      }

      let item = this.addedItems.find(item => {
        return item.barcode === this.barcode
      });

      if (item) {
        this.playSound(true);

        ElNotification({
          title: 'Ошибка',
          message: 'Отправления с таким штрихкодом уже добавлено',
          type: 'error',
        });

        return;
      }

      item = this.items.find(item => {
        return item.barcode === this.barcode
      });

      if (!item) {
        this.playSound(false);

        ElNotification({
          title: 'Ошибка',
          message: 'Отправления с таким штрихкодом не существует',
          type: 'error',
        });

        return;
      }

      this.barcode = '';
      this.playSound(true);
      this.$emit("scanned", item);
    },
  }
};
</script>

<style lang="scss" scoped>
.el-input__inner {
  height: 42px;
}

.el-input__inner:disabled {
  background-color: var(--el-disabled-bg-color);
  box-shadow: 0 0 0 1px var(--el-disabled-border-color) inset;
  color: var(--el-disabled-text-color);
  cursor: not-allowed;
}

.el-input__inner ~ .input__validate {
  display: none;
}
</style>
