import Warehouse from "./Warehouse.vue";
import CreateShipment from "./CreateShipment.vue";
import WarehouseInfo from "./WarehouseInfo.vue";
import EditWarehouse from "./EditWarehouse.vue";
import permissions from "@/modules/auth/auth.types.ts";

export const warehouseRoutes = [
  {
    path: "/warehouse",
    component: Warehouse,
    name: "warehouse",
    meta: {
      title: "Отправка на склад",
      middleware: [permissions.OWN_SENDING_VIEW]
    },
  },
  {
    path: "/warehouse/create",
    component: CreateShipment,
    name: "warehouse-create",
    meta: { title: "Создать отправку", isHeaderBackLink: true },
  },
  {
    path: "/warehouse/:id",
    component: WarehouseInfo,
    name: "warehouse-info",
    meta: {
      title: "Отправка",
      isHeaderBackLink: true,
      middleware: [permissions.OWN_SENDING_VIEW]
    },
  },
  {
    path: "/warehouse/:id/edit",
    component: EditWarehouse,
    name: "warehouse-edit",
    meta: { isHeaderBackLink: true },
  },
];
