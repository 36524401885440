<template>
  <el-dialog
    :close-on-press-escape="false"
    :show-close="false"
    :close-on-click-modal="false"
    v-model="isAcceptanceContractModalVisible"
    title="ВНИМАНИЕ"
    top="50px"
    width="60%"
    align-center
    center
  >
    <el-card style="height: 700px">
      <div class="message-container">
        <span class="acceptance-message">
          Уважаемый {{ modalContent.currentUserName }}!
          Для дальнейшей работы с программным обеспечением необходимо акцептовать
          договор оферты редакция от 20.07.2023 по {{ modalContent.partnerName }}
        </span>
      </div>

      <iframe
        loading="eager"
        allowfullscreen="allowfullscreen"
        :src="`${this.modalContent.file_src}#zoom=50`"
        type="application/pdf"
        style="height: 600px;width: 100%"
        title="Агентский договор"
      />
    </el-card>


    <template #footer>
        <span class="dialog-footer">
           <el-button type="info" @click="logout">Отмена</el-button>
          <el-button type="primary" @click="onAcceptanceContractBtnClick">
            Принять договор
          </el-button>
        </span>
    </template>
  </el-dialog>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions, mapGetters} from 'vuex'
import {USER_ROLE_FRANCHISEE_MULTI} from "@/constants";
import {ElNotification} from "element-plus";

export default defineComponent({
  data() {
    return {
      isAcceptanceContractModalVisible: false,
      modalContent: {
        currentUserName: null,
        partnerId: null,
        partnerName: null,
        partnerContractNumber: null
      }
    }
  },
  methods: {
    ...mapActions('user', ['logout']),
    ...mapActions({
      getContract: 'partners/getContract',
    }),
    onCheckAcceptanceContract() {
      if (this.userPartners.length < 1) {
        return;
      }
      const partnerWithUnsignedContract = this.userPartners.find(partner => !partner.is_contract_accepted);

      if (!partnerWithUnsignedContract) {
        return;
      }

      this.modalContent.currentUserName = `${this.currentUser.first_name ?? ''} ${this.currentUser.middle_name ?? ''} ${this.currentUser.last_name ?? ''}`;
      this.modalContent.partnerId = partnerWithUnsignedContract.id;
      this.modalContent.partnerName = partnerWithUnsignedContract.name;
      this.modalContent.partnerContractNumber = partnerWithUnsignedContract.contract_number;

      if (partnerWithUnsignedContract.contract !== null) {
        this.getContract(partnerWithUnsignedContract.contract).then(contract => {
          this.modalContent.file_src = URL.createObjectURL(contract);
          this.isAcceptanceContractModalVisible = true;
        })
      } else {
        this.modalContent.file_src = '/files/Агентский%20договор.pdf';
        this.isAcceptanceContractModalVisible = true;
      }
    },
    onAcceptanceContractBtnClick() {
      this.$store.dispatch('apiV2PartnersStore/acceptContract', this.modalContent.partnerId).then(() => {
        this.isAcceptanceContractModalVisible = false;
        return ElNotification({
          title: 'Договор подписан',
          message: 'Договор подписан',
          type: 'success',
        });
      }, (error) => {
        return ElNotification({
          title: 'Ошибка',
          message: error,
          type: 'error',
        });
      });
    }
  },
  computed: {
    ...mapGetters('user', ['currentUser', 'role_id', 'partners']),
    ...mapGetters({
      contract: 'partners/contract',
    }),
    isUserMultiFranchisee() {
      return this.role_id === USER_ROLE_FRANCHISEE_MULTI;
    },
    userPartners() {
      return this.partners;
    },
  },
  mounted() {

  },
  watch: {
    isUserMultiFranchisee(isMultiFranchisee) {
      if (!isMultiFranchisee) {
        return;
      }
      this.onCheckAcceptanceContract();
    }
  }
})
</script>

<style scoped lang="scss">
.dialog-footer button:first-child {
  margin-right: 10px;
}

.acceptance-message {
  word-break: normal;
  font-size: 14px;
  line-height: 25px;
}

.message-container {
  width: 100%;
  padding: 0 10%;
  text-align: center;
  margin-bottom: 20px;
  height: 100%;
}
</style>
