import client from "./client";
import createCrud from "./createCrud";

async function authentication(user) {
  return await client.post("/sanctum/token", user);
}
async function register(user) {
  return await client.post("/sanctum/register", user);
}
async function restore(user) {
  return await client.post("/sanctum/restore", user);
}
async function getAuthUser() {
  return await client.post("/sanctum/user");
}

async function sendLinkForChangePassword(email) {
  return await client.post("/users/sendLinkForChangePassword", {email: email});
}

async function getUsersForPvz(data) {
  return await client.post(`/users/franchisee/getUsersForPvz`, data);
}

async function createOrUpdateWorker(data) {
  return await client.post(`/users/franchisee/createOrUpdateWorker/${data.id}`, data);
}

async function blockFranchiseeUser(userId) {
  return await client.delete(`/users/franchisee/blockFranchiseeUser/${userId}`);
}

async function saveNewPassword(data) {
  return await client.post("/users/saveNewPassword/" + data.token, {password: data.password});
}

const { getAll, createItem, readItem, updateItem, deleteItem } = createCrud(
  client,
  "/users"
);

export {
  authentication,
  register,
  restore,
  getAuthUser,
  getAll,
  createItem,
  readItem,
  updateItem,
  deleteItem,
  sendLinkForChangePassword,
  saveNewPassword,
  getUsersForPvz,
  createOrUpdateWorker,
  blockFranchiseeUser
};
