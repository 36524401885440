<script>
import Toolbar from "@/components/toolbar";
import BaseSelect from "@/components/toolbar/BaseSelect.vue";
import DatePicker from "@/components/toolbar/DatePicker.vue";
import {USER_ROLE_ADMIN, USER_ROLE_SUPER} from "@/constants";
import {mapActions, mapGetters} from "vuex";
import SimplePagination from "@/components/SimplePagination.vue";
import {downloadFile, getFileNameWithExtensionFromHeaders} from "@/store/helper";

export default {
  name: "PpActs",
  components: {SimplePagination, DatePicker, BaseSelect, Toolbar},
  data() {
    return {
      isLoading: false,
      page: 1,
      filters: {
        point_ids: [],
        shop_ids: [],
        date_from: null,
        date_to: null,
        search: null,
      },
      tableData: [],
    }
  },
  mounted() {
    this.clearPpActs();
    this.onLoadingPpActs();
  },
  computed: {
    ...mapGetters('user', ['role_id', 'points']),
    ...mapGetters({
      ppActs: 'apiV2PpActStore/ppActs',
      ppActsMeta: 'apiV2PpActStore/ppActsMeta',
    }),
    isUserAdmin() {
      return [USER_ROLE_ADMIN, USER_ROLE_SUPER].indexOf(this.role_id) !== -1;
    },
    allPointsForSelect() {
      const sourcePoints =
        (this.points.length > 0 && [USER_ROLE_ADMIN, USER_ROLE_SUPER].indexOf(this.role_id) === -1)
          ? this.points
          : this.$store.state.points.list;

      return sourcePoints.map(item => ({value: item.id, title: item.name}));
    },
    allShopsForSelect() {
      return this.$store.state.apiV2ShopStore.list.map((item) => {
        return {value: item.id, title: item.name};
      });
    },
  },
  methods: {
    ...mapActions({
      getPpActs: 'apiV2PpActStore/ppActs',
      clearPpActs: 'apiV2PpActStore/clearPpActs',
    }),
    onDatePickerChanged() {
      setTimeout(() => {
        this.onLoadingPpActs();
      }, 100);
    },
    async onLoadingPpActs() {
      this.isLoading = true;
      await this.getPpActs({filter: this.filters, page: this.page}).finally(() => {
        this.isLoading = false;
      });
    },
    formatDate(date) {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }

      const options = {day: '2-digit', month: '2-digit', year: 'numeric'};
      return date.toLocaleDateString('ru-RU', options).replace(/\//g, '.');
    },
    downloadPpAct(ppAct) {
      this.$store.dispatch('apiV2DocumentStore/downloadPpAct', ppAct.uuid).then(response => {
        const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        downloadFile(url, fileNameWithExtension)
      });
    },
    next() {
      this.page++;
      this.onLoadingPpActs();
    },
    prev() {
      this.page--;
      this.onLoadingPpActs();
    },
    onRowClick(event, item) {
      if (event.target.classList.contains('act-button')) {
        return
      }
      this.$router.push({name: 'ppAct', params: {uuid: item.uuid}})
    },
    clearFilterSearch() {
      this.filters.search = null;
    },
    onSearchKeydownEnter() {
      this.page = 1;
      this.onLoadingPpActs()
    }
  },
  created() {
    if (!this.allPointsForSelect.length) {
      this.$store.dispatch("points/getList")
    }
    if (!this.allShopsForSelect.length) {
      this.$store.dispatch("apiV2ShopStore/getShops")
    }
  },
}
</script>

<template>
  <Toolbar>
    <template v-slot:filter>
      <span class="custom-search">
        <input
          v-model="this.filters.search"
          required
          class="custom-search-input form-control"
          placeholder="Номер акта ПП"
          type="search"
          @keydown.enter="onSearchKeydownEnter()"
        >
        <button
          @click="clearFilterSearch"
          type="button"
          class="close-icon"
        >
        </button>
      </span>

      <BaseSelect
        v-model="filters.point_ids"
        :options="allPointsForSelect"
        placeholder="ПВЗ приёма"
        multiple
        @search="onLoadingPpActs"
      />

      <template v-if="isUserAdmin">
        <BaseSelect
          v-model="filters.shop_ids"
          :options="allShopsForSelect"
          placeholder="Магазин"
          multiple
          @search="onLoadingPpActs"
        />
      </template>

      <DatePicker
        v-model="filters.date_from"
        placeholder="Дата от"
        @onUpdate="onDatePickerChanged"
      />

      <DatePicker
        v-model="filters.date_to"
        placeholder="Дата до"
        @onUpdate="onDatePickerChanged"
      />
    </template>
  </Toolbar>
  <div class="transport-current-table overflow-auto">
    <table style="width: 100%; border-collapse: collapse;">
      <thead>
      <tr>
        <th class="table__cell table__th">Статус</th>
        <th class="table__cell table__th">Номер акта</th>
        <th class="table__cell table__th">Дата акта</th>
        <th class="table__cell table__th">Кол-во отправлений</th>
        <th class="table__cell table__th">Курьер</th>
        <th class="table__cell table__th">Права отправителя</th>
        <th class="table__cell table__th">Акт приема передачи</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in ppActs" :key="item.id" @click="onRowClick($event, item)">
        <td class="table__cell">{{ item.status_name }}</td>
        <td class="table__cell">{{ item.number }}</td>
        <td class="table__cell">{{ formatDate(item.created_at) }}</td>
        <td class="table__cell">{{ item.shipment_count }}</td>
        <td class="table__cell">{{ item.courier_name }} {{ item.courier_surname }} {{ item.courier_patronymic }}</td>
        <td class="table__cell">{{ item.courier_document_type_name }}</td>
        <td class="table__cell">
          <button class="green-button act-button" @click.prevent="downloadPpAct(item)" :disabled="!item.is_closed"
                  type="button">Акт ПП
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <SimplePagination
    v-if="Object.keys(ppActs).length"
    v-model:page="ppActsMeta.page"
    @next="next"
    @prev="prev"
    :show-page="false"
    :is-last-page="ppActsMeta.isLastPage"
  />
</template>

<style scoped lang="scss">

</style>
