<template>
  <el-dialog
      :model-value="modelValue"
      title="Печать документов"
      width="40%"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :show-close="true"
      @update:modelValue="$emit('update:modelValue', $event)"
  >
    <el-row :gutter="20">
      <el-col :span="12">
        <el-button @click="this.printSticker(this.shipment.id)" type="primary" style="width: 100%">Печать этикетки</el-button>
      </el-col>
      <el-col :span="12">
        <el-button @click="this.receiptAvitoAcceptance(this.shipment.id)" type="primary" style="width: 100%">Печать накладной</el-button>
      </el-col>
    </el-row>

    <template v-if="shipment.point_src?.need_declaration">
      <el-row :gutter="20" style="margin-top: 20px;">
        <el-col :span="12">
          <el-button @click="printKaliningradInvoice" type="primary" style="width: 100%">Печать накладной (Калининград)
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button @click="printKaliningradContract" type="primary" style="width: 100%">Печать договора (Калининград)
          </el-button>
        </el-col>
      </el-row>
    </template>

  </el-dialog>
</template>


<script>
import {ElDialog} from 'element-plus';
import {usePrint} from "@/composition/usePrint";
import {downloadFile, getFileNameWithExtensionFromHeaders} from "@/store/helper";

export default {
  name: "PrintDocumentDialog",

  components: {ElDialog},

  setup() {
    return {
      ...usePrint(),
    }
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    shipment: {
      type: Object,
      required: true,
    }
  },
  methods: {
    printKaliningradInvoice() {
      this.$store.dispatch('apiV2DocumentStore/downloadKaliningradInvoice', this.shipment.uuid).then(response => {
        const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        downloadFile(url, fileNameWithExtension)
      });
    },
    printKaliningradContract() {
      this.$store.dispatch('apiV2DocumentStore/downloadKaliningradContract', this.shipment.uuid).then(response => {
        const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        downloadFile(url, fileNameWithExtension)
      });
    },
  }
}
</script>

<style scoped lang="scss">

</style>
