const usePrint = () => {
  const baseURL = process.env.VUE_APP_API_ROOT;

  const printLink = document.createElement('a');
  printLink.setAttribute('download', true);

  /*
  заменен на printSticker
  const generatePdf = (id) => {
      printLink.href = `${baseURL}/generate-pdf/?shipment_id=${id}`;
      printLink.click();
  }*/

  const receiptAvitoIssuance = (id) => {
    printLink.href = `${baseURL}/receipt_avito_issuance/?shipment_id=${id}`;
    printLink.click();
  }

  const receiptAvitoAcceptance = (id) => {
    printLink.href = `${baseURL}/receipt_avito_acceptance/?shipment_id=${id}`;
    printLink.click();
  }

  const exportRegistry = (id) => {
    printLink.href = `${baseURL}/export_registry?sending_id=${id}`;
    printLink.click();
  }

  const actPP = (id) => {
    printLink.href = `${baseURL}/freight_act?freight_id=${id}`;
    printLink.click();
  }

  const commercialAct = (id) => {
    printLink.href = `${baseURL}/refusal?shipment_id=${id}`;
    printLink.click();
  }

  const printSticker = (id) => {
    printLink.href = `${baseURL}/etic-pdf?shipment_id=${id}`;
    printLink.click();
  }

  return {
    // generatePdf,
    receiptAvitoIssuance,
    receiptAvitoAcceptance,
    exportRegistry,
    commercialAct,
    printSticker,
    actPP,
  }
}

export {usePrint}
