import Avito from "@/views/avito/Avito";
import permissions from "@/modules/auth/auth.types.ts";

export const avitoRoutes = [
    {
        path: '/avito',
        component: Avito,
        name: 'avito',
        meta: {
            middleware: [permissions.SEND_TARIFF_IN_AVITO],
            title: "Отправка тарифов в Авито"
        },
    }
]

export default avitoRoutes;
