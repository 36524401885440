<script>
import {ElNotification} from "element-plus";
import BaseSelect from "@/components/selectors/BaseSelect";
import permissions from "@/modules/auth/auth.types.ts";

export default {
  extends: BaseSelect,
  name: "PointSelect",
  created() {
    this.placeholder = 'Выберите пункт выдачи';

    this.isLoading = true;

    this.selectedIds = this.modelValue;

    this.isLoading = false;
  },
  data() {
    return {
      isLoading: false,
      label: "label",
      value: [],
      reduce: item => item.value,
      options: [],
      selectedIds: null
    }
  },
  emits: ['change'],
  props: {
    modelValue: {
      type: [String, Array, null],
      default: null,
    },
    partnerId: {
      type: [String, null],
      default: null
    },
  },
  methods: {
    onChange() {
      let value = [];
      if (this.multiple) {
        value = Object.assign([], this.selectedIds);
      } else {
        value = parseInt(this.selectedIds);
        value = isNaN(value) ? [] : [value];
      }

      this.$emit('change', value);
    },
    async getItems() {
      if (this.partnerId) {
        await this.$store.dispatch("partners/readItem", this.partnerId).then((result) => {
          this.options = [];

          if ((this.selectedIds !== null ) && !result.data.active_points.find(point => point.id === this.selectedIds)) {
            this.selectedIds = null

            ElNotification({
              title: 'Предупреждение',
              message: 'У пользователя был изменен ПВЗ',
              type: "warning"
            })
          }

          result.data.active_points.map((item) => {
            this.options.push({
              label: item.name,
              value: item.id,
            })
          });
        });
      } else {
        await this.$store.dispatch("points/getList", {force: true});

        if (this.$store.state.user.user.permissions.includes(permissions.VIEW_ANY_POINTS_IN_SELECTOR)) {
          this.$store.state.points.list.map((item) => {
            this.options.push({
              label: item.name,
              value: item.id,
            })
          });
        } else {
          this.$store.state.user.user.points.map((item) => {
            this.options.push({
              label: item.name,
              value: item.id,
            })
          });
        }
      }
    }
  },
  watch: {
    modelValue: function(newValue) {
      this.selectedIds = newValue;
    },

    partnerId: function() {
      this.getItems();
    },
  },
}
</script>
