<template>
  <select
    class="form-select modal-add-worker-form-style-select"
    aria-label=""
    @change.prevent.stop="onSelect"
    v-bind:value="value"
  >
    <option
      v-for="(option, index) in items"
      :selected="index === 0"
      :disabled="index === 0 && isPlaceholderDisabled"
      :key="option.value"
      :value="option.value"
    >
      {{ option.title }}
    </option>
  </select>
</template>

<script>
export default {
  name: "Select",
  props: {
    value: Number,
    placeholder: String,
    options: Array,
    isPlaceholderDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      return [{ value: -1, title: this.placeholder }, ...this.options];
    },
  },
  methods: {
    onSelect({ target }) {
      // console.log(this.options, this.items);
      this.$emit("select", parseInt(target.value, 10));
    },
  },
};
</script>
