<template>
  <el-table :data="logs">
    <el-table-column prop="description" label="Что" width="200px"/>
    <el-table-column label="Как" >
      <template #default="scope">
        <el-collapse v-if="isJson(scope.row.additional_info)">
          <el-collapse-item title="JSON" name="1">
            <div>
              <pre>
{{ pretty(scope.row.additional_info) }}
              </pre>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div v-else>
          {{ scope.row.additional_info }}
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="user" label="Кто"/>
    <el-table-column prop="created_at" label="Когда" width="180px"/>
  </el-table>
</template>

<script>
export default {
  props: {
    logs: Array,
  },
  methods: {
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }

      return true;
    },
    pretty(value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    }
  },
}
;
</script>
<style>
 .el-table .cell {
   word-break: normal;
   vertical-align: top;
 }
</style>
