import apiV2Service from "@/api/V2/services/apiV2.service";

const store = {
  namespaced: true,
  state() {
    return {
      all: [],
      default: 0,
    }
  },
  getters: {
    all(state) {
      return state.all;
    },
    default(state) {
      return state.default;
    },
  },
  actions: {
    fetch({commit}) {
      return apiV2Service.get(`shipment-code-types`).then(result => {
        commit('setAll', result);
      })
    },
  },
  mutations: {
    setAll(state, result) {
      state.all = result.options;
      state.default = result.default;
    },
  },
};
export default store;
