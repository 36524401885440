import Instructions from "./Instructions.vue";

export const instructionsRoutes = [
  {
    path: "/instructions",
    component: Instructions,
    name: "instructions",
    meta: { title: "Инструкции" },
  },
];
