<template>

  <el-form
    v-if="visible.searchingOpenPpAct"
    novalidate
    label-position="top"
    class="container-fluid mt-4"
  >
    <div class="col-lg-8 col-md-10 col-sm-12">
      <h3 class="h3__title">Поиск открытого акта приёма-передачи...</h3>
    </div>
  </el-form>


  <el-form
    v-if="visible.ppActFinder"
    novalidate
    :model="ppActFinderForm"
    ref="formPpActFinder"
    label-position="top"
    class="container-fluid mt-4"
    @submit.prevent="createPpAct"
  >
    <div class="col-lg-8 col-md-10 col-sm-12">
      <h3 class="h3__title">Создание акта приёма-передачи</h3>


      <el-row :gutter="16">
        <el-col :span="16">
          <el-form-item label="Фамилия курьера">
            <input class="el-input__inner" type="text"
                   v-model="ppActFinderForm.courier.surname"
                   placeholder="Фамилия" required>
            <p class="input__validate">Фамилия курьера</p>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="16">
        <el-col :span="16">
          <el-form-item label="Имя курьера">
            <input class="el-input__inner" type="text"
                   v-model="ppActFinderForm.courier.name"
                   placeholder="Имя" required>
            <p class="input__validate">Имя курьера</p>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="16">
        <el-col :span="16">
          <el-form-item label="Отчество курьера">
            <input class="el-input__inner" type="text"
                   v-model="ppActFinderForm.courier.patronymic"
                   placeholder="Отчество" required>
            <p class="input__validate">Отчество курьера</p>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="16">
        <el-col :span="16">
          <el-form-item label="Права отправителя">
            <el-radio-group v-model="ppActFinderForm.courier.document_type" required>
              <el-radio
                v-for="item in courier_document_types"
                :key="item.value"
                :label="item.value"
              >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="16">
        <el-col :span="16">
          <el-form-item label="Штрихкод">
            <input
              class="el-input__inner"
              type="search"
              @keyup.enter.prevent="createPpAct"
              v-model="ppActFinderForm.barcode"
              placeholder="Введите штрихкод (Enter для создания акта)"
              required
            >
            <p class="input__validate">Введите штрихкод отправления</p>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="16">
        <el-col :span="16" class="d-grid">
          <el-button
            type="primary"
            plain
            :icon="Search"
            :loading="isLoading"
            @click.prevent="createPpAct"
          >
            Создать акт
          </el-button>
        </el-col>
      </el-row>

    </div>
  </el-form>


  <template v-if="visible.multiAccept">
    <div class="storageItemInfo-title">
      <h3 class="h3__title">Мультиприем заказов в акт {{ ppAct.number }}</h3>

      <div class="buttons">
        <el-button
          type="primary"
          size="small"
          @click="onClosePpAct()"
          :loading="isLoading"
          :disabled="ppActIsClosed"
        >
          Закрыть акт ПП
        </el-button>


        <el-button
          type="success"
          size="small"
          :icon="Printer"
          :loading="isLoading"
          :disabled="!ppActIsClosed"
          @click="onDownloadPpAct()"
        >
          Печать акта ПП
        </el-button>
      </div>
    </div>

    <div class="storageItemInfo-data-info mb-4">
      <div class="storageItemInfo-data-info-container">
        <div class="storageItemInfo-data-info-row">
          <p class="storageItemInfo-data-items-left">Номер акта</p>
          <p class="storageItemInfo-data-items-right">{{ ppAct.number }}</p>
        </div>
        <div class="storageItemInfo-data-info-row">
          <p class="storageItemInfo-data-items-left">Статус акта</p>
          <p class="storageItemInfo-data-items-right">{{ ppAct.status_name }}</p>
        </div>
        <div class="storageItemInfo-data-info-row">
          <p class="storageItemInfo-data-items-left">Кол-во отправлений</p>
          <p class="storageItemInfo-data-items-right">{{ ppAct.shipment_count }}</p>
        </div>
      </div>
      <div class="storageItemInfo-data-info-container">
        <div class="storageItemInfo-data-info-row">
          <p class="storageItemInfo-data-items-left">Курьер</p>
          <p class="storageItemInfo-data-items-right">{{ ppAct.courier_surname }}
            {{ ppAct.courier_name }}
            {{ ppAct.courier_patronymic }}</p>
        </div>
        <div class="storageItemInfo-data-info-row">
          <p class="storageItemInfo-data-items-left">Права отправителя </p>
          <p class="storageItemInfo-data-items-right">{{ ppAct.courier_document_type_name }}</p>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <el-form
            novalidate
            :model="form"
            ref="formMultiplace"
            label-position="top"
            class=""
            @submit.prevent="scanShipment"
          >


            <div class="col-lg-12">
              <el-row :gutter="16" v-if="showDimensionsInputs">
                <el-col :span="8">
                  <el-form-item label="Ширина (см)">
                    <input class="el-input__inner" type="number" step="0.1" ref="dimensionXInput"
                           v-model="form.dimensions_x"
                           placeholder="Ширина"
                           :disabled="ppActIsClosed"
                           :required="showDimensionsInputs">
                    <p class="input__validate">Введите ширину (см)</p>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <el-form-item label="Длина (см)">
                    <input class="el-input__inner" type="number" step="0.1"
                           v-model="form.dimensions_y"
                           placeholder="Длина"
                           :disabled="ppActIsClosed"
                           :required="showDimensionsInputs">
                    <p class="input__validate">Введите длину (см)</p>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <el-form-item label="Высота (см)">
                    <input class="el-input__inner" type="number" step="0.1"
                           v-model="form.dimensions_z"
                           placeholder="Высота"
                           :disabled="ppActIsClosed"
                           :required="showDimensionsInputs">
                    <p class="input__validate">Введите высоту (см)</p>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="16" v-if="showMassInput">
                <el-col :span="8">
                  <el-form-item label="Вес (кг)">
                    <input class="el-input__inner"
                           id="inputMassMultiplace"
                           name="inputMassMultiplace"
                           ref="inputMassRef"
                           type="number"
                           step="0.1"
                           v-model="form.mass"
                           placeholder="Введите вес (кг)"
                           :required="showMassInput"
                           :disabled="ppActIsClosed"
                    >
                    <p class="input__validate">Введите вес (кг)</p>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="16">
                <el-col :span="16">
                  <el-form-item label="Штрихкод">
                    <input
                      class="el-input__inner"
                      type="search"
                      @keyup.enter.prevent="scanShipment"
                      v-model="form.barcode"
                      placeholder="Введите штрихкод (Enter для поиска)"
                      required
                      :disabled="ppActIsClosed"
                      ref="barcodeInput"
                    >
                    <p class="input__validate">Введите штрихкод отправления</p>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="16" class="multi-accept__search-block">
                <el-button
                  type="primary"
                  plain
                  :icon="Search"
                  :loading="isLoading"
                  :disabled="ppActIsClosed"
                  @click.prevent="scanShipment"
                >
                  Сканировать
                </el-button>
              </el-row>

            </div>

            <div class="col-lg-12" v-if="alerts.length > 0">
              <h3 class="h3__title">Результат сканирования:</h3>
              <div class="transport-current-table overflow-auto">
                <table style="width: 100%; border-collapse: collapse;">
                  <thead>
                  <tr v-for="(alert, index) in this.alerts" v-bind:key="index">
                    <td>
                      <div class="alert alert-primary d-flex align-items-center"
                           :class="alert.class" role="alert">
                        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img"
                             :aria-label="alert.ariaLabel">
                          <use :href="alert.svgHref"/>
                        </svg>
                        <div class="multi-accept__message" :innerHTML="alert.message"></div>
                      </div>
                    </td>
                  </tr>
                  </thead>
                </table>
              </div>
            </div>
          </el-form>
        </div>

        <div class="col-md-6">
          <el-table :data="shipmentsArray" style="width: 100%">
            <el-table-column label="№" width="50px">
              <template v-slot="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column prop="shipment.number" label="Номер"></el-table-column>
            <el-table-column prop="shipment.barcode" label="Штрих-код"></el-table-column>
            <el-table-column label="Печать этикетки">
              <template v-slot="scope">
                <el-button type="success" @click="() => printSticker(scope.row.shipment.id)">
                  Этикетка
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>


  </template>


  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path
        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
    </symbol>
    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
      <path
        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
    </symbol>
    <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path
        d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
    </symbol>
  </svg>
  <CeilSelect
    v-model="ceil.value"
    v-model:showModal="ceil.dialogVisible"
    @update:modelValue="onSelectCeil"
  />
</template>


<script>

import {ElMessageBox, ElNotification} from "element-plus";
import {reactive} from "vue";
import CeilSelect from "@/components/freights/franchisee/CeilSelect.vue";
import {usePrint} from "@/composition/usePrint";
import {Printer, Search} from '@element-plus/icons-vue'
import {downloadFile, getFileNameWithExtensionFromHeaders} from "@/store/helper";

const ALERT_TYPES = reactive({
  ALERT_TEMPLATE_PRIMARY: {
    class: 'alert-primary',
    ariaLabel: 'Info:',
    svgHref: '#info-fill',
    message: 'Информация!'
  },
  ALERT_TEMPLATE_SUCCESS: {
    class: 'alert-success',
    ariaLabel: 'Success:',
    svgHref: '#check-circle-fill',
    message: 'Успех!'
  },
  ALERT_TEMPLATE_WARNING: {
    class: 'alert-warning',
    ariaLabel: 'Warning:',
    svgHref: '#exclamation-triangle-fill',
    message: 'Предупреждение!'
  },
  ALERT_TEMPLATE_DANGER: {
    class: 'alert-danger',
    ariaLabel: 'Danger:',
    svgHref: '#exclamation-triangle-fill',
    message: 'Ошибка!'
  }
})

export default {
  computed: {
    ppActIsClosed() {
      return this.ppAct && this.ppAct.is_closed;
    },
    shipmentsArray() {
      if (!this.ppAct || !this.ppAct.pp_act_shipments) {
        return [];
      }
      return Object.values(this.ppAct.pp_act_shipments);
    },
    Printer() {
      return Printer
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
    Search() {
      return Search
    },
    showDimensionsInputs() {
      if (this.ppAct && this.ppAct.shop) {
        if (this.ppAct.shop.calculate_tariff_by_declared_weight) {
          return false;
        }
        return this.ppAct.shop.use_volume_weight;
      }
      return false;
    },

    showMassInput() {
      return (this.ppAct && this.ppAct.shop && (!this.ppAct.shop.calculate_tariff_by_declared_weight));
    }
  },
  components: {
    CeilSelect

  },
  setup() {
    return {
      ...usePrint(),
    }
  },
  data() {
    return {
      ppAct: null,
      courier_document_types: [
        {
          value: 1,
          label: 'Доверенность'
        },
        {
          value: 2,
          label: 'Представитель ИМ'
        },
        {
          value: 3,
          label: 'Представитель ТК'
        },
      ],
      visible: {
        searchingOpenPpAct: true,
        ppActFinder: false,
        multiAccept: false
      },
      ceil: {
        dialogVisible: false,
        value: null,
      },
      headerTitle: 'Мультиприем',
      result: {
        alerts: [],
        flags: {},
        shipment: null
      },
      alerts: [
        ALERT_TYPES.ALERT_TEMPLATE_PRIMARY,
        ALERT_TYPES.ALERT_TEMPLATE_SUCCESS,
        ALERT_TYPES.ALERT_TEMPLATE_WARNING,
        ALERT_TYPES.ALERT_TEMPLATE_DANGER,
      ],

      ppActFinderForm: {
        courier: {
          name: null,
          surname: null,
          patronymic: null,
          document_type: null,
        },
        barcode: null
      },
      form: {
        dimensions_x: null,
        dimensions_y: null,
        dimensions_z: null,
        mass: null,
        barcode: null,

        ceil_id: null,
        pp_act_uuid: null,
      }
    }
  },
  mounted() {
    this.alerts = [];
    this.$store.commit("SET_TITLE", this.headerTitle);
    this.findOpenPpAct();
  },
  methods: {
    isPrintShkAfterAccept() {
      return (this.ppAct && this.ppAct.shop && this.ppAct.shop.is_print_shk_after_accept)
    },
    onSelectCeil() {
      this.form.ceil_id = this.ceil.value;
      this.onAcceptShipment()
    },
    async onAfterScanDataLoaded() {
      this.onShowAlertsAfterLoadedData();
      if (!this.result.flags.is_multi_accept_required) {
        return;
      }
      if (this.result.flags.is_ceil_required) {
        return this.onShowDialogSelectCeil();
      }
      return this.onAcceptShipment();
    },


    async scanShipment() {
      const form = this.$refs.formMultiplace.$el;
      form.classList.add('form--submitted');
      if (!form.checkValidity()) {
        form.reportValidity();
        return ElNotification({
          title: 'Ошибка',
          message: 'Не заполнены обязательные поля формы',
          type: 'error',
        });
      }

      this.alerts = [];
      this.setLoadingAlert();
      this.findOpenPpAct();

      this.$store.dispatch('apiV2ShipmentStore/scanMulti', this.form).then(result => {
        this.alerts = [];
        this.result = result;
        this.onAfterScanDataLoaded();
        this.findOpenPpAct();
      }, (error) => {
        this.onError(error);
      });
    },
    setFocusOnFirstInput() {
      if (this.showDimensionsInputs) {
        this.$refs.dimensionXInput.focus();
      } else if (this.showMassInput) {
        this.$refs.inputMassRef.focus();
      } else {
        this.$refs.barcodeInput.focus();
      }
    },
    async onAfterAcceptDataLoaded() {
      this.onShowAlertsAfterLoadedData();
      if (this.isPrintShkAfterAccept()) {
        await this.printAcceptanceInvoice();
      }
      this.resetForm();
      setTimeout(() => {
        this.setFocusOnFirstInput();
      }, 0);
    },
    findOpenPpAct() {
      this.$store.dispatch('apiV2PpActStore/findOpenPpAct').then(result => {
        this.ppAct = result.pp_act;

        if (!this.ppAct) {
          this.visible.searchingOpenPpAct = false;
          this.visible.ppActFinder = true;
        } else {
          this.visible.searchingOpenPpAct = false;
          this.visible.multiAccept = true;

          this.form.pp_act_uuid = this.ppAct.uuid;

          this.$nextTick(() => {
            this.setFocusOnFirstInput();
          });
        }
      }, (error) => {
        console.warn(error);
        return ElNotification({
          title: 'Ошибка',
          message: error.message,
          type: 'error',
        });
      });
    },
    onShowDialogSelectCeil() {
      this.ceil.dialogVisible = true;
    },
    onShowAlertsAfterLoadedData() {
      this.result.alerts.forEach((item) => {
        let templateKey = `ALERT_TEMPLATE_${item.alert_type_key}`;
        this.addAlert(ALERT_TYPES[templateKey], item.alert_message);
      })
    },
    onError(error) {
      this.alerts = [];
      let alerts = error.errors;
      for (let key in alerts) {
        let alertsForKey = alerts[key];
        alertsForKey.forEach((message) => {
          this.addAlert(ALERT_TYPES.ALERT_TEMPLATE_WARNING, message);
        })
      }
      return ElNotification({
        title: 'Ошибка',
        message: error.message,
        type: 'error',
      });
    },
    setLoadingAlert() {
      this.addAlert(ALERT_TYPES.ALERT_TEMPLATE_PRIMARY, 'Загрузка данных ...');
    },
    addAlert(template, message) {
      this.alerts.push({
        ...(Object.assign(template)),
        ...{
          message: message,
        }
      })
    },
    setFocusOnDimensionX() {
      this.$refs.dimensionXInput.focus();
    },
    resetForm() {
      const form = this.$refs.formMultiplace.$el;
      form.classList.remove('form--submitted');
      form.reset();
      this.form.ceil_id = null;
    },
    onAcceptShipment() {
      let alertsBeforeLoading = this.alerts;
      this.alerts = [];
      this.setLoadingAlert();
      this.$store.dispatch('apiV2ShipmentStore/acceptMulti', this.form).then(result => {
        this.alerts = alertsBeforeLoading;
        this.result = result;
        this.onAfterAcceptDataLoaded();
      }, (error) => {
        this.onError(error);
      });

    },
    printAcceptanceInvoice() {
      return ElMessageBox.confirm(
        null,
        'Печать накладной',
        {
          confirmButtonText: 'Печать накладной',
          cancelButtonText: 'Отмена',
          type: 'success',
        }
      )
        .then(() => {
          this.receiptAvitoAcceptance(this.result.shipment.id);
        })
    },
    async createPpAct() {
      const form = this.$refs.formPpActFinder.$el;
      form.classList.add('form--submitted');
      if (!form.checkValidity()) {
        form.reportValidity();
        return ElNotification({
          title: 'Ошибка',
          message: 'Не заполнены обязательные поля формы',
          type: 'error',
        });
      }

      if (!this.ppActFinderForm.courier.document_type) {
        return ElNotification({
          title: 'Ошибка',
          message: 'Укажите права отправителя!',
          type: 'error',
        });
      }


      this.$store.dispatch('apiV2PpActStore/createPpAct', this.ppActFinderForm).then(() => {
        location.reload();
      }, (error) => {

        if (error.errors) {
          const errors = error.errors;
          for (const key in errors) {
            const errorsForKey = errors[key];
            errorsForKey.forEach((message) => {
              setTimeout(() => {
                ElNotification({
                  title: 'Ошибка',
                  message,
                  type: 'error',
                });
              }, 0);
            });
          }
          return;
        }

        return ElNotification({
          title: 'Ошибка',
          message: error.message,
          type: 'error',
        });
      });
    },
    onClosePpAct() {
      this.$store.dispatch('apiV2PpActStore/closePpActByUuid', this.ppAct.uuid).then((result) => {
        this.ppAct.status = result.pp_act.status;
        this.ppAct.status_name = result.pp_act.status_name;
        this.ppAct.is_closed = result.pp_act.is_closed;

        ElNotification({
          title: 'Акт закрыт',
          message: 'Акт закрыт',
          type: 'success',
        });
      }, error => {
        return ElNotification({
          title: 'Ошибка',
          message: error.message,
          type: 'error',
        });
      });
    },
    onDownloadPpAct() {
      this.$store.dispatch('apiV2DocumentStore/downloadPpAct', this.ppAct.uuid).then(response => {
        const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        downloadFile(url, fileNameWithExtension)
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.h3__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 44px;
  margin-bottom: 16px;
}

b {
  font-weight: bold;
}

.w-1\/2 {
  width: 50%;
}

.el-input__inner {
  height: 42px;
}

.el-input__inner:disabled {
  background-color: var(--el-disabled-bg-color);
  box-shadow: 0 0 0 1px var(--el-disabled-border-color) inset;
  color: var(--el-disabled-text-color);
  cursor: not-allowed;
}

.el-input__inner ~ .input__validate {
  display: none;
}

.form--submitted .el-input__inner:invalid {
  border: 1px solid #dc3545;
}

.form--submitted .el-input__inner:invalid ~ .input__validate {
  display: block;
  color: #dc3545;
}

.multi-accept {
  &__message {
    white-space: pre-line
  }

  &__search-block {
    flex-direction: column;
  }
}
</style>
