import Statistic from "./Statistic.vue";
import permissions from "@/modules/auth/auth.types.ts";
import ShopStatistic from "@/views/admin/ShopStatistic";

export const statisticRoutes = [
  {
    path: "/statistic",
    component: Statistic,
    name: "statistic",
    meta: { title: "Статистика" },
  },
  {
    path: "/statistic/shop",
    component: ShopStatistic,
    name: "shop-statistic",
    meta: {
      title: "Статистика по магазинам",
      isHeaderBackLink: true,
      middleware: [permissions.SHOP_STAT_VIEW]
    },
  },
];
