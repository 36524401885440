import client from './client'
import createCrud from './createCrud'


const { getAll, createItem, readItem, updateItem, deleteItem } = createCrud(client, '/ceils')

export {
  getAll,
  createItem,
  readItem,
  updateItem,
  deleteItem,
}
