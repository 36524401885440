<template>
  <Modal :show="isModalShow" modifyer="usercreate" @close="$emit('close')">
    <div class="modal-dialog custom-modal-dialog modal-dialog-centered">
      <div class="modal-content modal-content--delete">
        <div class="modal-header custom-modal-header">
          <h2 class="custom-modal-header-heading">
            {{ title }}
          </h2>
        </div>
        <div class="modal-body">
          <form
            class="modal-delete-worker deleteWorkerValidation"
            novalidate=""
          >
            <div class="row">
              <div class="col">
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#editWorkerModal"
                  class="delete-worker-modal-button-save cancel"
                  @click="$emit('onCancel')"
                  :disabled="isBtnsDisabled"
                >
                  Отменить
                </button>
              </div>
              <div class="col">
                <button
                  type="submit"
                  class="delete-worker-modal-button-save"
                  @click="$emit('onConfirm')"
                  :disabled="isBtnsDisabled"
                >
                  {{ btnTitle }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "src/components/modal/Modal.vue";

export default {
  components: { Modal },
  props: {
    isModalShow: Boolean,
    isBtnsDisabled: Boolean,
    title: String,
    btnTitle: {
      type: String,
      default: "Подтвердить",
    },
  },
};
</script>
