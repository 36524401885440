<template>
  <div class="container-search" style="justify-content: normal">
    <el-date-picker
      class="input--date-range"
      v-model="dateRange"
      type="daterange"
      start-placeholder="Дата от"
      end-placeholder="Дата до"
    />
    <button class="actionButton" :disabled="isLoading" @click.prevent="getStatData()">Показать</button>
  </div>
  <div class="row" style="margin-top:20px" v-if="statData">
    <div>
      <div class="transport-current-table overflow-auto">
        <table style="width: 100%; border-collapse: collapse;">
          <thead>
          <tr>
            <th class="table__cell table__th">Магазин</th>
            <th class="table__cell table__th">Созданные</th>
            <th class="table__cell table__th">Принятые</th>
            <th class="table__cell table__th">Выполненные</th>
            <th class="table__cell table__th">Отмененные</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in statData" :key="row.shop_id">
            <td class="table__cell">{{ row.shop_name }} {{ row.shop_id }}</td>
            <td class="table__cell">{{ row.cnt_created }}</td>
            <td class="table__cell">{{ row.cnt_accepted }}</td>
            <td class="table__cell">{{ row.cnt_issued }}</td>
            <td class="table__cell">{{ row.cnt_cancelled }}</td>
          </tr>
          </tbody>
        </table>
      </div>


    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {getShopStatistic} from "@/api/V2/statistic.js";

const today = new Date();

export default {
  name: "Users",
  components: {},
  setup() {
    const store = useStore();
    store.commit("SET_TITLE", "Статистика по магазинам");
  },
  computed: {},
  data() {
    return {
      loading: false,
      statData: null,
      dateRange: [
        new Date(today - 604800000),
        today
      ]
    }
  },

  watch: {},
  methods: {

    getStatData() {
      this.isLoading = true;

      const filters = {
        date_from: this.dateRange[0].toLocaleDateString('lt'),
        date_to: this.dateRange[1].toLocaleDateString('lt')
      }

      getShopStatistic(filters).then(response => {
        this.statData = response
      }, (error) => {
        console.log(error);
      });


      this.isLoading = false


    },
  },
};
</script>

