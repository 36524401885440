<template>
  <div class="context_menu">
      <div class="breadcrumb-profilePVZ-container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li v-if="activeManageTeam" class="breadcrumb-item" :class="{active: !isAddressTeam}">
                <router-link
                    :to="`/address/${$route.params.id}`"
                >
                  Пункт выдачи
                </router-link>
              </li>

              <li class="breadcrumb-item" :class="{active: isAddressTeam}">
                <router-link
                    v-if="activeManageTeam"
                    :to="`/address/${$route.params.id}/team`"
                >
                  Команда
                </router-link>
              </li>
              <!-- <li class="breadcrumb-item"><a href="#">Заказ расходников</a></li> -->
            </ol>
          </nav>
      </div>

      <div class="sort-form">
          <form class="sort-items">
            <Search
                v-model:value="filters.search"
                :placeholder="titles.search"
                @search="getPointUsers({ page: 1 })"
            />
            <el-select
                v-model="filters.status"
                class="m-2"
                placeholder="Select"
                size="large"
                style="width: 240px"
                @change="getPointUsers({ page: 1 })"
            >
              <el-option
                  v-for="status in statusSelect"
                  :key="status.value"
                  :label="status.title"
                  :value="status.value"
              />
            </el-select>
          </form>

          <button @click="createUser" class="addWorker">
              Добавить сотрудника
          </button>
      </div>


    <Grid
        :head="tableHead"
        :data="users"
        :cellClass="cellClass"
        @clickrow="true"
        @row-click="onRowClick"
        v-if="users.length > 0"
    />
    <div v-else-if="usersLoading" class="pt-3 pb-3">Загрузка...</div>
    <div v-else class="pt-3 pb-3">Нет информации</div>

    <el-dialog v-if="openDialog" @closed="closePointUserForm" v-model="openDialog" :title=titleDialog>
      <PointUserForm :editUser="editUser" :pointId="this.$route.params.id" ref="form"/>
    </el-dialog>

    <div style="margin-top: 20px; display: flex; justify-content: flex-end">
      <el-pagination @current-change="changePage" background layout="prev, pager, next" :total="total" />
    </div>
  </div>
</template>

<script>
import Grid from "src/components/grid";
import PointUserForm from "@/components/userforms/PointUserForm";
import { Search } from "src/components/toolbar";
import {USER_STATUS_NAMES} from "@/constants";
import permissions from "@/modules/auth/auth.types.ts";
import accessGuard from "@/router/middleware/accessGuard.middleware";

export default {
  name: "AddressTeam",
  components: {
    PointUserForm,
    Grid,
    Search,
  },
  data() {
    return {
      isAddressTeam: true,
      filters: {
        perPage: 10,
        page: 1,
        point_id: this.$route.params.id,
        search: null,
        status: null
      },
      titles: {
        search: "Поиск",
        status: "Все статусы",
      },
      limit: 10,
      total: 0,
      titleDialog: 'fhdshf',
      openDialog: false,
      partnerUsers: [],
      editUser: null,
      tableHead: {
        id: "ID",
        fio: "ФИО",
        email_adress: "Email",
        phone_primary: "Телефон",
        status_name: "Статус",
        registration_date: "Дата регистрации",
      },
    }
  },
  computed: {
    perPage() {
      return this.$store.state.user.pagination.perPage;
    },
    user() {
      return this.$store.state.user.user;
    },
    statusSelect() {
      let statuses = [{ value: null, title: "Все статусы" }];
 
      return statuses.concat(USER_STATUS_NAMES);
    },
    activeManageTeam() {
      return accessGuard.checkScope(this.user, permissions.OWN_USER_MANAGE);
    },
    users() {
      return this.partnerUsers.map((user) => {
        return {
          id: user.id,
          fio: user.full_name,
          email_adress: user.email_adress,
          phone_primary: user.phone_primary,
          status_name: user.status_name,
          registration_date: user.registration_date,
        };
      })
    },
  },
  created() {
    this.getPointUsers();
  },
  methods: {
    cellClass(name) {
      return `address-team address-team_${name}`;
    },

    onRowClick(row) {
      const selectedUser = this.partnerUsers.find(item => item.id === row.id);
      this.editUser = selectedUser;

      this.titleDialog = 'Редактирование пользователя ' + selectedUser.full_name;
      this.openDialog = true;
    },

    createUser() {
      this.titleDialog = 'Создание пользователя';
      this.openDialog = true;
    },
    onEditClose() {
      this.editUser = null;
      this.$store.commit("user/SET_ERRORS", {
        key: "saveUser",
        val: {},
      });
    },
    onUserChange(user) {
      Object.assign(this.editUser, user);
    },
    closePointUserForm() {
      this.getPointUsers();
      this.editUser = null;
    },

    async changePage(page) {
      this.filters.page = page;
      await this.getPointUsers();
    },

    getPointUsers(opts) {
      this.filters = { ...this.filters, ...opts };

      this.$store.dispatch('user/getUsersForPvz', this.filters).then((result) => {
        console.log(result.data.data.users.data);
        this.partnerUsers = result.data.data.users.data;
        this.total = result.data.data.users.total;
      }).catch((error) => {
        console.log(error);
      });
    }
  },
  mounted() {
    this.$store.commit("SET_TITLE", "EXMAIL");
  },
};
</script>

<style lang="scss">
.address-team {
  width: 130px;
  margin-right: 5px;
  word-wrap: break-word;
  &-button {
     width: 100%;
  }
  &_id {
     width: 50px;
   }
  &_fio {
    width: 250px;
  }
  &_email_adress {
     width: 250px;
     overflow: hidden;
  }
  &_status {
     width: 80px;
  }
  &_phone_primary {
     width: 200px;
   }
  &_registration_date {
     width: 150px;
     margin-right: 0;
   }
}
</style>
