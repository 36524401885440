<template>
  <div style="margin-top: 30px">
    <el-form
        novalidate
        @input="$emit('input', $event.target.value)"
        label-position="top"
        class="mt-4"
        @submit.prevent="$emit('submit', filterForm)"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="Имя пользователя">
            <el-input type="text" v-model="filterForm.fio" :placeholder="'Имя пользователя'"/>
          </el-form-item>
          <el-form-item label="email">
            <el-input type="text" v-model="filterForm.email" :placeholder="'email'"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Телефон">
            <el-input type="text" v-model="filterForm.phone" :placeholder="'Телефон'"/>
          </el-form-item>
          <el-form-item label="Инн">
            <el-input type="text" v-model="filterForm.inn" :placeholder="'Инн'"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Название юр. лица">
            <el-input type="text" v-model="filterForm.name" :placeholder="'Название Юр. лица'"/>
          </el-form-item>
          <el-form-item label="Адрес ПВЗ">
            <el-input type="text" v-model="filterForm.point_address" :placeholder="'Адрес ПВЗ'"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Статус заявки">
            <el-select class="w-100" v-model="filterForm.status" placeholder="Статус" clearable>
              <el-option
                  v-for="key in Object.keys(statuses)"
                  :key="key"
                  :label="statuses[key]"
                  :value="key"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="16" class="multi-accept__search-block">
        <el-col :offset="20" :span="4">
          <el-input
              type="submit"
              @click="getRequestsRegistrationUsers"
              :model-value="'Поиск'"
          >
          </el-input>
        </el-col>
      </el-row>
    </el-form>
<hr>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"/>
      <el-table-column prop="request_date" label="Дата" width="80"/>

      <el-table-column prop="user.full_name" label="ФИО польз." />
      <el-table-column prop="user.email_address" label="Email польз."/>
      <el-table-column prop="inn" label="Инн">
        <template #default="scope">
          {{ getPartnerInn(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column prop="full_name" label="Юр. лицо">
        <template #default="scope">
          {{ getPartnerFullName(scope.row) }}<br>
          <a target="_blank" v-if="scope.row.user && scope.row.user.partner_link != null" :href="scope.row.user.partner_link">Юр лицо</a>
        </template>
      </el-table-column>
      <el-table-column prop="full_name" label="ПВЗ" width="150">
        <template #default="scope">
          <div>
            <a target="_blank" v-if="scope.row && scope.row.point_link != null" :href="scope.row.point_link">Id:{{ scope.row.point? scope.row.point.id :''}}</a><br>
          </div>

          <div v-if="scope.row.point" style="margin-top: 5px;">
            <el-tag
                    :type="'primary'"
                    disable-transitions
            >{{ scope.row.point ? pointStatusName(scope.row.point.status) : '' }}
            </el-tag>
          </div>

          <div v-if="scope.row.status === REQUEST_STATUS_APPROVED
          && (scope.row.point != null && [POINT_STATUS_RESERVED, POINT_STATUS_ALMOST_OPEN].includes(scope.row.point.status))"
               style="margin-top: 5px;">
            <el-button size="small" @click="openDialogPointChangeStatus(scope.row)">
              <span style="font-size: 12px;">Сменить статус</span>
            </el-button>
          </div>
        </template>

      </el-table-column>
      <el-table-column prop="point_address"  label="Адрес">
        <template #default="scope">
          {{ scope.row.address }}<br>
        </template>
      </el-table-column>

      <el-table-column prop="point_address"  label="Договор">
        <template #default="scope">
          {{ (scope.row.user && scope.row.user.first_new_exists_and_accepted) ? 'подписан' : '-'  }}
        </template>
      </el-table-column>

      <el-table-column prop="status_name"  label="Статус" width="140">
        <template #default="scope">
          <el-tag
            :type="getColorStatusRequest(scope.row.status)"
            disable-transitions
          >{{ scope.row.status_name }}
          </el-tag
          >

          <div style="margin-top: 5px;">
            <el-tag
              v-if="scope.row.partner_id != null"
              :type="'primary'"
              effect="plain"
              disable-transitions
            >Заявка ПВЗ
            </el-tag
            >

            <el-tag
              v-if="scope.row.partner_request_id != null"
              :type="'primary'"
              effect="plain"
              disable-transitions
            >Заявка партнера
            </el-tag
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column align="right" width="50px">
        <template #default="scope">
          <button
            class="button-open"
            @click="handleEdit(scope.$index, scope.row)"

          ></button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 20px; display: flex; justify-content: flex-end">
      <el-pagination @current-change="changePage" background layout="prev, pager, next" :total="total"/>
    </div>
  </div>

  <el-dialog @closed="closeDialogRequestRegistrationInfo" @opened="loadFormDialog"
             v-model="dialogRequestRegistrationInfo" :title=titleDialog>

    <RegistrationLegalEntityForm v-if="this.row.partner_registration_request != null" isAdmin="true" :request-id="this.row.id" ref="form"/>
    <RegistrationPointView v-if="this.row.partner_registration_request == null" isAdmin="true" :request-id="this.row.id" ref="form" />

    <template v-if="this.row.partner_registration_request != null" #footer>
      <span class="dialog-footer">
        <el-button @click="dialogRequestRegistrationInfo = false">закрыть</el-button>
         <el-button v-if="this.row.status === this.REQUEST_STATUS_CHECK" type="warning" @click="openDialogSaveComment">
          Запросить информацию
        </el-button>
        <el-button v-if="this.row.status === this.REQUEST_STATUS_CHECK" @click="rejectRegistrationRequest(this.row.id)"
                   type="danger">
          Отказать
        </el-button>
        <el-button v-if="this.row.status === this.REQUEST_STATUS_CHECK" type="success"
                   @click="confirmRegistrationRequest(this.row.id)">
          Подтвердить
        </el-button>

        <el-button v-if="this.REQUEST_STATUS_REJECT === this.row.status" type="warning"
                   @click="returnRequestFromReject(this.row.id)">
          Вернуть заявку
        </el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog @closed="closeDialogChangeStatusPoint"
             v-model="dialogChangeStatusPoint" title="Сменить статус ПВЗ">
    <el-form :model="changeStatusPointForm">
      <el-form-item label="Статус">
        <el-select v-model="changeStatusPointForm.status" placeholder="Статус">
          <el-option    v-for="key in Object.keys(POINT_STATUSES_FOR_CHANGE_IN_REQUEST)"
                        :key="key"
                        :label="POINT_STATUSES_FOR_CHANGE_IN_REQUEST[key]"
                        :value="key" />
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogChangeStatusPoint = false">Закрыть</el-button>
        <el-button @click="savePointStatus()" type="primary">
          Сохранить
        </el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="dialogOpenSaveComment" title="Запросить информацию">
    <el-form :model="commentForm">
      <el-form-item label="Комментарий">
        <el-input v-model="commentForm.comment" autocomplete="off" type="textarea"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogOpenSaveComment = false">Закрыть</el-button>
        <el-button @click="askForInformation()" type="primary">
          Сохранить
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>

import RegistrationLegalEntityForm from "@/components/registration/RegistrationLegalEntityForm";
import {ElMessageBox, ElNotification} from "element-plus";
import {
  POINT_STATUS_ALMOST_OPEN,
  POINT_STATUS_RESERVED,
  POINT_STATUSES, POINT_STATUSES_FOR_CHANGE_IN_REQUEST,
  REQUEST_STATUS_APPROVED,
  REQUEST_STATUS_CHECK,
  REQUEST_STATUS_REJECT
} from "@/constants";
import RegistrationPointView from "@/components/registration/RegistrationPointForm";



export default {
  name: "RequestLegalEntityView",
  components: {
    RegistrationPointView,
    RegistrationLegalEntityForm,
  },
  data() {
    return {
      headerTitle: 'Заявки на регистрацию',
      tableData: [],
      dialogRequestRegistrationInfo: false,
      dialogChangeStatusPoint: false,
      dialogOpenSaveComment: false,
      POINT_STATUS_RESERVED: POINT_STATUS_RESERVED,
      POINT_STATUS_ALMOST_OPEN: POINT_STATUS_ALMOST_OPEN,
      POINT_STATUSES_FOR_CHANGE_IN_REQUEST: POINT_STATUSES_FOR_CHANGE_IN_REQUEST,
      REQUEST_STATUS_APPROVED: REQUEST_STATUS_APPROVED,
      REQUEST_STATUS_CHECK: REQUEST_STATUS_CHECK,
      REQUEST_STATUS_REJECT: REQUEST_STATUS_REJECT,
      pointStatuses: POINT_STATUSES,
      titleDialog: '',
      limit: 10,
      total: 0,
      row: '',
      commentForm: {
        id: '',
        comment: '',
      },
      changeStatusPointForm: {
        point_id: '',
        status: '',
      },
      filterForm: {
        fio: '',
        perPage: 10,
        page: 1
      },
      statuses: {
        1: 'Новая',
        2: 'Требует проверки',
        3: 'Одобрена',
        4: 'Отказ',
      },
      tabs: [
        {
          title: 'Заявка партнера',
          content: 'TabOne',
        },
        {
          title: 'Заявка ПВЗ',
          content: 'TabTwo',
        },
      ],
    };
  },
  async created() {
    this.getRequestsRegistrationUsers();
  },
  mounted() {
    this.$store.commit("SET_TITLE", this.headerTitle);
  },

  methods: {
    pointStatusName(id) {
      return this.pointStatuses[id] || "";
    },
    getRequestsRegistrationUsers() {
      this.$store.dispatch('apiV2RegistrationStore/getRequestsRegistrationUsers', this.filterForm).then((result) => {
        console.log(result);
        this.tableData = result.requests.data;
        this.total = result.requests.total;
      });
    },

    handleEdit(index, row) {
      this.dialogRequestRegistrationInfo = true;

      if (row.partner_request_id != null) {
        this.titleDialog = 'Заявка на регистрацию ' + this.getPartnerFullName(row);
      } else {
        this.titleDialog = 'Заявка на регистрацию ПВЗ';
      }
      this.row = row;

      this.commentForm.request_id = row.id;
      this.commentForm.comment = row.comment;
    },

    closeDialogRequestRegistrationInfo() {
      this.getRequestsRegistrationUsers();

      return this.$refs.form.clear();
    },

    loadFormDialog() {
      return this.$refs.form.loadFormDialog()
    },

    changePage(page) {
      this.filterForm.page = page;

      this.getRequestsRegistrationUsers();
    },

    async returnRequestFromReject(requestId) {
      await ElMessageBox.confirm(
          'Восстановить заявку?',
          {
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
            cancelButtonClass: 'el-button--info'
          },
      )

      this.$store.dispatch('apiV2RegistrationStore/returnRequestFromReject', requestId).then(() => {
        ElNotification({
          title: 'Успешно',
          message: 'Заявка восстановлена',
          type: 'success',
        });
      }).finally(async () => {
        await this.loadFormDialog();
        this.row.status = parseInt(this.$refs.form.registerForm.status);
      });
    },

    async confirmRegistrationRequest(userId) {
      await ElMessageBox.confirm(
          'Подтвердить заявку?',
          {
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
            cancelButtonClass: 'el-button--info'
          },
      )

      this.$store.dispatch('apiV2RegistrationStore/confirmRegistrationRequest', userId).then(() => {
        ElNotification({
          title: 'Успешно',
          message: 'Заявка подтверждена',
          type: 'success',
        });
      }).finally(async () => {
        await this.loadFormDialog();
        this.row.status = parseInt(this.$refs.form.registerForm.status);
      });
    },

    async rejectRegistrationRequest(requestId) {
      await ElMessageBox.confirm(
          'Отклонить заявку?',
          {
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
            cancelButtonClass: 'el-button--info'
          },
      )

      this.$store.dispatch('apiV2RegistrationStore/rejectRegistrationRequest', requestId).then(() => {
        ElNotification({
          title: 'Успешно',
          message: 'Заявка отклонена',
          type: 'success',
        });
      }).finally(async () => {
        await this.loadFormDialog();
        this.row.status = parseInt(this.$refs.form.registerForm.status);
      });
    },

    getColorStatusRequest(status) {
      switch (status) {
        case 2:
          return 'warning';
        case 3:
          return 'success';
        case 4:
          return 'danger';
        default:
          return '';
      }
    },

    openDialogSaveComment() {
      this.dialogOpenSaveComment = true;
    },

    savePointStatus() {
      this.$store.dispatch('apiV2RegistrationStore/updatePointStatus', this.changeStatusPointForm).then(() => {
        ElNotification({
          title: 'Успешно',
          message: 'Статус успешно изменен',
          type: 'success',
        });
      }).finally(async () => {
        this.dialogChangeStatusPoint = false;

        await this.getRequestsRegistrationUsers();
      });
    },

    openDialogPointChangeStatus(row) {
      this.changeStatusPointForm.point_id = row.point.id;
      this.changeStatusPointForm.status = row.point.status;

      this.dialogChangeStatusPoint = true;
    },

    defaultDataPointChangeStatusForm() {
      return {
        point_id: null,
        status: null
      }
    },

    // Обнулим всю инфу, после закртыия диалога
    closeDialogChangeStatusPoint() {
      this.changeStatusPointForm = this.defaultDataPointChangeStatusForm();
    },

    askForInformation() {
      this.$store.dispatch('apiV2RegistrationStore/askForInformation', this.commentForm).then(() => {
        ElNotification({
          title: 'Успешно',
          message: 'Комментарий сохранен',
          type: 'success',
        });
      }).finally(async () => {
        await this.loadFormDialog();
        this.row.status = parseInt(this.$refs.form.registerForm.status);
      });
    },

    getPartnerInn(pointRequest) {
      if (pointRequest.partner != null) {
        return pointRequest.partner.inn;
      }

      if (pointRequest.partner_registration_request != null) {
        return pointRequest.partner_registration_request.inn;
      }

      return 'Не указано';
    },

    getPartnerFullName(pointRequest) {
      if (pointRequest.partner != null) {
        return pointRequest.partner.name;
      }

      if (pointRequest.partner_registration_request != null) {
        return pointRequest.partner_registration_request.full_name;
      }

      return 'Не указано';
    }
  }
}
</script>

<style scoped>
::v-deep .el-table th.el-table__cell {
  background-color: rgb(239, 242, 247);
}

::v-deep .el-pagination .el-pager li {
  padding: 18px 16px;
  background-color: #ffffff;
  border-radius: 5px;
}

::v-deep .el-pagination .el-pager .el-icon {
  width: 3em;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  padding: 18px 16px;
  background-color: #556ee6;
  border-radius: 5px;
}

::v-deep .el-table__row .cell {
  font: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  white-space: pre-wrap;
  word-break: break-word;
}

::v-deep .el-table thead  {
  color: #495057;
  font: inherit;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  line-height: normal;
  white-space: pre-wrap;
  word-break: break-word;
}

div.cell {
  font-size: 12px;

}

::v-deep .el-input__wrapper {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .el-input__inner {
  margin-bottom: 0;
}
</style>
