<template>
    <router-link to="/profile" class="profile">
        <img v-if="avatar && avatar.length" :src="avatar" alt="profile">
        <img v-else src="../../assets/img/profile_icon.svg" alt="profile">
    </router-link>
</template>

<script>
    export default {
        name: 'Profile',
        props: {
            avatar: String
        },
    }
</script>