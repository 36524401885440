<template>
  <template v-if="!isChangePassword">
    <div style="margin-top: 30px;">
      <el-form ref="ruleFormRef" :model="resetPassword" :rules="rules" :label-position="labelPosition"
               label-width="120px">
        <el-form-item label="Пароль" prop="password">
          <el-input v-model="resetPassword.password" show-password/>
        </el-form-item>

        <el-form-item label="Подтвердите пароль" prop="repeat_password">
          <el-input v-model="resetPassword.repeat_password" show-password/>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="saveNewPassword(ruleFormRef)">
            Сохранить пароль
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </template>

  <template v-if="isChangePassword">
    <div style="text-align: center; margin-top: 30px">
      <el-icon :color="'#4ab34d'" :size="40">
        <component :is="SuccessFilled"/>
      </el-icon>
      <p style="font-size: 20px; padding: 10px 0">Вы успешно сменили пароль!</p>
    </div>
  </template>
</template>

<script>
import {reactive, ref} from "vue";
import {SuccessFilled} from "@element-plus/icons-vue";

export default {
  name: "ResetPassword",
  setup() {
    const ruleFormRef = ref()
    const labelPosition = ref('top')

    const resetPassword = reactive({
      password: '',
      repeat_password: '',
    })

    const validatePass2 = (rule, value, callback) => {
      if (value !== resetPassword.password) {
        callback(new Error('Пароли не совпадают'))
      } else {
        callback()
      }
    }

    const rules = reactive({
      password: [
        {
          required: true,
          message: 'Введите пароль',
          trigger: 'blur'
        },
        {
          min: 6,
          message: 'Длина пароля должна быть 6 символов и более',
          trigger: 'blur'
        },
      ],
      repeat_password: [
        {
          required: true,
          message: 'Подтвердите пароль',
          trigger: ['blur', 'change'],
        },
        {
          validator: validatePass2,
          trigger: 'blur'
        },
      ],
    })

    return {
      rules,
      ruleFormRef,
      resetPassword,
      labelPosition
    }
  },
  data() {
    return {
      SuccessFilled,
      isChangePassword: false
    }
  },
  methods: {
    async saveNewPassword(formEl) {
      if (!formEl) return

      await formEl.validate((valid) => {
        if (valid) {
          const urlArr = window.location.pathname.split('/');
          this.token = urlArr[urlArr.length - 1];

          this.$store.dispatch('user/saveNewPassword', {token: this.token, password: this.resetPassword.password}).then(() => {
            this.isChangePassword = true;
          })
        } else {
          this.isChangePassword = false;
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
