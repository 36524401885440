import { addressRoutes } from "@/views/address";
import { notificationRoutes } from "@/views/notifications";
import { profileRoutes } from "@/views/profile";
import { acceptRoutes } from "@/views/accept";
import { giveRoutes } from "@/views/give";
import { backRoutes } from "@/views/back";
import { warehouseRoutes } from "@/views/warehouse";
import { reportRoutes } from "@/views/report";
import { folderRoutes } from "@/views/folder";
import { helpRoutes } from "@/views/help";
import { usersRoutes } from "@/views/users";
import { clientsRoutes } from "@/views/clients";
import { pointsRoutes } from "@/views/points";
import { shipmentsRoutes } from "@/views/shipments";
import { partnersRoutes } from "@/views/partners";
import { freightsRoutes } from "@/views/freights";
import { tariffsRoutes } from "@/views/tariffs";
import { newsRoutes } from "@/views/news";
import { helpdeskRoutes } from "@/views/helpdesk";
import { instructionsRoutes } from "@/views/instructions";
import { settingsRoutes } from "@/views/settings";
import { inventoryRoutes } from "@/views/inventory";
import { statisticRoutes } from "@/views/statistic";
import {registrationRoutes} from "@/views/registration";
import {resetPasswordRoutes} from "@/views/resetPassword";
import { pointRatingRoutes } from "@/views/rating";
import { examplesRoutes } from "@/views/examples";


import Routes from "@/helpers/routes";

import Home from "@/Home.vue";
import {ppActsRoutes} from "@/views/pp-acts";
import {AccessErrorRoutes} from "@/modules/auth/pages";
import avitoRoutes from "@/views/avito";
import {returnActsRoutes} from "@/views/return-acts";

const routes = new Routes([{ path: "/", component: Home }])
  .addRoutes(notificationRoutes)
  .addRoutes(profileRoutes)
  .addRoutes(acceptRoutes)
  .addRoutes(giveRoutes)
  .addRoutes(backRoutes)
  .addRoutes(warehouseRoutes)
  .addRoutes(reportRoutes)
  .addRoutes(folderRoutes)
  .addRoutes(helpRoutes)
  .addRoutes(addressRoutes)
  .addRoutes(usersRoutes)
  .addRoutes(clientsRoutes)
  .addRoutes(pointsRoutes)
  .addRoutes(shipmentsRoutes)
  .addRoutes(partnersRoutes)
  .addRoutes(freightsRoutes)
  .addRoutes(tariffsRoutes)
  .addRoutes(newsRoutes)
  .addRoutes(settingsRoutes)
  .addRoutes(inventoryRoutes)
  .addRoutes(instructionsRoutes)
  .addRoutes(statisticRoutes)
  .addRoutes(helpdeskRoutes)
  .addRoutes(ppActsRoutes)
  .addRoutes(registrationRoutes)
  .addRoutes(resetPasswordRoutes)
  .addRoutes(AccessErrorRoutes)
  .addRoutes(avitoRoutes)
  .addRoutes(pointRatingRoutes)
  .addRoutes(examplesRoutes)
  .addRoutes(returnActsRoutes);


export default routes;
