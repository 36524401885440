<template>
  <!-- <h2>Контрагенты</h2> -->
  <h2 class="pt-4">У вас нет прав доступа к данному разделу</h2>
</template>

<script>
export default {
  name: "Partners",
  components: {},
};
</script>
