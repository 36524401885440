import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import ElementPlus from 'element-plus'
import ru from 'element-plus/es/locale/lang/ru'


import App from './App.vue';
import router from './router';
import store from './store';

const head = createHead();
const app = createApp(App)

app.use(router);
app.use(head);
app.use(store);
app.use(ElementPlus, {
    locale: ru,
});

app.mount('#app');
