import client from './client'
import createCrud from './createCrud'


const { getAll, createItem, readItem, updateItem, deleteItem } = createCrud(client, '/freights')

const getTypes = async () => {
  return await client.get('/freight_types')
};
const getSortingCenters = async () => {
  return await client.get('/sorting_centers')
};
const accept = async (id, data = {}) => {
  return await client.put(`/freights/${id}/accept`, data)
};
const finished = async (id, data = {}) => {
  return await client.put(`/freights/${id}/finished`, data)
};

export {
  getAll,
  createItem,
  readItem,
  updateItem,
  deleteItem,
  getTypes,
  getSortingCenters,
  accept,
  finished,
}
