<template>
  <el-form
      novalidate
      @input="$emit('input', $event.target.value)"
      label-position="top"
      class="mt-4"
      @submit.prevent="$emit('submit', searchForm)"
  >
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item label="Создан от">
          <el-date-picker
              class="w-100"
              v-model="searchForm.date_start"
              type="date"
              placeholder="Создан от"
              format="DD.MM.YYYY"
              value-format="YYYY-MM-DD"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Создан до">
          <el-date-picker
              class="w-100"
              v-model="searchForm.date_end"
              type="date"
              placeholder="Создан до"
              format="DD.MM.YYYY"
              value-format="YYYY-MM-DD"
          />
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <el-form-item label="Выберите пвз">
          <PointSelect
              @change="onChangePointId($event)"
              :multiple="true"
          ></PointSelect>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="16" class="multi-accept__search-block">
      <el-col :offset="20" :span="4">
        <el-button @click="downloadExcelUseTareReport" type="success">Скачать отчет</el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import PointSelect from "@/components/selectors/pointSelect/PointSelect";
import {downloadFile, getFileNameWithExtensionFromHeaders} from "@/store/helper";

export default {
  name: "ReportUseTare",
  components: {
    PointSelect,
  },
  data() {
    return {
      searchForm: {
        date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString('Ru-ru').split('.').reverse().join('-'),
        date_end: new Date().toLocaleDateString('Ru-ru').split('.').reverse().join('-'),
        point_ids: []
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    downloadExcelUseTareReport() {
      this.$store.dispatch('apiV2DocumentStore/downloadUseTareReport', this.searchForm).then(response => {
        const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        downloadFile(url, fileNameWithExtension)
      });
    },

    onChangePointId(event) {
      this.searchForm.point_ids = event.length > 0 ? event : [];
    },
  },
}
</script>

<style scoped>
  ::v-deep(.vue-select__xmail-style .vs__selected-options) {
    padding: 2px 16px;
  }
</style>
