<template>
  <div class="context_menu">
    <div class="storageItemInfo-data">
      <div class="freight-info__top storageItemInfo-title">
        <h2 class="title-style-small">Общая информация</h2>
        <el-button
          v-if="freight.status === '0'"
          type="primary"
          size="small"
          class="freight-info__top-button"
          @click="modalAddPlacesVisible = true"
        >Поступила на ПВЗ
        </el-button>
      </div>
      <div class="storageItemInfo-data-info">
        <div class="storageItemInfo-data-info-container">
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Номер отправки</p>
            <p class="storageItemInfo-data-items-right">{{ freight.number }}</p>
          </div>
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Дата прибытия</p>
            <p class="storageItemInfo-data-items-right">
              {{ new Date(freight.dispatch_date).toLocaleDateString() }}</p>
          </div>
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Время прибытия</p>
            <p class="storageItemInfo-data-items-right">
              {{ freight.accept_time }}
            </p>
          </div>
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">ПВЗ</p>
            <p class="storageItemInfo-data-items-right">{{ freight.dst_point?.name }}</p>
          </div>
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Статус</p>
            <p class="storageItemInfo-data-items-right">{{ statusNameById(freight.status) }}</p>
          </div>
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Машина</p>
            <p class="storageItemInfo-data-items-right">{{ freight.vehicle }}</p>
          </div>
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Водитель</p>
            <p class="storageItemInfo-data-items-right">{{ freight.driver }}</p>
          </div>
        </div>
        <div class="storageItemInfo-data-info-container">
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Мест заявлено ГБ</p>
            <p class="storageItemInfo-data-items-right">{{ freight.number_of_containers }}</p>
          </div>
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Мест принято ПВЗ</p>
            <p class="storageItemInfo-data-items-right">{{
                freight.number_of_containers_actual
              }}</p>
          </div>
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Отправлений</p>
            <p class="storageItemInfo-data-items-right">{{ freight.number_of_shipments }}</p>
          </div>
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Общий вес</p>
            <p class="storageItemInfo-data-items-right">{{ freight.total_weight }} кг</p>
          </div>
        </div>
      </div>
    </div>

    <ShipmentsTable
      v-if="freight.shipments_on_way?.length"
      :shipments="freight.shipments_on_way"
      :tableTitle="'В пути на ПВЗ'"
    ></ShipmentsTable>

    <ShipmentsTable
      v-if="freight.incorrect_shipments?.length"
      :shipments="freight.incorrect_shipments"
      :tableTitle="'Расхождения'"
    ></ShipmentsTable>

    <ShipmentsTable
      v-if="freight.ready_shipments?.length"
      :shipments="freight.ready_shipments"
      :tableTitle="'Приняты из перевозки'"
    ></ShipmentsTable>

    <modal-add-places
      v-model="modalAddPlacesVisible"
      :freight="freight"
      @refresh="initData"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {Printer} from '@element-plus/icons-vue'
import ModalAddPlaces from "@/components/freights/ModalAddPlaces";
import ShipmentsTable from '@/components/shipments/ShipmentsTable.vue'

export default {
  name: 'FreightInfo',
  components: {ModalAddPlaces, ShipmentsTable},
  data: () => ({
    Printer,
    modalAddPlacesVisible: false,
  }),

  computed: {
    ...mapState({
      freight: state => state.freights.editItem,
    }),

    ...mapGetters({
      statusNameById: 'freights/statusNameById',
      shipmentsStatusNameById: 'shipments/statusNameById',
    }),
  },

  created() {
    this.initData()
  },

  methods: {
    ...mapActions({
      readItem: 'freights/readItem',
    }),

    async initData() {
      await this.readItem(this.$route.params.id)
    }
  }
};
</script>

<style lang="scss" scoped>
.info-storage-item-id-width {
  width: 60px;
}

.freight-info {
  &__top {
    justify-content: flex-start;

    .title-style-small {
      margin-right: auto;
    }
  }
}
</style>
