import apiV2Service from "@/api/V2/services/apiV2.service";

const store = {
  namespaced: true,
  actions: {
    getScannedShipmentsByInventory() {
      return apiV2Service.get('inventory-shipments/getScannedShipmentsByInventory');
    },

    scan(context, data) {
      return apiV2Service.post('inventory-shipments/scan', data);
    }
  },
};
export default store;
