<template>
  <div v-if="tableData.length">
    <Toolbar>
      <template v-slot:filter>
        <Search
          v-model:value="filters.search"
          :placeholder="toolbar.search.title"
          @search="onSearch"
        />
        <BaseSelect
          v-model="filters.status"
          :options="statusOptions"
          :placeholder="toolbar.status.title"
          @search="getItems"
        />
      </template>
      <template v-slot:buttons>
        <!--<Button modifyer="rounded" @click="$router.push({ name: 'warehouse-create' })">Создать
          отправку
        </Button>-->
      </template>
    </Toolbar>
    <div class="transport-current-table overflow-auto">
        <table style="width: 100%; border-collapse: collapse;">
        <thead>
          <tr>
            <th class="table__cell table__th">Статус</th>
            <th class="table__cell table__th">Номер отправки</th>
            <th class="table__cell table__th">Дата отправки</th>
            <th class="table__cell table__th">Отправлений</th>
            <th class="table__cell table__th">Общий вес</th>
            <th class="table__cell table__th">ПВЗ отправки</th>
            <!--<th class="table__cell table__th">ПВЗ получения</th>-->
            <th class="table__cell table__th">Акт приема передачи</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in tableData" :key="item.id" @click="onRowClick(item)">
            <td class="table__cell">{{ item.status }}</td>
            <td class="table__cell">{{ item.number }}</td>
            <td class="table__cell">{{ item.dispatch_date }}</td>
            <td class="table__cell">{{ item.number_of_shipments || 0 }}</td>
            <td class="table__cell">{{ item.mass || 0 }}</td>
            <td class="table__cell" v-html="pointInfo(item.src_point)"></td>
            <!--<td class="table__cell" v-html="pointInfo(item.dst_point)"></td>-->
            <td class="table__cell">
              <button class="green-button act-button" @click.stop="print(item)" type="button" :disabled="item.status != 'Завершена'">Акт ПП</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pages
      :totalPages="totalPages"
      :currentPage="filters.page"
      :last="totalPages"
      :total="total"
      :perPage="perPage"
      @change="switchPage"
    />
  </div>
  <div v-else-if="getListLoading" class="pt-3 pb-3">Загрузка...</div>
  <div v-else class="pt-3 pb-3">Нет информации</div>
  <!-- TODO проверить где используется src/components/grid -->
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Toolbar, {Search} from 'src/components/toolbar'
import BaseSelect from "@/components/toolbar/BaseSelect";
import { usePrint } from '@/composition/usePrint.js';
import Pages from "src/components/Pages.vue";

export default {
  name: 'Warehouse',
  components: {
    Toolbar,
    Search,
    BaseSelect,
    Pages
  },
  setup() {
    return {
      ...usePrint()
    }
  },
  data() {
    return {
      filters: {
        page: 1,
        search: '',
        status: null,
      },
      toolbar: {
        search: {title: 'Номер отправки или отправления'},
        status: {
          title: 'Статус',
        },
      },
      tableHead: {
        status: 'Статус',
        number: 'Номер отправки',
        date: 'Дата отправки',
        quantity: 'Отправлений',
        mass: 'Общий вес',
        act: 'Акт приема перед',
      },
      tableData: [],
      getListLoading: false
    }
  },

  computed: {
    ...mapGetters({
      items: 'sendings/items',
      statusOptions: 'sendings/statusOptions',
    }),
    totalPages() {
      return this.$store.state.sendings.pagination.totalPages;
    },
    total() {
      return this.$store.state.sendings.pagination.totalItems;
    },
    perPage() {
      return this.$store.state.sendings.pagination.perPage;
    }, 
  },

  created() {
    this.getItems();
  },

  methods: {
    pointInfo(point) {
      if (point) {
        return `№: ${point.id}, ${point.name}`;
      }
      return '-';
    },
    ...mapActions('sendings', ['getList']),
    print(item) {
      this.exportRegistry(item.hiddenID);
    },

    async getItems() {
      this.getListLoading = true;
      await this.getList(this.filters);
      this.getListLoading = false;
      this.tableData = this.items;
    },

    async switchPage(page) {
      this.filters.page = page;
      await this.getItems();
    },

    onSearch() {
      this.getItems();
    },

    cellClass(name) {
      return `send-storage-item-${name}-width`
    },

    onRowClick(item) {
      this.$router.push({name: 'warehouse-info', params: {id: item.hiddenID}})
    }
  }
}
</script>

<style lang="scss">
.send-storage-item {
  &-status-width {
    width: 146px;
  }

  &-number-width {
    width: 194px;
  }

  &-mass-width {
    width: 162px;
  }

  &-date-width {
    width: 194px;
  }

  &-quantity-width {
    width: 162px;
  }

  &-button-width {
    width: 219px;
  }

}
</style>
