import axios from "axios";
import EventBus from "@/helpers/EventBus";

import {ElNotification} from "element-plus";

const avito = axios.create({
  baseURL: process.env.VUE_APP_EX_AVITO_API
});

avito.interceptors.request.use((config) => {
  let token = localStorage.getItem("auth") || sessionStorage.getItem("auth");

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  EventBus.dispatch("loading");
  return config;
});

avito.interceptors.response.use((config) => {
  EventBus.dispatch("loaded");
  return config;
}, (error) => {
  EventBus.dispatch("loaded");
  if (error && error.message && error.message === 'Network Error') {
    ElNotification({
      title: 'Обновление системы',
      message: 'В данный момент проводится обновление системы. Повторите ваш запрос через несколько минут',
      type: 'error',
    });
    return Promise.reject(null);
  }
  if (408 === error.response.status) {
    ElNotification({
      title: 'Обновление системы',
      message: 'Необходимо обновить систему, нажав на кнопку Shift+F5 (на ноутбуке ctrl+shift+R',
      type: 'error',
    });
    return Promise.reject(error);
  }

  if (401 === error.response.status) {
    EventBus.dispatch("unauthorizedAccess");
  }
  if (error && error.response && error.response.status && error.response.status !== 422 && error.response.data && error.response.data.message) {
    ElNotification({
      title: 'Ошибка',
      message: error.response.data.message,
      type: 'error',
    });

    return Promise.reject(error);
  }

  return Promise.reject(error);
});

export default avito;
