import { getAll, createItem, readItem, updateItem, deleteItem } from 'src/api/schedules'

import parseStatus from "./parseStatus"


const store = {
    namespaced: true,
    state () {
        return {
            list: [],
            editItem: undefined,
            errors: {}
        }
    },
    actions: {
        async getList(context/*, { filters }*/) {
            try {
                const { status, data } = await getAll(/*filters*/)
                if (status === 200) {
                    context.commit('SET_LIST', data)
                }
            } catch ({ message, response = {}}) {
                context.commit('SET_ERROR', parseStatus(response.status) || message)
            }
        },
        async createItem(context) {
            try {
                const { status, data } = await createItem(context.state.editItem)
                if (status === 200) {
                    context.commit('SET_ITEM', data)
                }
            } catch ({ message, response = {}}) {
                context.commit('SET_CREATE_ERROR', parseStatus(response.status) || message)
            }
        },
        async readItem(context, id) {
            try {
                const { status, data } = await readItem(id)
                if (status === 200) {
                    context.commit('SET_ITEM', data)
                }
            } catch ({ message, response = {}}) {
                context.commit('SET_ERROR', parseStatus(response.status) || message)
            }
        },
        async updateItem(context, id) {
            try {
                const { status, data } = await updateItem(id, context.state.editItem)
                if (status === 200) {
                    context.commit('SET_ITEM', data)
                }
            } catch ({ message, response = {}}) {
                context.commit('SET_CREATE_ERROR', parseStatus(response.status) || message)
            }
        },
        async deleteItem(context, id) {
            try {
                const { status, data } = await deleteItem(id)
                if (status === 200) {
                    context.commit('CLEAR_ITEM', data)
                }
            } catch ({ message, response = {}}) {
                context.commit('SET_CREATE_ERROR', parseStatus(response.status) || message)
            }
        }
    },
    mutations: {
        SET_CREATE_ERROR(state, code) {
            state.errors.create = code
            console.error(code)
        },
        SET_ERROR(state, code) {
            state.errors.common = code
            console.error(code)
        },
        SET_ITEM(state, item) {
            state.editItem = item
        },
    },
}
export default store
