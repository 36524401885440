<template>
  <div style="margin-top: 30px;">
    <el-form v-if="!isSuccessRegister" ref="ruleFormRef" :model="registerForm" :rules="rules" :label-position="labelPosition"
             label-width="120px">
      <el-form-item label="Email" prop="email">
        <el-input v-model="registerForm.email"/>
      </el-form-item>

      <el-form-item label="Фамилия" prop="last_name">
        <el-input v-model="registerForm.last_name"/>
      </el-form-item>

      <el-form-item label="Имя" prop="first_name">
        <el-input v-model="registerForm.first_name"/>
      </el-form-item>

      <el-form-item label="Отчество" prop="middle_name">
        <el-input v-model="registerForm.middle_name"/>
      </el-form-item>

      <el-form-item label="Телефон" prop="phone">
        <el-input v-model="registerForm.phone"/>
      </el-form-item>

      <el-form-item label="Пароль" prop="password">
        <el-input v-model="registerForm.password" show-password/>
      </el-form-item>

      <el-form-item label="Подтвердите пароль" prop="repeat_password">
        <el-input v-model="registerForm.repeat_password" show-password/>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm(ruleFormRef)">
          Продолжить регистрацию
        </el-button>
      </el-form-item>
    </el-form>

    <div v-if="isSuccessRegister">
      <div style="text-align: center">
        <el-icon :color="'#4ab34d'" :size="40">
          <component :is="SuccessFilled"/>
        </el-icon>
      <p style="font-size: 20px; padding: 10px 0">Вы успешно зарегистрированы!</p>
      <p style="color: #82929d; font-size: 14px;">Вам было направлено письмо для подтверждения email, который вы указали при регистрации</p>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, ref} from 'vue'
import {SuccessFilled} from "@element-plus/icons-vue";

export default {
  name: "Registration",
  setup() {
    const ruleFormRef = ref()
    const labelPosition = ref('top')

    const registerForm = reactive({
      first_name: '',
      email: '',
      last_name: '',
      middle_name: '',
      phone: '',
      password: '',
      repeat_password: ''
    })

    const validatePass2 = (rule, value, callback) => {
      if (value !== registerForm.password) {
        callback(new Error('Пароли не совпадают'))
      } else {
        callback()
      }
    }

    const rules = reactive({
      first_name: [
        {
          required: true,
          message: 'Введите имя',
          trigger: 'blur'
        },
      ],
      email: [
        {
          required: true,
          message: 'Введите email',
          trigger: 'blur'
        },
        {
          type: 'email',
          message: 'Некорректный email',
          trigger: ['blur', 'change'],
        },
      ],
      last_name: [
        {
          required: true,
          message: 'Введите фамилию',
          trigger: 'blur'
        },
      ],
      phone: [
        {
          required: true,
          message: 'Введите телефон',
          trigger: 'blur'
        },
      ],
      middle_name: [
        {
          required: true,
          message: 'Введите отчество',
          trigger: 'blur'
        },
      ],
      password: [
        {
          required: true,
          message: 'Введите пароль',
          trigger: 'blur'
        },
        {
          min: 6,
          message: 'Длина пароля должна быть 6 символов и более',
          trigger: 'blur'
        },
      ],
      repeat_password: [
        {
          required: true,
          message: 'Подтвердите пароль',
          trigger: ['blur', 'change'],
        },
        {
          validator: validatePass2,
          trigger: 'blur'
        },
      ],
    })

    return {
      ruleFormRef,
      rules,
      registerForm,
      labelPosition
    }
  },
  data() {
    return {
      SuccessFilled,
      isSuccessRegister: false
    }
  },
  methods: {
    async submitForm(formEl) {
      if (!formEl) return
      await formEl.validate((valid) => {
        if (valid) {
          this.$store.dispatch('apiV2RegistrationStore/registrationUser', this.registerForm).then(() => {
            this.isSuccessRegister = true;
          })
        } else {
          this.isSuccessRegister = false;
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
