import AccessErrorPage from "@/modules/auth/pages/AccessErrorPage";

export const AccessErrorRoutes = [
    {
        path: '/access-error-page',
        component: AccessErrorPage,
        name: 'AccessErrorPage',
        meta: {
            title: "Доступ запрещен",
        }
    },
];
