<template>
  <el-select
    :model-value="value"
    :placeholder="placeholder"
    :multiple="multiple"
    style="width: 240px"
    class="toolbar-select"
    @change="onChange"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.title"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "BaseSelect",

  props: {
    value: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Выбрать',
    },
    options: {
      type: Array,
      required: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onChange(value) {
      this.$emit("update:modelValue", value);
      this.$emit("search");
    },
  },
};
</script>

<style lang="scss">
.toolbar-select {
  margin: 0 20px 0 0;

  .el-input__inner {
    height: 42px;
    box-shadow: 2px 2px 12px rgba(42, 48, 66, 0.11);
  }
}
.el-select__tags{
  height: 35px;
  line-height: 33px;
  overflow: hidden;
}
</style>
