export function getSrcClientFio(shipment) {
    return getClientFio(shipment, 'client_src');
}

export function getDstClientFio(shipment) {
    return getClientFio(shipment, 'client_dst');
}

function getClientFio(shipment, clientObjectName) {
    if (!shipment || !shipment[clientObjectName]) {
        return '-';
    }

    const client = shipment[clientObjectName];

    if (
        (!client.first_name || !client.first_name.trim())
        && (!client.middle_name || !client.middle_name.trim())
        && (!client.last_name || !client.last_name.trim())
    ) {
        return client.nickname;
    }

    return (client.first_name ?? " ") + ' ' + (client.middle_name  ?? " ") + ' ' + (client.last_name ?? " ").trim()
}


export function isDataUpdateRequired(lastUpdateTime) {
    const sixHoursInMilliseconds = 6 * 60 * 60 * 1000; // 6 часов в миллисекундах
    const currentTime = new Date().getTime();
    return (currentTime - lastUpdateTime) > sixHoursInMilliseconds;
}


export function getFileNameWithExtensionFromHeaders(headers) {
    const fileName = headers['file-name'];
    if (fileName) {
       return JSON.parse(fileName);
    }
    return 'file';
}

export function downloadFile(href, fileNameWithExtension) {
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileNameWithExtension);
    document.body.appendChild(link);
    link.click();

    link.remove();
    window.URL.revokeObjectURL(href);
}
