<template>
  <div class="comment" v-if="message && !isAdmin">
    <el-alert :title="message" type="info" effect="dark" :closable="false"/>
  </div>

  <el-form ref="ruleFormRef" :model="registerPointForm" :rules="rules" :label-position="labelPosition"
           label-width="120px" :disabled="isDisableForm">

    <TabStrip :selected="selectedTab" :tabs="tabs">
      <template v-slot:TabOne>
        <el-form-item label="ФИО руководителя">
          <el-input :disabled="true" :placeholder="pointRequest.partner ? pointRequest.partner.dir_fio : ''" />
        </el-form-item>

        <el-form-item label="email">
          <el-input :disabled="true" :placeholder="pointRequest.partner ? pointRequest.partner.email_adress : ''" />
        </el-form-item>

        <el-form-item label="Название Юр лица">
          <el-input :disabled="true" :placeholder="pointRequest.partner ? pointRequest.partner.name : ''" />
        </el-form-item>

        <el-form-item label="Адрес точки выдачи" prop="address">
          <el-autocomplete
            style="width: 100%;"
            v-model="registerPointForm.address"
            :disabled="editDisabled"
            :fetch-suggestions="getDaDataSuggestions"
            placeholder="Адрес точки выдачи"
            :trigger-on-focus="false"
            :debounce="1000"
          />
        </el-form-item>

        <el-form-item label="Тип здания, в котором расположена точка выдачи" prop="type_building">
          <el-select v-model="registerPointForm.type_building" :value-key=registerPointForm.type_building  placeholder="Тип здания" :disabled="editDisabled">
            <el-option :label="buildingType.name" :value="buildingType.id" v-for="buildingType in BUILDING_TYPES" :key="buildingType.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="График работы" prop="schedule_scheme_id">
          <el-select v-model="registerPointForm.schedule_scheme_id" :value-key=registerPointForm.schedule_scheme_id  placeholder="График работы" :disabled="editDisabled">
            <el-option :label="scheduleScheme.title" :value="scheduleScheme.id" v-for="scheduleScheme in scheduleSchemes" :key="scheduleScheme.id"></el-option>
          </el-select>
        </el-form-item>

        <div style="padding: 20px 0" v-if="isViewPhotoUpload">
          <el-form-item label="Фото точки выдачи" prop="photo_point">
            <el-upload :disabled="editDisabled"
                       v-model:file-list="fileList"
                       :drag="true"
                       accept="image/jpeg,image/gif,image/png"
                       list-type="picture-card"
                       :on-preview="handlePictureCardPreview"
                       :on-remove="deletePhotoPoint"
                       :auto-upload="true"
                       :http-request="uploadFile"
                       :limit="photoLimit"
                       :on-exceed="notificationMaxLimit"
                       :on-change="handleChange"
            >
              <el-icon v-if="!editDisabled">
                <component :is="Plus"/>
              </el-icon>
            </el-upload>

            <el-dialog v-model="dialogVisible">
              <img class="w-100" :src="this.dialogImageUrl" alt="Preview Image"/>
            </el-dialog>
          </el-form-item>
        </div>

        <el-form-item label="Описание “Как пройти”" prop="path_description">
          <el-input v-model="registerPointForm.path_description" :disabled="editDisabled" />
        </el-form-item>

        <div style="display:flex;">
          <el-form-item>
            <el-button v-loading="loadingSave" :disabled="editDisabled"  type="primary" @click="savePointForm(ruleFormRef)">
              Сохранить
            </el-button>
          </el-form-item>
        </div>

        <el-form-item>
          <template v-if="isAdmin && isDisableForm">
            <div class="alert alert-success d-flex align-items-center" role="alert">
              <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
              <div>
                Работа с заявкой завершена, дальнейшие изменения необходимо производить в <a :href="registerPointForm.partner_edit_link" target="_blank" class="alert-link">карточке партнёра</a>.
              </div>
            </div>
          </template>
        </el-form-item>
      </template>
    </TabStrip>
  </el-form>

  <div class="d-flex justify-content-end">
     <span class="dialog-footer">
         <el-button v-if="pointRequest.status === this.REQUEST_STATUS_CHECK" type="warning" @click="openDialogSaveComment">
          Запросить информацию
        </el-button>
        <el-button v-if="pointRequest.status === this.REQUEST_STATUS_CHECK" @click="rejectPointRegistrationRequest()"
                   type="danger">
          Отказать
        </el-button>
        <el-button v-if="pointRequest.status === this.REQUEST_STATUS_CHECK" type="success"
                   @click="confirmPointRegistrationRequest()">
          Подтвердить
        </el-button>

        <el-button v-if="this.REQUEST_STATUS_REJECT === pointRequest.status" type="warning"
                   @click="returnPointRequestFromReject()">
          Вернуть заявку
        </el-button>
    </span>
  </div>

  <el-dialog v-model="dialogOpenSaveComment" title="Запросить информацию">
    <el-form :model="commentForm">
      <el-form-item label="Комментарий">
        <el-input v-model="commentForm.comment" autocomplete="off" type="textarea"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogOpenSaveComment = false">Закрыть</el-button>
        <el-button @click="askForInformation()" type="primary">
          Сохранить
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {reactive, ref} from "vue";
import {ElMessageBox, ElNotification, UploadUserFile} from "element-plus";
import {
  BUILDING_TYPES,
  REQUEST_STATUS_APPROVED,
  REQUEST_STATUS_CHECK,
  REQUEST_STATUS_NEW, REQUEST_STATUS_REJECT
} from "@/constants";
import {Plus} from "@element-plus/icons-vue";
import { TabStrip } from '@progress/kendo-vue-layout';

const baseURL = process.env.VUE_APP_API_ROOT;
const getPhotoUrl = baseURL + '/v2/registration-user/getPhotoPoint/';

export default {
  name: "RegistrationPointView",
  setup() {
    const ruleFormRef = ref()
    const labelPosition = ref('top')
    const fileList = ref<UploadUserFile>([])

    const registerPointForm = reactive({
      request_id: '',
      address: '',
      type_building: '',
      path_description: '',
      schedule_scheme_id: '',
      status: REQUEST_STATUS_NEW,
    })

    const rules = reactive({
      address: [
        {
          required: true,
          message: 'Введите адрес точки выдачи',
          trigger: 'blur'
        },
      ],
    })

    return {
      ruleFormRef,
      rules,
      registerPointForm,
      labelPosition,
      fileList,
    }
  },
  props: {
    isAdmin: Boolean,
    userId: Number,
    requestId: Number
  },
  components: {
    TabStrip
  },
  async created() {
    await this.$store.dispatch("apiV2ScheduleSchemeStore/getScheduleSchemes")

    if (this.isAdmin === false) {
      this.getRequestRegistration();
    }
  },
  data() {
    return {
      Plus,
      REQUEST_STATUS_CHECK: REQUEST_STATUS_CHECK,
      REQUEST_STATUS_REJECT: REQUEST_STATUS_REJECT,
      photoLimit: 3,
      dialogImageUrl: '',
      isDisableForm: false,
      REQUEST_STATUS_NEW: REQUEST_STATUS_NEW,
      BUILDING_TYPES: BUILDING_TYPES,
      loadingSendCheck: false,
      loadingSave: false,
      dialogVisible: false,
      isViewPhotoUpload: false,
      message: '',
      pointRequest: [],
      dialogOpenSaveComment: false,
      commentForm: {
        id: '',
        comment: '',
      },
      selectedTab: 0,
      tabs: [
        {
          title: 'Заявка ПВЗ',
          content: 'TabOne',
        },
      ],
    }
  },
  methods: {
    loadFormDialog() {
      return this.$store.dispatch('apiV2RegistrationStore/getPointRequestById', this.requestId).then((data) => {
        this.loadForm(data);
      });
    },

    getRequestRegistration() {
      this.$store.dispatch('apiV2RegistrationStore/getPointRequest', this.userId).then((data) => {
        if (data !== null) {
          this.loadForm(data);
        }
      })
    },

    loadForm(data) {
      if (data !== null) {
        this.isViewPhotoUpload = true;

        this.pointRequest = data.pointRequest;

        this.commentForm.request_id = data.pointRequest.id;

        this.registerPointForm.path_description = data.pointRequest.path_description;
        this.registerPointForm.request_id = data.pointRequest.id;
        this.registerPointForm.address = data.pointRequest.address;
        this.registerPointForm.type_building = data.pointRequest.type_building;
        this.registerPointForm.status = data.pointRequest.status;
        this.registerPointForm.partner_edit_link = data.pointRequest.partner_edit_link;
        this.registerPointForm.schedule_scheme_id = data.pointRequest.schedule_scheme_id;

        if (data.pointRequest.status === REQUEST_STATUS_APPROVED) {
          this.isDisableForm = true;
        }

        this.message = data.pointRequest.comment;

        this.fileList = data.pointRequest.point_photos.map((photo) => ({
          id: photo.id,
          name: photo.name,
          url: getPhotoUrl + photo.id
        }));
      }
    },

    handlePictureCardPreview(uploadFile) {
      this.dialogImageUrl = uploadFile.url;
      this.dialogVisible = true
    },

    deletePhotoPoint(deletePhoto) {
      let photoId = deletePhoto.id ?? deletePhoto.response;

      let index = this.fileList.map(photo => {
        return photo.id;
      }).indexOf(deletePhoto.id);

      this.fileList.splice(index, 1);

      this.$store.dispatch('apiV2RegistrationStore/deletePhotoPoint', {photoId: photoId}).then(() => {
        ElNotification({
          title: 'Успешно',
          message: 'Фото удалено',
          type: 'success',
        });
      });
    },

    clear() {
      this.$refs.ruleFormRef.resetFields()

      this.pointRequest = [];
    },

    async uploadFile(file) {
      let data = new FormData();

      data.append('attach', file.file);
      data.append('request_id', this.registerPointForm.request_id);

      await this.$store.dispatch('apiV2RegistrationStore/uploadPhotoPoint', data).then((result) => {
        this.newPhotoId = result.photo.id;

        this.fileList.push({
          'id': result.photo.id,
          'url': getPhotoUrl + result.photo.id,
          'name': result.photo.name,
        });

        ElNotification({
          title: 'Успешно',
          message: 'Фото загружено',
          type: 'success',
        });
      });
    },

    notificationMaxLimit() {
      ElNotification({
        title: 'Предупреждение',
        message: 'Загружено максимальное количество фотографий',
        type: 'warning',
      });
    },

    handleChange(file) {
      file.response = this.newPhotoId;
    },

    getDaDataSuggestions(address, returnResult) {
      this.$store.dispatch('apiV2RegistrationStore/getDaDataSuggestions', address).then((data) => {
        let result = [];

        data.addresses.forEach((item) => {
          result.push({value: item.value});
        });

        returnResult(result);
      })
    },

    savePointForm() {
      this.$store.dispatch('apiV2RegistrationStore/savePointForm',
        {
          requestId: this.pointRequest.id,
          data: this.registerPointForm
        }
      ).then(() => {
        ElNotification({
          title: 'Успешно',
          message: 'Заявка сохранена',
          type: 'success',
        });
      }).finally(async () => {
        await this.loadFormDialog();
      });
    },

    async rejectPointRegistrationRequest() {
      await ElMessageBox.confirm(
        'Отклонить заявку?',
        {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          cancelButtonClass: 'el-button--info'
        },
      )

      this.$store.dispatch('apiV2RegistrationStore/rejectPointRegistrationRequest', this.pointRequest.id).then(() => {
        ElNotification({
          title: 'Успешно',
          message: 'Заявка отклонена',
          type: 'success',
        });
      }).finally(async () => {
        await this.loadFormDialog();
      });
    },

    async confirmPointRegistrationRequest() {
      await ElMessageBox.confirm(
        'Подтвердить заявку?',
        {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          cancelButtonClass: 'el-button--info'
        },
      )

      this.$store.dispatch('apiV2RegistrationStore/confirmPointRegistrationRequest', this.pointRequest.id).then(() => {
        ElNotification({
          title: 'Успешно',
          message: 'Заявка подтверждена',
          type: 'success',
        });
      }).finally(async () => {
        await this.loadFormDialog();this.row.status = parseInt(this.$refs.form.registerForm.status);
      });
    },

    async returnPointRequestFromReject() {
      await ElMessageBox.confirm(
        'Восстановить заявку?',
        {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          cancelButtonClass: 'el-button--info'
        },
      )

      this.$store.dispatch('apiV2RegistrationStore/returnPointRequestFromReject', this.pointRequest.id).then(() => {
        ElNotification({
          title: 'Успешно',
          message: 'Заявка восстановлена',
          type: 'success',
        });
      }).finally(async () => {
        await this.loadFormDialog();
      });
    },
    openDialogSaveComment() {
      this.dialogOpenSaveComment = true;
    },

    askForInformation() {
      this.$store.dispatch('apiV2RegistrationStore/askForInformation', this.commentForm).then(() => {
        ElNotification({
          title: 'Успешно',
          message: 'Комментарий сохранен',
          type: 'success',
        });
      }).finally(async () => {
        await this.loadFormDialog();
      });
    },
  },
  computed: {
    editDisabled() {
      return this.registerPointForm.request_id !== '' && this.registerPointForm.status !== REQUEST_STATUS_NEW && !this.isAdmin
    },
    scheduleSchemes() {
      return this.$store.state.apiV2ScheduleSchemeStore.list;
    }
  },
}
</script>

<style scoped>
.comment {
  padding: 0 0 30px 0;
}

::v-deep .el-upload-dragger {
  width: 100%;
  height: 100%;
}

::v-deep .loader__backdrop {
  display: none;
}
</style>
