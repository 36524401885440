<template>
  <el-dialog
      :model-value="modelValue"
      title="Приемка со сканером"
      width="30%"
      @open="open"
      @close="close"
      @update:modelValue="$emit('update:modelValue', $event)"
  >
    <departure-placement
        v-model="departurePlacementVisible"
        :shipment-id="currentShipmentId"
        :freight-id="freight.id"
        :initial-place="initialPlace"
        @place="onPlace"
    />
    <div class="receiving-with-scanner__content">
      <div
          v-for="item in filteredList"
          :key="item.id"
          class="receiving-with-scanner__content-item"
      >
        <span v-if="item.checkStatus === statuses.real">{{ item.number }}</span>
        <span v-else-if="item.checkStatus === statuses.existing" class="error">{{ item.id }} - излишек</span>
        <span v-else-if="item.checkStatus === statuses['non-existent']" class="error">Ошибка - некорректный код</span>
        <div v-else-if="item.checkStatus === statuses.danger" class="alert alert-danger" role="alert" >
          {{item.errorMessage}}
        </div>

      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">

      </span>
    </template>
  </el-dialog>
</template>

<script>
import {ElDialog, ElMessageBox, ElNotification} from 'element-plus';
import DeparturePlacement from "@/components/freights/franchisee/DeparturePlacement";
import {mapActions} from "vuex";
import {useScanner} from "@/composition/useScanner.js";

const statuses = {
  real: 'real',
  existing: 'existing',
  'non-existent': 'non-existent',
  danger: 'danger'
}
// корректный
// существующий
// не существующий

export default {
  name: "ReceivingWithScanner",

  components: {DeparturePlacement, ElDialog},

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    freight: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return {
      ...useScanner()
    }
  },

  data: () => ({
    statuses,
    list: [],
    departurePlacementVisible: false,
    currentShipmentId: null,
    initialPlace: null
  }),

  computed: {
    filteredList() {
      return this.list.filter(item => item.scanned)
    }
  },

  watch: {
    freight() {
      this.initList()
    },
  },

  methods: {
    ...mapActions({
      getShipment: 'shipments/readItem',
      getShipmentCode: 'shipments/getShipmentCode',
      finished: 'freights/finished',
    }),

    initList() {
      this.list = this.freight.shipments.map(item => ({
        ...item,
        scanned: item.status !== '75',
      }))
    },

    async checkShipment(shipmentId) {
      try {
        await this.getShipment(shipmentId)
        return true
      } catch (e) {
        console.dir(e);
        return false
      }
    },

    async scan(e) {
      if (e.key !== 'Shift') {
        return
      }

      const shipmentId = prompt('Введите код отправления')
      this.setShipmentId(shipmentId);
    },

    async fromScanner(e) {
      const {route, id} = JSON.parse(e.detail);
      console.log(route, id);

      let shipmentId = id;

      if (shipmentId.toString().length === 14 || shipmentId.toString().length === 18) {
        const {data} = await this.getShipmentCode(id);

        if (typeof data === 'number') {
          shipmentId = data;

          this.setShipmentId(shipmentId);

          return;
        }
      }

      if (route == 1) {
        this.setShipmentId(shipmentId);
      } else if (this.departurePlacementVisible && route == 4) {
        console.log('select place');
        this.initialPlace = shipmentId;
      }
    },

    async setShipmentId(shipmentId) {
      this.currentShipmentId = +shipmentId

      if (shipmentId) {
        const index = this.list.findIndex(item => item.id === +shipmentId)

        if (index >= 0) {
          this.list[index].checkStatus = statuses.real
          this.departurePlacementVisible = true
          return
        }
        const isExist = await this.checkShipment(shipmentId)

        if (isExist) {
          this.departurePlacementVisible = true
        } else {
          this.list.push({
            id: this.currentShipmentId,
            scanned: true,
            checkStatus: statuses["non-existent"],
          })
        }
      }
    },

    onPlace(event, errorMessage) {
      // Сбрасываем ячейку
      this.initialPlace = null;

      if (!event && errorMessage) {
        return this.list.push({
          id: this.currentShipmentId,
          scanned: true,
          checkStatus: statuses.danger,
          errorMessage: errorMessage
        });
      }

      const index = this.list.findIndex(item => item.id === this.currentShipmentId)

      if (index >= 0) {
        this.list[index].scanned = true
      } else {
        this.list.push({
          id: this.currentShipmentId,
          scanned: true,
          checkStatus: statuses.existing,
        })
      }
    },

    async onFinish() {
      try {
        const body = {
          id: this.freight.id,
          data: {}
        };
        await this.finished(body);
      } catch ({message}) {
        ElNotification({
          title: 'Ошибка',
          message: message,
          type: 'error',
        });
      }
    },

    async onSubmit() {
      const filtered = this.list.filter(item => !item.scanned)

      if (!filtered.length) {
        this.onFinish()
        this.$emit('update:modelValue', false)
        return
      }

      try {
        await ElMessageBox.confirm(
            'В Перевозке имеются неотсканированные отправления. Вы действительно хотите завершить Перевозку с недостачей?', 'Подтверждение действия',
            {
              confirmButtonText: 'Завершить',
              cancelButtonText: 'Отменить',
              cancelButtonClass: 'el-button--info'
            },
        )
        this.onFinish()
        this.$emit('update:modelValue', false)
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    },

    open() {
      this.initList()
      window.addEventListener('keyup', this.scan);
      window.addEventListener('scanned', this.fromScanner);
    },

    close() {
      window.removeEventListener('keyup', this.scan);
      window.removeEventListener('scanned', this.fromScanner);
    }
  }
}
</script>

<style scoped lang="scss">
.receiving-with-scanner {
  &__content {
    display: flex;
    flex-direction: column;

    &-item {
      margin-bottom: 5px;

      .error {
        color: red;
      }
    }
  }
}
</style>
