<template>
  <el-dialog
    :model-value="modelValue"
    title="Подтверждение"
    width="375px"

    @close="close"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="top"
      class="modal-sms-confirm"

      @submit.prevent="onSend()"
    >
      <el-form-item label="Введите код" prop="code">
        <p class="modal-sms-confirm__msg mb-3">
          {{text}}
        </p>
        <el-input
          v-model="form.code"
          placeholder="----"
          :minlength="codeLength"
          :maxlength="codeLength"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="$emit('update:modelValue', false)">
          Отменить
        </el-button>
        <el-button type="primary" @click="onSend">
          Отправить
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<style scoped>
.modal-sms-confirm__msg{
  font-weight: 700;
}
</style>
<script>
import {ElDialog, ElNotification} from 'element-plus';
import {mapActions} from "vuex";

export default {
  name: "ModalSmsConfirm",

  components: { ElDialog },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    shipment: {
      type: Object,
      required: true,
    },
    text: {
      type: String
    },
    refusedGoodIds: {
      type: Array,
      defaults: []
    },
    reason: {
      type: Number,
      defaults: null
    },
    shipmentCodeType: {
      type: Number,
      defaults: 0,
    }
  },

  data() {
    return {
      form: {
        code: '',
      },
    };
  },

  computed: {
    codeLength() {
      const codeLengthMap = {
        3: 6,
        4: 8,
      };

      return codeLengthMap[this.shipmentCodeType] || 4;
    },
    rules() {
      return {
        code: [
          { required: true, message: 'Введите код', trigger: 'change' },
          {
            min: this.codeLength,
            max: this.codeLength,
            message: `Код должен состоять из ${this.codeLength} цифр`,
            trigger: 'change',
          },
        ],
      };
    },
  },

  methods: {
    ...mapActions({
      issued: 'shipments/issued',
    }),

    close() {

      this.$refs.form.resetFields();
    },

    async onIssued() {
      try {
        const body = {
          uuid: this.shipment.uuid,
          data: {
            shipment_code_type: this.shipmentCodeType,
            code: this.form.code
          }
        };

        if (this.refusedGoodIds && this.refusedGoodIds.length) {
          body.data.refused_items = this.refusedGoodIds;
        }

        if (this.reason != null) {
          body.data.refusal_reason = this.reason;
        }

        await this.issued(body);

        this.$emit('update:modelValue', false);

        ElNotification({
          title: 'Успешно',
          message: `Отправление №${this.shipment.number} выдано`,
          type: 'success',
        });

        this.$router.push({ name: 'give' });
      } catch ({ message }) {
        ElNotification({
          title: 'Ошибка',
          message: message || 'Не удалось выдать отправление',
          type: 'error',
        });
      }
    },

    onSend() {

      this.$refs.form.validate((isValid) => {
        if (isValid) {
          this.onIssued();
        }
      });
    },
  }
}
</script>
