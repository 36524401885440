<template>
    <div class="loader__backdrop" :class="{'loader__backdrop--active': isShow}">
        <div class="loader"></div>
    </div>
</template>

<style>
.loader__backdrop{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0);
    z-index: 9;
    display: none;
}
.loader__backdrop--active{
    display: block;
}
.loader {
  padding: 16px;
  border: 4px solid #00aeff;
  border-right-color: transparent;
  border-radius: 50%;
  animation: rotate 1s infinite linear;
  position:absolute;
  right: 32px;
  bottom: 16px;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>

<script>
export default {
    name: "Loader",
    computed: {
        isShow() {
            return this.$store.state.isLoading;
        }
    }
}
</script>