import PointRating from './PointRating.vue'
import permissions from "@/modules/auth/auth.types.ts";


export const pointRatingRoutes = [
  {
    path: '/point-rating',
    component: PointRating,
    name: 'pointRating',
    meta: {
      title: "Рейтинг ПВЗ",
      middleware: [permissions.MANAGE_RATING_POINTS]
    },
  }
]
