<template>
  <el-dialog
      :model-value="showModal"
      title="Выбор ячейки"
      width="30%"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :show-close="false"
      append-to-body
      @close="onClose"
      @update:modelValue="$emit('update:showModal', $event)"
  >
    <el-form ref="formRef" :model="form" class="text-center">
      <el-form-item label="Ячейка">
        <el-select v-model="form.ceil" placeholder="Выбрать">
          <el-option
              v-for="item in ceilList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
      <div class="col-12 text-center">
        <el-button type="primary" @click="onSubmitCeil">
          Выбрать ячейку
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {ElDialog, ElNotification} from 'element-plus';

export default {
  components: {ElDialog},

  props: {
    modelValue: {
      type: [Number, null],
      required: true,
    },
    showModal: {
      type: Boolean,
      required: true,
    },
    initialPlace: {
      type: Number
    }
  },
  emits: [
      'update:showModal',
      'update:modelValue',
  ],

  data: () => ({
    form: {
      ceil: null,
    }
  }),
  computed: {
    ceilList() {
      return this.$store.state.ceils.list;
    }
  },
  async created() {
    await this.$store.dispatch('ceils/getList');
  },
  watch: {
    initialPlace(value) {
      if (this.initialPlace !== null) {
        this.form.ceil = value;
      }
    }
  },
  methods: {
    onSubmitCeil() {
      if (!this.form.ceil) {
        return ElNotification({
          title: 'Выберите ячейку!',
          message: 'Ячейка не может быть пустой!',
          type: 'error',
        });
      }
      this.$emit('update:showModal', false);
      this.$emit('update:modelValue', this.form.ceil);
    },
    onClose() {
      this.form.ceil = null
    }
  }
}
</script>

<style scoped lang="scss">
.receiving-with-scanner {
  &__content {
    display: flex;
    flex-direction: column;

    &-item {
      margin-bottom: 5px;
    }
  }
}
</style>
