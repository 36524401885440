<template>
  <div class="row" style="margin-top:20px" v-if="statData">
    <div>
      <div class="transport-current-table overflow-auto">
        <table style="width: 100%; border-collapse: collapse;">
          <thead>
          <tr>
            <th class="table__cell table__th">Магазин</th>
            <th class="table__cell table__th">Созданные</th>
            <th class="table__cell table__th">Принятые</th>
            <th class="table__cell table__th">Выполненные</th>
            <th class="table__cell table__th">Отмененные</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in statData" :key="row.shop_id">
            <td class="table__cell">{{ row.shop_name }} {{ row.shop_id }}</td>
            <td class="table__cell">{{ row.cnt_created }}</td>
            <td class="table__cell">{{ row.cnt_accepted }}</td>
            <td class="table__cell">{{ row.cnt_issued }}</td>
            <td class="table__cell">{{ row.cnt_cancelled }}</td>
          </tr>
          </tbody>
        </table>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "table",
  data() {
    return {
      statData: [{
        shop_name: "Магазин 1",
        cnt_created: 1,
        cnt_accepted: 2,
        cnt_issued: "something else",
        cnt_cancelled: "another value"
      },
        {
          shop_name: "Магазин 2",
          cnt_created: 44,
          cnt_accepted: 123,
          cnt_issued: "something ",
          cnt_cancelled: "one value"
        }]
    }
  }
}
</script>

<style scoped>

</style>
