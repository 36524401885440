import {
  getAll,
  createItem,
  getTypes,
  getSortingCenters,
  readItem,
  accept,
  finished,
  //   updateItem,
  //   deleteItem,
} from "src/api/freights";

import {FREIGHT_STATUS_VALUES, USERS_PAGE_LIMIT} from "@/constants";
import { FREIGHT_TYPES } from "src/constants";
import { FREIGHT_TYPES_ADMIN } from "src/constants";
// TODO check uses DATE_FORMAT & dateFormat

const store = {
  namespaced: true,
  state() {
    return {
      list: [],
      pagination: {
        totalPages: 1,
        totalItems: 0,
        perPage: 0
      },
      types: FREIGHT_TYPES,
      typesAdmin: FREIGHT_TYPES_ADMIN,
      newFreight: {},
      errors: {
        getList: {},
        saveFreight: {},
        accept: {},
      },
      typeList: [],
      sortingCenters: [],
      editItem: {},
      statuses: FREIGHT_STATUS_VALUES,
    };
  },
  getters: {
    typesOptions(state) {
      return Object.keys(state.types).map((key) => {
        return { value: parseInt(key), title: state.types[key] };
      });
    },
    typesNameById: (state) => (id) => {
      return state.types[parseInt(id)] || "";
    },
    typesAdminOptions(state) {
      return Object.keys(state.typesAdmin).map((key) => {
        return { value: parseInt(key), title: state.typesAdmin[key] };
      });
    },
    typesAdminNameById: (state) => (id) => {
      return state.typesAdmin[parseInt(id)] || "";
    },
    typeListOptions(state) {
      return state.typeList.map(item => ({
        value: item.id,
        title: item.name,
      }))
    },
    sortingCentersOptions(state) {
      return state.sortingCenters.map(item => ({
        value: item.id,
        title: item.name,
      }))
    },

    statusOptions({ statuses }) {
      return Object.keys(statuses).map((key) => {
        return { value: parseInt(key), title: statuses[key] };
      });
    },

    statusNameById: ({ statuses }) => (id) => {
      return statuses[parseInt(id)] || "";
    },
  },
  actions: {
    async getList(context, filters = {}) {
      context.commit("SET_ERRORS", {
        key: "getList",
        val: {},
      });

      const filter = {
        items: USERS_PAGE_LIMIT,
        page: filters.page || 1,
      };
      if (filters.search) {
        filter.search = filters.search;
      }
      if (filters.type > -1) {
        filter.type = filters.type;
      }

      if (filter.type) {
        const status_multiple = ({
          1: '[20,0]',
          2: '[102,101]'
        })[filter.type];

        filter.status_multiple = status_multiple;
      }

      if (filters.status > -1) {
        filter.status = filters.status;
      }

      if (filters?.date?.length === 2) {
        filter.dt_from = filters.date[0].toLocaleDateString('lt');
        filter.dt_to = filters.date[1].toLocaleDateString('lt');
      }

      try {
        const resp = await getAll(filter);
        context.commit("SET_LIST", resp.data);

        context.commit("SET_PAGINATION", {
          key: "totalPages",
          val: resp.headers["last_page"] * 1 || 1,
          totalItems: resp.headers["total"],
          perPage: resp.headers["per_page"]
        });
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "getList",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "getList",
            val: { error: "Ошибка " + e.status },
          });
        }
      }
    },
    async save(context, payload) {
      context.commit("SET_ERRORS", {
        key: "saveFreight",
        val: {},
      });

      const freight = { ...payload };
      try {
        freight.shipments = freight.shipments || [];

        await createItem(freight);
        if (!freight.id) {
          context.commit("RESET_NEW_FREIGHT");
        }
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "saveFreight",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "saveFreight",
            val: { error: "Ошибка " + e.message },
          });
        }
      }
    },
    async getFreightTypes({ commit }) {
      commit("SET_ERRORS", {
        key: "getFreightTypes",
        val: {},
      });
      try {
        const response = await getTypes();
        commit("SET_TYPE_LIST", response.data)
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          commit("SET_ERRORS", {
            key: "getFreightTypes",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          commit("SET_ERRORS", {
            key: "getFreightTypes",
            val: { error: "Ошибка " + e.message },
          });
        }
      }
    },
    async getSortingCenters({ commit }) {
      commit("SET_ERRORS", {
        key: "getSortingCenters",
        val: {},
      });
      try {
        const response = await getSortingCenters();
        commit("SET_SORTING_CENTERS", response.data)
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          commit("SET_ERRORS", {
            key: "getSortingCenters",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          commit("SET_ERRORS", {
            key: "getSortingCenters",
            val: { error: "Ошибка " + e.message },
          });
        }
      }
    },
    async readItem({ commit }, id) {
      try {
        const { status, data } = await readItem(id)
        if (status === 200) {
          commit("SET_ITEM", data.data)
        }
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          commit("SET_ERRORS", {
            key: "readItem",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          commit("SET_ERRORS", {
            key: "readItem",
            val: { error: "Ошибка " + e.message },
          });
        }
      }
    },
    async accept(context, { id, data = {} }) {
      context.commit("SET_ERRORS", {
        key: "accept",
        val: {},
      });

      try {
        await accept(id, data);
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "accept",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "accept",
            val: { error: "Ошибка" },
          });
        }
        throw new Error(e.response.data.error || e.message)
      }
    },
    async finished(context, { id, data = {} }) {
      context.commit("SET_ERRORS", {
        key: "finished",
        val: {},
      });

      try {
        await finished(id, data);
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "finished",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "finished",
            val: { error: "Ошибка" },
          });
        }
        throw new Error(e.response.data.error || e.message)
      }
    },
  },
  mutations: {
    SET_PAGINATION(state, payload) {
      state.pagination[payload.key] = payload.val;
      state.pagination.totalItems = payload.totalItems;
      state.pagination.perPage = payload.perPage;
    },
    SET_ERRORS(state, error) {
      state.errors[error.key] = error.val;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_NEW_FREIGHT(state, payload) {
      state.newFreight = { ...state.newFreight, ...payload };
    },
    RESET_NEW_FREIGHT(state) {
      state.newFreight = {};
    },
    SET_TYPE_LIST(state, payload) {
      state.typeList = payload
    },
    SET_SORTING_CENTERS(state, payload) {
      state.sortingCenters = payload
    },
    SET_ITEM(state, item) {
      state.editItem = item
    }
  },
};
export default store;
