import apiV2Service from "@/api/V2/services/apiV2.service";

const store = {
  namespaced: true,
  actions: {
    index(context, data) {
      return apiV2Service.post('point-rating', data)
    },

    create(context, data) {
      return apiV2Service.post('point-rating/create', data)
    },

    delete(context, data) {
      return apiV2Service.post('point-rating/delete', data)
    },
  },
};
export default store;
