<template>
  <ul class="table-container" :class="isHeader && 'table-head'">
    <Cell
      v-for="(value, name) in cells"
      v-bind:key="name"
      :value="value"
      :is-header="isHeader"
      :type="name"
      :class="[cellClass(name), isCellHidden(name)]"
      :cursor="cursor"
      @onEdit="$emit('onEdit')"
      @onDelete="$emit('onDelete')"
      @onBtnClick="$emit('onBtnClick')"
    />
  </ul>
</template>

<script>
import Cell from "./Cell.vue";

export default {
  name: "GridRow",
  components: {
    Cell,
  },
  props: {
    isHeader: Boolean,
    cells: Object,
    cellClass: Function,
    cursor: String,
  },
  methods: {
    isCellHidden(name) {
      return name === "hiddenID" ? "hidden" : "";
    },
  },
};
</script>
