import Shipments from "./Shipments.vue";
import ShipmentsDetail from "./ShipmentsDetail.vue";
import ShipmentsSetStatus from "@/views/shipments/admin/ShipmentsSetStatus";
import permissions from "@/modules/auth/auth.types.ts";
import ShipmentsRecalculate from "@/views/shipments/admin/ShipmentsRecalculate";

export const shipmentsRoutes = [
  {
    path: "/shipments",
    component: Shipments,
    name: "shipments",
    meta: {
      middleware: [permissions.OWN_SHIPMENT_SEARCH, permissions.ANY_SHIPMENT_SEARCH]
    },
  },
  {
    path: "/shipments/:id",
    component: ShipmentsDetail,
    name: "shipmentsDetail",
    meta: { isHeaderBackLink: true },
  },
  {
    path: "/shipments/set-status-for-shipments",
    component: ShipmentsSetStatus,
    name: "ShipmentsSetStatus",
    meta: {
      isHeaderBackLink: true,
      middleware: [permissions.MULTI_CHANGE_STATUS]
    },
  },
  {
    path: "/shipments/recalculate-shipments",
    component: ShipmentsRecalculate,
    name: "ShipmentsRecalculate",
    meta: {
      isHeaderBackLink: true,
      middleware: [permissions.RECALCULATE_FRANCH_TARIFFS]
    },
  },
];
