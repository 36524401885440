<template>
  <div class="row">
    <div class="d-flex justify-content-between mt-5">
      <div>
        <p>
          <button
              class="edit-worker-modal-button-save"
              type="button"
              @click="openFormTariffsV2"
          >
            Залить тариф
          </button>
        </p>
      </div>

      <div>
        <p>
          <button
              class="edit-worker-modal-button-save"
              type="button"
              @click="exportSortingCenter"
          >
            Залить РЦ Xmail в Авито
          </button>
        </p>
      </div>

      <div>
        <p>
          <button
              class="edit-worker-modal-button-save"
              type="button"
              @click="runCommand"
          >
            Забрать чужие РЦ в Xmail
          </button>
        </p>
      </div>

      <div>
        <p>
          <button
              class="edit-worker-modal-button-save"
              type="button"
              @click="openFormSetTagsForSortingCenter"
          >
            Проставить теги для РЦ
          </button>
        </p>
      </div>

      <div>
        <p>
          <button
            class="green-rounded-button"
            type="button"
            @click="reloadTasks"
          >
            Обновить данные
          </button>
        </p>
      </div>
    </div>
  </div>



  <div class="row" style="margin-top:20px">
    <div>
      <div class="transport-current-table overflow-auto">
        <table style="width: 100%; border-collapse: collapse;" v-if="tasks">
          <thead>
          <tr>
            <th class="table__cell table__th">ID</th>
            <th class="table__cell table__th">Название</th>
            <th class="table__cell table__th">Task ID</th>
            <th class="table__cell table__th">Статус</th>
            <th class="table__cell table__th">Результат</th>
            <th class="table__cell table__th">Ошибка</th>
            <th class="table__cell table__th"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in tableData" :key="row.id" >
            <td class="table__cell">{{ row.id }}</td>
            <td class="table__cell">{{ row.name }}</td>
            <td class="table__cell">{{ (row.task_id === 0) ? '-': row.task_id}}</td>
            <td class="table__cell">{{ row.state }}</td>
            <td class="table__cell">
              {{ isShowFullInfo(row.result) ? trimStr(row.result) : row.result }}
              <a href="#" v-if="isShowFullInfo(row.result)" @click.stop="showJson(row.result,row.task_id)">больше</a>

            </td>
            <td class="table__cell">
                {{ isShowFullInfo(row.error) ? trimStr(row.error) : row.error }}
              <a href="#" v-if="isShowFullInfo(row.error)" @click.stop="showJson(row.error,row.task_id)">больше</a>
            </td>
            <td class="table__cell">

              <button class="green-button act-button" @click.prevent="downloadJson(row.id)"
                      type="button">Скачать запрос
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div style="margin-top: 20px; display: flex; justify-content: flex-end">
        <el-pagination @current-change="changePage" background layout="prev, pager, next" :total="total" />
      </div>
    </div>
  </div>

  <el-dialog v-model="isShowJsonDialog" :title="titleDialog">
    <span class="dialog-footer">
      <pre>{{ getJsonData(json) }}</pre>
    </span>
    <template #footer>
      <el-button @click="isShowJsonDialog = false">закрыть</el-button>
    </template>
  </el-dialog>

  <el-dialog v-model="dialogTariffsV2Form" title="Залить тариф в Авито">
    <el-form :model="tariffsV2Form">
      <el-form-item label="Тип отправления">
        <el-select
            v-model="tariffsV2Form.shipment_type"
            class="m-2"
            placeholder="Выберите тип отправлений"
            size="large"
            style="width: 240px"
        >
          <el-option
              v-for="item in shipmentTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogTariffsV2Form = false">Отмена</el-button>
        <el-button @click="tariffsV2()" type="primary">
          Залить тариф
        </el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="dialogSetTagsForSortingCenterForm" title="Проставить теги для РЦ">
    <el-form :model="setTagsForSortingCenterForm" label-width="160px">
      <el-form-item label="Номер тарифа">
        <el-input
            v-model="setTagsForSortingCenterForm.tariff_id"
            controls-position="right"
        />
      </el-form-item>
      <el-form-item label="Только для РЦ Exmail">
        <el-checkbox v-model="setTagsForSortingCenterForm.xmail_only"  />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogSetTagsForSortingCenterForm = false">Закрыть</el-button>
        <el-button @click="setTagsForSortingCenter()" type="primary">Проставить теги
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {ElMessageBox, ElNotification} from "element-plus";
import {downloadFile, getFileNameWithExtensionFromHeaders} from "@/store/helper";

export default {
  name: "Avito",

  created() {
    this.getAvitoTasks();
  },
  data() {
    return {
      isShowJsonDialog: false,
      dialogTariffsV2Form: false,
      dialogSetTagsForSortingCenterForm: false,
      titleDialog: null,

      limit: 10,
      total: 0,
      tableData: [],
      tasks: null,
      paginateFilter: {
        perPage: 10,
        page: 1
      },
      tariffsV2Form: {
        shipment_type: null,
      },
      setTagsForSortingCenterForm: {
        tariff_id: null,
        xmail_only: false,
      },

      shipmentTypes: [
        {
          label: 'Все',
          value: null
        },
        {
          label: 'C2C',
          value: 10
        },
        {
          value: 20,
          label: 'B2C'
        },
        {
          value: 30,
          label: 'XDeliveryFirstLeg'
        },
        {
          value: 40,
          label: 'XDeliveryLastLeg'
        },
        {
          value: 50,
          label: 'XDeliveryLastLegReturn'
        },
        {
          value: 60,
          label: 'XdeliveryFirstLegSmallTown'
        }
      ]
    }
  },
  methods: {
    openFormTariffsV2() {
      this.dialogTariffsV2Form = true;
    },
    openFormSetTagsForSortingCenter() {
      this.dialogSetTagsForSortingCenterForm = true;
    },
    async tariffsV2() {
      this.$store.dispatch('apiV2AvitoStore/tariffsV2', this.tariffsV2Form).then(() => {
        this.dialogTariffsV2Form = false;
        this.successMessage()
      });
    },

    async exportSortingCenter() {
      await ElMessageBox.confirm(
          'Залить РЦ Xmail в Авито?',
          {
            confirmButtonText: 'Залить',
            cancelButtonText: 'Отмена',
            cancelButtonClass: 'el-button--info'
          },
      )

      this.$store.dispatch('apiV2AvitoStore/exportSortingCenter').then(() => {
        this.successMessage()
      });
    },

    async runCommand() {
      await ElMessageBox.confirm(
          'Импортировать чужие РЦ в Xmail?',
          {
            confirmButtonText: 'Импортировать',
            cancelButtonText: 'Отмена',
            cancelButtonClass: 'el-button--info'
          },
      )

      this.$store.dispatch('apiV2AvitoStore/sortingCenterImport').then(() => {
        this.successMessage('РЦ залиты')
      })
    },

    async setTagsForSortingCenter() {


      this.$store.dispatch('apiV2AvitoStore/setTagsForSortingCenter', this.setTagsForSortingCenterForm).then(() => {
        this.setTagsForSortingCenterForm = false
        this.successMessage()
      });
    },
    successMessage(message = 'Задача на передачу данных в Авито создана. Обновите данные через некоторое время, чтобы увидеть результат выполнения') {

      ElNotification({
        title: 'Задача создана',
        message: message,
        type: 'success',
      });

    },
    getAvitoTasks() {
      this.$store.dispatch('apiV2AvitoStore/getAvitoTasks', this.paginateFilter).then(result => {
        this.tasks = result.tasks
        this.tableData = result.tasks.data;
        console.log('tableData');
        console.log(this.tableData)
        this.total = result.tasks.total;
      })
    },

    getColorStateName(state) {
      switch (state) {
        case 'processing':
          return 'warning';
        case 'success':
          return 'success';
        case 'failed':
          return 'danger';
        default:
          return '';
      }
    },
    showLimited(str) {
      if (str == null) {
        return ''
      }
      if (str.length > 60) {
        return str.substring(0, length) + '...'
      }
    },
    isShowFullInfo(str) {
      if (str == null) {
        return false
      }
      return  (str.length > 60);
    },

    /**
     * Если ошибка больше определнной длины
     * То мы обрезаем ошибку и добавляем кнопочку посомтреть все
     */
    isExceededMaxLengthError(error) {
       if (error == null) {
         return false;
       }
      return (error.length > 100);
    },

    trimStr(str, length = 100) {
      if (str == null) {
        return str;
      }

      return str.substring(0, length) + '...';
    },

    changePage(page) {
      this.paginateFilter.page = page;

      this.getAvitoTasks();
    },

    reloadTasks() {
      this.getAvitoTasks();
    },

    getJsonData(json) {
      if (json == null) {
        return json;
      }

      return JSON.parse(json);
    },

    showJson(json,task_id) {
      this.isShowJsonDialog = true;
      this.titleDialog = 'Avito Task № ' + task_id;
      this.json = json;
    },

    downloadJson(avitoTaskId) {
      this.$store.dispatch('apiV2AvitoStore/downloadAvitoTaskRequest', avitoTaskId).then(response => {
        const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        downloadFile(url, fileNameWithExtension)
      }, (error) => {
        ElNotification({
          title: 'Ошибка',
          message: error.message,
          type: 'error',
        });
      });
    }
  }
}
</script>

<style scoped>
::v-deep .el-table th.el-table__cell {
  background-color: rgb(239, 242, 247);
}

::v-deep .el-pagination .el-pager li {
  padding: 18px 16px;
  background-color: #ffffff;
  border-radius: 5px;
}
::v-deep .el-pagination .el-pager .el-icon {
  width: 3em;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  padding: 18px 16px;
  background-color: #556ee6;
  border-radius: 5px;
}

::v-deep .el-table__row .cell {
  color: #495057;
  font: inherit;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  line-height: normal;
  white-space: pre-wrap;
  word-break: break-word;
}

.green-rounded-button {
  background-color: #34c38f;
  border-radius: 8px;
  font-size: 14px;
  color: #FFFFFF;
  padding: 12px 16px;
  border: none;
}
</style>
