<script>
import BaseSelect from "@/components/selectors/BaseSelect";
import {
  getShopsSelfPickupForReturns,
  getShopsWithReturnShipmentsStatusReady
} from "@/views/return-acts/api/api";

const TYPE_ALL_SHOPS = 1;
const TYPE_SHOPS_ON_POINT_USER_HAVE_RETURN = 2;
const TYPE_SHOPS_ON_POINT_USER_HAVE_RETURN_STATUS_READY_FOR_SHIPMENT = 3;
const TYPE_SHOPS_SELF_PICKUP_FOR_RETURNS = 4;

export default {
  name: "ShopSelect",
  props: [
    'typeShops'
  ],
  extends: BaseSelect,
  created() {
    this.isLoading = true;

    this.getItems();

    this.isLoading = false;
  },
  data() {
    return {
      placeholder: 'Магазин',
      isLoading: false,
      label: "label",
      value: [],
      reduce: item => item.value,
      options: [],
      selectedIds: null,
      shops: null
    }
  },
  methods: {
    allShopsForSelect() {
      return this.$store.state.apiV2ShopStore.list.map((item) => {
        return {value: item.id, label: item.name};
      });
    },

    shopsOnPointUserHaveReturnSelect() {
      return this.shops.map((item) => {
        return {value: item.id, label: item.name};
      });
    },
    async getItems() {
      switch (this.typeShops) {
        case TYPE_ALL_SHOPS:
          if (!this.allShopsForSelect.length) {
            await this.$store.dispatch("apiV2ShopStore/getShops");
          }

          this.options = this.allShopsForSelect();

          break;
        case TYPE_SHOPS_ON_POINT_USER_HAVE_RETURN:
          if (!this.allShopsForSelect.length) {
            await this.$store.dispatch("apiV2ReturnActStore/getShopsWithReturnShipmentsOnPointUser").then((result) => {
              this.shops = result;
            })
          }

          this.options = this.shopsOnPointUserHaveReturnSelect();
          break;
        case TYPE_SHOPS_ON_POINT_USER_HAVE_RETURN_STATUS_READY_FOR_SHIPMENT:
          if (!this.allShopsForSelect.length) {
            await getShopsWithReturnShipmentsStatusReady().then((result) => {
              this.shops = result.data.shops;
            })
          }

          this.options = this.shopsOnPointUserHaveReturnSelect();
          break;
        case TYPE_SHOPS_SELF_PICKUP_FOR_RETURNS:
          if (!this.allShopsForSelect.length) {
            await getShopsSelfPickupForReturns().then((result) => {
              this.shops = result.data.shops;
            })
          }

          this.options = this.shopsOnPointUserHaveReturnSelect();
          break;
      }
    }
  }
}
</script>

<style scoped>

</style>
