<template>
  <div>
    <a
      class="team-edit me-3"
      href="javascript:void(0)"
      @click.prevent="$emit('onEdit')"
    >
      <img class="team-edit__img" src="@/assets/img/pen_icon.svg" alt="pen" />
    </a>
    <a
      class="team-edit"
      href="javascript:void(0)"
      @click.prevent="$emit('onDelete')"
    >
      <img
        class="team-edit__img"
        src="@/assets/img/trashcan_icon.svg"
        alt="trash"
      />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    type: String,
  },
};
</script>
