<template>
  <div>
    <FilterTabs
      :options="typesOptions"
      :value="filters.type"
      @onClick="onFilterTabClick"
    />

    <Toolbar>
      <template v-slot:filter>
        <Search
          v-model:value="filters.search"
          :placeholder="titles.search"
          @search="getList({ page: 1 })"
        />
        <Select
          v-if="filters.type === 2"
          :options="statusSelect"
          v-model:value="filters.status"
          :placeholder="titles.status"
          @search="getList({ page: 1 })"
        />

        <RangeDatePicker
          v-if="filters.type === 2"
          @onUpdate="onPeriodUpdate"
          @clear="onPeriodUpdate"
          @onApply="getList({ page: 1 })"
        />
      </template>

      <template v-slot:buttons>
        <Button @click="$router.push({ name: 'freight-create' })" modifyer="rounded">
          Новая перевозка
        </Button>
      </template>
    </Toolbar>

    <div v-if="freights.length">
      <component
        :is="freightsComponent"
        :freights="freights"
        @onPrint="onPrint"
      />
    </div>
    <div v-else-if="getListLoading" class="pt-3 pb-3">Загрузка...</div>
    <div v-else class="pt-3 pb-3">Нет информации</div>

    <Pages
      v-if="freights.length"
      :totalPages="totalPages"
      :currentPage="filters.page"
      :last="totalPages"
      :total="total"
      :perPage="perPage"
      @change="switchPage"
    />
  </div>
</template>

<script>
import FilterTabs from "@/components/partials/FilterTabs.vue";
import Toolbar, {
  Search,
  Select,
  RangeDatePicker,
} from "src/components/toolbar";
import Pages from "src/components/Pages.vue";
import Button from "src/components/base/Button.vue";
import InPointFreights from "src/components/freights/admin/InPointFreights.vue";
import FromPointFreights from "src/components/freights/admin/FromPointFreights.vue";
import ModalCreate from "src/components/modal/ModalCreate.vue";
import CreateFreight from "src/components/freights/CreateFreight.vue";

export default {
  name: "AdminFreights",
  components: {
    FilterTabs,
    Toolbar,
    Search,
    Select,
    RangeDatePicker,
    Pages,
    Button,
    InPointFreights,
    FromPointFreights,
    ModalCreate,
    CreateFreight,
  },
  data() {
    return {
      filters: {
        page: 1,
        search: "",
        type: this.$store.getters["freights/typesAdminOptions"][0].value || 1,
        date: [],
        status: -1,
      },
      titles: {
        search: "Поиск",
        status: "Все статусы",
      },
      statusSelect: [
        { value: 0, title: "Значение 1" },
        { value: 1, title: "Значение 2" },
      ],
      getListLoading: true,
    };
  },
  computed: {
    totalPages() {
      return this.$store.state.freights.pagination.totalPages;
    },
    total() {
      return this.$store.state.freights.pagination.totalItems;
    },
    perPage() {
      return this.$store.state.freights.pagination.perPage;
    }, 
    typesOptions() {
      return this.$store.getters["freights/typesAdminOptions"];
    },
    freightsComponent() {
      return this.filters.type === 1 ? InPointFreights : FromPointFreights;
    },
    freights() {
      return this.$store.state.freights.list;
    },
  },
  mounted() {
    this.$store.commit("SET_TITLE", "Перевозки");

    let dicts = [];
    if (!this.$store.state.points.list.length) {
      dicts.push(this.$store.dispatch("points/getList"));
    }
    Promise.all(dicts).then(() => {
      this.getList();
    });
  },
  methods: {
    async getList(opts) {
      this.filters = { ...this.filters, ...opts };
      this.getListLoading = true;
      await this.$store.dispatch("freights/getList", this.filters);
      this.getListLoading = false;
    },
    onFilterTabClick(e) {
      this.filters.type = e.value;
      if (this.filters.type !== 2) {
        this.filters.date = [];
        this.filters.status = -1;
      }
      this.getList({ page: 1 });
    },
    async switchPage(page) {
      this.filters.page = page;
      await this.getList();
    },
    onPeriodUpdate(valArr) {
      if (valArr) {
        this.filters.date = [...valArr];
      } else {
        this.filters.date = [];
      }
    },
    onPrint(item) {
      console.log("onPrint");
      console.log(item);
    },
  },
};
</script>
