<template>
  <div class="registration-shipment">
    <el-form
        novalidate
        :model="form"
        ref="form"
        label-position="top"
        class="registration-shipment__form"
        @submit="confirm"
    >
      <div v-if="shipment.status == 100" class="mb-3">
        <b>Отменен по причине:</b> {{ shipment.refuse_reason?.reason }}
      </div>

      <el-form-item class="w-100" label="Интернет-магазин" prop="shop">
        <div class="w-100 d-flex">
          <el-select v-model="form.shop" :disabled="true" placeholder="Выберите магазин">
            <el-option label="Авито" value="avito"></el-option>
          </el-select>
          <button v-if="shipment.status == 0" @click="modalRefuseToggle()" type="button"
                  class="el-button el-button--default" style="margin-left: auto">Отказ
          </button>
        </div>
      </el-form-item>
      <ModalRefuseAccept v-if="isModalRefuseShow" @close="modalRefuseToggle" :uuid="shipment?.uuid" from="accept"/>


      <h3 class="registration-shipment__title">Данные отправителя</h3>

      <el-form-item label="Телефон отправителя" prop="phoneSrc">
        <el-input v-model="form.phoneSrc" disabled></el-input>
      </el-form-item>

      <!-- ФИО -->
      <el-form-item label="Фамилия" prop="src_last_name">
        <input class="el-input__inner" v-model="form.src_last_name" required>
        <p class="input__validate">Обязательное поле</p>
      </el-form-item>
      <el-form-item label="Имя" prop="src_first_name">
        <input class="el-input__inner" v-model="form.src_first_name" required>
        <p class="input__validate">Обязательное поле</p>
      </el-form-item>
      <el-form-item label="Отчество" prop="src_middle_name">
        <input class="el-input__inner" v-model="form.src_middle_name" required>
        <p class="input__validate">Обязательное поле</p>
      </el-form-item>
      <el-form-item v-if="(shipment.point_dst?.need_declaration || shipment.point_src?.need_declaration)"
                    label="Дата рождения" prop="src_birthdate">
        <input type="date" class="el-input__inner" v-model="form.src_birthdate" placeholder="Дата рождения" required>
        <p class="input__validate">Обязательное поле</p>
      </el-form-item>
      <el-form-item label="Nickname" prop="nickname">
        <el-input v-model="form.nickname" disabled></el-input>
      </el-form-item>
      <el-form-item label="E-mail отправителя" prop="emailSrc">
        <el-input v-model="form.emailSrc" disabled></el-input>
      </el-form-item>

      <el-form-item label="Тип документа">
        <el-radio v-model="form.document_type" label="0">Паспорт</el-radio>
        <el-radio v-model="form.document_type" label="1" disabled>Вод. удостоверение</el-radio>
        <el-radio v-model="form.document_type" label="2">Паспорт иностр. гражданина</el-radio>
        <el-radio v-model="form.document_type" label="3">Иное</el-radio>
      </el-form-item>


      <div class="d-flex gap-3">
        <el-form-item class="w-1/2" label="Серия документа">
          <input class="el-input__inner" v-model="form.document_series" placeholder="Серия документа" required>
          <p class="input__validate">Обязательное поле</p>
        </el-form-item>

        <el-form-item class="w-1/2" label="Номер документа">
          <input class="el-input__inner" v-model="form.document_number" placeholder="Номер документа" required>
          <p class="input__validate">Обязательное поле</p>
        </el-form-item>
      </div>
      {{ shipment.point }}

      <div class="d-flex gap-3" v-if="shipment.point_src?.need_declaration">
        <el-form-item class="w-100" label="Адрес регистрации">
          <input class="el-input__inner" v-model="form.src_client_document_registration_address"
                 placeholder="Адрес регистрации" required>
          <p class="input__validate">Обязательное поле</p>
        </el-form-item>

        <el-form-item class="w-1/2" label="Дата выдачи">
          <input class="el-input__inner" v-model="form.src_client_document_issue_date" type="date"
                 placeholder="Дата выдачи" required>
          <p class="input__validate">Обязательное поле</p>
        </el-form-item>
      </div>


      <h3 class="registration-shipment__title">Характеристики отправления</h3>

      <el-form-item label="Мест" prop="places.count">
        <el-select v-model="form.places.count" :disabled="true" placeholder="Выберите">
          <el-option label="1" :value="1"></el-option>
        </el-select>
      </el-form-item>

      <h4 class="registration-shipment__subtitle">Место 1</h4>

      <el-form-item label="Вес (кг)">
        <input class="el-input__inner" v-model="form.mass" placeholder="Введите вес" required>
        <p class="input__validate">Обязательное поле</p>
      </el-form-item>

      <!-- Упаковка -->
      <div v-if="packageTypes && packageTypes.length">
        <el-form-item label="Упаковка">
          <el-select v-model="form.package_type" placeholder="Выберите" required>
            <el-option :label="item.name" :value="item.id" v-for="item in packageTypes" :key="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Габариты (см)">
          <el-row :gutter="15">
            <el-col :span="8">
              <input class="el-input__inner" type="number" v-model="form.dimensions_x" placeholder="Ширина"
                     :disabled="disableSizes" required>
              <p class="input__validate">Обязательное поле</p>
            </el-col>
            <el-col :span="8">
              <input class="el-input__inner" type="number" v-model="form.dimensions_y" placeholder="Длина"
                     :disabled="disableSizes" required>
              <p class="input__validate">Обязательное поле</p>
            </el-col>
            <el-col :span="8">
              <input class="el-input__inner" type="number" v-model="form.dimensions_z" placeholder="Высота"
                     :disabled="disableSizes" required>
              <p class="input__validate">Обязательное поле</p>
            </el-col>
          </el-row>
        </el-form-item>
      </div>
      <div class="registration-shipment__card" v-for="item in shipment.items" :key="item.id">
        <div class="registration-shipment__card-title">Опись вложения</div>
        <div class="shipment-accordion-items">
          <div class="shipment-accordion-titles">
            <p class="shipment-accordion-text-xNumbers"
            >x{{ item.content_quantity }}</p
            >
            <p class="shipment-accordion-text-name">
              {{ item.content_name }}</p
            >
          </div>
          <div class="shipment-accordion-images">
            <el-image
                v-for="(imgObj, index) in item.attached_images"
                style="width: 100px; height: 100px"
                :src="imgObj.link"
                :preview-src-list="getImgLink(item.attached_images)"
                :initial-index="index"
                :hide-on-click-modal="true"
                fit="cover"
                :key="imgObj.id"
            />
          </div>
          <p class="shipment-accordion-items-description" v-html="item.comment">
          </p>
        </div>

      </div>

      <el-form-item label="Объявленная стоимость" prop="valuation">
        <el-input v-model="form.valuation" placeholder="Руб" disabled></el-input>
      </el-form-item>

      <h3 class="registration-shipment__title">Данные получателя</h3>

      <el-form-item label="Телефон получателя" prop="phoneDst">
        <el-input v-model="form.phoneDst" disabled></el-input>
      </el-form-item>

      <!-- ФИО -->
      <el-form-item label="Фамилия" prop="dst_last_name">
        <input class="el-input__inner" v-model="form.dst_last_name" disabled>
        <p class="input__validate mb-3">Обязательное поле</p>
      </el-form-item>
      <el-form-item label="Имя" prop="dst_first_name">
        <input class="el-input__inner" v-model="form.dst_first_name" disabled>
        <p class="input__validate mb-3">Обязательное поле</p>
      </el-form-item>
      <el-form-item label="Отчество" prop="dst_middle_name">
        <input class="el-input__inner" v-model="form.dst_middle_name" disabled>
        <p class="input__validate mb-3">Обязательное поле</p>
      </el-form-item>

      <el-form-item label="E-mail получателя" prop="emailDst">
        <el-input v-model="form.emailDst" disabled></el-input>
      </el-form-item>

      <h3 class="registration-shipment__title">Доставка</h3>

      <el-form-item label="Тип доставки" prop="deliveryType">
        <el-select v-model="form.deliveryType" placeholder="Выберите" disabled>
          <el-option label="Доставка до ПВЗ" value="pvz"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Адрес доставки" prop="deliveryAddress">
        <el-select v-model="form.deliveryAddress" placeholder="Выберите" disabled>
          <el-option
              v-for="item in points"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <h3 class="registration-shipment__title">Оплата</h3>

      <el-form-item label="Предоплачено" prop="prepaid">
        <el-row>
          <el-col :span="24">
            <el-input v-model="form.prepaid" disabled></el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="Сумма к оплате" prop="amountPayable">
        <el-row>
          <el-col :span="24">
            <el-input v-model="form.amountPayable" disabled></el-input>
          </el-col>
        </el-row>
      </el-form-item>

      <template v-if="shipment.status == 0">

        <template v-if="isB2CShipment">
          <div class="alert alert-warning" role="alert">
            Данное отправление должно быть принято
            <router-link to="/multi-accept" class="alert-link">
              мультиприёмом
            </router-link>
          </div>
        </template>
        <template v-else>
          <button class="el-button el-button--primary el-button--default" :disabled="isPending">Провести</button>
        </template>
      </template>
      <template v-else>
        <div class="text-danger mb-3">Отправление уже было принято, статус: <b>{{ status }}</b></div>
      </template>
    </el-form>

    <!-- модалка выборка места -->
    <departure-placement v-model="departurePlacementVisible" :shipment-id="shipment.id" @place="print"/>
    <print-document-dialog
        v-model="isPrintDocumentDialogVisible"
        :shipment="shipment"
        @close="this.$router.push({name: 'shipmentsDetail', params: {id: this.shipment.id}});"
    />
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {ElMessageBox, ElNotification} from "element-plus";
import ModalRefuseAccept from "src/components/modal/ModalRefuseAccept.vue";
import DeparturePlacement from "@/components/freights/franchisee/DeparturePlacement";
import {usePrint} from '@/composition/usePrint.js';
import {AVITO_SHOP_ID, SHIPMENT_STATUS_VALUES, SHIPMENT_TYPE_B2C} from '@/constants';
import PrintDocumentDialog from "@/components/freights/franchisee/PrintDocumentDialog.vue";

const DEFAULT_PACKAGE_ID = 9;

export default {
  name: 'RegistrationShipment',
  components: {
    ModalRefuseAccept,
    DeparturePlacement,
    PrintDocumentDialog,
  },
  setup() {
    return {
      ...usePrint(),
    }
  },
  data: () => ({
    isPrintDocumentDialogVisible: false,
    departurePlacementVisible: false,
    disableSizes: false,
    isPending: false,
    form: {
      shop: 'avito',
      phoneSrc: '',
      emailSrc: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      src_birthdate: '',
      nickname: '',
      document_type: '0',
      document_series: '',
      document_number: '',
      src_client_document_issue_date: null,
      src_client_document_registration_address: null,
      mass: 0,
      package_type: '',
      places: {
        count: 1,
      },
      declaredValue: '',
      valuation: '',
      phoneDst: '',
      emailDst: '',
      firstNameDst: '',
      secondNameDst: '',
      lastNameDst: '',
      deliveryType: 'pvz',
      deliveryAddress: null,
      prepaid: '0',
      amountPayable: '',
    },
    isModalRefuseShow: false,
  }),

  computed: {
    isB2CShipment() {
      return ((this.shipment.shop_id !== AVITO_SHOP_ID) && this.shipment.type === SHIPMENT_TYPE_B2C);
    },
    status() {
      return SHIPMENT_STATUS_VALUES[this.shipment.status];
    },
    ...mapState({
      packageTypes: state => state.shipments.packageTypes,
      shipment: state => state.shipments.readItem,
      points: state => state.points.list,
    }),
  },

  async created() {
    this.getPoints();
    this.initForm();
    this.getPackageTypes();
  },
  watch: {
    // При изменении типа упаковки меняем размеры из этой упаковки
    'form.package_type': function (value) {
      const pkg = this.packageTypes.find(item => item.id == value);

      if (pkg && pkg.xsize != null) {
        this.disableSizes = true;
        this.form.dimensions_x = pkg.xsize;
        this.form.dimensions_y = pkg.ysize;
        this.form.dimensions_z = pkg.zsize;
      } else {
        this.disableSizes = false;
        this.form.dimensions_x = +this.shipment.dimensions_x || '';
        this.form.dimensions_y = +this.shipment.dimensions_y || '';
        this.form.dimensions_z = +this.shipment.dimensions_z || '';
      }

    }
  },
  methods: {
    getImgLink(ArrayObjects) {
      return ArrayObjects.map(item => item.link);
    },
    ...mapActions({
      getShipment: 'shipments/readItem',
      accept: 'shipments/accept',
      getPackageTypes: 'shipments/getPackageTypes',
      getPoints: 'points/getList',
    }),

    async initForm() {
      await this.getShipment(this.$route.params.id);
      const {
        package_type,
        valuation,
        client_src,
        client_dst,
        mass,
        dimensions_x,
        dimensions_y,
        dimensions_z,
        dts_point_id,
      } = this.shipment;

      this.form = {
        ...this.form,
        phoneSrc: client_src.phone_primary,
        emailSrc: client_src.email_adress,
        src_first_name: (client_src.first_name || '').trim(),
        src_middle_name: (client_src.middle_name || '').trim(),
        src_last_name: (client_src.last_name || '').trim(),
        src_birthdate: client_src.birthdate,
        document_type: client_src.document_type === null ? '0' : '' + client_src.document_type,
        document_series: client_src.document_series,
        document_number: client_src.document_number,
        src_client_document_issue_date: client_src?.document?.issue_date,
        src_client_document_registration_address: client_src?.document?.registration_address,
        nickname: client_src.nickname,
        phoneDst: client_dst.phone_primary,
        emailDst: client_dst.email_adress,
        dst_first_name: (client_dst.first_name || '').trim(),
        dst_middle_name: (client_dst.middle_name || '').trim(),
        dst_last_name: (client_dst.last_name || '').trim(),
        valuation: valuation,
        amountPayable: 0,//sum
        deliveryAddress: dts_point_id,
        mass,
        dimensions_x,
        dimensions_y,
        dimensions_z,
        package_type: package_type ? package_type.id : DEFAULT_PACKAGE_ID,
        places: {
          count: this.form.places.count,
        },
      }
    },

    async confirm(e) {
      e.preventDefault();

      const form = e.target;
      form.classList.add('form--submitted');

      if (!form.checkValidity()) {
        return ElNotification({
          title: 'Ошибка',
          message: 'Не заполнены обязательные поля формы',
          type: 'error',
        });
      }

      try {
        this.isPending = true;

        await ElMessageBox.confirm('', 'Принять заказ?', {
          "lock-scroll": true,
          "close-on-click-modal": false,
          confirmButtonText: 'Продолжить',
          cancelButtonText: 'Отмена',
          cancelButtonClass: 'el-button--info'
        })
        this.sendData();
        // eslint-disable-next-line no-empty
      } catch (e) {
        this.isPending = false;

      }
    },

    async print() {
      this.isPrintDocumentDialogVisible = true;
    },
    async sendData() {
      try {
        const data = {
          package_type: this.form.package_type,
          src_first_name: this.form.src_first_name,
          src_middle_name: this.form.src_middle_name,
          src_last_name: this.form.src_last_name,
          src_birthdate: this.form.src_birthdate,
          dst_first_name: this.form.dst_first_name,
          dst_middle_name: this.form.dst_middle_name,
          dst_last_name: this.form.dst_last_name,
          document_type: +this.form.document_type,
          document_series: this.form.document_series,
          document_number: this.form.document_number,
          dts_point_id: this.form.deliveryAddress,
          mass: this.form.mass || 0,
          dimensions_x: this.form.dimensions_x,
          dimensions_y: this.form.dimensions_y,
          dimensions_z: this.form.dimensions_z,
          src_client_document: {
            type: +this.form.document_type,
            number: this.form.document_number,
            series: this.form.document_series,
            issue_date: this.form.src_client_document_issue_date,
            registration_address: this.form.src_client_document_registration_address,
          }
        };

        // ждем изменений dts_point_id, src_point_id
        const result = await this.accept({id: this.$route.params.id, data});
        const {dts_point_id, src_point_id} = result.data;

        if (dts_point_id == src_point_id) {
          this.departurePlacementVisible = true;
        } else {
          await this.print();
        }

      } catch (error) {
        ElNotification({
          title: 'Ошибка',
          message: `Не удалось оформить отправление ${error}`,
          type: 'error',
        })
      } finally {
        this.isPending = false;
      }
    },
    modalRefuseToggle() {
      this.isModalRefuseShow = !this.isModalRefuseShow;
    },
  }
};
</script>
<style scoped>
b {
  font-weight: bold;
}

.w-1\/2 {
  width: 50%;
}

.el-input__inner {
  height: 42px;
}

.el-input__inner:disabled {
  background-color: var(--el-disabled-bg-color);
  box-shadow: 0 0 0 1px var(--el-disabled-border-color) inset;
  color: var(--el-disabled-text-color);
  cursor: not-allowed;
}

.el-form-item .input__validate {
  display: none;
}

.form--submitted .el-input__inner:invalid {
  border: 1px solid #dc3545;
}

.form--submitted .el-input__inner:invalid ~ .input__validate {
  display: block;
  color: #dc3545;
}
</style>
<style lang="scss">
.registration-shipment {
  padding-top: 32px;

  &__form {
    width: 540px;

    .el-input {
      .el-input__inner {
        height: 42px;
        border: 1px solid #EFF2F7;
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 44px;
    margin-bottom: 16px;
  }

  &__card {
    padding: 16px;
    margin-bottom: 10px;
    background: #FFFFFF;
    box-shadow: 2px 2px 12px rgba(42, 48, 66, 0.11);
    border-radius: 4px;

    &-title {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
</style>
