<template>
  <div>
    <div class="modal-backdrop fade show" @click="$emit('onClose')"></div>
    <div class="modal fade show d-block" id="addNewWorker">
      <div class="modal-dialog custom-modal-dialog modal-dialog-centered">
        <div class="modal-content customModalNewWorker">
          <button
            type="button"
            class="btn-close custom-close-modal"
            aria-label="Close"
            @click="$emit('onClose')"
          ></button>
          <div class="modal-body">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalCreate",
};
</script>
