import Points from "./Points.vue";

export const pointsRoutes = [
  {
    path: "/points",
    component: Points,
    name: "points",
    meta: { title: "Справочник ПВЗ" },
  },
];
