<template>
  <div class="context_menu">
    <div class="container-search">
      <form class="sort-items">
                    <span class="custom-search">
                        <button class="invise-searchButton"></button>
                        <input required class="custom-search-input form-control" placeholder="Поиск" type="search">
                        <button onclick="clearSearch()" type="button" class="close-icon"></button>
                    </span>
      </form>
    </div>
    <div class="search-table">
      <div class="horizontal-table-scroll">
        <ul class="table-container table-head">
          <li class="table-items-head search-table-item-number-width">
            <span>Номер отправления</span>
          </li>
          <li class="table-items-head search-table-item-name-width">
            <span>Отправитель</span>
          </li>
          <li class="table-items-head search-table-item-reciver-width">
            <span>Получатель</span>
          </li>
          <li class="table-items-head search-table-item-shop-width">
            <span>Интернет-магазин</span>
          </li>
          <li class="table-items-head search-table-item-from-width">
            <span>Отправлено из</span>
          </li>
          <li class="table-items-head search-table-item-where-width">
            <span>Место назначения</span>
          </li>
          <li class="table-items-head search-table-item-pay-width">
            <span>К оплате</span>
          </li>
          <li class="table-items-head search-table-item-status-width">
            <span>Статус</span>
          </li>
        </ul>
        <ul class="table-container">
          <li class="table-items search-table-item-number-width">
                            <span>
                                <a class="ship-order-link" href="https://yodepro.gitlab.io/exbox/searchShipmentItemInfo.html">
                                    12345678
                                </a>
                            </span>
          </li>
          <li class="table-items search-table-item-name-width">
                            <span>Христорождественская-Воскобойникова
                                Вильгельмина
                                Аполлинариевна</span>
          </li>
          <li class="table-items search-table-item-reciver-width">
            <span>Смирнов Иван Иванович</span>
          </li>
          <li class="table-items search-table-item-shop-width">
            <span>Авито</span>
          </li>
          <li class="table-items search-table-item-from-width">
            <span>Москва_11</span>
          </li>
          <li class="table-items search-table-item-where-width">
            <span>Красноярск_13</span>
          </li>
          <li class="table-items search-table-item-pay-width">
            <span>1650.00 Р</span>
          </li>
          <li class="table-items search-table-item-status-width">
            <span>Готово к выдаче</span>
          </li>
        </ul>
        <ul class="table-container">
          <li class="table-items search-table-item-number-width">
            <a class="ship-order-link" href="https://yodepro.gitlab.io/exbox/searchShipmentItemInfo.html">
              12345678
            </a>
          </li>
          <li class="table-items search-table-item-name-width">
                            <span>Христорождественская-Воскобойникова
                                Вильгельмина
                                Аполлинариевна</span>
          </li>
          <li class="table-items search-table-item-reciver-width">
            <span>Смирнов Иван Иванович</span>
          </li>
          <li class="table-items search-table-item-shop-width">
            <span>Авито</span>
          </li>
          <li class="table-items search-table-item-from-width">
            <span>Москва_11</span>
          </li>
          <li class="table-items search-table-item-where-width">
            <span>Красноярск_13</span>
          </li>
          <li class="table-items search-table-item-pay-width">
            <span>1650.00 Р</span>
          </li>
          <li class="table-items search-table-item-status-width">
            <span>Готово к выдаче</span>
          </li>
        </ul>
        <ul class="table-container">
          <li class="table-items search-table-item-number-width">
            <a class="ship-order-link" href="https://yodepro.gitlab.io/exbox/searchShipmentItemInfo.html">
              12345678
            </a>
          </li>
          <li class="table-items search-table-item-name-width">
                            <span>Христорождественская-Воскобойникова
                                Вильгельмина
                                Аполлинариевна</span>
          </li>
          <li class="table-items search-table-item-reciver-width">
            <span>Смирнов Иван Иванович</span>
          </li>
          <li class="table-items search-table-item-shop-width">
            <span>Авито</span>
          </li>
          <li class="table-items search-table-item-from-width">
            <span>Москва_11</span>
          </li>
          <li class="table-items search-table-item-where-width">
            <span>Красноярск_13</span>
          </li>
          <li class="table-items search-table-item-pay-width">
            <span>1650.00 Р</span>
          </li>
          <li class="table-items search-table-item-status-width">
            <span>Готово к выдаче</span>
          </li>
        </ul>
        <ul class="table-container">
          <li class="table-items search-table-item-number-width">
            <a class="ship-order-link" href="https://yodepro.gitlab.io/exbox/searchShipmentItemInfo.html">
              12345678
            </a>
          </li>
          <li class="table-items search-table-item-name-width">
                            <span>Христорождественская-Воскобойникова
                                Вильгельмина
                                Аполлинариевна</span>
          </li>
          <li class="table-items search-table-item-reciver-width">
            <span>Смирнов Иван Иванович</span>
          </li>
          <li class="table-items search-table-item-shop-width">
            <span>Авито</span>
          </li>
          <li class="table-items search-table-item-from-width">
            <span>Москва_11</span>
          </li>
          <li class="table-items search-table-item-where-width">
            <span>Красноярск_13</span>
          </li>
          <li class="table-items search-table-item-pay-width">
            <span>1650.00 Р</span>
          </li>
          <li class="table-items search-table-item-status-width">
            <span>Готово к выдаче</span>
          </li>
        </ul>
        <ul class="table-container">
          <li class="table-items search-table-item-number-width">
            <a class="ship-order-link" href="https://yodepro.gitlab.io/exbox/searchShipmentItemInfo.html">
              12345678
            </a>
          </li>
          <li class="table-items search-table-item-name-width">
                            <span>Христорождественская-Воскобойникова
                                Вильгельмина
                                Аполлинариевна</span>
          </li>
          <li class="table-items search-table-item-reciver-width">
            <span>Смирнов Иван Иванович</span>
          </li>
          <li class="table-items search-table-item-shop-width">
            <span>Авито</span>
          </li>
          <li class="table-items search-table-item-from-width">
            <span>Москва_11</span>
          </li>
          <li class="table-items search-table-item-where-width">
            <span>Красноярск_13</span>
          </li>
          <li class="table-items search-table-item-pay-width">
            <span>1650.00 Р</span>
          </li>
          <li class="table-items search-table-item-status-width">
            <span>Готово к выдаче</span>
          </li>
        </ul>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Back",
  components: {},
};
</script>
