import { getRegions, getTimezones } from "src/api/dicts";

import { getRoles } from "src/api/dictionaries";

async function getDictAction(context, dict, commit, apiFn) {
  try {
    const { status, data } = await apiFn();
    if (status === 200) {
      context.commit(commit, { dict, data });
    }
  } catch (e) {
    console.error("dict_load_error");
  }
}

const store = {
  namespaced: true,
  state() {
    return {
      userRoles: [],
      regions: {},
      timezones: {},
      rawDict: {
        regions: [],
        timezones: [],
      },
    };
  },
  actions: {
    async getRegions(context) {
      return await getDictAction(context, "regions", "SET_LIST", getRegions);
    },
    async getTimezones(context) {
      return await getDictAction(
        context,
        "timezones",
        "SET_LIST",
        getTimezones
      );
    },
    async getUserRoles(context) {
      try {
        const { data } = await getRoles();
        context.commit("SET_USER_ROLES", data);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {},
  mutations: {
    SET_LIST(state, { dict, data = [] }) {
      // console.log(data);
      state.rawDict[dict] = data;
      state[dict] = data.reduce(
        (result, item) => Object.assign(result, { [item.id]: item }),
        {}
      );
      // console.log(state[dict]);
    },
    SET_USER_ROLES(state, payload) {
      state.userRoles = payload;
    },
  },
};

export default store;
