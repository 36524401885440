<template>
    <div>
        <slot name="label" />
        <input class="form-control LoginInput"
            :autocomplete="autocomplete"
            :type="type"
            :placeholder="placeholder"
            :class="inputClass"
            :value="value"
            @change="onChange"
            :required="required">
        <div class="invalid-feedback" v-if="error">
            {{ error }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Input',
        props: {
            placeholder: String,
            value: String,
            class: String,
            required: Boolean,
            error: String,
            autocomplete: String,
            type: String,
        },
        computed: {
            inputClass() {
                return this.class
            },
        },
        methods: {
            onChange({ target }) {
                this.$emit('update:value', target.value)
            },
        }
    }
</script>
