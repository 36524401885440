<template>    
    <div class="checkForget">
        <div>
            <input class="RememberCheckBox" 
                type="checkbox"
                id="remember"
                :checked="checked"
                @input="onChecked">
            <label class="checkBox-Label" for="remember"></label>
            <label class="textForCheckbox" for="remember">
                {{ title }}
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Checkbox',
        props: {
            checked: Boolean,
            title: String,
        },
        methods: {
            onChecked({ target }) {
                this.$emit('update:checked', target.checked)
            }
        }
    }
</script>
