import Registration from "./Registration.vue";
import RegistrationConfirmedEmail from "./RegistrationConfirmedEmail.vue";
import LegalEntity from "@/views/registration/LegalEntity";
import permissions from "@/modules/auth/auth.types.ts";

export const registrationRoutes = [
    {
        path: "/registration/new-user",
        component: Registration,
        name: "registration",
        meta: {
            title: "Регистрация",
        }
    },
    {
        path: "/registration/registration-confirmed-email",
        component: RegistrationConfirmedEmail,
        name: "RegistrationConfirmedEmail",
        meta: {
            title: "Подтверждение Email"
        }
    },
    {
        path: "/registration/legal-entity-edit",
        component: LegalEntity,
        name: "legalEntityEdit",
        meta: {
            title: "Заявка на регистрацию партнера",
            middleware: [permissions.CREATE_REQUEST_REGISTRATION],
        }
    },
];
