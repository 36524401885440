<template>
  <button
    type="button"
    class="mainButton"
    :class="`${modifyer}-button`"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    modifyer: String,
    disabled: Boolean,
  },
};
</script>

<style lang="scss">
.secondary {
  background: #a6b0cf;
}
</style>
