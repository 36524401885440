import {
    getWidgets,
    getDataForModals
} from "src/api/statistic";

const store = {
    namespaced: true,
    state() {
      return {
        widgets: null,
        dataForModals: null
      }
    },
    actions: {
        async getWidgets(context, filters) {
            const { data } = await getWidgets(filters.from, filters.to, filters.points, filters.isTodayDate);
            context.state.widgets = data;
        },
        async getDataForModals(context, filters){
            const { data } = await getDataForModals(filters.from, filters.to, filters.points, filters.isTodayDate);
            context.state.dataForModals = data;
        }
    }
}

export default store;
