<template>
    <form class="LogIn needs-validation" novalidate @submit.prevent="authenticate">
        <label class="DescriptionLog">Введите логин и пароль.</label>
        <div class="invalid-feedback" v-show="error">
            {{ error }}
        </div>
        <Input placeholder="Логин" v-model:value="user.email_adress" required autocomplete="email" type="email" />
        <Input placeholder="Пароль" v-model:value="user.password" required type="password" />
        <div class="d-flex justify-content-between">
        <Checkbox title="Запомнить" v-model:checked="user.remember" />
        <!--TODO: вернуть когда будет реализовано апи восстановления пароля-->

        <div>
          <input id="forgetButton" class="Forget" type="forget" value="Забыли пароль?" @click="showForget" readonly>
        </div>
        </div>
        <div>
            <input class="Access"
                type="submit"
                name="logIn"
                value="Войти">
        </div>
        <router-link to="/registration/new-user" v-if="false">
          <input type="help" value="Зарегистрироваться" readonly>
        </router-link>
    </form>
</template>

<script>
    import Input from './Input.vue'
    import Checkbox from './Checkbox.vue'
    import { LOGIN_FORM_ERRORS } from 'src/constants'

    export default {
        name: 'LoginForm',
        components: {
            Input,
            Checkbox,
        },
        data() {
            return {
                showForgetPopup: false,
            }
        },
        computed: {
            isAuthenticated() {
                return this.$store.state.user.isAuthenticated
            },
            user() {
                return this.$store.state.user.form
            },
            error() {
                return LOGIN_FORM_ERRORS[this.$store.state.user.error]
            }
        },
        methods: {
            async authenticate() {
                try {
                    await this.$store.dispatch('user/authenticate')
                    this.$emit('getUser')
                } catch (e) {
                    console.error(e)
                }
            },
            showForget() {
                this.$store.commit('user/CLEAR_ERROR')
                this.$emit('forget')
            }
        },
    }
</script>
