<template>
  <div class="shipments-table-container">
    <h2 class="title-style-small">{{ tableTitle }}</h2>
    <el-table :data="shipments">
      <el-table-column label="ID">
        <template #default="scope">
          <router-link :to="{ name: 'shipmentsDetail', params: { id: scope.row.id } }">{{ scope.row.id }}</router-link>
        </template>
      </el-table-column>
      <el-table-column label="Интернет-магазин" >
        <template #default="scope">
        {{ scope.row.shop ?  scope.row.shop.name: '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="number" label="№ отправления" />
      <el-table-column prop="valuation" label="Объявленная стоимость" />
      <el-table-column label="Статус" >
        <template #default="scope">
          {{ shipmentsStatusNameById(scope.row.status) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'ShipmentsTable',
  props: {
    shipments: Array,
    tableTitle: String,
  },

  computed: {
    ...mapGetters({
      shipmentsStatusNameById: 'shipments/statusNameById',
    }),
  },
};
</script>
<style lang="scss" scoped>
.shipments-table-container {
  margin: 40px 0;
}

.shipments-table-container h2 {
  margin: 10px 0;
}



</style>
