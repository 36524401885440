export default function createCrud(client, path) {
    return {
        async getAll(filters) {
            const query = Object.keys(filters).map(key => `${key}=${filters[key]}`)
            return await client.get(`${path}?${query.join('&')}`)
        },
        async createItem(item) {
            return await client.post(path, item)
        },
        async readItem(id) {
            return await client.get(`${path}/${id}`)
        },
        async updateItem(id, item) {
            return await client.put(`${path}/${id}`, item)
        },
        async deleteItem(id) {
            return await client.delete(`${path}/${id}`)
        },
    }
}
