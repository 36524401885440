<template>
  <button @click="$emit('onBtnClick')">{{ title }}<slot /></button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    title: String,
    type: String,
  },
};
</script>
