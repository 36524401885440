<template>
  <div
    class="block-shadow accordion accordion-flush shipment-acordion-container"
  >
    <div class="accordion-item">
      <h2 class="accordion-header" id="shipmentAccordionTitle">
        <button
          class="custom-accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#shipmentInfoCollapse"
          aria-expanded="false"
          aria-controls="shipmentInfoCollapse"
          @click.prevent="toggleGoods"
        >
          Товары из отправления № {{ shipment.number }} / ШК {{ shipment.barcode }}
        </button>
      </h2>
      <div
        v-if="isGoodsShow"
        id="shipmentInfoCollapse"
        class="accordion-collapse collapse show"
        aria-labelledby="shipmentAccordionTitle"
        data-bs-parent="#shipmentFlush"
      >
        <div
          v-for="item in shipment.items"
          :key="item.id"
          class="custom-accordion-body accordion-body"
        >
          <div class="shipment-accordion-items">
            <div class="shipment-accordion-titles">
              <p class="shipment-accordion-text-xNumbers"
                >x{{ item.content_quantity }}</p
              >
              <p class="shipment-accordion-text-name">
                {{ item.content_name }}</p
              >
              <p class="shipment-accordion-text-line"></p>
              <p class="shipment-accordion-text-price">{{ item.declared_cost }} Р</p>
            </div>
            <div class="shipment-accordion-images">
              <el-image 
                v-for="(imgObj, index) in item.attached_images"
                style="width: 100px; height: 100px"
                :src="imgObj.link"
                :preview-src-list="getImgLink(item.attached_images)"
                :initial-index="index"
                hide-on-click-modal="true"
                fit="cover"
                :key="imgObj.id"
              />
            </div>
            <p class="shipment-accordion-items-description" v-html="item.comment">
            </p>
          </div>
        </div>
        <div
          v-if="!shipment.items.length"
          class="custom-accordion-body accordion-body"
        >
          <div class="shipment-accordion-items">
            <div class="shipment-accordion-titles">
              <p class="shipment-accordion-text-name"> --- </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.shipment-accordion-items-description{
  white-space: pre-wrap;
}
</style>
<script>
export default {
  name: "ShipmentGoods",
  props: {
    shipment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isGoodsShow: true,
    };
  },
  methods: {
    getImgLink(ArrayObjects) {
      return ArrayObjects.map(item => item.link);
    },
    toggleGoods() {
      this.isGoodsShow = !this.isGoodsShow;
    },
  },
};
</script>
