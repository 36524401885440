import apiV2Service from "@/api/V2/services/apiV2.service";
import indexDB, {SCHEDULE_SCHEMES} from '@/helpers/indexdb'
import parseStatus from "@/store/parseStatus";
import {isDataUpdateRequired} from "@/store/helper";

// Период обновления в минутах
const TIME_TO_REFRESH = 10;

const store = {
  namespaced: true,
  state() {
    return {
      list: [],
      listLastUpdate: null,
      errors: {},
    };
  },
  actions: {
    async getScheduleSchemes(context, params = {}) {
      if (context.state.listLastUpdate && context.state.list.length > 0 && !isDataUpdateRequired(context.state.listLastUpdate)) {
        return;
      }

      const interval = await indexDB.lastUpdateInterval(SCHEDULE_SCHEMES);

      if (interval < TIME_TO_REFRESH && !params.force) {
        indexDB.getItems(SCHEDULE_SCHEMES).then((result) => {
          context.commit("SET_LIST", result);
        });

        return;
      }

      try {
        const data = await apiV2Service.get('schedule-scheme/getScheduleSchemes');

        context.commit("SET_LIST", data);
        indexDB.saveItems(SCHEDULE_SCHEMES, data);
      } catch ({message, response = {}}) {
        context.commit("SET_ERROR", parseStatus(response.status) || message);
      }

      context.commit("SET_LIST_LAST_UPDATE", new Date().getTime());
    },
  },
  mutations: {
    SET_LIST_LAST_UPDATE(state, payload) {
      state.listLastUpdate = payload;
    },
    SET_ERROR(state, code) {
      state.errors.common = code;
      console.error(code);
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
  }
};
export default store;
