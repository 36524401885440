<template>
    <div id="forgetModal" :class="`${modifyer}Modal`" v-if="show" @click.prevent="onClick">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        show: Boolean,
        modifyer: String,
    },
    methods: {
        onClick() {
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss" scoped>
    .ForgetModal, .usercreateModal {
        display: block;
        position: fixed;
        z-index: 35;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: rgba(0,0,0,0.4);
    }
</style>
