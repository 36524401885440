<template>
    <div class="container-search">
        <form class="sort-items">
            <slot name="filter"></slot>
        </form>
        <div class="storage-button-container">
            <slot name="buttons"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Toolbar',
    }
</script>
