import positiveSound from '@/assets/audio/neutral.mp3';
import negativeSound from '@/assets/audio/negative.mp3';
import scannedCeilSound from '@/assets/audio/scanned_ceil.mp3';
import alertLongSound from '@/assets/audio/alert_long.mp3';

import {onMounted} from "@vue/runtime-core";

const useSound = () => {
  let positiveAudio = null;
  let negativeAudio = null;
  let scannedCeilAudio = null;
  let alertLongAudio = null;

  const playPositiveSound = () => {
    if (positiveAudio) {
      positiveAudio.play();
    }
  }
  const playNegativeSound = () => {
    if (negativeAudio) {
      negativeAudio.play();
    }
  }

  const playScannedCeilSound = () => {
    if (scannedCeilAudio) {
      scannedCeilAudio.play();
    }
  }

  const playAlertLongSound = () => {
    if (alertLongAudio) {
      alertLongAudio.play();
    }
  }

  onMounted(() => {
    positiveAudio = new Audio(positiveSound); // path to file
    negativeAudio = new Audio(negativeSound);
    scannedCeilAudio = new Audio(scannedCeilSound);
    alertLongAudio = new Audio(alertLongSound);
  });

  return {
    playPositiveSound,
    playNegativeSound,
    playScannedCeilSound,
    playAlertLongSound,
  };
}

export {useSound}
