<template>
  <div class="mt-5">
    <div>
      <button
        class="edit-worker-modal-button-save k-mr-3"
        type="button"
        @click="toggleDialog"
      >
        Добавить
      </button>
    </div>

    <div class="mt-3">
      <Grid
        ref="grid"
        :take="take"
        :skip="skip"
        :sortable="true"
        :sort="sort"
        :data-items="ratings"
        :columns="getColumns"
        :total="total"
        :pageable="true"
        :page-size="pageSize"
        @pagechange="pageChangeHandler"
      >
        <template v-slot:action="{props}">
          <td>
            <div class="d-flex justify-content-start">
              <div>
                <Button
                  :theme-color="'warning'"
                  @click="downloadRatingFile(props.dataItem.id)"
                >Скачать файл</Button>
              </div>

              <div style="padding-left: 10px;">
                <Button
                  :theme-color="'error'"
                  @click="deleteRatingRecord(props.dataItem.id)"
                >Удалить</Button>
              </div>
            </div>
          </td>
        </template>
      </Grid>
    </div>
  </div>

  <Dialog v-if="visibleDialog" @close="toggleDialog" :title="'Добавить новый рейтинг'">
    <el-form
      novalidate
      @input="$emit('input', $event.target.value)"
      label-position="top"
      class="mt-4"
      @submit.prevent="$emit('submit', ratingForm)"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="Начало периода">
            <el-date-picker
              class="w-100"
              v-model="ratingForm.date_start"
              type="date"
              placeholder="Создан от"
              format="DD.MM.YYYY"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Конец периода">
            <el-date-picker
              class="w-100"
              v-model="ratingForm.date_end"
              type="date"
              placeholder="Создан до"
              format="DD.MM.YYYY"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="16" class="multi-accept__search-block">
        <el-col>
          <div class="k-form-field mb-3">
            <label class="k-label k-form-label">Выберите файл:</label>
            <div :style="{ width: '100%' }">
              <localization :language="'ru-RU'">
                <intl :locale="'ru'" >
                  <Upload
                    :files="ratingForm.file"
                    :batch="false"
                    :multiple="false"
                    :restrictions="{
                            allowedExtensions: ['.xls', '.xlsx'],
                          }"
                    @add="onAdd"
                    @remove="onRemove"
                    @progress="onProgress"
                    @statuschange="onStatusChange"
                    :with-credentials="false"
                    :auto-upload="false"
                    :actions-layout="'stretched'"
                    :save-url="pathApiUploadRating"
                  />
                </intl>
              </localization>
              <div class="k-form-hint">
                Допустимые форматы: <strong>XLS и XLSX</strong>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="16" class="multi-accept__search-block">
        <el-col :span="4">
          <el-button @click="uploadRatings" type="success">Загрузить</el-button>
        </el-col>
      </el-row>
    </el-form>
  </Dialog>
</template>

<script>
import { Dialog } from '@progress/kendo-vue-dialogs';
import { Grid } from '@progress/kendo-vue-grid';
import { Upload } from '@progress/kendo-vue-upload';
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-vue-intl';
import {ElMessageBox, ElNotification} from "element-plus";
import { Button } from '@progress/kendo-vue-buttons';
import {downloadFile, getFileNameWithExtensionFromHeaders} from "@/store/helper";

const ruMessages = {
  "upload": {
    "dropFilesHere": "Перетащите файл сюда для загрузки",
    "invalidFileExtension": "Выбранный тип файла не подходит.",
    "select": "Выбрать файл",
  }
};
loadMessages(ruMessages, 'ru-RU');

export default {
  name: "PointRating",
  components: {
    Grid,
    Dialog,
    Upload,
    Button,
    intl: IntlProvider,
    localization: LocalizationProvider
  },
  created() {
    this.getRatings();
  },
  data() {
    return {
      ratings: null,
      skip: 0,
      take: 10,
      pageSize: 10,
      total: null,
      visibleDialog: false,
      ratingForm: {
        date_start: '',
        date_end: '',
        file: [],
      },
      pathApiUploadRating: process.env.VUE_APP_API_ROOT + '/v2/point-rating/create'
    }
  },
  methods: {
    getRatings() {
      this.$store.dispatch('apiV2PointRatingStore/index', {
        take: this.take,
        skip: this.skip,
      }).then((result) => {
        this.ratings = result.pointRating;
        this.total = result.total;
      });
    },
    handleExceed() {

    },
    toggleDialog() {
      this.visibleDialog = !this.visibleDialog;
    },
    uploadRatings() {
      let data = new FormData();

      console.log(this.ratingForm.file[0].getRawFile());

      data.append('file', this.ratingForm.file[0].getRawFile());
      data.append('date_start', this.ratingForm.date_start);
      data.append('date_end', this.ratingForm.date_end);

      this.$store.dispatch('apiV2PointRatingStore/create', data).then(() => {
        this.toggleDialog();
        this.getRatings();

        ElNotification({
          title: 'Успешно',
          message: 'Начата загрузка рейтингов',
          type: 'success',
        });
      }, error => {
        this.toggleDialog();
        this.getRatings();

        ElNotification({
          title: 'Ошибка',
          message: error.message,
          type: 'error',
        });
      });
    },
    hasOneValidFile() {
      return this.ratingForm.file.some((f) => f.status === 4);
    },
    onAdd(event) {
      this.ratingForm.file = event.newState;
    },
    onRemove(event) {
      this.ratingForm.file = event.newState;
    },
    onProgress(event) {
      this.ratingForm.file = event.newState;
    },
    onStatusChange(event) {
      this.ratingForm.file = event.newState;
    },
    pageChangeHandler(event) {
      this.take = event.page.take;
      this.skip = event.page.skip;

      this.getRatings();
    },
    async deleteRatingRecord(ratingRecordId) {
      await ElMessageBox.confirm(
        'Удалить рейтинг? Действие нельзя будет отменить!!!',
        {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          cancelButtonClass: 'el-button--info'
        },
      )

      this.$store.dispatch('apiV2PointRatingStore/delete', {
        'ratingId': ratingRecordId
      }).then(() => {
        this.getRatings();

        ElNotification({
          title: 'Успешно',
          message: 'Рейтинг удален',
          type: 'success',
        });
      }, error => {
        this.getRatings();

        ElNotification({
          title: 'Ошибка',
          message: error.message,
          type: 'error',
        });
      });
    },

    downloadRatingFile(ratingId) {
      this.$store.dispatch('apiV2DocumentStore/downloadRatingFile', ratingId).then(response => {
        const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        downloadFile(url, fileNameWithExtension)
      });
    }
  },
  computed: {
    getColumns() {
      return [
        {
          field: 'date_start',
          title: 'Начало периода',
        },
        {
          field: 'date_end',
          title: 'Конец периода',
        },
        {
          cell: 'action',
          title: 'Действие',
        }
      ];
    }
  }
}
</script>

<style scoped>

::v-deep(.k-dialog) {
  z-index: 2000!important;
}

.k-dialog-wrapper {
  z-index: 1999!important;
}

::v-deep(.k-actions) {
  display: none;
}

::v-deep(.k-overlay) {
  z-index: 1999!important;
}

</style>
