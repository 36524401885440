import apiV2Service from "@/api/V2/services/apiV2.service";

const store = {
    namespaced: true,
    state() {
        return {
            list: [],
        }
    },
    actions: {
        async getShops({commit}) {
            return await apiV2Service.get(`shops`).then(result => {
                commit("setList", result);
            });
        },
    },
    mutations: {
        setList(state, payload) {
            state.list = payload;
        }
    }
};
export default store;
