import {
  getAll,
  createItem,
  readItem,
  accept,
  updateItem,
  //   deleteItem,
} from "src/api/sendings";
import {
  SENDING_STATUS_VALUES,
  USERS_PAGE_LIMIT
} from "@/constants";


const store = {
  namespaced: true,
  state() {
    return {
      list: [],
      pagination: {
        totalPages: 1,
        totalItems: 0,
        perPage: 0
      },
      errors: {
        getList: {},
        save: {},
        readItem: {},
        accept: {},
        updateItem: {},
      },
      statuses: SENDING_STATUS_VALUES,
      editItem: {},
    };
  },
  getters: {
    items({ list }, { statusNameById }) {
      return list.map((item) => {
        return {
          ...item,
          hiddenID: item.id,
          status: statusNameById(item.status) || item.status,
          number: item.number,
          date: item.dispatch_date,
          quantity: item.number_of_shipments,
          mass: item.total_weight,
          button: {type: 'act', title: 'Акт ПП'}
        }
      })
    },

    statusOptions({ statuses }) {
      return Object.keys(statuses).map((key) => {
        return { value: parseInt(key), title: statuses[key] };
      });
    },

    statusNameById: ({ statuses }) => (id) => {
      return statuses[parseInt(id)] || "";
    },
  },
  actions: {
    async getList(context, filters = {}) {
      context.commit("SET_ERRORS", {
        key: "getList",
        val: {},
      });

      const filter = {
        items: USERS_PAGE_LIMIT,
        page: filters.page || 1,
      };
      if (filters.search) {
        filter.search = filters.search;
      }

      if (filters.status || filters.status === 0) {
        filter.status = filters.status;
      }

      try {
        const resp = await getAll(filter);
        context.commit("SET_LIST", resp.data);

        context.commit("SET_PAGINATION", {
          key: "totalPages",
          val: resp.headers["last_page"] * 1 || 1,
          totalItems: resp.headers["total"],
          perPage: resp.headers["per_page"]
        });
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "getList",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "getList",
            val: { error: "Ошибка " + e.status },
          });
        }
      }
    },
    async save(context, payload) {
      context.commit("SET_ERRORS", {
        key: "save",
        val: {},
      });

      try {
        await createItem(payload);
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "save",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "save",
            val: { error: "Ошибка " + e.message },
          });
        }
        throw e;
      }
    },
    async update(context, { id, data }) {
      context.commit("SET_ERRORS", {
        key: "updateItem",
        val: {},
      });

      try {
        await updateItem(id, data);
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "updateItem",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "updateItem",
            val: { error: "Ошибка " + e.message },
          });
        }
        throw e;
      }
    },
    async readItem({ commit }, id) {
      try {
        const { status, data } = await readItem(id)
        if (status === 200) {
          commit("SET_ITEM", data.data)

          return data;
        }
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          commit("SET_ERRORS", {
            key: "readItem",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          commit("SET_ERRORS", {
            key: "readItem",
            val: { error: "Ошибка " + e.message },
          });
        }
      }
    },
    async accept(context, { id, data = {} }) {
      context.commit("SET_ERRORS", {
        key: "accept",
        val: {},
      });

      try {
        await accept(id, data);
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "accept",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "accept",
            val: { error: "Ошибка" },
          });
        }
        throw new Error(e.message)
      }
    },
  },
  mutations: {
    SET_PAGINATION(state, payload) {
      state.pagination[payload.key] = payload.val;
      state.pagination.totalItems = payload.totalItems;
      state.pagination.perPage = payload.perPage;
    },
    SET_ERRORS(state, error) {
      state.errors[error.key] = error.val;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_ITEM(state, item) {
      state.editItem = item
    }
  },
};
export default store;
