<template>
  <div class="context_menu">
    <div class="storageCreate-context-container">
      <div class="send-PVZ storageCreate-items">
        <h2 class="title-style-main">
          Отправления на ПВЗ
        </h2>
        <scan-shipment-form
          :items="items"
          :addedItems="sendingItems"
          :disabled="items.length === 0"
          @scanned="addShipment"
        />
        <create-shipment-table
          v-loading="itemsLoading"
          :items="items"
          :show-status="true"
        />
      </div>
      <div class="ready-to-send  storageCreate-items">
        <h2 class="title-style-main">
          Отправления к отправке {{ sending ? sending.number : '' }}
        </h2>
        <create-shipment-table
          v-loading="sendingLoading"
          :items="sendingItems"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import CreateShipmentTable from '@/components/warehouse/CreateShipmentTable';
import ScanShipmentForm from "@/components/warehouse/ScanShipmentForm.vue";
import {ElNotification} from "element-plus";
import {
  SHIPMENT_ACCEPTED_FROM_SENDER,
  SHIPMENT_ARRIVED_AT_WRONG_PICKUP_POINT,
  SHIPMENT_MOVING_TO_ANOTHER_POINT,
  SHIPMENT_RETURN
} from "@/constants";

export default {
  name: 'CreateSendingContent',
  components: {
    CreateShipmentTable,
    ScanShipmentForm,
  },
  data() {
    return {
      items: [],
      sending: null,
      sendingItems: [],
      itemsLoading: false,
      sendingLoading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    }
  },
  created() {
    this.initItems();
    this.initSending();
  },
  methods: {
    ...mapActions({
      getForSending: 'apiV2ShipmentStore/forSending',
      addShipments: 'apiV2SendingStore/addShipments',
      getSending: 'sendings/readItem',
    }),
    async initItems() {
      this.itemsLoading = true;
      await this.getForSending().then((result) => {
        this.items = result;
        this.itemsLoading = false;
      });
    },
    async initSending() {
      this.sendingLoading = true;
      this.getSending(this.$route.params.id).then((result) => {
        this.sending = result.data;
        this.sendingItems = result.data.shipments;
        this.$store.commit("SET_TITLE", `Наполнение отправки №${this.sending.number}`);
      }).finally(() => {
        this.sendingLoading = false;
      });
    },
    addShipment(shipment) {
      if (![
        SHIPMENT_ACCEPTED_FROM_SENDER,
        SHIPMENT_RETURN,
        SHIPMENT_ARRIVED_AT_WRONG_PICKUP_POINT,
        SHIPMENT_MOVING_TO_ANOTHER_POINT,
      ].includes(parseInt(shipment.status))) {
        ElNotification({
          title: 'Ошибка',
          message: 'Отправление не может быть добавлено',
          type: 'error',
        });

        return;
      }

      const params = {
        id: this.sending.id,
        body: {shipments: [shipment.id]},
      }

      this.itemsLoading = true;
      this.addShipments(params).then(() => {
        this.items = this.items.filter((item) => {
          return shipment.id !== item.id;
        });
        this.sendingItems.unshift(shipment);

        ElNotification({
          title: 'Успешно',
          message: 'Отправление успешно добавлено в отправку',
          type: 'success',
        });
      }).finally(() => {
        this.itemsLoading = false;
      });
    },
  }
};
</script>

