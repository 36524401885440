<template>
  <div class="transport-current-table overflow-auto">
    <table style="width: 100%; border-collapse: collapse;">
      <thead>
        <tr>
          <th class="table__cell table__th">Статус</th>
          <th class="table__cell table__th">Дата прибытия</th>
          <th class="table__cell table__th">Номер перевозки</th>
          <th class="table__cell table__th">Мест</th>
          <th class="table__cell table__th">Отправлений</th>
          <!--<th class="table__cell table__th">ПВЗ отправки</th>-->
          <th class="table__cell table__th">ПВЗ получения</th>
          <th class="table__cell table__th">Фио водителя</th>
          <th class="table__cell table__th">Госномер</th>
          <th class="table__cell table__th"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="freight in freights" :key="freight.id" @click="onRowClick($event, freight)">
          <td class="table__cell">{{ freight.statusName }}</td>
          <td class="table__cell">{{ freight.arrival_date }}</td>
          <td class="table__cell">{{ freight.number }}</td>
          <td class="table__cell">{{ freight.number_of_containers }}</td>
          <td class="table__cell">{{ freight.number_of_shipments }}</td>
          <!--<td class="table__cell" v-html="pointInfo(freight.src_point)"></td>-->
          <td class="table__cell" v-html="pointInfo(freight.dst_point)"></td>
          <td class="table__cell">{{ freight.driver }}</td>
          <td class="table__cell">{{ freight.vehicle }}</td>
          <td class="table__cell">
            <button
              type="button"
              class="table-button"
              v-if="freightCanBeAccepted(freight)"
              @click="freightCanBeAccepted(freight) && $emit('onAcceptWithBarcodes', freight)"
            >Принять</button
            >
            <button
              type="button"
              class="table-button mt-1"
              v-if="freightCanBeAccepted(freight)"
              @click="freightCanBeAccepted(freight) && $emit('onPrintAct', freight)"
            >
              Печать Акта ПП
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {FREIGHT_STATUS_ACCEPTED_ON_POINT} from "@/constants";

export default {
  name: "CurrentFrights",
  props: {
    freights: {
      type: Array,
      required: true,
    },
  },

  methods: {
    freightCanBeAccepted(freight) {
      return freight.status == FREIGHT_STATUS_ACCEPTED_ON_POINT
    },
    pointInfo(point) {
      if (point) {
        return `№: ${point.id}, ${point.name}`;
      }
      return '-';
    },
    onRowClick(event, freight) {
      if (event.target.classList.contains('table-button')) {
        return
      }

      this.$router.push({ name: 'freight-info', params: { id: freight.id } })
    }
  }
};
</script>
