<template>
  <component :is="component" />
</template>

<script>
import ShipmentsSearch from "./ShipmentsSearch.vue";
import {USER_ROLE_ADMIN, USER_ROLE_FRANCHISEE, USER_ROLE_SUPER, USER_ROLE_FRANCHISEE_MULTI} from "@/constants";

export default {
  name: "Shipments",
  components: {
    ShipmentsSearch,
  },
  computed: {
    component() {
      let component;

      switch (this.$store.getters["user/role_id"]) {
        case USER_ROLE_SUPER:
        case USER_ROLE_ADMIN:
        case USER_ROLE_FRANCHISEE:
        case USER_ROLE_FRANCHISEE_MULTI:
          component = ShipmentsSearch;
          break;
        default:
          component = "span";
      }
      return component;
    },
  },
};
</script>
