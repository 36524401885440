<template>
  <div class="context_menu" v-if="point" :key="point.id">
    <div class="breadcrumb-profilePVZ-container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" :class="{active: !isAddressTeam}">
            <router-link
                :to="`/address/${$route.params.id}`"
            >
              Пункт выдачи
            </router-link>
          </li>

          <li v-if="activeManageTeam" class="breadcrumb-item" :class="{active: isAddressTeam}">
            <router-link
                :to="`/address/${$route.params.id}/team`"
            >
              Команда
            </router-link>
          </li>
          <!-- <li class="breadcrumb-item"><a href="#">Заказ расходников</a></li> -->
        </ol>
      </nav>
    </div>
    <h2 class="h5 m-1 my-4">{{point.name}}, {{point.comment}}</h2>
    <!--<a data-bs-toggle="collapse"
        class="collapseButtonAddress"
        href="#collapseAddress"
        role="button"
        aria-expanded="false">
      {{point.name}}, {{point.comment}}
    </a>
    <div class="collapse" id="collapseAddress">
      <div class="lists-address">
        <a class="address-item" href="">Адрес1</a>
        <a class="address-item" href="">Адрес2</a>
        <a class="address-item" href="">Адрес3</a>
      </div>
    </div>-->
    <div class="main-context-PVZ">
      <div class="general-info">
        <h2>Общая информация</h2>
        <div class="text-info-context">
          <p class="leftPart-context">ПВЗ</p>
          <p>{{point.id}}</p>
        </div>
        <div class="text-info-context">
          <p class="leftPart-context">Адрес</p>
          <p>{{point.name}}, {{point.comment}}</p>
        </div>
        <!-- <div  class="text-info-PVZ">
            <p class="left-part-text-PVZ">Контактный телефон</p>
            <p>+79084567890</p>
        </div>
        <div  class="text-info-PVZ">
            <p class="left-part-text-PVZ">Email</p>
            <p>email@gmail.com</p>
        </div> -->
        <!--<h3>Как добраться</h3>
        <p class="descriptionWhere">
          lat:{{point.lat}} lng:{{point.lng}}
        </p>-->
      </div>
      <div class="window-debt" v-if="user">
        <!--<h2 class="red-debt">
          Задолженность: <span class="window-debt-num">100 000 Р</span>
        </h2>-->
        <p>ФИО: {{user.last_name}} {{user.first_name}} {{user.middle_name}}</p>
        <p>Email: {{user.email_adress}}</p>
        <p>Телефон: {{user.phone_primary}}</p>
        <p>Роль: {{user.role_id}}</p>
      </div>
      <!-- break to a new row -->
      <div class="break"></div>

      <div class="window-schedule">
        <h2>График работы ПВЗ</h2>
        <div class="text-info-schedule title-schedule">
          <p class="leftPart-schedule">День</p>
          <p>Время работы</p>
        </div>
        <div class="text-info-schedule">
          <p class="leftPart-schedule">Пн</p>
          <p>{{point?.schedule?.mon || '9:00 - 21:00'}}</p>
        </div>
        <div class="text-info-schedule">
          <p class="leftPart-schedule">Вт</p>
          <p>{{point?.schedule?.tue || '9:00 - 21:00'}}</p>
        </div>
        <div class="text-info-schedule">
          <p class="leftPart-schedule">Ср</p>
          <p>{{point?.schedule?.wed || '9:00 - 21:00'}}</p>
        </div>
        <div class="text-info-schedule">
          <p class="leftPart-schedule">Чт</p>
          <p>{{point?.schedule?.thu || '9:00 - 21:00'}}</p>
        </div>
        <div class="text-info-schedule">
          <p class="leftPart-schedule">Пт</p>
          <p>{{point?.schedule?.fri || '9:00 - 21:00'}}</p>
        </div>
        <div class="text-info-schedule">
          <p class="leftPart-schedule">Сб</p>
          <p>{{point?.schedule?.sat || '9:00 - 21:00'}}</p>
        </div>
        <div class="text-info-schedule">
          <p class="leftPart-schedule">Вс</p>
          <p>{{point?.schedule?.sun || '9:00 - 21:00'}}</p>
        </div>
      </div>
      <div class="window-setting">
        <h2>Настройки пункта выдачи</h2>
        <h3>Способы оплаты:</h3>
        <div class="align-checkbox">
          <span>Наличный расчёт</span>
          <div class="form-check form-switch">
            <input class="form-check-input"
                   type="checkbox"
                   role="switch"
                   id="switchCash"
                   disabled>
          </div>
        </div>
        <div class="align-checkbox">
          <span>Безналичный расчет</span>
          <div class="form-check form-switch">
            <input class="form-check-input"
                   type="checkbox"
                   role="switch"
                   id="switchCard"
                   disabled>
          </div>
        </div>
        <h3>Другие настройки</h3>
        <div class="align-checkbox">
          <span>Частичный выкуп</span>
          <div class="form-check form-switch">
            <input class="form-check-input"
                   type="checkbox"
                   role="switch"
                   id="switchPart"
                   disabled>
          </div>
        </div>
        <div class="align-checkbox">
          <span>Наличие примерочных</span>
          <div class="form-check form-switch">
            <input class="form-check-input"
                   type="checkbox"
                   role="switch"
                   id="switchInclude"
                   disabled>
          </div>
        </div>
        <div class="align-checkbox">
          <span>Возможность вскрытия отправлений</span>
          <div class="form-check form-switch">
            <input class="form-check-input"
                   type="checkbox"
                   role="switch"
                   id="switchOpen"
                   disabled>
          </div>
        </div>
        <!-- <div class="max-setting">
            <div class="max-setting-items">
                <p>Макс. вес посылки:</p>
                <p>5 кг</p>
            </div>
            <div class="max-setting-items">
                <p>Макс. сумма измерений:</p>
                <p>Д:100 х Ш:50 х В: 50 см3г</p>
            </div>
            <div class="max-setting-items">
                <p>Макс. вместимость:</p>
                <p>200 посылок/день</p>
            </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<style scoped>
.main-context-PVZ{
  display: flex;
  flex-wrap: wrap
}
.window-debt{
  width: auto;
}
.general-info{
  width: auto;
  flex: 1
}
</style>
<script>
import accessGuard from "@/router/middleware/accessGuard.middleware";
import permissions from "@/modules/auth/auth.types.ts";

export default {
  name: "AddressItem",
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    activeManageTeam() {
      return accessGuard.checkScope(this.user, permissions.OWN_USER_MANAGE);
    },
  },
  created() {
    this.$store.commit("SET_TITLE", "EXMAIL");
    this.getPoints();
  },
  data() {
    return {
      isAddressTeam: false,
      point: null
    }
  },
  watch: {
    '$route'() {
      this.getPoints();
    }
  },
  methods: {
    getPoints() {
      const { id } = this.$route.params;

      if (id != undefined) {
        this.$store.dispatch('points/readItem', id).then((result) => {
          this.point = result.data;
        });
      }
    }
  }
};
</script>
