import Partners from "./Partners.vue";

export const partnersRoutes = [
  {
    path: "/partners",
    component: Partners,
    name: "partners",
    meta: { title: "Контрагенты" },
  },
];
