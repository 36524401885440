import PpActs from "@/views/pp-acts/PpActs.vue";
import PpAct from "@/views/pp-acts/PpAct.vue";
import permissions from "@/modules/auth/auth.types.ts";

export const ppActsRoutes = [
    {
        path: "/pp-acts",
        component: PpActs,
        name: "ppActs",
        meta: {
            title: "Акты приёма-передачи",
            middleware: [permissions.OWN_ACT_VIEW]
        },
    },
    {
        path: "/pp-acts/:uuid",
        component: PpAct,
        name: "ppAct",
        meta: {
            isHeaderBackLink: true,
            title: "Акт приёма-передачи",
            middleware: [permissions.OWN_ACT_VIEW]
        },
    },
];
