<template>
    <ul :class="rowClass">
        <li class="table-items create-storage-item-name-width">
            <div class="accordion">
                <div class="accordion-item custom-accordion-item-sendPvz">
                    <h2 class="accordion-header custom-accordion-header-sendPvz ">
                        <div class="custom-checkBox-container-sendPvz" v-if="showCheckbox"  >
                            <el-checkbox :model-value="item.selected" @change="$emit('toggle-selected', item)" />
                        </div>
                        <button

                            :class="accordeonBtnClass"
                            type="button"
                            @click="toggleCollapse"
                        >
                            {{ item.id }}
                        </button>
                    </h2>
        </div>
      </div>
    </li>

    <li class="table-items create-storage-item-number-width">
      <span>{{ item.barcode }}</span>
    </li>
    <li class="table-items create-storage-item-number-width">
      <span>{{ item.number }}</span>
    </li>
    <li v-if="this.showStatus" class="table-items create-storage-item-number-width">
      <span>{{ shipmentsStatusNameById(item.status) }}</span>
    </li>
    <li v-if="this.showCell" class="table-items create-storage-item-number-width">
      <span>{{ getCeil(item.ceil_id).name }}</span>
    </li>
  </ul>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {
  SHIPMENT_ARRIVED_AT_WRONG_PICKUP_POINT, SHIPMENT_ARRIVED_TO_PICK_UP_POINT_WRONG_TRANSPORT,
  SHIPMENT_CANCELLED, SHIPMENT_DID_NOT_ARRIVE_AT_THE_PICKUP_POINT,
  SHIPMENT_LOST_BY_CARRIER,

} from "@/constants";

export default {
  name: 'CreateShipmentTableItem',

  props: {
    item: {
      type: Object,
      required: true,
    },
    showStatus: {
      type: Boolean,
      required: false
    },
    showCell: {
      type: Boolean,
      required: false
    },
    showCheckbox: {
      type: Boolean,
      required: false
    },
    showAlert: {
      type: Boolean,
      required: false
    }
  },

  data: () => ({
    isOpen: false,
  }),
  methods: {
    ...mapActions({
      getCeils: 'ceils/getList',
    }),
    toggleCollapse() {
      // this.isOpen = !this.isOpen
    }
  },
  mounted() {
    if (this.ceils.length === 0 && this.showCell) {
      this.getCeils();
    }
  },
  computed: {
      rowClass(){
        if (this.showAlert && [SHIPMENT_CANCELLED,SHIPMENT_LOST_BY_CARRIER,SHIPMENT_ARRIVED_AT_WRONG_PICKUP_POINT,SHIPMENT_DID_NOT_ARRIVE_AT_THE_PICKUP_POINT,SHIPMENT_ARRIVED_TO_PICK_UP_POINT_WRONG_TRANSPORT].includes(parseInt(this.item.status))) {
          return 'table-container alert-danger'
        } else {
          return 'table-container'

        }
      },
      accordeonBtnClass() {
        if (this.showAlert && [SHIPMENT_CANCELLED,SHIPMENT_LOST_BY_CARRIER,SHIPMENT_ARRIVED_AT_WRONG_PICKUP_POINT,SHIPMENT_DID_NOT_ARRIVE_AT_THE_PICKUP_POINT,SHIPMENT_ARRIVED_TO_PICK_UP_POINT_WRONG_TRANSPORT].includes(parseInt(this.item.status))) {
          return 'accordion-button custom-accordion-button-sendPvz alert-danger collapsed'
        } else {
          return 'accordion-button custom-accordion-button-sendPvz collapsed'
        }
      },
    ...mapGetters({
      shipmentsStatusNameById: 'shipments/statusNameById',
      ceils: 'ceils/items',
      getCeil: 'ceils/itemById',
    }),
  },
}
</script>

<style scoped lang="scss">
.accordion-button {
  &::after {
    display: none;
  }
}
</style>
