import apiV2Service from "@/api/V2/services/apiV2.service";


function getShopStatistic(data) {
  return apiV2Service.post('statistic/shop', data)
}

function getBaseStatistic(data) {
  return apiV2Service.post('statistic/base', data)
}

export {getBaseStatistic, getShopStatistic}

