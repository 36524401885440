<template>
    <div class="search-table">
        <div class="horizontal-table-scroll">
            <ul class="table-container table-head">
                <li class="table-items-head deliveryItems-item-shop-width">
                    <span>Интернет-магазин </span>
                    <div class="sort-button-container">
                        <button class="sort-button sort-button-arrow-up"></button>
                        <button class="sort-button sort-button-arrow-down"></button>
                    </div>
                </li>
                <li class="table-items-head deliveryItems-item-id-width">
                    <span>ID</span>
                </li>
                <li class="table-items-head deliveryItems-item-number-width">
                    <span>Номер отправления</span>
                </li>
                <li class="table-items-head deliveryItems-item-cell-width">
                    <span>Ячейка</span>
                </li>
                <li class="table-items-head deliveryItems-item-reciver-width">
                    <span>Получатель</span>
                </li>
                <li class="table-items-head deliveryItems-item-pay-width">
                    <span>Объявленная стоимость</span>
                </li>
                <li class="table-items-head deliveryItems-item-accept-width">
                    <span>Принят</span>
                </li>
                <li class="table-items-head deliveryItems-item-before-width">
                    <span>Хранить до</span>
                </li>
                <li class="table-items-head deliveryItems-item-status-width">
                    <span>Статус</span>
                    <div class="sort-button-container">
                        <button class="sort-button sort-button-arrow-up"></button>
                        <button class="sort-button sort-button-arrow-down"></button>
                    </div>
                </li>
            </ul>

            <ul
                    v-for="shipment in shipments"
                    :key="shipment.id"
                    class="table-container"
                    @click="$router.push({ name: 'give-item', params: { id: shipment.id } })"
            >
                <li class="table-items deliveryItems-item-shop-width">
                    <span>Интернет-магазин</span>
                </li>
                <li class="table-items deliveryItems-item-id-width">
                    <span>{{ shipment.id }}</span>
                </li>
                <li class="table-items deliveryItems-item-number-width">
                  <span>
                    {{ shipment.number }}
                  </span>
                </li>
                <li class="table-items deliveryItems-item-cell-width">
                    <span>{{ getCeil(shipment.ceil_id).name }}</span>
                </li>
                <li class="table-items deliveryItems-item-reciver-width">
                  <span>
                      {{ getDstClientFio(shipment) }}
                    <br>
                    {{ shipment.client_dst.phone_primary }}
                  </span>
                </li>
                <li class="table-items deliveryItems-item-pay-width">
                    <span> {{ shipment.sum }} Р</span>
                </li>
                <li class="table-items deliveryItems-item-accept-width">
                  <span>
                    {{ shipment.arrival_date }}
                  </span>
                </li>
                <li class="table-items deliveryItems-item-before-width">
                    <span>-</span>
                </li>
                <li class="table-items deliveryItems-item-status-width">
          <span>
            {{ statusNameById(shipment.status) }}
          </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {useCeils} from '@/composition/useCeils.js';
import {getDstClientFio} from "@/store/helper";

export default {
    name: "ShipmentsTable",

    props: {
        shipments: {
            type: Array,
            required: true,
        },
    },
    setup() {
        return {
            ...useCeils(),
        }
    },
    methods: {
        getDstClientFio,
        statusNameById(statusID) {
            return this.$store.getters["shipments/statusNameById"](statusID);
        },
    },
};
</script>
