import apiV2Service from "@/api/V2/services/apiV2.service";

const store = {
  namespaced: true,
  actions: {
    fetch(context, id) {
      return apiV2Service.get(`shipments/${id}/logs`);
    },
  },
};
export default store;
