<template>
  <P>Пример использования вызова апи</P>
  <el-button @click="apiUsageFunc('xxx')">Запустить</el-button>
</template>

<script>
import {someApi} from "@/views/examples/api/someApi";

export default {
  name: "apiUsage.vue",
  methods: {
    apiUsageFunc(args) {
      someApi(args).then((data) => {
        console.log(data)
      }).catch((err) => {
        if (err.status === 403) {
          console.log('do something')
        } else {
          console.log('do something else')
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
