<template>
  <el-form
      v-if="franchiseeUser"
      :model="franchiseeUser"
      label-width="100px"
      :label-position="top"
  >
    <el-form-item label="Фамилия">
      <el-input tabindex="-1" v-model="franchiseeUser.last_name" autocomplete="off" />
    </el-form-item>

    <el-form-item label="Имя">
      <el-input tabindex="-1" v-model="franchiseeUser.first_name" autocomplete="off" />
    </el-form-item>

    <el-form-item label="Отчество">
      <el-input tabindex="-1" v-model="franchiseeUser.middle_name" autocomplete="off" />
    </el-form-item>

    <el-form-item label="Email">
      <el-input tabindex="-1" v-model="franchiseeUser.email_adress" autocomplete="off" />
    </el-form-item>

    <el-form-item label="Телефон">
      <el-input tabindex="-1" v-model="franchiseeUser.phone_primary" autocomplete="off" />
    </el-form-item>

    <div class="d-flex justify-content-between">
      <el-form-item>
        <el-button v-loading="loadingSave" type="primary" @click="saveForm()">
          Сохранить
        </el-button>
      </el-form-item>

      <el-form-item v-if="this.franchiseeUser.id && this.franchiseeUser.status !== USER_STATUS_BLOCKED">
        <el-button v-loading="loadingBlock" type="danger" @click="blockFranchiseeUser()">
          Заблокировать
        </el-button>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import {ElMessageBox, ElNotification} from "element-plus";
import {USER_STATUS_BLOCKED} from "@/constants";

export default {
  name: "PointUserForm",
  props: [
      'editUser',
      'pointId'
  ],
  created() {

  },
  data() {
    return {
      USER_STATUS_BLOCKED: USER_STATUS_BLOCKED,
      loadingSave: false,
      loadingBlock: false,
      franchiseeUser: this.editUser ?? {
        id: null,
        point_id: this.pointId,
        partner_id: this.$store.state.user.user.partner_id
      }
    }
  },
  methods: {
    saveForm() {
      this.loadingSave = true;

      this.$store.dispatch('user/createOrUpdateWorker', this.franchiseeUser).then((result) => {
        this.loadingSave = false;

        if (this.franchiseeUser.id) {
          ElNotification({
            title: 'Пользователь сохранен',
            message: 'Пользователь успешно сохранен',
            type: 'success',
          });
        } else {
          ElNotification({
            title: 'Пользователь успешно создан',
            message: 'Пользователь создан',
            type: 'success',
          });
        }

        this.franchiseeUser = result.data.user;
      }).catch(() => {
        this.loadingSave = false;
      });
    },

    async blockFranchiseeUser() {
      await ElMessageBox.confirm(
          'Заблокировать пользователя?',
          {
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
            cancelButtonClass: 'el-button--info'
          },
      )

      this.loadingBlock = true;

      this.$store.dispatch('user/blockFranchiseeUser', this.franchiseeUser.id).then((result) => {
        this.loadingBlock = false;
        this.franchiseeUser = result.data.user;

        ElNotification({
          title: 'Пользователь заблокирован',
          message: 'Пользователь успешно заблокирован',
          type: 'success',
        });
      }).catch(() => {
        this.loadingBlock = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
