export default Array(10)
  .fill(1)
  .map((_v, value) => {
    return {
      value,
      title: `Регион ${value}`,
      dadata: {
        macro_region_name: `Тест Регион ${value}`,
        macro_region_ddid: value,
      },
    };
  });

// console.log({
//     "source":"астрахань сахалинская 7а 35",
//     "result":"г Астрахань, ул Сахалинская, д 7А, кв 35",
//     "postal_code":"414021",
//     "country":"Россия",
//     "country_iso_code":"RU",
//     "federal_district":"Южный",
//     "region_fias_id":"83009239-25cb-4561-af8e-7ee111b1cb73",
//     "region_kladr_id":"3000000000000",
//     "region_iso_code":"RU-AST",
//     "region_with_type":"Астраханская обл",
//     "region_type":"обл",
//     "region_type_full":"область",
//     "region":"Астраханская",
//     "area_fias_id":null,
//     "area_kladr_id":null,
//     "area_with_type":null,
//     "area_type":null,
//     "area_type_full":null,
//     "area":null,
//     "city_fias_id":"a101dd8b-3aee-4bda-9c61-9df106f145ff",
//     "city_kladr_id":"3000000100000",
//     "city_with_type":"г Астрахань",
//     "city_type":"г",
//     "city_type_full":"город",
//     "city":"Астрахань",
//     "city_area":null,
//     "city_district_fias_id":null,
//     "city_district_kladr_id":null,
//     "city_district_with_type":null,
//     "city_district_type":null,
//     "city_district_type_full":null,
//     "city_district":null,
//     "settlement_fias_id":null,
//     "settlement_kladr_id":null,
//     "settlement_with_type":null,
//     "settlement_type":null,
//     "settlement_type_full":null,
//     "settlement":null,
//     "street_fias_id":"18ec109e-e721-4b49-92f6-c54e1e243b19",
//     "street_kladr_id":"30000001000159100",
//     "street_with_type":"ул Сахалинская",
//     "street_type":"ул",
//     "street_type_full":"улица",
//     "street":"Сахалинская",
//     "house_fias_id":"b748ed73-f109-455e-9bfa-8ddcc292ed7a",
//     "house_kladr_id":"3000000100015910071",
//     "house_type":"д",
//     "house_type_full":"дом",
//     "house":"7А",
//     "block_type":null,
//     "block_type_full":null,
//     "block":null,
//     "entrance":null,
//     "floor":null,
//     "flat_fias_id":"8259c247-3042-4a9d-ab6a-106e7c14ce82",
//     "flat_type":"кв",
//     "flat_type_full":"квартира",
//     "flat":"35",
//     "flat_area":"65.8",
//     "square_meter_price":"43208",
//     "flat_price":"2843087",
//     "postal_box":null,
//     "fias_id":"8259c247-3042-4a9d-ab6a-106e7c14ce82",
//     "fias_code":"30000001000000015910071",
//     "fias_level":"9",
//     "fias_actuality_state":"0",
//     "kladr_id":"3000000100015910071",
//     "capital_marker":"2",
//     "okato":"12401000000",
//     "oktmo":"12701000001",
//     "tax_office":"3025",
//     "tax_office_legal":"3025",
//     "timezone":"UTC+4",
//     "geo_lat":"46.3234197",
//     "geo_lon":"48.0261488",
//     "beltway_hit":null,
//     "beltway_distance":null,
//     "qc_geo":0,
//     "qc_complete":0,
//     "qc_house":2,
//     "qc":0,
//     "unparsed_parts":null,
//     "metro":null
// })
