import permissions from "@/modules/auth/auth.types.ts";
import ReturnActCreate from "@/views/return-acts/ReturnActCreate";
import ReturnActAssembling from "@/views/return-acts/ReturnActAssembling";
import IssueReturn from "@/views/return-acts/IssueReturn.vue";
import ReturnActTable from "@/views/return-acts/ReturnActTable.vue";

export const returnActsRoutes = [
  {
    path: "/return-act-create",
    component: ReturnActCreate,
    name: "returnAct",
    meta: {
      title: "Создать возврат",
      middleware: [permissions.OWN_RETURNS_VIEW]
    },
  },
  {
    path: "/return-acts",
    component: ReturnActTable,
    name: "returnActs",
    meta: {
      title: "Возвраты",
      middleware: [permissions.OWN_RETURNS_VIEW, permissions.ANY_RETURNS_VIEW]
    },
  },
  {
    path: "/return-act/assembling/:id",
    component: ReturnActAssembling,
    name: "ReturnActAssembling",
    meta: {
      title: "Возврат",
      middleware: [permissions.OWN_RETURNS_VIEW]
    },
  },
  {
    path: "/issue-return",
    component: IssueReturn,
    name: "issueReturn",
    meta: {
      title: "Передача возврата",
      middleware: [permissions.OWN_RETURNS_VIEW]
    },
  },
];
