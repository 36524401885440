import apiV2Service from "@/api/V2/services/apiV2.service";

const store = {
    namespaced: true,
    actions: {
        acceptContract(context, partnerId) {
            return apiV2Service.post(`partners/${partnerId}/acceptContract`)
        },
    },
};
export default store;
