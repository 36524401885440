import client from './client'
import createCrud from './createCrud'

const { getAll, createItem, readItem, updateItem, deleteItem } = createCrud(client, '/sendings')

const accept = async (id, data = {}) => {
  return await client.put(`/sendings/${id}/accept`, data)
};

export {
  getAll,
  createItem,
  readItem,
  updateItem,
  deleteItem,
  accept,
}
