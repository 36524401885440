<template>
<div class="d-flex align-items-center justify-content-end">
  <div class="total">Показано записей: {{Math.min(currentPage * perPage, total)}} из {{total}}</div>
  <ul class="pages">
    <li class="page" @click="goFirst" v-if="currentPage > 2">&lt;&lt;</li>
    <li class="page" @click="goPrev" v-if="currentPage > 1">&lt;</li>
    <li
      class="page"
      v-for="page in pages"
      :key="page.name"
      :class="{ active: page.name === currentPage }"
      :disabled="page.isDisabled"
      @click="goTo(page)"
      >{{ page.name }}</li
    >
    <li class="page" @click="goNext" v-if="currentPage < last">&gt;</li>
    <li class="page" @click="goLast" v-if="currentPage < last - 1">&gt;&gt;</li>
  </ul>
</div>
</template>
<style scoped>
.total{
  margin-top: 10px;
  font-weight: 500;
}
</style>
<script>
export default {
  props: {
    totalPages: Number,
    currentPage: Number,
    last: Number,
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5,
    },
    perPage: Number,
    total: Number
  },
  computed: {
    startPage() {
      let startPage = this.currentPage - 1;

      if (this.currentPage === 1) {
        startPage = 1;
      }

      if (this.currentPage === this.totalPages) {
        startPage = this.totalPages - this.maxVisibleButtons + 1;
      }

      return startPage > 0 ? startPage : 1;
    },
    endPage() {
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      );
    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    goFirst() {
      this.$emit("change", 1);
    },
    goLast() {
      this.$emit("change", this.totalPages);
    },
    goPrev() {
      this.$emit("change", this.currentPage - 1);
    },
    goNext() {
      this.$emit("change", this.currentPage + 1);
    },
    goTo(page) {
      if (page.isDisabled) {
        return;
      }
      this.$emit("change", page.name);
    },
  },
};
</script>

<style lang="scss">
.pages {
  margin-top: 10px;
  text-align: end;
  .page {
    display: inline-block;
    cursor: pointer;
    border-radius: 4px;
    padding: 10px;
    width: 40px;
    height: 40px;
    text-align: center;
    background: #ffffff;
    margin: 3px 0px 3px 10px;
    &.active {
      background: #556ee6;
      color: #ffffff;
    }
  }
}
</style>
