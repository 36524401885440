import Inventory from "./Inventory.vue";
import InventoryManage from "@/views/inventory/InventoryManage";
import permissions from "@/modules/auth/auth.types.ts";

export const inventoryRoutes = [
  {
    path: "/inventory",
    component: Inventory,
    name: "inventory",
    meta: {
      title: "Инвентаризация",
      middleware: [permissions.OWN_INVENTORY_VIEW, permissions.ANY_INVENTORY_VIEW]
    },
  },

  {
    path: "/inventory/manage",
    component: InventoryManage,
    name: "inventory-manage",
    meta: {
      title: "Управление инвентаризацией",
      middleware: [permissions.OWN_INVENTORY_MANAGE]
    },
  },
];
