import Report from "./Report.vue";
import ReportRemains from "./ReportRemains.vue";
import ReportDestroyed from "@/views/report/ReportDestroyed";
import permissions from "@/modules/auth/auth.types.ts";
import ReportUseTare from "@/views/report/ReportUseTare";

export const reportRoutes = [
  {
    path: "/report",
    component: Report,
    name: "report",
    meta: {
      title: "Отчеты",
      middleware: [permissions.OWN_FRANCH_REPORT_VIEW]
    },
  },
  {
    path: "/report/remains",
    component: ReportRemains,
    name: "report-remains",
    meta: {
      title: "Отчет остатки",
      middleware: [permissions.OWN_REPORT_SURPLUS]
    },
  },
  {
    path: "/report/destroyed",
    component: ReportDestroyed,
    name: "report-destroyed",
    meta: {
      title: "Отчет утилизация",
      middleware: [permissions.OWN_REPORT_UTIL]
    },
  },
  {
    path: "/report/use-tare",
    component: ReportUseTare,
    name: "report-use-tare",
    meta: {
      title: "Отчет по использованию тары",
      middleware: [permissions.TARE_REPORT]
    },
  },
];
