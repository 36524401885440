import apiservice from "@/api/V2/services/api.service";

const createPayment = (shipmentUuid, refusedItemsUuids) => {
  return apiservice.post(`/modulkassa-payments/createPayment`, {
    shipment_uuid: shipmentUuid,
    refusedItemsUuids: (refusedItemsUuids ? refusedItemsUuids : [])
  })
};

const checkPaymentStatus = (paymentUuid) => {
  return apiservice.post(`/modulkassa-payments/${paymentUuid}/checkStatus`)
};

const getPaymentStatus = (paymentUuid) => {
  return apiservice.get(`/modulkassa-payments/${paymentUuid}/getStatus`)
};

export {
  createPayment,
  checkPaymentStatus,
  getPaymentStatus
}
