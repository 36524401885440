<template>
  <div class="breadcrumb-transport-container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li
          v-for="option in options"
          :key="option.value"
          class="breadcrumb-item w-60"
          :class="{ active: option.value === value }"
          @click.prevent="$emit('onClick', option)"
        >
          <a href="javascript:void(0)">
            {{ option.title }}
          </a>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  name: "FilterTabs",
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
};
</script>
