import client from './client'
import createCrud from './createCrud'

const {getAll, createItem, readItem, updateItem, deleteItem} = createCrud(client, '/partners')

const getContract = async (path) => {
  return await client.get(`partners/contract/${path}`, {
    responseType: 'blob',
  })
};

export {
  getAll,
  createItem,
  readItem,
  updateItem,
  deleteItem,
  getContract
}
