<script>

import {mapActions} from "vuex";
import {downloadFile, getFileNameWithExtensionFromHeaders} from "@/store/helper";
import {Printer} from "@element-plus/icons-vue";

export default {
  name: "PpAct",
  data() {
    return {
      ppAct: null
    }
  },
  computed: {
    Printer() {
      return Printer
    },
    points() {
      return this.$store.state.points.list;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
    ppActIsClosed() {
      return this.ppAct && this.ppAct.is_closed;
    },
    shipmentsArray() {
      if (!this.ppAct || !this.ppAct.pp_act_shipments) {
        return [];
      }
      return Object.values(this.ppAct.pp_act_shipments);
    },
  },
  created() {
    this.getPoints()
  },
  mounted() {
    this.onStartLoadingData();

    console.warn(this.points);
  },
  methods: {
    ...mapActions({
      getPoints: 'points/getList',
    }),
    onDownloadPpAct() {
      this.$store.dispatch('apiV2DocumentStore/downloadPpAct', this.ppAct.uuid).then(response => {
        const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        downloadFile(url, fileNameWithExtension)
      });
    },
    getPoint(pointId) {
      return this.points.find(x => x.id === pointId);
    },
    onStartLoadingData() {
      this.$store.dispatch("apiV2PpActStore/getPpActByUuid", this.$route.params.uuid).then(data => {
        this.ppAct = data.pp_act;
        this.$store.commit("SET_TITLE", `Акт приёма-передачи ${this.ppAct.number}`);
      });
    },
    formatDate(date) {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }

      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };

      return date.toLocaleString('ru-RU', options).replace(/\//g, '.');
    },
    onRowClick(item) {

      this.$router.push({name: 'shipmentsDetail', params: {id: item.shipment.id}});
    }
  }
}
</script>

<template>

  <template v-if="!this.ppAct">
   <p class="mt-4">Загрузка акта приёма-передачи...</p>
  </template>

  <template v-if="this.ppAct">
    <div class="storageItemInfo-title">
      <h3 class="h4">Акт приёма-передачи {{ ppAct.number }}</h3>

      <div class="buttons">


        <el-button
          type="success"
          size="small"
          :icon="Printer"
          :loading="isLoading"
          :disabled="!ppActIsClosed"
          @click="onDownloadPpAct()"
        >
          Печать акта ПП
        </el-button>
      </div>
    </div>

    <div class="storageItemInfo-data-info mb-4">
      <div class="storageItemInfo-data-info-container">
        <div class="storageItemInfo-data-info-row">
          <p class="storageItemInfo-data-items-left">Номер акта</p>
          <p class="storageItemInfo-data-items-right">{{ ppAct.number }}</p>
        </div>
        <div class="storageItemInfo-data-info-row">
          <p class="storageItemInfo-data-items-left">Статус акта</p>
          <p class="storageItemInfo-data-items-right">{{ ppAct.status_name }}</p>
        </div>
        <div class="storageItemInfo-data-info-row">
          <p class="storageItemInfo-data-items-left">Кол-во отправлений</p>
          <p class="storageItemInfo-data-items-right">{{ ppAct.shipment_count }}</p>
        </div>
      </div>
      <div class="storageItemInfo-data-info-container">
        <div class="storageItemInfo-data-info-row">
          <p class="storageItemInfo-data-items-left">Курьер</p>
          <p class="storageItemInfo-data-items-right">{{ ppAct.courier_surname }} {{ ppAct.courier_name }}
            {{ ppAct.courier_patronymic }}</p>
        </div>
        <div class="storageItemInfo-data-info-row">
          <p class="storageItemInfo-data-items-left">Права отправителя </p>
          <p class="storageItemInfo-data-items-right">{{ ppAct.courier_document_type_name }}</p>
        </div>
      </div>
    </div>

    <div class="transport-current-table overflow-auto">
      <table style="width: 100%; border-collapse: collapse;">
        <thead>
        <tr>
          <th class="table__cell table__th">Номер</th>
          <th class="table__cell table__th">Штрих-Код</th>
          <th class="table__cell table__th">ПВЗ отправления</th>
          <th class="table__cell table__th">ПВЗ назначения</th>
          <th class="table__cell table__th">Объявленная стоимость</th>
          <th class="table__cell table__th">Добавлен в акт</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in shipmentsArray" :key="item.id" @click="onRowClick(item)">
          <td class="table__cell">{{ item.shipment.number }}</td>
          <td class="table__cell">{{ item.shipment.barcode }}</td>
          <td class="table__cell">{{ getPoint(item.shipment.src_point_id)?.name }} [{{item.shipment.src_point_id}}]</td>
          <td class="table__cell">{{ getPoint(item.shipment.dts_point_id)?.name }} [{{item.shipment.dts_point_id}}]</td>
          <td class="table__cell">{{ item.shipment.valuation }}</td>
          <td class="table__cell">{{ formatDate(item.created_at) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </template>
</template>

<style scoped lang="scss">

</style>
