<template>
  <div class="container-search d-flex justify-content-between">

  </div>

  <div class="show-sended-container d-flex align-items-center justify-content-between">
    <span></span>
    <a class="el-button el-button--success el-button--default text-decoration-none"
       :href="link || exportLink" download>Экспорт Excel</a>
  </div>

  <div class="search-table" v-if="shipments.length > 0" :shipments="shipments">
    <div class="horizontal-table-scroll">
      <ul class="table-container table-head">
        <li class="table-items-head deliveryItems-item-shop-width">
          <span>Интернет-магазин</span>
          <div class="sort-button-container">
            <button class="sort-button sort-button-arrow-up"></button>
            <button class="sort-button sort-button-arrow-down"></button>
          </div>
        </li>
        <li class="table-items-head deliveryItems-item-id-width">
          <span>ID</span>
        </li>
        <li class="table-items-head deliveryItems-item-number-width">
          <span>Номер отправления (ШК)</span>
        </li>
        <li class="table-items-head deliveryItems-item-number-width">
          <span>Номер отправления (ИМ)</span>
        </li>
        <li class="table-items-head deliveryItems-item-cell-width">
          <span>Ячейка</span>
        </li>
        <li class="table-items-head deliveryItems-item-date-width">
          <span>Дата создания</span>
        </li>
        <li class="table-items-head table-item-where-width">
          <span>ПВЗ приема</span>
        </li>
        <li class="table-items-head table-item-where-width">
          <span>ПВЗ выдачи</span>
        </li>

        <li class="table-items-head deliveryItems-item-reciver-width">
          <span>Получатель</span>
        </li>
        <li class="table-items-head deliveryItems-item-pay-width">
          <span>Сумма к оплате</span>
        </li>
        <li class="table-items-head deliveryItems-item-status-width">
          <span>Статус</span>
          <div class="sort-button-container">
            <button class="sort-button sort-button-arrow-up"></button>
            <button class="sort-button sort-button-arrow-down"></button>
          </div>
        </li>
        <li class="table-items-head deliveryItems-item-status-width">
          <span>Хранить до</span>
        </li>
      </ul>

      <ul
        v-for="shipment in shipments"
        :key="shipment.id"
        class="table-container"
        @click="$router.push({ name: 'give-item', params: { id: shipment.id } })"
      >
        <li class="table-items deliveryItems-item-shop-width">
          <span>Авито</span>
        </li>
        <li class="table-items deliveryItems-item-id-width">
          <span>{{ shipment.id }}</span>
        </li>
        <li class="table-items deliveryItems-item-number-width">
          <span>
            {{shipment.ean13 || shipment.id}}
          </span>
        </li>
        <li class="table-items deliveryItems-item-number-width">
          <span>
            {{ shipment.number }}
          </span>
        </li>
        <li class="table-items deliveryItems-item-cell-width">
          <span>{{getCeil(shipment.ceil_id).name}}</span>
        </li>

        <li class="table-items deliveryItems-item-date-width">
          {{shipment.created_at?.split('T')[0]}}
        </li>
        <li class="table-items table-item-where-width">
          <span>{{shipment.point_src.name}}</span>
        </li>
        <li class="table-items table-item-where-width">
          <span>{{shipment.point_dst.name}}</span>
        </li>
        <li class="table-items deliveryItems-item-reciver-width">
          <span>
            {{getDstClientFio(shipment)}}
            <br>
            {{shipment.client_dst.phone_primary}}
          </span>
        </li>
        <li class="table-items deliveryItems-item-pay-width">
          <span> {{ shipment.sum }} Р</span>
        </li>
        <li class="table-items deliveryItems-item-status-width">
          <span>
            {{ statusNameById(shipment.status) }}
          </span>
        </li>
        <li class="table-items deliveryItems-item-status-width">
          <span>
            {{ shipment.keep_until }}
          </span>
        </li>
      </ul>
    </div>
  </div>
  <div v-else-if="isLoading" class="pt-3 pb-3">Загрузка...</div>
  <div v-else class="pt-3 pb-3">Нет информации</div>
</template>

<style scoped>
.deliveryItems-item-cell-width {
  width: 80px;
}

.deliveryItems-item-date-width {
  width: 100px;
}
</style>
<style>
.input--date-range {
  height: 42px;
  box-shadow: 2px 2px 12px rgb(42 48 66 / 11%);
}

.toolbar-select {
  margin: 0;
}
</style>

<script>

import {mapActions, mapGetters, mapState} from 'vuex';
import {useCeils} from '@/composition/useCeils.js';
import {getDstClientFio} from "@/store/helper";

const baseURL = process.env.VUE_APP_API_ROOT;

export default {
  name: "ReportRemains",
  setup() {
    return {
      ...useCeils(),
    }
  },
  data() {
    return {
      multipleStatus: [],
      dateRange: [],
      link: '',
      isLoading: false,
    }
  },

  computed: {
    exportLink() {
      const token = window.localStorage.getItem("auth") || window.sessionStorage.getItem("auth")
      return baseURL + `/surplus?token=${token}`;
    },
    ...mapState({
      shipments: state => state.shipments.surplus,
      totalPages: state => state.shipments.pagination.totalPages,
      total: state => state.shipments.pagination.totalItems,
    }),
    perPage() {
      return this.$store.state.shipments.pagination.perPage;
    },
    ...mapGetters({
      statusOptions: 'shipments/statusOptions',
    }),
  },

  created() {
    this.isLoading = true;
    this.getShipments().finally(() => {
      this.isLoading = false;
    });
  },

  methods: {
    getDstClientFio,
    ...mapActions({
      getSurplus: 'shipments/getSurplus',
    }),

    async getShipments() {
      this.filters = {...this.filters, surplus: true};
      await this.getSurplus(this.filters);
    },

    async switchPage(page) {
      this.filters.page = page;
      await this.getShipments();
    },

    statusNameById(statusID) {
      return this.$store.getters["shipments/statusNameById"](statusID);
    },
  },
};
</script>
