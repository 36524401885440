<template>
  <div style="margin-top: 30px;" v-if="this.user">
    <RegistrationLegalEntityForm :is-admin="false" :user-id="this.user.id" />
  </div>
</template>

<script>

import RegistrationLegalEntityForm from "@/components/registration/RegistrationLegalEntityForm";

export default {
  name: "LegalEntity",
  components: {RegistrationLegalEntityForm},
  computed: {
    user(){
      return this.$store.state.user.user;
    },
  }
}
</script>

<style scoped>
</style>
