import client from "./client";

const getReasons = async (event, reason = '') => {
    /* TODO search params 
        'GOODS_CHECK_FAILED': [],
        'REFUSAL_BY_RECEIVER': [],
        'RETURN_REFUSAL_BY_SENDER': [],	
    */
    return await client.get(`/refusal_reasons?event=${event}&reason=${reason}`);
}

export {
    getReasons,
}