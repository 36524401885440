import {
  getReports,
} from "src/api/reports";

const store = {
    namespaced: true,
    state() {
      return {
        reports: []
      }
    },
    actions: {
        async getReports(context, filters) {
            const { data } = await getReports(filters.from, filters.to);
            context.state.reports = data;
        }
    }
}

export default store;