
import { useStore } from 'vuex';

const useCeils = () => {
    const store = useStore();

    store.dispatch('ceils/getList');
    
    const getCeil = (id) => {
        return store.state.ceils.list.find(item => item.id == id) || { name: '-' };
    }

    return {
        getCeil
    }
}

export { useCeils }