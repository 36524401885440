<template>
  <div class="transport-current-table overflow-auto">
    <table style="width: 100%; border-collapse: collapse;">
      <thead>
        <tr>
          <th class="table__cell table__th">Статус</th>
          <th class="table__cell table__th">Дата прибытия</th>
          <th class="table__cell table__th">Номер перевозки</th>
          <th class="table__cell table__th">Мест</th>
          <th class="table__cell table__th">Отправлений</th>
          <th class="table__cell table__th">Недостатки</th>
          <th class="table__cell table__th">Излишки</th>
          <!--<th class="table__cell table__th">ПВЗ отправки</th>-->
          <th class="table__cell table__th">ПВЗ получения</th>
          <th class="table__cell table__th">Фио водителя</th>
          <th class="table__cell table__th">Госномер</th>
          <th class="table__cell table__th">Сотрудник</th>
          <th class="table__cell table__th"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="freight in freights" :key="freight.id" @click="onRowClick($event, freight)">
          <td class="table__cell">{{ freight.statusName || textStatus(freight.status)}}</td>
          <td class="table__cell">{{ freight.arrival_date }}</td>
          <td class="table__cell">{{ freight.number }}</td>
          <td class="table__cell">{{ freight.number_of_containers }}</td>
          <td class="table__cell">{{ freight.number_of_shipments }}</td>
          <td class="table__cell">{{ freight.shortage }}</td>
          <td class="table__cell">{{ freight.surplus }}</td>
          <!--<td class="table__cell" v-html="pointInfo(freight.src_point)"></td>-->
          <td class="table__cell" v-html="pointInfo(freight.dst_point)"></td>
          <td class="table__cell">{{ freight.driver }}</td>
          <td class="table__cell">{{ freight.vehicle }}</td>
          <td class="table__cell">{{ freight.dispatch_user_id }}</td>
          <td class="table__cell">
            <button
              type="button"
              class="table-button"
              @click="$emit('onPrint', freight)"
              >Акт ПП</button
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { FREIGHT_STATUS_VALUES } from '@/constants.js';

export default {
  name: "InPointFreights",
  props: {
    freights: {
      type: Array,
      required: true,
    },
  },

  methods: {
    pointInfo(point) {
      if (point) {
        return `№: ${point.id}, ${point.name}`;
      }
      return '-';
    },
    textStatus(status) {
      return FREIGHT_STATUS_VALUES[status];
    },
    onRowClick(event, freight) {
      if (event.target.classList.contains('act-button')) {
        return
      }

      this.$router.push({ name: 'freight-info', params: { id: freight.id } })
    }
  }
};
</script>
