import {
  getAll,
  //   createItem,
  //   readItem,
  //   updateItem,
  //   deleteItem,
} from "src/api/clients";
import { USERS_PAGE_LIMIT, USER_STATUS_VALUES } from "../constants";

const store = {
  namespaced: true,
  state() {
    return {
      list: [],
      pagination: {
        totalPages: 1,
      },
      errors: {
        getList: {},
      },
    };
  },
  actions: {
    async getList(context, filters = {}) {
      context.commit("SET_ERRORS", {
        key: "getList",
        val: {},
      });

      const filter = {
        items: USERS_PAGE_LIMIT,
        page: filters.page || 1,
      };
      if (filters.search) {
        filter.search = filters.search;
      }
      if (filters.status > -1) {
        filter.status = USER_STATUS_VALUES[filters.status];
      }

      try {
        const resp = await getAll(filter);
        context.commit("SET_LIST", resp.data);

        context.commit("SET_PAGINATION", {
          key: "totalPages",
          val: resp.headers["pages"] * 1 || 1,
        });
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "getList",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "getList",
            val: { error: "Ошибка" },
          });
        }
      }
    },
  },
  mutations: {
    SET_PAGINATION(state, payload) {
      state.pagination[payload.key] = payload.val;
    },
    SET_ERRORS(state, error) {
      state.errors[error.key] = error.val;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
  },
};
export default store;
