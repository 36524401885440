<template>
  <create-freight-content />
</template>

<script>
import CreateFreightContent from '@/components/freights/CreateFreightContent';

export default {
  name: 'CreateFreight',

  components: {
    CreateFreightContent,
  }
};
</script>
