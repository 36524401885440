export default function parseStatus(status) {
    let error = 'unknown_error'
    switch (status ) {
        case 500:
            error = 'server_error'
        break
        case 401:
        case 404:
            error = 'credentials_error'
    }
    return error
}
