<template>
  <el-table :data="news">
    <el-table-column prop="title" label="Название"/>
    <el-table-column prop="created_at" label="Создано"/>
    <el-table-column label="Действия">
      <template #default="scope">
        <el-button
            link
            type="primary"
            size="small"
            @click.prevent="open(scope.row)"
        >
          Показать
        </el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-dialog
      v-model="isNewsModalVisible"
      :title="title"
      top="50px"
      width="60%"
      align-center
      center
  >
    <el-card style="height: auto; max-height: 700px">
      <div class="message-container">
        <span class="news-message">
          {{ content }}
        </span>
      </div>

      <iframe
          v-if="fileUrl"
          loading="eager"
          allowfullscreen="allowfullscreen"
          :src="`${fileUrl}#zoom=50`"
          type="application/pdf"
          style="height: 600px;width: 100%"
      />
    </el-card>
  </el-dialog>
</template>

<script>
export default {
  props: {
    news: Array,
  },
  data() {
    return {
      isNewsModalVisible: false,
      title: null,
      fileUrl: null,
      content: null,
    };
  },
  methods: {
    open(row) {
      this.title = row.title;
      this.fileUrl =  row.file_url;
      this.content = row.content;
      this.isNewsModalVisible = true;
      console.log(row);
    }
  }
};
</script>
<style>
</style>
