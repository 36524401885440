import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import store from "@/store";
import accessGuard from "@/router/middleware/accessGuard.middleware";


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes.all,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach( async (to, from, next) => {
  if (to.meta.title) {
    store.commit("SET_TITLE", to.meta.title);
  }

  if (store.getters['user/isAuthenticated']) {
    if (!store.getters['user/currentUser']) {
      await store.dispatch('user/getAuthUser').then();
    }

    const route = accessGuard.accessGuardMiddleware(to);

    if (route) {
      router.replace(route)
      return;
    }
  }

  next();
});


export default router;
