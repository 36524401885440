import Freights from "./Freights.vue";
import CreateFreight from "./CreateFreight.vue";
import FreightInfo from "./FreightInfo.vue";
import FreightReception from "./FreightReception.vue";
import FreightReceptionWithBarcodes from "@/views/freights/FreightReceptionWithBarcodes";
import permissions from "@/modules/auth/auth.types.ts";

export const freightsRoutes = [
  {
    path: "/freights",
    component: Freights,
    name: "freights",
    meta: {
      middleware: [permissions.OWN_FREIGHT_VIEW],
      title: "Перевозки"
    },
  },
  {
    path: "/freights/create",
    component: CreateFreight,
    name: "freight-create",
    meta: {
      title: "Создание перевозки",
      isHeaderBackLink: true,
      middleware: [permissions.ANY_FREIGHT_CREATE]
    },
  },
  {
    path: "/freights/:id",
    component: FreightInfo,
    name: "freight-info",
    meta: { title: "Перевозка", isHeaderBackLink: true },
  },
  {
    path: "/freights/:id/reception",
    component: FreightReception,
    name: "freight-reception",
    meta: { title: "Прием перевозки", isHeaderBackLink: true },
  },

  {
    path: "/freights/:id/freight-reception-with-barcodes",
    component: FreightReceptionWithBarcodes,
    name: "freight-reception-with-barcodes",
    meta: { title: "Прием перевозки по штрихкодам", isHeaderBackLink: true },
  },
];
