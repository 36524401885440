import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import regions from './mocks/dicts/regions'
import timezones from './mocks/dicts/timezones'

const client = axios.create({
    baseURL: 'https://exmail.yode.pro/api',
    headers: {
        get Authorization() {
            const token = localStorage.getItem('auth') || sessionStorage.getItem('auth')
            if (token) {
                return `Bearer ${token}`
            }
            return undefined
        }
    }
})
const mock = new MockAdapter(client)

function createRoute(path, data) {
    mock.onGet(path).reply(200, data)
    return async function () {
        return await client.get(path)
    }
}

export const getRegions = createRoute('/dicts/regions', regions)
export const getTimezones = createRoute('/dicts/timezones', timezones)