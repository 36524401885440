<template>
 <div class="block-access-error">
   <el-icon :color="'red'" :size="40">
     <component :is="Lock"/>
   </el-icon>
   <p style="font-weight: 700">Доступ запрещен</p>
   <p>В данный момент у вас нет доступа к этой странице</p>
 </div>
</template>

<script>

import {Lock} from "@element-plus/icons-vue";

export default {
  name: "AccessErrorPage",
  data() {
    return {
      Lock
    }
  },
}
</script>

<style scoped>
  .block-access-error {
    display: flex;
    line-height: 1.8;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    min-height: 150px;
    background-color: #ffebe8;
    margin-top: 20px;
    border: 1px solid red;
  }
</style>
