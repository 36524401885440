import { getReasons } from "src/api/refusalReasons.js";
/*
Методы:
PUT api/shipments/{id}/cancelled - меняет статуст отправления на 100
необходимо передать refusal_reason -  id причины отмены

GET /api/refusal_reasons - получить все причины отказа от получения
гет-параметры для фильтра:
event - один из трех кодов
reason - неточное сравнение, поиск

GET /api/refusal_reasons/{id} - конкретная причина (не знаю зчем может понадобиться, но пусть будет)
*/
const store = {
    namespaced: true,
    state() {
        return {
            reasonsList: []
        }
    },
    actions: {
        async getReasons(context, reasonEvent = 'GOODS_CHECK_FAILED') {
            try {
                console.log(reasonEvent);
                const { status, data } = await getReasons(reasonEvent);

                if (status == 200) {
                    if (reasonEvent == 'REFUSAL_BY_RECEIVER') {
                        data.push({
                            id: 91,
                            reason: 'Выдано частично'
                        });
                    }

                    context.commit('SET_REASONS_LIST', data);
                }

            } catch(error) {
                console.error('TODO SET ERRROR', error);
            }
        },
    },
    mutations: {
        SET_REASONS_LIST(state, payload) {
            state.reasonsList = payload;
        },
    }
}


export default store;