import apiV2Service from "@/api/V2/services/apiV2.service";

const store = {
    namespaced: true,
    actions: {
        getUrl() {
            return apiV2Service.get(`helpdesk`)
        },
    },
};
export default store;
