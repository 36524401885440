import client from './client';

const getWidgets = async (from, to, points, isTodayDate) => {
    return await client.get('/statistics/widgets', {
        params: {
            date_from: from,
            date_to: to,
            points,
            is_today: isTodayDate
        }
    });
};

const getDataForModals = async (from, to, points, isTodayDate) => {
  return await client.get('/statistics/data', {
    params: {
      date_from: from,
      date_to: to,
      points,
      is_today: isTodayDate
    }
  });
};



export {
    getWidgets,
    getDataForModals
}
