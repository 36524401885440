import {ElNotification} from "element-plus";
import axios from "axios";


let startDate = new Date();
const clientUpdate = axios.create();
class FrontendNeedUpdateService {

  checkLastModifiedTime() {
    const url =  location.protocol + '//' + location.host + '/'

    return clientUpdate.get(url, {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      }
    ).then(response => {

      let lastModified = new Date(response.headers['last-modified']);
      if (lastModified > startDate) {

        ElNotification({
          title: 'Обновление системы',
          message: 'Мы провели обновление системы. Пожалуйста, обновите страницу, нажав на кнопку Shift+F5 (на ноутбуке Ctrl+Shift+R)',
          type: 'error',
        });
      }

    }, () => {
      console.log('Ошибка')
    });
  }

  startCheck() {
    let that = this
    setInterval(function () {
      that.checkLastModifiedTime();
    }, 130000);
  }

}

export default new FrontendNeedUpdateService();
