<template>
  <NewsTable :news="news" v-loading="isLoading" class="mt-4" />
</template>

<script>
import NewsTable from "@/components/userNews/NewsTable";
import {mapActions} from "vuex";
export default {
  name: "Notifications",
  components: {NewsTable},
  data() {
    return {
      isLoading: false,
      news: [],
    }
  },
  mounted() {
    this.isLoading = true;
    this.fetchNews().then((result) => {
      this.news = result;
    }).finally(() => {
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions({
      fetchNews: 'apiV2NewsStore/fetch',
    }),
  },
};
</script>

<style lang="scss">
.give-detail {
  &__buttons {
    margin-bottom: 26px;
  }
}

.el-table {
  margin-top: 2em;
}
</style>
