import { onBeforeMount } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const BLOCK_LENGTH = 18;
const RESET_DELAY = 1000 * 10;

const useScanner = () => {
    let tmp = '';
    let timeout;

    const router = useRouter();
    const route = useRoute();

    const runResetTimeout = () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            tmp = '';
        }, RESET_DELAY);
    }


    const handleKeys = (e) => {
        const disableRedirects = ({
            'freight-reception': 1,
            'warehouse-edit': 1,
            'freight-create': 1,
            'inventory-manage' : 1
        })[route.name];

        console.log(route.name)
        // Если нажали ENTER и имеем правильную длину блока парсим
        if (
                e.keyCode === 13
                && [
                    'multiAccept',
                    'freight-reception-with-barcodes',
                    'ppAct',
                    'ppActs',
                    'inventory-manage'
                ].indexOf(route.name) === -1
            ) {
            if (tmp.length === BLOCK_LENGTH || tmp.length === 13 || tmp.length === 14) {
                e.preventDefault();

                parseCode(tmp,  async ({ route, id }) => {
                    // Всегда отправляем событие с id
                    const event = new CustomEvent('scanned', { detail: JSON.stringify({ route, id }) });
                    window.dispatchEvent(event);

                    // Запрещаем редирект
                    if (disableRedirects) return;

                    const routePath = ({
                        1: 'give',
                        2: 'warehouse',
                        3: 'freights'
                    })[route];

                    if (routePath) {
                        router.push({ path: `/${routePath}/${id}` });
                    }
                });
                console.debug('parseCode');
            }
            console.debug('clear by user');
            tmp = '';
        }

        // Набираем блок из цифр
        if (/^\d+$/.test(e.key)) {
            // Если нет символов стартуем таймер
            if(!tmp) runResetTimeout();

            tmp = tmp + e.key;
            console.debug(`code: ${tmp} (${tmp.length})`);

            if (tmp.length > BLOCK_LENGTH) {
                tmp = '';
                console.debug('clear: max block size');
            }
        }
    }

    const parseCode = (code, callback) => {
        const route = +('' + code).substring(0, 1);
        const payload = '' + parseInt(code.substring(1));
        const id = +payload.substring(0, payload.length - 1);

        if (code.length === 14 || code.length === 18) {
          console.debug({ route, payload, id: code });

          if (callback) {
            callback.call(this, { route, payload, id: code })
          }

          return { route, payload, id: code }
        }

      console.debug({ route, payload, id });

        if (code.length === 14) {
          if (callback) {
            callback.call(this, { route, payload, id: code })
          }

          return { route, payload, id: code }
        }

        if (callback) {
            callback.call(this, { route, payload, id });
        }

        return { route, payload, id };
    }

    onBeforeMount(() => window.addEventListener('keydown', handleKeys, true));

    return {
        parseCode
    }
}

export { useScanner }
