<template>
  <div class="context_menu">
    <div class="adressStorage-control-menu">
      <form class="sort-items">
                    <span class="custom-search">
                        <button class="invise-searchButton"></button>
                        <input required class="custom-search-input form-control" placeholder="Поиск" type="search">
                        <button onclick="clearSearch()" type="button" class="close-icon"></button>
                    </span>
      </form>
      <div class="adressStorage-buttons-container">
        <button class="green-button print-shk-button">ШК ячеек</button>
        <button class="mainButton">Отключить</button>
        <button class="mainButton">Добавить зону</button>
      </div>
    </div>
    <div class="accordion" id="bigAccordion">
      <div class="accordion-item address-custom-accordion-item">
        <h2 class="accordion-header address-custom-accordion-header" id="accordionTittle1">
          <button class="accordion-button collapsed address-custom-accordion-button"
                  type="button" data-bs-toggle="collapse" data-bs-target="#collapse1"
                  aria-expanded="false" aria-controls="collapse1">
            Зона А
          </button>
          <button class="accordion-add-button"></button>
          <button onclick="this.parentNode.parentNode.remove();" class="accordion-delete-button"></button>
        </h2>
        <div id="collapse1" class="accordion-collapse collapse" aria-labelledby="accordionTittle1">
          <div class="accordion-body address-custom-accordion-body">
            <div class="accordion-item address-custom-accordion-item">
              <h2 class="accordion-header address-custom-accordion-header" id="accordionTittle1-1">
                <button class="accordion-button collapsed address-custom-accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapse1-1" aria-expanded="false" aria-controls="collapse1-1">
                  Стеллаж А
                </button>
                <button class="accordion-add-button"></button>
                <button onclick="this.parentNode.parentNode.remove();" class="accordion-delete-button"></button>
              </h2>
              <div id="collapse1-1" class="accordion-collapse collapse" aria-labelledby="accordionTittle1-1">
                <div class="accordion-body address-custom-accordion-body address-custom-accordion-body">
                  <div class="address-custom-accordion-body-item">
                    <p>Ячейка 01 (А-00-01)</p>
                    <button onclick="this.parentNode.remove();" class="accordion-delete-button"></button>
                  </div>
                  <div class="address-custom-accordion-body-item">
                    <p>Ячейка 01 (А-00-01)</p>
                    <button onclick="this.parentNode.remove();" class="accordion-delete-button"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item address-custom-accordion-item">
        <h2 class="accordion-header address-custom-accordion-header" id="accordionTittle2">
          <button class="accordion-button collapsed address-custom-accordion-button" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false"
                  aria-controls="collapse2">
            Зона B
          </button>
          <button class="accordion-add-button"></button>
          <button onclick="this.parentNode.parentNode.remove();" class="accordion-delete-button"></button>
        </h2>
        <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2">
          <div class="accordion-body">
          </div>
        </div>
      </div>
      <div class="accordion-item address-custom-accordion-item">
        <h2 class="accordion-header address-custom-accordion-header" id="accordionTittle3">
          <button class="accordion-button collapsed address-custom-accordion-button" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false"
                  aria-controls="collapse3">
            Зона C
          </button>
          <button class="accordion-add-button"></button>
          <button onclick="this.parentNode.parentNode.remove();" class="accordion-delete-button"></button>
        </h2>
        <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="accordionTittle3">
          <div class="accordion-body">
          </div>
        </div>
      </div>
      <div class="accordion-item address-custom-accordion-item">
        <h2 class="accordion-header address-custom-accordion-header" id="accordionTittle4">
          <button class="accordion-button collapsed address-custom-accordion-button" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false"
                  aria-controls="collapse4">
            Зона D
          </button>
          <button class="accordion-add-button"></button>
          <button onclick="this.parentNode.parentNode.remove();" class="accordion-delete-button"></button>
        </h2>
        <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="accordionTittle4">
          <div class="accordion-body">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Folder",
  components: {},
};
</script>
