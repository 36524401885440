<template>
  <div class="context_menu">
    <div class="confirm-age">
      У вас нет не одного закрепленного ПВЗ, обратитесь к администратору
    </div>
    <!--
    <div class="debts1">
      <div class="sum_debts">
        <h2>Общая задолженность:</h2>
        <p class="numeric_value-debts">80 000р</p>
        <p class="debts-popUp-red">Аккаунт будет заблокирован 15.12.2021г</p>
      </div>
      <div class="grid-debts">
        <div class="debts-item">
          <h3>Точка 1</h3>
          <p>Ул. Ленина, д. 40, г. Майкоп, Республика Адыгея, 385000</p>
          <p>Статус: Работает</p>
          <p class="sum-address-deb">Задолженность: 20 000 Р</p>
        </div>
        <div class="debts-item">
          <h3>Точка 1</h3>
          <p>Ул. Ленина, д. 40, г. Майкоп, Республика Адыгея, 385000</p>
          <p>Статус: Работает</p>
          <p class="sum-address-deb">Задолженность: 20 000 Р</p>
        </div>
        <div class="debts-item">
          <h3>Точка 1</h3>
          <p>Ул. Ленина, д. 40, г. Майкоп, Республика Адыгея, 385000</p>
          <p>Статус: Работает</p>
          <p class=" sum-address-deb">Задолженность: 20 000 Р
          </p>
        </div>
        <div class="debts-item">
          <h3>Точка 1</h3>
          <p>Ул. Ленина, д. 40, г. Майкоп, Республика Адыгея, 385000</p>
          <p>Статус: Работает</p>
          <p class="sum-address-deb">Задолженность: 20 000 Р</p>
        </div>
      </div>
    </div>
    -->
  </div>
</template>

<script>
export default {
  name: "Address",
  components: {},
  mounted() {
    const { point, points } = this.$store.state.user?.user;
    const { id } = (points[0] || point || {});

    if (id) {
      this.$router.replace({ path: `/address/${id}`});
    }
    
    this.$store.commit("SET_TITLE", "EXMAIL");
  },
};
</script>
