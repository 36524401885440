<template>
  <div class="d-flex">
    <Datepicker
      :value="value"
      range
      locale="ru"
      :format="format"
      inputClassName="form-control custom__dp custom__dp--shadow"
      type="datepicker"
      hideInputIcon
      :enableTimePicker="false"
      @update:modelValue="$emit('onUpdate', $event)"
      @clear="$emit('onUpdate', $event)"
      selectText="Выбрать"
      cancelText="Отмена"
      placeholder="Весь период"
    />
    <button
      type="button"
      class="sortButton w-auto"
      @click.prevent="$emit('onApply')"
    >
      Применить
    </button>
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: "RangeDatePicker",
  components: { Datepicker },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      format: "dd-MM-yyyy",
    };
  },
};
</script>
