<template>
  <div class="context_menu">
    <div class="storageCreate-context-container">
      <div class="send-PVZ storageCreate-items">
        <h2 class="title-style-main">
          Отправления на ПВЗ
        </h2>
        <el-form
          label-position="top"
          class="mt-4"
          @submit.prevent
          ref="formScan"
        >
          <el-form-item>
            <input
              class="el-input__inner"
              type="search"
              @keyup.enter.prevent="scanShipment"
              v-model="barcode"
              placeholder="Введите штрихкод (Enter для подтверждения)"
              required
            >
          </el-form-item>
        </el-form>

        <div class="storage-buttons">
          <button
            class="mainButton"
            @click="addSelected"
          >
            Добавить
          </button>
        </div>
        <create-shipment-table
          :items="itemsAll"
          :show-checkbox="true"
          :show-status="true"
          @toggle-selected="toggleSelectedItemsAll"
        />

      </div>
      <div class="ready-to-send  storageCreate-items">
        <h2 class="title-style-main">
          Отправления к отправке {{ sending ? sending.number : '' }}
        </h2>

        <div class="storage-buttons" style="padding-top: 65px">
          <button
            v-if="sending"
            :disabled="min || max"
            class="mainButton"
            @click="submit"
          >
            Сохранить
          </button>
          <button
            v-else
            :disabled="min || max"
            class="mainButton"
            @click="toggleCreateModal(true)"
          >
            Сформировать
          </button>
        </div>
        <create-shipment-table
          
          :items="selectedItems"
          :show-checkbox="true"
          @toggle-selected="toggleSelected"
        />
      </div>
    </div>
    <ModalCreate
      v-if="isCreateModalShown"
      @onClose="toggleCreateModal(false)"
    >
      <CreateFreight :is-submit-freight="false" @add-freight="submit"/>
    </ModalCreate>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {ElNotification} from 'element-plus'
import CreateShipmentTable from '@/components/warehouse/CreateShipmentTable';
import ModalCreate from '@/components/modal/ModalCreate';
import CreateFreight from '@/components/freights/CreateFreight';
import {
  SHIPMENT_ACCEPTED_FROM_SENDER,
  SHIPMENT_RETURN,
  SHIPMENT_ARRIVED_AT_WRONG_PICKUP_POINT
} from "@/constants";

const MAX_SENDS = 80;

export default {
  name: 'CreateSendingContent',

  components: {
    CreateShipmentTable,
    ModalCreate,
    CreateFreight,
  },

  props: {
    sending: {
      type: Object,
      default: null,
      required: true
    },
  },

  data() {
    return {
      itemsAll: [],
      selectedItems: [],
      isCreateModalShown: false,
      barcode: "",
    };
  },

  computed: {
    len() {
      return this.selectedItems.length;
    },

    min() {
      return this.len == 0;
    },

    max() {
      return this.len > MAX_SENDS;
    },

    user() {
      return this.$store.state.user.user;
    }
  },

  created() {
    this.getItems();
  },
  methods: {
    scanShipment() {
      const form = this.$refs.formScan.$el;

      if (!form.checkValidity()) {
        form.reportValidity();

        return;
      }

      const item = this.itemsAll.find(item => {
        return item.barcode === this.barcode
      });

      if (!item) {
        ElNotification({
          title: 'Ошибка',
          message: 'Отправления с таким штрихкодом не существует',
          type: 'error',
        });

        return;
      }

      if (![SHIPMENT_ACCEPTED_FROM_SENDER, SHIPMENT_RETURN,SHIPMENT_ARRIVED_AT_WRONG_PICKUP_POINT].includes(parseInt(item.status))
      ) {
        ElNotification({
          title: 'Ошибка',
          message: 'Отправление не может быть добавлено',
          type: 'error',
        });
        return;
      }

      const index = this.itemsAll.findIndex(el => el.id === item.id);

      if (!~index) return;

      this.toggleSelectedItemsAll(item, index);
      this.barcode = "";

      ElNotification({
        title: 'Успешно',
        message: 'Отправление успешно отсканировано',
        type: 'success',
      });
    },

    ...mapActions({
      getForSending: 'apiV2ShipmentStore/forSending',
      update: 'sendings/update',
    }),

    async getItems() {
      // FIXME user not ready on this state
      if (!this.user && this.$store.state.user.userLoadedPromise) {
        await this.$store.state.user.userLoadedPromise;
      }

     await this.getForSending().then((result) => {
        this.itemsAll = result.map(item => ({
          ...item,
          selected: false,
        }));
      });
    },

    toggleSelectedItemsAll(item, index) {
      this.itemsAll[index].selected = !item.selected
    },

    toggleSelected(item, index) { // index
      if (!item.selected) {
        this.selectedItems.push(item)
      } else {
        const item = this.selectedItems.splice(index, 1);
        this.itemsAll.push(...item);
      }
    },

    addSelected() {
      // Перенос из левой колонки
      this.selectedItems = this.itemsAll.filter(item => item.selected)
      this.itemsAll = this.itemsAll.filter(item => !item.selected)
    },

    toggleCreateModal(val) {
      this.isCreateModalShown = val;
    },

    async submit(freight) {
      if (this.sending) {
        this.edit()
      } else {
        this.create(freight)
      }
    },

    async create(freight) {
      const data = {
        ...freight,
        shipments: this.selectedItems.map(item => item.id),
        number_of_shipments: this.selectedItems.length,
      };
      try {
        await this.save(data)
        ElNotification({
          title: 'Успешно',
          message: 'Перевозка была успешно создана',
          type: 'success',
        })
        this.$router.push({name: 'warehouse'})
        this.toggleCreateModal(false)
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    },

    async edit() {
      const body = {
        id: this.sending.id,
        data: {shipments: this.selectedItems.map(item => item.id)}
      }
      try {
        await this.update(body)
        ElNotification({
          title: 'Успешно',
          message: 'Отправка была изменена',
          type: 'success',
        })
        this.$router.push({name: 'warehouse-info'})
        this.toggleCreateModal(false)
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.h3__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 44px;
  margin-bottom: 16px;
}

b {
  font-weight: bold;
}

.w-1\/2 {
  width: 50%;
}

.el-input__inner {
  height: 42px;
}

.el-input__inner:disabled {
  background-color: var(--el-disabled-bg-color);
  box-shadow: 0 0 0 1px var(--el-disabled-border-color) inset;
  color: var(--el-disabled-text-color);
  cursor: not-allowed;
}

.el-input__inner ~ .input__validate {
  display: none;
}
</style>
