<template>
  <div class="storage-table">
    <ul class="table-container table-head">
      <li class="table-items-head create-storage-item-name-width">
        <span>ID</span>
      </li>
      <li class="table-items-head create-storage-item-number-width">
        <span>Штрихкод</span>
      </li>
      <li class="table-items-head create-storage-item-number-width">
        <span>Номер</span>
      </li>
      <li v-if="this.showStatus" class="table-items-head create-storage-item-number-width">
        <span>Статус</span>
      </li>
      <li v-if="this.showCell" class="table-items-head create-storage-item-number-width">
        <span>Ячейка</span>
      </li>
    </ul>
    <create-shipment-table-item
      v-for="(item, index) in items"
      :key="item.id"
      :item="item"
      :showStatus="this.showStatus"
      :showCell="this.showCell"
      :show-checkbox="this.showCheckbox"
      :showAlert="this.showAlert"
      @toggle-selected="$emit('toggle-selected', $event, index)"
    />
  </div>
</template>

<script>
import CreateShipmentTableItem from "@/components/warehouse/CreateShipmentTableItem";

export default {
  name: 'CreateShipmentTable',

  components: {CreateShipmentTableItem},

    props: {
        items: {
            type: Array,
            required: true,
        },
      showStatus: {
          type: Boolean,
           required: false
      },
      showCell: {
        type: Boolean,
        required: false
      },
      showCheckbox: {
        type: Boolean,
        required: false
      },
      showAlert: {
        type: Boolean,
        required: false
      }

  },
}
</script>

<style scoped>

</style>
