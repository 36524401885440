export default class Routes {
    #routes = []
    constructor(routes) {
        this.#routes = [...this.#routes, ...routes]
    }
    addRoutes(routes) {
        this.#routes = [...this.#routes, ...routes]
        return this
    }
    get all() {
        return this.#routes
    }
}