<template>
  <el-form
    novalidate
    :model="issueReturnToCourierForm"
    :rules="rules"
    ref="formPpActFinder"
    label-position="top"
    class="container-fluid mt-4"
    @submit.prevent="issueReturnToCourier"
  >
    <div class="col-lg-8 col-md-10 col-sm-12">
      <h3 class="h3__title">Заполнение данных для передачи возврата</h3>

      <el-row :gutter="16">
        <el-col :span="16">
          <el-form-item label="Магазин">
            <div class="me-5">
              <ShopSelect :type-shops="2" @change="onChangeShopId($event)"></ShopSelect>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="16">
        <el-col :span="16">
          <el-form-item label="Фамилия курьера" prop="courier.surname">
            <el-input
              :minlength="2"
              v-model="issueReturnToCourierForm.courier.surname"
              placeholder="Фамилия"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="16">
        <el-col :span="16">
          <el-form-item label="Имя курьера" prop="courier.name">
            <el-input
              :minlength="2"
              v-model="issueReturnToCourierForm.courier.name"
              placeholder="Имя"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="16">
        <el-col :span="16">
          <el-form-item label="Отчество курьера" prop="courier.patronymic">
            <el-input
              :minlength="2"
              v-model="issueReturnToCourierForm.courier.patronymic"
              placeholder="Отчество"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="16">
        <el-col :span="16">
          <el-form-item label="Номер доверенности" prop="courier.document_number">
            <el-input
              :minlength="4"
              :maxlength="20"
              v-model="issueReturnToCourierForm.courier.document_number"
              placeholder="Введите номер доверенности"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="16">
        <el-col :span="16">
          <el-form-item label="Штрихкод (Отсканируйте штрихкод возврата)" prop="barcode">
            <el-input
              :minlength="6"
              :maxlength="20"
              v-model="issueReturnToCourierForm.barcode"
              placeholder="Штрихкод"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="16">
        <el-col :span="16" class="d-grid">
          <el-button
            v-if="!scanned"
            type="primary"
            plain
            :icon="Search"
            :loading="isLoading"
            :disabled="!isFormValid"
            @click.prevent="scan"
          >
            Сканировать
          </el-button>
        </el-col>
      </el-row>

      <el-row :gutter="16">
        <el-col :span="12" class="d-grid">
          <el-button
            v-if="scanned"
            :disabled="isCainiao && !isCainiaoActReady"
            type="primary"
            plain
            :icon="Search"
            :loading="isLoading"
            @click.prevent="toggleDialog"
          >
            Передать курьеру
          </el-button>
        </el-col>
        <el-button
          v-if="scanned && !isCainiao"
          type="primary"
          plain
          :icon="Printer"
          :loading="isLoading"
          @click.prevent="downloadReturnAct"
        >
          Печать
        </el-button>
        <el-button
          v-if="scanned && isCainiao"
          type="primary"
          plain
          :icon="Printer"
          :loading="isLoading"
          @click.prevent="downloadReturnActCainiao"
        >
          Печать
        </el-button>
        <el-button
          v-if="scanned && isCainiao"
          type="primary"
          plain
          :icon="Printer"
          :loading="isLoading"
          @click.prevent="downloadCainiaoReturnLabel"
        >
          Этикетка Cainiao
        </el-button>
      </el-row>

      <el-dialog
        title="Передача возврата"
        v-model="windowVisible"
        width="600px"
        align-center
      >
        <span style="font-size: 20px">Передать возврат курьеру?</span>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="toggleDialog" type="info">Отмена</el-button>
            <el-button type="primary" @click="issueReturnToCourier">
              Подтвердить
            </el-button>
          </div>
        </template>
      </el-dialog>

    </div>
  </el-form>
</template>
<script>

import {ElNotification} from "element-plus";
import {usePrint} from "@/composition/usePrint";
import {Printer, Search} from '@element-plus/icons-vue'
import ShopSelect from "@/components/selectors/shopSelect/ShopSelect.vue";
import {
  downloadReturnAct,
  issueReturnToCourier,
  scanReturn
} from "@/views/return-acts/api/api";
import {downloadFile, getFileNameWithExtensionFromHeaders} from "@/store/helper";
import {
  downloadCainiaoReturnDocument,
  downloadCainiaoReturnLabel,
} from "./api/api";
import { CAINIAO_SHOP_ID } from "../../constants";

export default {
  name: "IssueReturn",
  components: {
    ShopSelect,
  },
  setup() {
    return {
      ...usePrint(),
    }
  },
  data() {
    return {
      windowVisible: false,
      scanned: false,
      isCainiao: false,
      isCainiaoActReady: false,
      successAudio: new Audio(require('@/assets/audio/positive.mp3')),
      errorAudio: new Audio(require('@/assets/audio/negative.mp3')),
      shopId: null,
      headerTitle: 'Передача возврата',
      result: {
        alerts: [],
        flags: {},
        shipment: null
      },

      issueReturnToCourierForm: {
        courier: {
          name: null,
          surname: null,
          patronymic: null,
          document_number: null,
        },
        barcode: null
      },
    }
  },
  mounted() {
    this.alerts = [];
    this.$store.commit("SET_TITLE", this.headerTitle);
  },
  methods: {
    resetForm() {
      this.scanned = false
      this.issueReturnToCourierForm = {
        courier: {
          name: null,
          surname: null,
          patronymic: null,
          document_number: null,
        },
        barcode: null,
      };
    },
    toggleDialog() {
      this.windowVisible = !this.windowVisible;
    },
    onChangeShopId(event) {
      this.shopId = event.length > 0 ? event[0] : null;
    },
    checkDataFilled(){
      if (!this.shopId) {
        return ElNotification({
          title: 'Ошибка',
          message: 'Выберите магазин!',
          type: 'error',
        });
      }
      if (!this.issueReturnToCourierForm.courier.surname) {
        return ElNotification({
          title: 'Ошибка',
          message: 'Укажите фамилию курьера!',
          type: 'error',
        });
      }
      if (!this.issueReturnToCourierForm.courier.name) {
        return ElNotification({
          title: 'Ошибка',
          message: 'Укажите имя курьера!',
          type: 'error',
        });
      }
      if (!this.issueReturnToCourierForm.courier.patronymic) {
        return ElNotification({
          title: 'Ошибка',
          message: 'Укажите отчество курьера!',
          type: 'error',
        });
      }
      if (!this.issueReturnToCourierForm.courier.document_number) {
        return ElNotification({
          title: 'Ошибка',
          message: 'Укажите номер доверенности!',
          type: 'error',
        });
      }
      if (!this.issueReturnToCourierForm.barcode) {
        return ElNotification({
          title: 'Ошибка',
          message: 'Укажите номер возврата!',
          type: 'error',
        });
      }
    },
    async scan(){
      this.checkDataFilled()

      scanReturn(this.issueReturnToCourierForm.courier, this.issueReturnToCourierForm.barcode, this.shopId).then(() => {
        this.successAudio.play();
        this.scanned = true
        if (parseInt(this.shopId) === parseInt(CAINIAO_SHOP_ID)){
          this.isCainiao = true;
        }
      }, () => {
        this.errorAudio.play();
      });
    },
    downloadReturnAct() {
      downloadReturnAct(this.issueReturnToCourierForm.barcode, this.issueReturnToCourierForm.courier).then(response => {
        if (response.data) {
          const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          downloadFile(url, fileNameWithExtension)
          this.successAudio.play();
        } else {
          this.errorAudio.play()

          return ElNotification({
            title: 'Ошибка',
            message: 'Ошибка при печати акта!',
            type: 'error',
          });
        }
      });
    },
    downloadReturnActCainiao() {
      downloadCainiaoReturnDocument(this.issueReturnToCourierForm.barcode, this.issueReturnToCourierForm.courier).then(response => {
        if (response.data) {
          const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          downloadFile(url, fileNameWithExtension)
          this.successAudio.play();
          this.isCainiaoActReady = true;
        } else {
          this.errorAudio.play()

          return ElNotification({
            title: 'Ошибка',
            message: 'Ошибка при печати акта!',
            type: 'error',
          });
        }
      });
    },
    downloadCainiaoReturnLabel() {
      downloadCainiaoReturnLabel(this.issueReturnToCourierForm.barcode).then(response => {
        if (response.data) {
          const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          downloadFile(url, fileNameWithExtension)
          this.successAudio.play();
        } else {
          this.errorAudio.play()

          return ElNotification({
            title: 'Ошибка',
            message: 'Ошибка при печати акта!',
            type: 'error',
          });
        }
      });
    },
    async issueReturnToCourier() {
      this.checkDataFilled()

      issueReturnToCourier(this.issueReturnToCourierForm.courier, this.issueReturnToCourierForm.barcode, this.shopId).then((response) => {
        if (response) {
          this.successAudio.play();
          this.resetForm()
          this.toggleDialog()

          return ElNotification({
            title: 'Успешно',
            message: 'Возврат успешно выдан курьеру!',
            type: 'success',
          });
        } else {
          this.errorAudio.play();

          return ElNotification({
            title: 'Ошибка',
            message: 'Ошибка при передаче возврата!',
            type: 'error',
          });
        }
      }, () => {
        this.errorAudio.play();

        return ElNotification({
          title: 'Ошибка',
          message: 'Ошибка при передаче возврата!',
          type: 'error',
        });
      });
    },
  },
  computed: {
    Printer() {
      return Printer
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
    Search() {
      return Search
    },
    rules() {
      return {
        'courier.surname': [
          {required: true, message: 'Введите фамилию курьера', trigger: 'blur'},
          {min: 2, message: 'Фамилия курьера должна быть не менее 2 символов', trigger: 'blur'},
        ],
        'courier.name': [
          {required: true, message: 'Введите имя курьера', trigger: 'blur'},
          {min: 2, message: 'Имя курьера должно быть не менее 2 символов', trigger: 'blur'},
        ],
        'courier.patronymic': [
          {required: true, message: 'Введите отчество курьера', trigger: 'blur'},
          {min: 2, message: 'Отчество курьера должно быть не менее 2 символов', trigger: 'blur'},
        ],
        'courier.document_number': [
          {required: true, message: 'Введите номер доверенности', trigger: 'blur'},
          {
            min: 4,
            max: 20,
            message: 'Номер доверенности должен содержать от 4 до 20 символов',
            trigger: 'blur',
          },
        ],
        barcode: [
          {required: true, message: 'Введите номер возврата', trigger: 'blur'},
          {
            min: 6,
            max: 20,
            message: 'Штрихкод должен содержать от 6 до 20 символов',
            trigger: 'blur'
          },
        ],
      };
    },
    isFormValid() {
      return (
        this.shopId &&
        this.issueReturnToCourierForm.courier.surname &&
        this.issueReturnToCourierForm.courier.name &&
        this.issueReturnToCourierForm.courier.patronymic &&
        this.issueReturnToCourierForm.courier.document_number &&
        this.issueReturnToCourierForm.barcode &&
        this.issueReturnToCourierForm.courier.surname.length >= 2 &&
        this.issueReturnToCourierForm.courier.name.length >= 2 &&
        this.issueReturnToCourierForm.courier.patronymic.length >= 2 &&
        this.issueReturnToCourierForm.courier.document_number.length >= 4 &&
        this.issueReturnToCourierForm.courier.document_number.length <= 20 &&
        this.issueReturnToCourierForm.barcode.length >= 6 &&
        this.issueReturnToCourierForm.barcode.length <= 20
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.h3__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 44px;
  margin-bottom: 16px;
}

b {
  font-weight: bold;
}

.w-1\/2 {
  width: 50%;
}

.el-input__inner {
  height: 42px;
}

.el-input__inner:disabled {
  background-color: var(--el-disabled-bg-color);
  box-shadow: 0 0 0 1px var(--el-disabled-border-color) inset;
  color: var(--el-disabled-text-color);
  cursor: not-allowed;
}

.el-input__inner ~ .input__validate {
  display: none;
}

.form--submitted .el-input__inner:invalid {
  border: 1px solid #dc3545;
}

.form--submitted .el-input__inner:invalid ~ .input__validate {
  display: block;
  color: #dc3545;
}

.multi-accept {
  &__message {
    white-space: pre-line
  }

  &__search-block {
    flex-direction: column;
  }
}
</style>
