<template>
  <div v-if="this.activeInventory != null">
    <div class="d-flex justify-content-around mt-3">
      <div class="d-flex justify-content-center flex-column">
        <div>
          <p style="text-align: center" class="m-2">Отправлений</p>
        </div>
        <div class="circle d-flex justify-content-center align-items-center fs-3">
          <p>
            {{ this.activeInventory.count_shipments }}
          </p>
        </div>
      </div>

      <div>
        <p class="m-2">Отсканировано</p>
        <div class="circle d-flex justify-content-center align-items-center fs-3">
          <p>
            {{ this.activeInventory.count_scanned_shipments}}
          </p>
        </div>
      </div>

      <div>
        <p style="text-align: center" class="m-2">Недостач</p>
        <div class="circle d-flex justify-content-center align-items-center fs-3">{{ this.activeInventory.count_shortage_shipments }}</div>
      </div>

      <div>
        <p style="text-align: center" class="m-2">Излишков</p>
        <div class="circle d-flex justify-content-center align-items-center fs-3">{{ this.activeInventory.count_surplus_shipments }}</div>
      </div>
    </div>

    <div class="mt-5 mb-4 d-flex justify-content-between">
      <div>
        <span>
         Менять полку  посылки при нахождении ее на другой полке
        </span>
        <Switch
          :offLabel="'Нет'"
          :onLabel="'Да'"
          @change="onChange"
        ></Switch>
      </div>

      <div>
        <button
          class="edit-worker-modal-button-save k-mr-3"
          type="button"
          @click="downloadExcelInventoryReport"
        >
          Проверить
        </button>
        <button
          class="edit-worker-modal-button-save k-mr-3"
          type="button"
          @click="finishInventory"
        >
          Завершить
        </button>

        <button
          class="edit-worker-modal-button-save k-mr-3"
          style="background-color: #f83a3a"
          type="button"
          @click="cancelInventory"
        >
          Отменить
        </button>
      </div>
    </div>

    <div class="container-search d-flex justify-content-start">
      <form class="sort-items"  @submit.prevent="scanShipment"
      >
              <span class="custom-search">
                <button class="invise-searchButton"/>
                <input
                  v-model="barcode"
                  ref="setFocusScanShipment"

                  class="custom-search-input form-control"
                  placeholder="Сканировать"
                  type="search"
                  autofocus
                >
                <button
                  @click="clearBarcode()"
                  type="button"
                  class="close-icon"
                />
              </span>
      </form>
    </div>

    <Grid
      ref="gridInventoryManage"
      :sortable="true"
      :data-items="scannedShipments"
      :columns="columns"
    >
      <template v-slot:shipment_barcode="{props}">
        <td><a style="color: #556EE6" target="_blank" :href=props.dataItem.shipment.shipment_link>{{ props.dataItem.shipment_barcode}}</a></td>
      </template>
  </Grid>
  </div>

  <div v-else class="mt-5">
    <p>В данный момент на этом пвз инвентаризация не проводится</p>
  </div>
</template>

<script>
import {Grid} from "@progress/kendo-vue-grid";
import { Switch } from "@progress/kendo-vue-inputs";
import {useSound} from "@/composition/useSound";
import {ElMessageBox, ElNotification} from "element-plus";
import {downloadFile, getFileNameWithExtensionFromHeaders} from "@/store/helper";
import {useBarcodeScan} from "@/composition/useBarcodeScan";

export default {
  name: "InventoryManage",
  components: {
    Grid,
    Switch,
  },
  data() {
    return {
      activeInventoryId: null,
      barcode: null,
      ceil: null,
      isChangeCeil: false,
      activeInventory: null,
      columns: [
        {
          field: 'shipment_barcode',
          title: 'Номер',
          width: '180px',
          cell: 'shipment_barcode'
        },
        {
          field: 'ceil_old.name',
          title: 'Полка до',
          width: '100px',
        },
        {
          field: 'ceil_new.name',
          title: 'Полка после',
          width: '120px',
        },
        {
          field: 'ceil_find_shipment.name',
          title: 'Полка после',
          width: '120px',
        },
        {
          field: 'shipment.pick_up_date',
          title: 'Дата поступления',
          width: '160px',
        },
        {
          field: 'shipment.dts_point_id',
          title: 'ПВЗ источник',
          width: '150px',
        },
        {
          field: 'scanned_at',
          title: 'Сканирование',
          width: '200px',
        },
        {
          field: 'user.full_name',
          title: 'Оператор',
          width: '200px',
        },
      ],
      scannedShipments: null
    }
  },
  created() {
    this.getActiveInventory();
  },
  setup() {
    return {
      ...useSound(),
      ...useBarcodeScan()
    }
  },
  mounted() {
    console.log('subscribe to event')
    window.addEventListener('barcodescan', this.fromScanner);
  },
  unmounted() {
    console.log('unsubscribe to event')

    window.removeEventListener('barcodescan', this.fromScanner);
  },

  methods: {
    getInventoryScannedShipments() {
      this.$store.dispatch('apiV2InventoryShipmentStore/getScannedShipmentsByInventory').then((result) => {
        this.scannedShipments = result;
      })
    },
    async fromScanner(e) {
      console.log(e);
      this.barcode = e.detail;

      this.scanShipment();

    },
    onChange(e) {
      this.isChangeCeil = e.target.value;
    },
    clearBarcode() {
      this.barcode = null;
    },
    getActiveInventory() {
      this.$store.dispatch("apiV2InventoryStore/getActiveInventory").then((result) => {
        this.activeInventory = result;

        if (this.activeInventory) {
          this.getInventoryScannedShipments();
        }
      }, error => {
        console.log(error);
      })
    },
    async scanShipment() {
      if (this.isShelfBarcode(this.barcode)) {
        this.ceil = this.parseEan(this.barcode);
        this.playScannedCeilSound();

        this.clearBarcode();

        return;
      }

      this.$store.dispatch('apiV2InventoryShipmentStore/scan', {
        inventory_uuid: this.activeInventory.uuid,
        barcode: this.barcode,
        new_ceil: this.ceil,
        is_change_ceil: this.isChangeCeil,
      }).then((result) => {
        this.playSound(result);

        this.getActiveInventory();
      }, (error) => {
        this.playSound(error);

        if (error.status === 422) {
          let firstErrorKey = Object.keys(error.errors)[0];

          ElNotification({
            title: 'Ошибка',
            message: error.errors[firstErrorKey][0],
            type: 'error',
          });
        }
      })


    },
    playSound(result) {
      switch (result.audio) {
        case "longError":
          this.playAlertLongSound();

          ElNotification({
            title: 'Ошибка',
            message: result.message,
            type: 'error',
          });
          break;
        case "smallError":
          this.playNegativeSound()

          ElNotification({
            title: 'Ошибка',
            message: result.message,
            type: 'error',
          });
          break;
        default:
          this.playPositiveSound()
      }
    },
    async finishInventory() {
      await ElMessageBox.confirm(
        'Вы уверены что хотите завершить инвентаризацию?\
        Это действие невозможно будет отменить.',
        {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          cancelButtonClass: 'el-button--info'
        },
      )

      this.$store.dispatch("apiV2InventoryStore/finishInventory", this.activeInventory.uuid).then(() => {
        ElNotification({
          title: 'Успешно',
          message: 'Запущен процесс завершения инвентаризации',
          type: 'success',
        });

        this.getActiveInventory();
      }, error => {
        console.log(error);
      })
    },

    async cancelInventory() {
      await ElMessageBox.confirm(
        'Вы уверены что хотите отменить инвентаризацию?\
        Это действие невозможно будет отменить.',
        {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          cancelButtonClass: 'el-button--info'
        },
      )

      this.$store.dispatch("apiV2InventoryStore/cancelInventory", this.activeInventory.uuid).then(() => {
        ElNotification({
          title: 'Успешно',
          message: 'Инвентаризация успешно отменена',
          type: 'success',
        });

        this.getActiveInventory();
      }, error => {
        console.log(error);
      })
    },

    downloadExcelInventoryReport() {
      this.$store.dispatch('apiV2DocumentStore/downloadInventoryReport', this.activeInventory.uuid).then(response => {
        const fileNameWithExtension = getFileNameWithExtensionFromHeaders(response.headers);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        downloadFile(url, fileNameWithExtension)
      });
    },
  }
}
</script>

<style scoped>
  .circle {
    margin: 0 auto;
    width: 80px;
    height: 80px;
    border: 1px solid;
    border-radius: 50%;
    text-align: center;
    background-color: #ffb05f;
    color: #f8f8f8;
    align-items: center;
  }
</style>
