import AcceptShipments from "./AcceptShipments.vue";
import AcceptShipmentsCreate from "./AcceptShipmentsCreate.vue";
import RegistrationShipment from "./RegistrationShipment.vue";
import MultiAcceptShipments from "@/views/accept/MultiAcceptShipments.vue";
import permissions from "@/modules/auth/auth.types.ts";

export const acceptRoutes = [
  {
    path: "/accept",
    component: AcceptShipments,
    name: "acceptShipments",
    // meta: { title: "Прием отправлений" },
    meta: {
      middleware: [permissions.OWN_SHIPMENT_ACCEPT]
    }
  },
  {
    path: "/multi-accept",
    component: MultiAcceptShipments,
    name: "multiAccept",
    meta: {
      middleware: [permissions.OWN_SHIPMENT_MULTI_ACCEPT]
    }
  },
  {
    path: "/accept/create",
    component: AcceptShipmentsCreate,
    name: "acceptShipmentsCreate",
    meta: {
      isHeaderBackLink: true
    },
  },
  {
    path: "/accept/:id",
    component: RegistrationShipment,
    name: "registration-shipment",
    meta: {
      title: 'Оформление отправления',
      isHeaderBackLink: true,
      middleware: [permissions.OWN_SHIPMENT_ACCEPT]
    },
  },
];
