<template>
  <div class="warehouse-info">
    <div class="storageItemInfo-data">
      <div class="warehouse-info__top storageItemInfo-title">
        <h2 class="title-style-small">Общая информация</h2>
        <el-button
          type="primary"
          size="small"
          class="warehouse-info__top-button"
          @click="onGiven"
        >Выдать</el-button>
        <el-button
          type="success"
          size="small"
          :icon="Printer"
          @click="print"
          :disabled="sendingsItem.status != 100"
        >Акт ПП</el-button>
      </div>


      <div class="storageItemInfo-data-info">
        <div class="storageItemInfo-data-info-container">
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Номер отправки</p>
            <p class="storageItemInfo-data-items-right">{{ sendingsItem.number }}</p>
          </div>
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Дата прибытия</p>
            <p class="storageItemInfo-data-items-right">{{ new Date(sendingsItem.dispatch_date).toLocaleDateString() }}</p>
          </div>
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Время прибытия</p>
            <p class="storageItemInfo-data-items-right">{{ sendingsItem.dispatch_time_from || 'Неизвестно' }} - {{ sendingsItem.dispatch_time_to || 'Неизвестно' }}</p>
          </div>
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">ПВЗ</p>
            <p class="storageItemInfo-data-items-right">{{ sendingsItem.point_dst_name }}</p>
          </div>
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Статус</p>
            <p class="storageItemInfo-data-items-right">{{ sendingsStatusNameById(sendingsItem.status) }}</p>
          </div>
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Машина</p>
            <p class="storageItemInfo-data-items-right">{{ sendingsItem.vehicle }}</p>
          </div>
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Водитель</p>
            <p class="storageItemInfo-data-items-right">{{ sendingsItem.driver }}</p>
          </div>
        </div>
        <div class="storageItemInfo-data-info-container">
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Мест</p>
            <p class="storageItemInfo-data-items-right">{{ sendingsItem.number_of_containers }}</p>
          </div>
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Отправлений</p>
            <p class="storageItemInfo-data-items-right">{{ sendingsItem.number_of_shipments }}</p>
          </div>
          <div class="storageItemInfo-data-info-row">
            <p class="storageItemInfo-data-items-left">Общий вес</p>
            <p class="storageItemInfo-data-items-right">{{ sendingsItem.total_weight }} кг</p>
          </div>
        </div>
      </div>
    </div>




    <div class="storageItemInfo-table-container">
      <div class="storage-buttons"  v-if="sendingsItem.status < 100">
        <button
          class="mainButton"
          @click="editSending()"
        >
          Добавить отправления
        </button>
      </div>
      <div class="storage-table">
        <ul class="table-container table-head">
          <li class="table-items-head info-storage-item-shop-width">
            <span>Интернет-магазин</span>
          </li>
          <li class="table-items-head info-storage-item-price-width">
            <span>ID</span>
          </li>
          <li class="table-items-head info-storage-item-price-width">
            <span>Штрихкод</span>
          </li>
          <li class="table-items-head info-storage-item-send-width">
            <span>№ отправления</span>
          </li>
          <li class="table-items-head info-storage-item-price-width">
            <span>Объявленная стоимость</span>
          </li>
          <li class="table-items-head info-storage-item-status-width">
            <span>Статус</span>
          </li>
        </ul>
        <ul
          v-for="item in sendingsItem.shipments"
          :key="item.id"
          class="table-container"
        >
          <li class="table-items info-storage-item-shop-width">
            <span>{{ (item.shop) ? item.shop.name : '-'}}</span>
          </li>

          <li class="table-items info-storage-item-price-width">
            <a :href="`/shipments/${item.id}`">{{ item.id }}</a>
          </li>
          <li class="table-items info-storage-item-price-width">
            <span>{{ item.barcode }}</span>
          </li>
          <li class="table-items info-storage-item-send-width">
            <span>{{ item.number }}</span>
          </li>

          <li class="table-items info-storage-item-price-width">
            <span>{{ item.valuation }} Р</span>
          </li>
          <li class="table-items info-storage-item-status-width">
            <span>{{ statusNameById(item.status) }}</span>
          </li>
        </ul>
      </div>


    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import { Printer } from '@element-plus/icons-vue'
import {ElNotification} from "element-plus";
import { usePrint } from '@/composition/usePrint.js';

export default {
  name: 'WarehouseInfo',
  setup() {
    return {
      ...usePrint()
    }
  },
  data: () => ({
    Printer,
  }),

  computed: {
    ...mapState({
      sendingsItem: state => state.sendings.editItem,
    }),
    ...mapGetters({
      statusNameById: 'shipments/statusNameById',
      sendingsStatusNameById: 'sendings/statusNameById',
    }),
  },

  created() {
    this.initData()
  },

  methods: {
    ...mapActions({
      readItem: 'sendings/readItem',
      accept: 'sendings/accept',
    }),

    editSending() {
      this.$router.push({ name: 'warehouse-edit', params: { id: this.$route.params.id } })
    },
    print() {
      this.exportRegistry(this.$route.params.id);
    },
    async initData() {
      await this.readItem(this.$route.params.id)
    },

    async onGiven() {
      try {
        await this.accept({ id: this.$route.params.id })
        ElNotification({
          title: 'Успешно',
          message: `Отправка №${this.sendingsItem.number} выдана`,
          type: 'success',
        });
        this.$router.push({ name: 'warehouse' });
      } catch (e) {
        ElNotification({
          title: 'Ошибка',
          message: 'Не удалось выдать отправку',
          type: 'error',
        })
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.info-storage-item-id-width{
  width: 60px;
}
.warehouse-info {
  &__top {
    justify-content: flex-start;

    .title-style-small {
      margin-right: auto;
    }
  }

  &__link {
    display: block;
    width: max-content;
    margin-top: 20px;
    text-decoration: none;
    color: #556ee6;
    border-bottom: 1px solid transparent;

    &:hover {
      border-color: #556ee6;
    }
  }
}
</style>
