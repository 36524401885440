import store from "@/store";
import AccessErrorPage from "@/modules/auth/pages/AccessErrorPage";

const accessGuard = {
  accessGuardMiddleware(to) {

    const permissions = to.meta.middleware;
    let isHavePermission = false;

    if (!permissions) {
      return ;
    }

    permissions.forEach((permission) => {
      if (this.checkScope(store.getters['user/currentUser'], permission)) {
        isHavePermission = true;
      }
    })

    if (isHavePermission) {
      return;
    }

    return {
      path: "/AccessErrorPage",
      component: AccessErrorPage,
      name: "AccessErrorPage",
      meta: {
        title: "AccessErrorPage"
      },
    }
  },
  checkScope(user, permission) {
    if (!user) {
      return false;
    }
    for (const user_permission of user.permissions) {
      if (permission === user_permission) {
        return true;
      }
    }

    return false;
  }
}
export default accessGuard;


