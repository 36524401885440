<template>
  <el-dialog
    :model-value="modelValue"
    title="Подтверждение"
    width="350px"
    @close="close"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <el-form
      :model="form"
      ref="form"
      label-position="top"
      class="modal-add-places"
    >
      <el-form-item label="Введите кол-во мест" prop="places">
        <el-input-number
          v-model="form.places"
          controls-position="right"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="$emit('update:modelValue', false)">
          Отменить
        </el-button>
        <el-button type="primary" @click="onSend">
          Отправить
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {ElDialog, ElNotification} from 'element-plus';
import {mapActions} from "vuex";

export default {
  name: "ModalAddPlaces",

  components: { ElDialog },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    freight: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    form: {
      places: '',
    },
  }),

  methods: {
    ...mapActions({
      accept: 'freights/accept',
    }),

    close() {
      this.$refs.form.resetFields();
    },

    async onSend() {
      try {
        const body = {
          id: this.freight.id,
          data: {
            number_of_containers_actual: this.form.places,
          }
        };
        await this.accept(body);

        this.$emit('refresh');
        this.$emit('update:modelValue', false);
      } catch ({ message }) {
        ElNotification({
          title: 'Ошибка',
          message: message,
          type: 'error',
        });
      }
    },
  }
}
</script>
