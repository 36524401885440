<template>
  <li :class="classes">
    <component
      :is="wrap"
      :class="wrapClass"
      @onEdit="$emit('onEdit')"
      @onDelete="$emit('onDelete')"
      @onBtnClick="$emit('onBtnClick')"
      >{{ getValue }}</component
    >
  </li>
</template>

<script>
import Button from "./Button.vue";
import InlineActions from "./InlineActions.vue";

export default {
  components: {
    Button,
    InlineActions,
  },
  props: {
    value: [String, Object, Number],
    isHeader: Boolean,
    // isButton: Boolean,
    cursor: String,
    type: String,
  },
  computed: {
    classes() {
      return {
        "table-items-head": !!this.isHeader,
        "table-items": !this.isHeader,
        "cursored-table-cell": !!this.cursor,
      };
    },
    wrap() {
      let component = "span";
      switch (this.type) {
        case "button":
          component = "Button";
          break;
        case "inline_actions":
          component = "InlineActions";
          break;
        default:
      }
      return component;
    },
    wrapClass() {
      let className = "";
      switch (this.type) {
        case "button":
          className = this.value.classes
            ? this.value.classes
            : `green-button ${this.value.type}-button`;
          break;
        default:
      }

      return className;
    },
    getValue() {
      let title = "";
      switch (this.type) {
        case "button":
          title = this.value.title;
          break;
        default:
          title = this.value;
      }

      return title;
    },
  },
};
</script>

<style lang="scss">
.cursored-table-cell {
  cursor: pointer;
}
</style>
