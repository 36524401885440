<template>
  <div class="simple-pagination">
    <el-button
      :icon="ArrowLeft"
      class="btn-prev"
      :disabled="page === 1"
      @click="this.$emit('prev')"
    >
    </el-button>
    <ul class="el-pager" v-if="showPage">
      <li class="active number">{{ page }}</li>
    </ul>
    <el-button
      :icon="ArrowRight"
      class="btn-next"
      @click="this.$emit('next')"
      :disabled="isLastPage"
    >
    </el-button>
  </div>
</template>

<script>
import {ArrowLeft, ArrowRight} from '@element-plus/icons-vue'
export default {
  name: 'SimplePagination',
  data() {
    return {
      ArrowLeft,
      ArrowRight,
    }
  },
  props: {
    page: {
      default: null,
      type: Number,
    },
    showPage: {
      default: true,
      type: Boolean,
    },
    isLastPage: {
      default: false,
      type: Boolean,
    },
  },
}
</script>
<style scoped>
.simple-pagination {
  white-space: nowrap;
  padding: 2px 5px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.simple-pagination button {
  background: center center no-repeat #ffffff;
  height: 32px;
  line-height: 32px;
}

.simple-pagination ul {
  user-select: none;
  list-style: none;
  display: flex;
  align-items: center;
}

.simple-pagination li {
  padding: 0 4px;
  background: #ffffff;
  font-size: 16px;
  height: 32px;
}

.simple-pagination button:hover, .simple-pagination button:focus {
  color: #556EE6;
}

.simple-pagination button.is-disabled:hover, .simple-pagination button.is-disabled:focus {
  color: #c0c4cc;
}

</style>
