import apiV2Service from "@/api/V2/services/apiV2.service";

const store = {
    namespaced: true,
    actions: {
        getFirstActiveUserNews() {
            return apiV2Service.get(`news/firstActiveUserNews`)
        },
        markNewsAsAcceptedForCurrentUser(context, newsId) {
            return apiV2Service.post(`news/${newsId}/accept`)
        },
        fetch() {
            return apiV2Service.get(`news`);
        },
    },
};
export default store;
