<template>
<div style="padding-top: 20px">
  <el-button @click="showSuccess()" >Показать уведомление об успехе</el-button>
  <el-button @click="showError()">Показать уведомление об ошибке</el-button>
</div>
</template>

<script>
import {ElNotification} from "element-plus";

export default {
  name: "notificationUsage",
  methods: {
    showSuccess() {
      ElNotification({
        title: 'Успешно',
        message: `Произошло что-то хорошее`,
        type: 'success',
      });
    },
    showError() {
      ElNotification({
        title: 'Ошибка',
        message: 'Произошло что-то плохое',
        type: 'error',
      });
    }
  }
}
</script>

<style scoped>

</style>
