import client from './client'
import createCrud from './createCrud'


const { getAll, createItem, readItem, updateItem, deleteItem } = createCrud(client, '/schedules')

export {
    getAll,
    createItem,
    readItem,
    updateItem,
    deleteItem
}
