import apiV2Service from "@/api/V2/services/apiV2.service";

const store = {
    namespaced: true,
    actions: {
        generateUPD(context, franchiseeReportId) {
            return apiV2Service.postBlob(`documents/generateUPD/${franchiseeReportId}`)
        },
        generatePaymentOrder(context, franchiseeReportId) {
            return apiV2Service.postBlob(`documents/generatePaymentOrder/${franchiseeReportId}`)
        },
        downloadFranchiseeReport(context, franchiseeReportId) {
            return apiV2Service.postBlob(`documents/downloadFranchiseeReport/${franchiseeReportId}`)
        },
        downloadPpAct(context, uuid) {
            return apiV2Service.postBlob(`documents/downloadPpAct/${uuid}`)
        },
        downloadKaliningradInvoice(context, uuid) {
            return apiV2Service.postBlob(`documents/downloadKaliningradInvoice/${uuid}`)
        },
        downloadKaliningradContract(context, uuid) {
            return apiV2Service.postBlob(`documents/downloadKaliningradContract/${uuid}`)
        },
        downloadUseTareReport(context, data) {
            return apiV2Service.postBlob(`documents/downloadExcelUseTareReport`, data);
        },
        downloadInventoryReport(context, inventoryId) {
          return apiV2Service.postBlob(`documents/downloadInventoryReport/${inventoryId}`);
        },
        downloadRatingFile(context, ratingId) {
          return apiV2Service.postBlob(`documents/downloadRatingFile/${ratingId}`);
        }
    },
};
export default store;
