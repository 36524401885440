<template>
  <div class="context_menu">
    <div class="storageCreate-context-container">
      <div class="send-PVZ storageCreate-items">
        <h2 class="title-style-main">
          Отправления на ПВЗ
        </h2>
        <div class="container-search">
          <form class="sort-items">
            <span class="custom-search">
              <button class="invise-searchButton"/>
              <input
                  required
                  class="custom-search-input form-control"
                  placeholder="Поиск"
                  type="search"
              >
              <button
                  onclick="clearSearch()"
                  type="button"
                  class="close-icon"
              />
            </span>
          </form>
        </div>
        <create-shipment-table
            :items="itemsAll"
            :show-checkbox="true"
            @toggle-selected="toggleSelectedItemsAll"
        />
        <div class="storage-buttons">
          <button
              class="mainButton"
              @click="addSelected"
          >
            Добавить
          </button>
        </div>
      </div>
      <div class="ready-to-send  storageCreate-items">
        <h2 class="title-style-main">
          Отправления к отправке
        </h2>
        <div class="container-search storageCreate-custom-margin">
          <form class="sort-items">
            <span class="custom-search">
              <button class="invise-searchButton"/>
              <input
                  required
                  class="custom-search-input form-control"
                  placeholder="Поиск"
                  type="search"
              >
              <button
                  onclick="clearSearchTwo()"
                  type="button"
                  class="close-icon"
              />
            </span>
          </form>
        </div>
        <create-shipment-table
            :items="selectedItems"
            :show-checkbox="true"

            @toggle-selected="toggleSelected"
        />
        <div class="storage-buttons">
          <button
              :disabled="selectedItems.length === 0"
              class="mainButton"
              @click="toggleCreateModal(true)"
          >
            Сформировать
          </button>
        </div>
      </div>
    </div>
    <ModalCreate
        v-if="isCreateModalShown"
        @onClose="toggleCreateModal(false)"
    >
      <CreateFreight :is-submit-freight="false" @add-freight="submit"/>
    </ModalCreate>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {ElNotification} from 'element-plus'
import CreateShipmentTable from '@/components/warehouse/CreateShipmentTable';
import ModalCreate from '@/components/modal/ModalCreate';
import CreateFreight from '@/components/freights/CreateFreight';
import {SHIPMENT_ACCEPTED_FROM_SENDER, SHIPMENT_CANCELLED} from "@/constants";

export default {
  name: 'CreateFreightContent',

  components: {
    CreateShipmentTable,
    ModalCreate,
    CreateFreight,
  },

  data() {
    return {
      itemsAll: [],
      selectedItems: [],
      isCreateModalShown: false,
    };
  },

  computed: {
    ...mapState({
      list: state => state.apiV2ShipmentStore.searchResult,
    })
  },

  created() {
    window.addEventListener('scanned', this.selectFromScanner);
    this.getItems();
  },
  beforeUnmount() {
    window.removeEventListener('scanned', this.selectFromScanner);
  },
  methods: {
    ...mapActions({
      getList: 'apiV2ShipmentStore/forFreight',
      save: 'freights/save',
    }),

    selectFromScanner(e) {
      const {id} = JSON.parse(e.detail);
      const item = this.list.find(item => {
        return (item.id == id || (item.partner_barcode && item.partner_barcode == id))
      });

      if (item) {
        if (item.status == SHIPMENT_ACCEPTED_FROM_SENDER || item.status == SHIPMENT_CANCELLED) {
          let index = this.itemsAll.findIndex(el => el.id == item.id);

          if (!~index) return;

          this.toggleSelectedItemsAll(item, index);

          ElNotification({
            title: 'Успешно',
            message: 'Отправление успешно отсканировано',
            type: 'success',
          });
        } else {
          ElNotification({
            title: 'Ошибка',
            message: 'Отправление не может быть добавлено',
            type: 'error',
          });
        }
      } else {
        ElNotification({
          title: 'Ошибка',
          message: 'Отправления с таким ID не существует',
          type: 'error',
        });
      }
    },

    async getItems() {
      await this.getList().then((result) => {
        this.itemsAll = result.map(item => ({
          ...item,
          selected: false,
        }));
      });
    },

    toggleSelectedItemsAll(item, index) {
      this.itemsAll[index].selected = !item.selected
    },

    toggleSelected(item, index) {
      if (!item.selected) {
        this.selectedItems.push(item)
      } else {
        this.selectedItems.splice(index, 1)
      }
    },

    addSelected() {
      this.selectedItems = this.itemsAll.filter(item => item.selected)
      this.itemsAll = this.itemsAll.filter(item => !item.selected)
    },

    toggleCreateModal(val) {
      this.isCreateModalShown = val;
    },

    async submit(freight) {
      // if (this.itemsAll.length === this.selectedItems.length) {
      //   this.saveFreight()
      //   return
      // }

      // try {
      // await ElMessageBox.confirm(
      //     'Остались неотсканированные отправления.',
      //     'Внимание',
      //     {
      //         confirmButtonText: 'Завершить',
      //         cancelButtonText: 'Продолжить',
      //         type: 'warning',
      //     }
      // );
      // this.saveFreight();
      // eslint-disable-next-line no-empty
      // } catch (e) {
      // }

      this.saveFreight(freight);
    },

    async saveFreight(freight) {
      const data = {
        ...freight,
        shipments: this.selectedItems.map(item => item.id),
        number_of_shipments: this.selectedItems.length,
      };
      try {
        await this.save(data)
        ElNotification({
          title: 'Успешно',
          message: 'Перевозка была успешно создана',
          type: 'success',
        })
        this.$router.push({name: 'freights'})
        this.toggleCreateModal(false)
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    }
  }
};
</script>

<style lang='scss'>
</style>
