import client from '@/api/client';


class apiservice {
  get(url, data = {}) {
    return client.get(url, data).then(response => {
      return this.responseSuccess(url, response)
    }, (error) => {
      return this.responseError(url, error);
    });
  }

  put(url, data = {}) {
    return client.put(url, data).then(response => {
      return this.responseSuccess(url, response)
    }).catch((error) => {
      return this.responseError(url, error);
    });
  }

  post(url, data = {}) {
    return client.post(url, data).then(response => {
      return this.responseSuccess(url, response)
    }).catch((error) => {
      return this.responseError(url, error);
    });
  }

  postBlob(url, data = {}) {
    return client.post(url, data, {
      responseType: 'blob',
    }).then(response => {
      return this.responseSuccess(url, response, true)
    }).catch((error) => {
      return this.responseError(url, error, true);
    });
  }

  responseSuccess(url, response, isExcel) {
    if (!response) {
      return;
    }

    if (isExcel) {
      return Promise.resolve(response);
    }

    return Promise.resolve(response.data);
  }

  async responseError(url, error, isExcel) {
    if (!error) {
      return;
    }

    let response = {
      status: null,
      message: 'Произошла ошибка',
      errors: []
    }


    if (error.response) {
      response.status = error.response.status;
      response.message = error.response.data.message;
      response.errors = error.response.data.errors ?? [];
    }

    if (isExcel && !response.message) {
      const data = JSON.parse(await error.response.data.text());
      response.message = data.message;
    }

    return Promise.reject(response);
  }
}

export default new apiservice();
