<script>
import ModalRefuse from "@/components/modal/ModalRefuse";

export default {
  name: 'ModalRefuseAccept',
  extends: ModalRefuse,

  methods: {
    getReasonName() {
      return 'GOODS_CHECK_FAILED';
    },
    sendReason() {
      this.$store.dispatch('shipments/cancelInsteadOfAccept',
        {
          uuid: this.uuid,
          reasonId: this.getSelectedValue()
        }
      ).then(() => {
        this.close();
      }, (error) => {
        console.log(error)
      });
    },
  }
}
</script>
<style scoped>
b {
  font-weight: 700;
}

.modal__wrapper {
  display: flex;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

form {
  max-height: 95vh;
  background: #fff;
  width: 640px;
  margin: auto;
  box-shadow: 2px 2px 12px rgb(42 48 66 / 11%);
  border-radius: 10px;
}

.w-full {
  width: 100%;
}
</style>
