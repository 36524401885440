<template>
  <component :is="component" />
</template>

<script>
import Admin from "./Admin.vue";
import Franchisee from "./Franchisee.vue";

export default {
  name: "Report",
  computed: {
    component() {
      const role = this.$store.getters["user/role_id"];
      const isAdmin = role == 1 || role == 0;

      return isAdmin ? Admin : Franchisee;
    },
  },
};
</script>
