<template>
  <VueSelect
      :closeOnSelect="true"
      :clearSearchOnSelect="true"
      :autoscroll="true"
      :deselectFromDropdown="true"
      :loading="isLoading"
      :multiple="multiple"
      :placeholder="placeholder"
      :options="options"
      :label="label"
      :reduce="reduce"
      v-model="selectedIds"
      @update:modelValue="onChange()"
      class="form-control"
      :searchable="true"
      :autocomplete="false"
  >
  </VueSelect>
</template>

<script>
import VueSelect from "vue-select";
import {ElNotification} from "element-plus";

export default {
  name: "PartnerSelect",
  components: {
    VueSelect
  },
  data() {
    return {
      isLoading: false,
      label: "label",
      value: [],
      reduce: item => item.value,
      options: [],
      selectedIds: null
    }
  },
  emits: ['change'],
  props: {
    placeholder: {
      type: String,
      default: 'Выберите партнера'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Array, null],
      default: null,
    },
    pointId: {
      type: [String, null],
      default: null
    },
  },
  methods: {
    onChange() {
      let value = [];
      if (this.multiple) {
        value = Object.assign([], this.selectedIds);
      } else {
        value = parseInt(this.selectedIds);
        value = isNaN(value) ? [] : [value];
      }

      this.$emit('change', value);
    },
    async getItems() {
      if (this.pointId) {
        await this.$store.dispatch("points/readItem", this.pointId).then((result) => {
          this.options = [];

          if ((this.selectedIds !== null ) && !result.data.active_partners.find(partner => partner.id === this.selectedIds)) {
            this.selectedIds = null

            ElNotification({
              title: 'Предупреждение',
              message: 'У пользователя был изменен Партнер',
              type: "warning"
            })
          }

          result.data.active_partners.map((item) => {
            this.options.push({
              label: item.name,
              value: item.id,
            })
          });
        });
      } else {
        this.$store.state.partners.list.map((item) => {
          this.options.push({
            label: item.name,
            value: item.id,
          })
        });
      }
    }
  },
  watch: {
    modelValue: function(newValue) {
      this.selectedIds = newValue;
    },

    pointId: function() {
      this.getItems();
    },
  },
  created() {
    this.isLoading = true;

    this.getItems();
    this.selectedIds = this.modelValue;

    this.isLoading = false;
  }
}
</script>

<style scoped>
    ::v-deep(.vgt-wrap__footer .footer__navigation__page-info__current-entry) {
      width: 50px;
    }

    ::v-deep(.vs__search) {
      font-size: 12px;
      padding-left: 0;
    }

    ::v-deep(.vs__selected-options) {
      padding: 6px 16px;
    }

    ::v-deep(.vs__selected) {
      padding-left: 0;
    }

    .form-control {
      border: 0;

      padding: 0;
      color: #495057;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
    }
</style>
