<template>
  <create-sending-content />
</template>

<script>
import CreateSendingContent from "@/components/warehouse/CreateSendingContent";

export default {
  name: 'CreateShipment',

  components: {
    CreateSendingContent,
  }
};
</script>
