import Clients from "./Clients.vue";

export const clientsRoutes = [
  {
    path: "/clients",
    component: Clients,
    name: "clients",
    meta: { title: "Данные физлиц" },
  },
];
