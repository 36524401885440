import Settings from "./Settings.vue";

export const settingsRoutes = [
  {
    path: "/settings",
    component: Settings,
    name: "settings",
    meta: { title: "Настройки системы" },
  },
];
