<template>
  <div v-if="returnAct" class="mt-5">
    <div class="d-flex justify-content-start">
      <button
        class="edit-worker-modal-button-save k-mr-3"
        type="button"
        @click="finishAssemblingReturnAct"
        :disabled="returnAct.status !== RETURN_ACT_STATUS_IN_ASSEMBLY"
      >
        Завершить сборку
      </button>
      <button
        class="edit-worker-modal-button-save k-mr-3"
        type="button"
        @click="printAssemblyList"
      >
        Печать списка сборки
      </button>

      <button
        class="edit-worker-modal-button-save k-mr-3"
        type="button"
        @click="printLabel"
        :disabled="returnAct.status === RETURN_ACT_STATUS_GIVEN_TO_COURIER"
      >
        Печать этикетки
      </button>
    </div>

    <div class="mt-4" v-if="returnAct.status === RETURN_ACT_STATUS_READY_FOR_SHIPMENT">
      <p class="alert alert-success">
        Напечатайте этикетку и наклейте ее на грузовое место.<br>
        Напечатайте возвратный акт и приложите его к возврату
      </p>

    </div>

    <div class="alert alert-secondary mt-4"
         style="font-variant-ligatures: no-contextual;padding-top: 10px" role="alert">
      <p v-if="returnAct.status === RETURN_ACT_STATUS_IN_ASSEMBLY">
        Отсканируйте заказ и добавьте его в собираемое грузовое место
      </p>

      <p class="mt-2">
        Возврат акт №{{ returnAct.id }} / {{ returnAct.number }}
      </p>

      <p class="mt-2">
        Статус: {{ returnAct.status_name }}
      </p>
    </div>

    <div v-if="returnAct.status === RETURN_ACT_STATUS_IN_ASSEMBLY">
      <div class="container-search d-flex justify-content-start">
        <form class="sort-items" @submit.prevent="scanShipment"
        >
              <span class="custom-search">
                <button class="invise-searchButton"/>
                <input
                  v-model="barcode"
                  ref="setFocusScanShipment"

                  class="custom-search-input form-control"
                  placeholder="Сканировать"
                  type="search"
                  autofocus
                >
                <button
                  @click="clearBarcode()"
                  type="button"
                  class="close-icon"
                />
              </span>
        </form>
      </div>
    </div>

    <div class="d-flex mt-4">
      <div class="col-6">
        <div class="col-11">
          <h2 class="title_column_block">Ожидают добавления в акт</h2>

          <div class="mt-4">
            <Grid
              ref="waitingAddedInActGrid"
              :columns="getWaitingAddedInActColumns"
              :data-items="remainingShipmentsInAssembly"
            >
              <GridNoRecords>Записей не найдено</GridNoRecords>
            </Grid>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="col-11">
          <h2 class="title_column_block">Добавлены в акт</h2>

          <div class="mt-4">
            <Grid
              ref="addedInActGrid"
              :columns="getAddedInActColumn"
              :data-items="shipmentsAddedToAct"
            >
              <GridNoRecords>Записей не найдено</GridNoRecords>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const baseURL = process.env.VUE_APP_API_ROOT;

import { Grid, GridNoRecords } from '@progress/kendo-vue-grid';
import {useSound} from "@/composition/useSound";
import {useBarcodeScan} from "@/composition/useBarcodeScan";
import {ElMessageBox, ElNotification} from "element-plus";
import {
  RETURN_ACT_STATUS_GIVEN_TO_COURIER,
  RETURN_ACT_STATUS_IN_ASSEMBLY,
  RETURN_ACT_STATUS_READY_FOR_SHIPMENT
} from "@/constants";

export default {
  name: "ReturnActAssembling",
  components: {
    Grid,
    GridNoRecords
  },
  created() {
    this.getReturnAct();
  },
  setup() {
    return {
      ...useSound(),
      ...useBarcodeScan(),
    }
  },
  mounted() {
    console.log('subscribe to event')
    window.addEventListener('barcodescan', this.fromScanner);
  },
  unmounted() {
    console.log('unsubscribe to event')

    window.removeEventListener('barcodescan', this.fromScanner);
  },
  data() {
    return {
      returnAct: null,
      remainingShipmentsInAssembly: null,
      shipmentsAddedToAct: null,
      barcode: null,

      RETURN_ACT_STATUS_IN_ASSEMBLY: RETURN_ACT_STATUS_IN_ASSEMBLY,
      RETURN_ACT_STATUS_READY_FOR_SHIPMENT: RETURN_ACT_STATUS_READY_FOR_SHIPMENT,
      RETURN_ACT_STATUS_GIVEN_TO_COURIER: RETURN_ACT_STATUS_GIVEN_TO_COURIER
    }
  },
  methods: {
    getReturnAct() {
      this.$store.dispatch('apiV2ReturnActStore/show', this.$route.params.id).then((returnAct) => {
        this.returnAct = returnAct;
        this.shipmentsAddedToAct = returnAct.added_in_act_shipments;
        this.remainingShipmentsInAssembly = returnAct.remaining_shipments_in_assembly;
      });
    },
    clearBarcode() {
      this.barcode = null;
    },
    async fromScanner(e) {
      console.log(e);
      this.barcode = e.detail;

      await this.scanShipment();

    },
    async scanShipment() {
      this.$store.dispatch('apiV2ReturnActStore/scanShipmentForReturnAct', {
        return_act_id: this.returnAct.id,
        barcode: this.barcode
      }).then((result) => {
        console.log(result);

        this.playSound(result);

        this.getReturnAct();
      });
    },
    playSound(result) {
      switch (result.audio) {
        case "smallError":
          this.playNegativeSound()

          ElNotification({
            title: 'Ошибка',
            message: result.message,
            type: 'error',
          });
          break;
        default:
          this.playPositiveSound()
      }
    },
    async finishAssemblingReturnAct() {
      await ElMessageBox.confirm(
        'Завершить сборку?',
        {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          cancelButtonClass: 'el-button--info'
        },
      )

      this.$store.dispatch('apiV2ReturnActStore/finishAssemblyReturnAct', {
        return_act_id: this.returnAct.id,
      }).then(() => {
        ElNotification({
          title: 'Успешно',
          message: 'Сборка акта завершена',
          type: 'success',
        });

        this.getReturnAct();
      });
    },
    printLabel() {
      window.open(this.printLabelLink, '_blank');
    },
    printAssemblyList() {
      window.open(this.printAssemblyListLink, '_blank');
    }
  },
  computed: {
    getWaitingAddedInActColumns() {
      return [
        {
          field: 'ceil.name',
          title: 'Полка'
        },
        {
          field: 'barcode',
          title: 'Штрихкод',
        },
        {
          field: 'number',
          title: 'Номер',
        },
      ]
    },
    getAddedInActColumn() {
      return [
        {
          field: 'barcode',
          title: 'Штрихкод',
        },
        {
          field: 'number',
          title: 'Номер',
        },
      ]
    },
    printLabelLink() {
      const token = window.localStorage.getItem("auth") || window.sessionStorage.getItem("auth")
      return baseURL + `/pdf/returnLabel/${this.returnAct.id}?token=${token}`;
    },

    printAssemblyListLink() {
      const token = window.localStorage.getItem("auth") || window.sessionStorage.getItem("auth")
      return baseURL + `/excel/returnAssemblyList/${this.returnAct.id}?token=${token}`;
    }
  },
}
</script>

<style scoped>
  .title_column_block {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }
</style>
