import {
  getAll,
} from "src/api/ceils";

import {isDataUpdateRequired} from "@/store/helper";
import indexDB, {STORAGE_CEILS} from '@/helpers/indexdb'

// Период обновления в минутах
const TIME_TO_REFRESH = 60;
const store = {
  namespaced: true,
  state() {
    return {
      list: [],
      listLastUpdate: null,
      pagination: {
        totalPages: 1,
      },
      newFreight: {},
      errors: {
        getList: {},
        saveFreight: {},
      },
      editItem: {},
    };
  },
  getters: {
    itemById: ({ list }) => (id) => {
      return list.find(item => item.id == id) || { name: '-' };
    },
    items: ({ list }) => {
      return list;
    },
  },
  actions: {
    async getList(context, params = {}) {
      context.commit("SET_ERRORS", {
        key: "getList",
        val: {},
      });

      if (context.state.listLastUpdate && context.state.list.length > 0 && !isDataUpdateRequired(context.state.listLastUpdate)) {
        return;
      }

      const interval = await indexDB.lastUpdateInterval(STORAGE_CEILS);

      if (interval < TIME_TO_REFRESH && !params.force) {
        indexDB.getItems(STORAGE_CEILS).then((result) => {
          context.commit("SET_LIST", result);
        });

        return;
      }

      try {
        const resp = await getAll({});
        context.commit("SET_LIST", resp.data && resp.data.ceils );
        indexDB.saveItems(STORAGE_CEILS, resp.data.ceils)
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "getList",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "getList",
            val: { error: "Ошибка " + e.status },
          });
        }
      }
      context.commit("SET_LIST_LAST_UPDATE", new Date().getTime());
    },
  },
  mutations: {
    SET_LIST_LAST_UPDATE(state, payload) {
      state.listLastUpdate = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination[payload.key] = payload.val;
    },
    SET_ERRORS(state, error) {
      state.errors[error.key] = error.val;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_ITEM(state, item) {
      state.editItem = item
    }
  },
};
export default store;
