<template>
  <el-form
    novalidate
    @input="$emit('input', $event.target.value)"
    label-position="top"
    class="mt-4"
    @submit.prevent="$emit('submit', searchForm)"
  >
    <el-row>
      <el-col :span="4">
        <el-form-item label="Начало периода">
          <el-date-picker
            class="w-100"
            v-model="searchForm.date_start"
            type="date"
            placeholder="Начало периода"
            format="DD.MM.YYYY"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item label="Конец периода">
          <el-date-picker
            class="w-100"
            v-model="searchForm.date_end"
            type="date"
            placeholder="Конец периода"
            format="DD.MM.YYYY"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
      </el-col>

      <el-col :span="7">
        <el-form-item label="Пвз">
          <PointSelect
            @change="onChangePointId($event)"
            :multiple="true"
          ></PointSelect>
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-button @click="recalculateFranchTariffs" style="margin-top:20px;margin-left:10px" type="success">
          Пересчитать
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import PointSelect from "@/components/selectors/pointSelect/PointSelect";
import {recalculateFranchTariffs} from "@/api/shipments";
import {ElNotification} from "element-plus";


export default {
  name: "ShipmentRecalculate",
  components: {
    PointSelect,
  },
  data() {
    return {
      searchForm: {
        date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString('Ru-ru').split('.').reverse().join('-'),
        date_end: new Date().toLocaleDateString('Ru-ru').split('.').reverse().join('-'),
        point_ids: []
      }
    }
  },

  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    async recalculateFranchTariffs() {
      let response = await recalculateFranchTariffs(this.searchForm)
       ElNotification({
        title: 'Данные отправлены',
        message: response.data.message,
        type: 'success',
      });
    },

    onChangePointId(event) {
      this.searchForm.point_ids = event.length > 0 ? event : [];
    },
  },
}
</script>

<style scoped>
::v-deep(.vue-select__xmail-style .vs__selected-options) {
  padding: 2px 16px;
}
</style>
