<template>
  <component :is="component" />
</template>

<script>


export default {
  name: "Home",
  computed: {
    component() {
      return false;
    },
  },
};
</script>
