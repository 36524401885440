import {getAvailableCodeTypes} from "@/api/V2/shop";

const store = {
  namespaced: true,
  state() {
    return {
      types: null,
    }
  },
  actions: {
    async getAvailableCodeTypes(context, filters) {
      const {data} = await getAvailableCodeTypes(filters.shop_id);
      context.state.types = data;
    },
  }
}

export default store;
