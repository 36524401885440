<template>
  <div class="modal__wrapper" @click.self="close">
    <form class="p-4" @submit.prevent="sendReason">
      <el-select v-model="value" placeholder="Выбрать причину" class="mb-3 w-full" size="large">
        <el-option
          v-for="item in reasonsList"
          :key="item.id"
          :label="item.reason"
          :value="item.id"
        />
      </el-select>
      <div class="mb-3" v-if="value">
        <b>Причина:</b> {{ this.reasonItem.reason }}
      </div>
      <div class="flex">
        <button class="el-button el-button--primary el-button--default" :disabled="!value">Отказать</button>
        <button type="button" class="el-button el-button--default" @click="close">Закрыть</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ModalRefuse',
  props: [
    'from',
    'has-parent',
    'uuid'
  ],
  computed: {

    reasonItem() {
      return (this.reasonsList.find(item => item.id == this.value) || {});
    },
    reasonsList() {
      return this.$store.state.refuse.reasonsList;
    }
  },
  data() {
    return {
      value: ''
    }
  },

  mounted() {
    this.getReasons();
  },
  methods: {
    getSelectedValue() {
      console.log('get selected value')
       return this.value
    },
    getReasonName() {

      if (this.hasParent) {
        return  'RETURN_REFUSAL_BY_SENDER';
      } else {
        return 'REFUSAL_BY_RECEIVER';
      }

    },
    getReasons() {
      this.$store.dispatch('refuse/getReasons', this.getReasonName());
    },
    sendReason() {
      if (!this.value) return;
      this.$store.dispatch('shipments/cancelled', {
        id: +this.$route.params.id,
        reasonId: +this.value
      }).then(() => {
        this.close();
      }, (error) => {
        console.log(error)
      });
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
b {
  font-weight: 700;
}

.modal__wrapper {
  display: flex;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

form {
  max-height: 95vh;
  background: #fff;
  width: 640px;
  margin: auto;
  box-shadow: 2px 2px 12px rgb(42 48 66 / 11%);
  border-radius: 10px;
}

.w-full {
  width: 100%;
}
</style>
