import client from "./client";

async function getReports(from, to) {
  return await client.get('/reports_front/franchisee', {
    params: {
        date_from: from,
        date_to: to
    }
  });
}

export {
  getReports
};
