<template>
  <Toolbar>
    <template v-slot:filter>
      <Search
        v-model:value="filters.search"
        :placeholder="titles.search"
        @search="getList({ page: 1 })"
      />
      <Select
        :options="statusSelect"
        v-model:value="filters.status"
        :placeholder="titles.status"
        @search="getList({ page: 1 })"
      />
      <Select
        :options="imSelect"
        v-model:value="filters.im"
        :placeholder="titles.im"
        @search="getList({ page: 1 })"
      />
    </template>
  </Toolbar>
  <Grid
    :head="tableHead"
    :data="clients"
    :cellClass="cellClass"
    v-if="clients.length > 0"
  />
  <div v-else-if="getListLoading" class="pt-3 pb-3">Загрузка...</div>
  <div v-else class="pt-3 pb-3">Нет информации</div>
  <Pages
    v-if="clients.length"
    :totalPages="totalPages"
    :currentPage="filters.page"
    :last="totalPages"
    :total="total"
    :perPage="perPage"
    @change="switchPage"
  />
</template>

<script>
import Toolbar, { Search, Select } from "src/components/toolbar";
import Grid from "src/components/grid";
import Pages from "src/components/Pages.vue";

export default {
  name: "Clients",
  components: {
    Toolbar,
    Search,
    Select,
    Grid,
    Pages,
  },
  mounted() {
    this.getList();
  },
  data() {
    return {
      filters: {
        page: 1,
        search: "",
        status: -1,
        im: -1,
      },
      titles: {
        search: "Поиск",
        status: "Все статусы",
        im: "Все ИМ",
      },
      statusSelect: [
        { value: 0, title: "Активный" },
        { value: 1, title: "Заблокирован" },
      ],
      imSelect: [
        { value: 0, title: "ИМ 1" },
        { value: 1, title: "ИМ 2" },
      ],
      tableHead: {
        id: "ID",
        registration_date: "Дата",
        fio: "ФИО",
        passport: "Паспорт",
        passport_num: "Серия и номер паспорта",
        phone_primary: "Телефон",
        email_adress: "Email",
        country: "Страна",
        city: "Город",
      },
      getListLoading: true,
    };
  },
  computed: {
    totalPages() {
      return this.$store.state.clients.pagination.totalPages;
    },
    total() {
      return this.$store.state.clients.pagination.totalItems;
    },
    perPage() {
      return this.$store.state.clients.pagination.perPage;
    }, 
    clients() {
      const clientsList = this.$store.state.clients.list;
      return clientsList.map((client) => {
        const {
          id,
          registration_date,
          first_name,
          middle_name,
          last_name,
          phone_primary,
          email_adress,
        } = client;

        return {
          id,
          registration_date,
          fio: `${first_name} ${middle_name} ${last_name}`,
          passport: "",
          passport_num: "",
          phone_primary,
          email_adress,
          country: "",
          city: "",
        };
      });
    },
  },
  methods: {
    async getList(opts) {
      this.filters = { ...this.filters, ...opts };
      this.getListLoading = true;
      await this.$store.dispatch("clients/getList", this.filters);
      this.getListLoading = false;
    },
    cellClass(name) {
      return `users-item users-item_${name}`;
    },
    async switchPage(page) {
      this.filters.page = page;
      await this.getList();
    },
  },
};
</script>
