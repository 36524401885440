<template>
  <div v-if="!isMobile" class="sideBar">
    <div class="sideBar__logo">
      <router-link to="/"
      ><img src="../../assets/img/logo_icon.svg" alt=""
      /></router-link>
    </div>
    <div class="sideBar__components">
      <ul class="sideBar__list">
        <NavigationGroup
          v-for="(item, key) in this.getGuestLinks"
          v-bind:key="key"
          :item="item"
        />
      </ul>
    </div>
  </div>
  <div class="header">
    <nav class="navbar">
      <div class="header-title">
        <a
          class="navbar-brand"
          href="javascript:void(0)"
          @click.prevent="onTitleClick"
        >{{ title }}</a
        >
      </div>
    </nav>
  </div>
  <div>
    <acceptance-contract-modal></acceptance-contract-modal>
    <router-view></router-view>
  </div>
</template>

<script>
import NavigationGroup from "@/components/sidebar/NavigationGroup";

export default {
  name: "BaseTemplateFrontForRegistrationMobile",
  components: {
    NavigationGroup
  },
  computed: {
    title() {
      return this.$store.state.title;
    },
    isMobile() {
      return this.$route.query.is_mobile;
    }
  }
}
</script>

<style scoped>

</style>
