<template>
  <el-table :data="history">
    <el-table-column prop="status" label="Статус" width="160px">
      <template #default="scope">
        {{ statusById(scope.row.status) }}
      </template>
    </el-table-column>
    <el-table-column prop="created_by" label="Кем создано" width="200px"/>
    <el-table-column prop="status_date" label="Дата" width="160px">
      <template #default="scope">
        {{ dateFormat(scope.row.status_date) }}
      </template>
    </el-table-column>
    <el-table-column prop="comment" label="Комментарий">
      <template #default="scope">
        {{ getComment(scope.row.comment) }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import dateFormat from "dateformat";
import {SHIPMENT_HISTORY_DATE_FORMAT} from "src/constants";

export default {
  name: "ShipmentHistory",
  props: {
    shipment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    history() {
      return this.$store.state.shipments.history;
    },
  },
  created() {
    this.$store.dispatch('shipments/getHistory', this.shipment.id);
  },
  methods: {
    statusById(id) {
      return this.$store.getters["shipments/statusNameById"](id);
    },
    dateFormat(date) {
      return dateFormat(date, SHIPMENT_HISTORY_DATE_FORMAT);
    },
    getComment(comment) {
      const jsonIndex = comment.indexOf('{');

      if (jsonIndex >= 0) {
        const jsonObject = comment.slice(jsonIndex)

        return `${comment.slice(0, jsonIndex)} ${JSON.stringify(JSON.parse(jsonObject))}`
      }

      return comment
    }
  }
};
</script>
