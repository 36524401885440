<template>
  <div class="context_menu">
    <div class="personal-information style-profile-all">
      <h2>Основная информация</h2>
      <div class="personal-information-flex-container">
        <div class="personal-information-item">
          <h3>ФИО</h3>
          <input class="form-control style-profile-all-input" type="text" value="Иванов Денис Петрович" disabled>
        </div>
        <div class="personal-information-item">
          <h3>Номер телефона</h3>
          <input class="form-control style-profile-all-input" type="tel" value="+7 (909) 111-11-11" disabled>
        </div>
        <div class="personal-information-item">
          <h3>Серия и номер паспорта</h3>
          <input class="form-control style-profile-all-input" type="text" value="1234 566678" disabled>
        </div>
        <div class="personal-information-item">
          <h3>Email</h3>
          <input class="form-control style-profile-all-input" type="text" value="Email@gmail.com" disabled>
        </div>
      </div>
      <p>Для смены пароля и обновления данных обратитесь к вашему территориальному менеджеру</p>
    </div>
    <div class="personal-requisites style-profile-all">
      <h2>Реквизиты</h2>
      <div class="personal-requisites-flex-container">
        <div class="personal-requisites-col">
          <div>
            <h3>Договор</h3>
            <input class="form-control style-profile-all-input" type="text" value="77/092015" disabled>
          </div>
          <div>
            <h3>Дата договора</h3>
            <input class="form-control style-profile-all-input" type="text" value="12.10.21" disabled>
          </div>
          <div>
            <h3>Организация</h3>
            <input class="form-control style-profile-all-input" type="text" value="Организация" disabled>
          </div>
        </div>
        <div class="personal-requisites-col">
          <div>
            <h3>ИНН</h3>
            <input class="form-control style-profile-all-input" type="text" value="123456789023" disabled>
          </div>
          <div>
            <h3>КПП</h3>
            <input class="form-control style-profile-all-input" type="text" value="246401001" disabled>
          </div>
          <div>
            <h3>ОГРН</h3>
            <div class="personal-requisites-group-input">
              <input class="form-control style-profile-all-input" type="text" value=" 391405656623" disabled>
              <p class="personal-requisites-small-text">От</p>
              <input class="form-control style-profile-all-input" type="text" value=" 22.10.2019" disabled>
            </div>
          </div>
        </div>
        <div class="personal-requisites-col">
          <div>
            <h3>Юридический адрес</h3>
            <p class="p-input style-profile-all-input">
              660064, Россия, Красноярский край, г. Красноярск, ул. Капитанская, д.10, кв.172
            </p>
          </div>
          <div>
            <h3>Почтовый адрес</h3>
            <p class="p-input style-profile-all-input">
              660064, Россия, Красноярский край, г. Красноярск, ул. Капитанская, д.10, кв.172
            </p>
          </div>
        </div>
      </div>

    </div>
    <div class="personal-bank-info style-profile-all">
      <h2>Банковские реквизиты</h2>
      <div class="personal-bank-item">
        <h3>Банк</h3>
        <input class="form-control style-profile-all-input" type="text" value='АО "Банк"' disabled>
      </div>
      <div class="personal-bank-item">
        <h3>Р/С</h3>
        <input class="form-control style-profile-all-input" type="text" value="Р/С" disabled>
      </div>
      <div class="personal-bank-item">
        <h3>К/С</h3>
        <input class="form-control style-profile-all-input" type="text" value="40702810697570000119" disabled>
      </div>
      <div class="personal-bank-item">
        <h3>БИК</h3>
        <input class="form-control style-profile-all-input" type="text" value="044525256" disabled>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile",
  components: {},
};
</script>
