import apiV2Service from "@/api/V2/services/apiV2.service";

const store = {
  namespaced: true,
  actions: {
    index(context, data) {
      return apiV2Service.post('inventory', data)
    },

    create(context, data) {
      return apiV2Service.post('inventory/create', data)
    },

    startInventory(context, inventoryId) {
      return apiV2Service.post(`inventory/startInventory/${inventoryId}`)
    },

    finishInventory(context, inventoryId) {
      return apiV2Service.put(`inventory/finishInventory/${inventoryId}`)
    },

    cancelInventory(context, inventoryId) {
      return apiV2Service.put(`inventory/cancelInventory/${inventoryId}`)
    },

    getActiveInventory() {
      return apiV2Service.get(`inventory/getActiveInventory`)
    }
  },
};
export default store;
