import apiService from "@/api/V2/services/api.service";

const getRequestRegistrationCommentsByRequestId = (requestID) => {
  return apiService.get(`/v2/request-registration-user/getRequestRegistrationCommentsByRequestId/`+requestID)
};

const getPhotoTypes = () => {
  return apiService.get(`/v2/partner-photo/getPhotoTypes`)
};

const getPhotoTypesByRequestId = (requestId) => {
  return apiService.get(`/v2/partner-photo/getPhotoTypesByRequestId/`+requestId)
};

const uploadPartnerPhoto = (data) => {
  return apiService.post(`/v2/partner-photo/store`, data)
};

const deletePartnerPhoto = (data) => {
  return apiService.post(`/v2/partner-photo/delete`, data)
};

export {
  getPhotoTypes,
  getPhotoTypesByRequestId,
  getRequestRegistrationCommentsByRequestId,
  uploadPartnerPhoto,
  deletePartnerPhoto
}
