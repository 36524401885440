import Back from "./Back.vue";

export const backRoutes = [
  {
    path: "/back",
    component: Back,
    name: "back",
    meta: { title: "Прием возвратов" },
  },
];
