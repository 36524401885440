<template>
  <component :is="component" :key="$route.fullPath" />
</template>

<script>
import AdminShipmentsDetail from "./admin/ShipmentsDetail.vue";
import GiveDetail from "@/views/give/GiveDetail.vue";

export default {
  name: "ShipmentsDetail",
  components: {
    AdminShipmentsDetail,
    GiveDetail,
  },
  computed: {
    component() {
      let component;

      switch (this.$store.getters["user/role_id"]) {
        case 0:
        case 1:
          component = AdminShipmentsDetail;
          break;
        case 6:
          component = GiveDetail;
          break;
        default:
          component = "span";
      }
      return component;
    },
  },
};
</script>
