import apiV2Service from "@/api/V2/services/apiV2.service";

const store = {
  namespaced: true,
  actions: {
    index(context, data) {
      return apiV2Service.post('franch-return-acts', data)
    },
    show(context, returnActId) {
      return apiV2Service.get(`franch-return-acts/${returnActId}`)
    },
    viewOrCreate(context, data) {
      return apiV2Service.post(`franch-return-acts/viewOrCreate`, data)
    },
    getShopsWithReturnShipmentsOnPointUser() {
      return apiV2Service.get('franch-return-acts/getShopsWithReturnShipmentsOnPointUser');
    },
    scanShipmentForReturnAct(context, data) {
      return apiV2Service.post('franch-return-acts/scanShipmentForReturnAct', data);
    },
    finishAssemblyReturnAct(context, data) {
      return apiV2Service.post('franch-return-acts/finishAssemblyReturnAct', data);
    },
  },
};
export default store;
